/**
* East Coast Metal Distributors
*/

// ==============================================
// Variables
// ==============================================

$product-image-width: 650px;
$product-image-height: 650px;

// ==============================================
// Product Image Container
// ==============================================

.product-image-container {
    display: inline-block;
    max-width: 100%;
}

#s7viewer_container:not([mode=fullscreen]) {
    max-height: $product-image-height + 96px;
    max-width: $product-image-width;
    margin: 0 auto $space-xl;

    canvas {
        max-height: $product-image-height;
        max-width: $product-image-width;
    }

    .s7zoomview {
        max-height: $product-image-height;
        max-width: $product-image-width;

        div {
            max-height: $product-image-height;
            max-width: $product-image-width;
        }
    }
}
