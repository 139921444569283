/**
* East Coast Metal Distributors
*/



// ==============================================
// Block
// ==============================================

.block {
    margin-bottom: $space-xxl;
}

// ----------------------------------------------
// Modifier: Line

//.block--line {}


// ==============================================
// Block - Title
// ==============================================

.block-title {
    margin-bottom: $space-base;
}

.block-title strong {
    @include h3;
}

// ----------------------------------------------
// Modifier: Line

.block--line .block-title {
    border-bottom: 1px solid $c-border;
    padding-bottom: $space-s;
}


// ==============================================
// Block - Subtitle
// ==============================================

.block-subtitle {
    display: inline-block;
    @include label();
    font-size: $font-size-l;
    font-weight: $font-weight-normal;
    padding-bottom: $space-base;
}


// ==============================================
// Block - Content
// ==============================================

//.block-content {}
