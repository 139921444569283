//
//  Messages
//  _____________________________________________

// Helper function to easier get values related to specific type
@function message($_message-type, $_key) {
    $_map: $message-info-map;

    // find maps adequate to message type
    @if $_message-type == error {
        $_map: $message-error-map;
    }

    @if $_message-type == success {
        $_map: $message-success-map;
    }

    @if $_message-type == warning {
        $_map: $message-warning-map;
    }

    @if $_message-type == notice {
        $_map: $message-notice-map;
    }

    @return map-get($_map, $_key);
}

@mixin lib-message(
    $_message-type: info
) {
    @include _lib-message();
    @include _lib-message-style(
        $_message-color            : message($_message-type, color),
        $_message-background       : message($_message-type, background),
        $_message-link-color       : message($_message-type, link__color),
        $_message-link-color-hover : message($_message-type, link__color-hover),
        $_message-link-color-active: message($_message-type, link__color-active),
        $_message-border-color     : message($_message-type, border-color)
    );
}

@mixin _lib-message(
    $_message-padding: $message__padding,
    $_message-margin: $message__margin,
    $_message-border-radius: $message__border-radius
) {
    border-radius: $_message-border-radius;
    display: block;

    color       : $message__color;
    font-size   : $message__font-size;
    font-weight : $message__font-weight;
    font-style  : $message__font-style;
    line-height : $message__line-height;
    @include _lib-message-border($message__border-width, $message__border-style);

    transition: all .4s ease-in-out;
    max-height: auto;

    &.none {
        max-height: 0;
    }

    & > *:first-child {
        margin: $_message-margin;
        padding: $_message-padding;
        padding-left: $message-icon__inner-padding-left;
        display: block;
    }
}

@mixin _lib-message-style(
    $_message-background: $message-info__background,
    $_message-color: $message-info__color,
    $_message-link-color: $message-info-link__color,
    $_message-link-color-hover: $message-info-link__color-hover,
    $_message-link-color-active: $message-info-link__color-active,
    $_message-border-color: $message-info__border-color
) {
    background: $_message-background;
    border-color: $_message-border-color;
    color: $_message-color;

    a {
        color: $_message-link-color;

        &:hover {
            color: $_message-link-color-hover;
        }

        &:active {
            color: $_message-link-color-active;
        }
    }
}

@mixin _lib-message-border(
    $_message-border-width: $message__border-width,
    $_message-border-style: $message__border-style
) {
    @if $_message-border-width != 0 and $_message-border-width != inherit and $_message-border-style != inherit {
        border: $_message-border-width $_message-border-style;
    }
}

//  Messages with inner icon
@mixin lib-message-icon($_message-type: info) {
    @include lib-message($_message-type);
    @include _lib-message-icon-inner(
        message($_message-type, icon),
        message($_message-type, icon__color-inner),
        message($_message-type, icon__background),
        message($_message-type, icon__top),
        message($_message-type, icon__right),
        message($_message-type, icon__bottom),
        message($_message-type, icon__left)
    );
}

@mixin _lib-message-icon-inner(
    $_message-icon,
    $_message-icon-color,
    $_message-icon-background,
    $_message-icon-top,
    $_message-icon-left,
    $_message-icon-bottom,
    $_message-icon-right
) {
    position: relative;

    & > *:first-child::before {
        @include _lib-icon-font(
            $_icon-font-content        : $_message-icon,
            $_icon-font                : $icon-font,
            $_icon-font-size           : $message-icon__font-size,
            $_icon-font-line-height    : $message-icon__font-line-height,
            $_icon-font-color          : $_message-icon-color,
            $_icon-font-margin         : 0,
            $_icon-font-vertical-align : $icon-font__vertical-align
        );
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: $_message-icon-bottom;
        left: $_message-icon-left;
        right: $_message-icon-right;
        top: $_message-icon-top;
        width: $message-icon__lateral-width;
        background-color: $_message-icon-background;
        height: 100%;
        border-radius: 2px 0 0 2px;
        position: absolute;
        text-align: center;
    }

    animation: show-message 0.3s linear forwards;
}
