/**
* East Coast Metal Distributors
*/

// ==============================================
// Branch Selector
// ==============================================

.branchSelector-wrapper {
    padding: 10px 0;
    width: 100%;
    margin-top: 10px;
}


// ==============================================
// Branch Selector - Results
// ==============================================

.branchSelector-results {
    padding-left: 20px;
    list-style: none;
    counter-reset: item;
}

// ----------------------------------------------

.branchSelector-results .branch-distance-location .directions,
.branchSelector-results .actions-primary .secondary {
    display: none;
}

@media (min-width: $bp-screen-m) {

    .branchSelector-results {
        position: relative;
        overflow-y: scroll;
        max-height: 65vh;
        padding: 20px 20px 0 20px;
    }

}


// ==============================================
// Branch Selector - Item
// ==============================================

.branchSelector-item {
    padding: 0 0 15px 12px;
    counter-increment: item;
    margin-bottom: 25px;
    position: relative;

    &:last-child {
        border-bottom: 0;
    }

    &::before {
        content: counter(item);
        margin-right: 10px;
        color: $gray-600;
        width: 1.2em;
        float: left;
        margin-left: -30px;
    }

    &::after {
        content: '';
        background: $gray-100;
        height: 1px;
        width: calc(100% + 20px) ;
        display: block;
        position: absolute;
        bottom: 0;
        left: -20px;
    }
}

.branchSelector-item .actions-primary {
    margin: 10px 0;
}

// [todo] - Should relocate to "store-hour" component
.branchSelector-item .storeItem-hour {
    padding: 10px 0;
}

// [todo] - Should relocate to "store-hour" component
.branchSelector-item .storeItem-hour .open {
    color: $green-700;
}

// [todo] - Should relocate to "store-hour" component
.branchSelector-item .storeItem-hour .closed {
    color: $c-brand;
}

.branchSelector-item .storeItem-hour {
    @include lib-clearfix;
    display: inline-block;
    position: relative;

    &.active {
        overflow: visible;

        .storeHour-detail {
            display: block;
            padding: 0;
            float: left;
        }
    }
}

.branchSelector-item .storeItem-hour .storeHour-detail {
    display: none;
    position: static;
}

// [todo] - Should relocate to "store-hour" component
.branchSelector-item .storeHour-info {
    cursor: pointer;
    position: relative;
    color: $c-brand;

    @include lib-icon-font(
        $_icon-font-content: $icon-pointer-down,
        $_icon-font-size: 18px,
        $_icon-font-line-height: 18px,
        $_icon-font-text-hide: false,
        $_icon-font-position: after,
        $_icon-font-display: block,
        $_icon-font-color: $c-brand
    );

    &:after {
        float: left;
        margin-top: 2px;
    }

    &.active::after {
        content: $icon-pointer-up;
    }

    &:hover {
        text-decoration: underline;
    }

    span {
        float: left;
    }
}

.branchSelector-item .storeHour-info.closed::after,
.branchSelector-item .storeHour-info.closed.active,
.branchSelector-item .storeHour-info.closed:hover::after {
    color: $c-brand;
}


// ==============================================
// Branch Selector - Detail
// ==============================================

.branchSelector-detail {
    padding: 10px 0 10px 10px;
    max-width: 280px;
}

// ==============================================
// Branch Name
// ==============================================


.branch-name {
    cursor: pointer;
}
