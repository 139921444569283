/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Lead
// ==============================================

.lead {
    font-size: $font-size-l;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .lead {
        font-size: $font-size-xl;
    }

}
