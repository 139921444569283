/**
* East Coast Metal Distributors
*/


// ==============================================
// Vars
// ==============================================

 // When tables linearize for small screens
$bp-table-linearize: 700px !default;
$bp-table-linearize-max: 1332px !default;

$c-table-background: #FFF      !default;
$c-table-border:     $c-gray-lighter !default;

$c-table-hover: $gray-75 !default;

$c-table-zebra-light: #FFF    !default;
$c-table-zebra-dark:  #FBFBFB !default;


// ==============================================
// Table
// ==============================================

.table-wrapper {
    overflow-x: scroll;
}

.table {
    width: 100%;
    background: $c-table-background;
}

.table td,
.table th {
    padding: $space-s $space-xs;
    vertical-align: middle;
    white-space: normal;

    &.col--nowrap {
        white-space: nowrap;
    }
}

.table thead tr {
    border-bottom: 1px solid $c-table-border;
}

.table tbody tr:not(:last-child) {
    border-bottom: 1px solid $c-table-border;
}

.table:has(+ *) tbody tr:last-child {
    border-bottom: 1px solid $c-table-border;
}

.table tbody tr.loader {
    border: none;
}

.table th {
    white-space: nowrap;
}

.table .label--linearized {
    display: none;
}

.table .nowrap,
.simple-table .nowrap {
    white-space: nowrap;
}

.table tr.disabled td,
.table td.disabled {
    color: $c-gray-lighter;
}

.table-no-results__message {
    height: 50px;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.table-head-title--sort {
    @include lib-icon-font(
        $_icon-font-content: $icon-unfold,
        $_icon-font-size: 14px,
        $_icon-font-position: after,
        $_icon-font-display: block,
        $_icon-font-margin: 0 0 0 $space-xxs
    );
}

.table-head-title--sort-desc {
    @include lib-icon-font(
        $_icon-font-content: $icon-arrow-down-thin,
        $_icon-font-size: 14px,
        $_icon-font-position: after,
        $_icon-font-display: block,
        $_icon-font-margin: 0 0 0 $space-xxs
    );
}

.table-head-title--sort-asc {
    @include lib-icon-font(
        $_icon-font-content: $icon-arrow-up-thin,
        $_icon-font-size: 14px,
        $_icon-font-position: after,
        $_icon-font-display: block,
        $_icon-font-margin: 0 0 0 $space-xxs
    );
}

// Fix for checkboxes inside tables
// ----------------------------------------------

.table [type='checkbox'] + label {
    margin-left: 0;
}

// ----------------------------------------------

@media print {

    .table td,
    .table th {
        padding: $space-xxs;
    }

}


// ==============================================
// Modifier: Border
// ==============================================

.table--border {
    border: 1px solid $c-table-border;
}

.table--border th,
.table--border td {
    border: 1px solid $c-table-border;
}


// ==============================================
// Modifier: Compact
// ==============================================

.table--compact th,
.table--compact td {
    padding: 10px 8px;
}

// ==============================================
// Modifier: Clickable
// ==============================================

.table--clickable tbody tr {
    cursor: pointer;

    &:hover td {
        background: $c-lightestgray;
    }
}

// ==============================================
// Modifier: Clickable
// ==============================================

.table--no-bottom-border tbody tr:last-of-type {
    border-bottom: none;
}

// ==============================================
// Modifier: Zebra Dark
// ==============================================

.table--zebra-dark tbody tr:nth-child(odd) {
    background: $c-table-zebra-dark;
}

.table--zebra-dark tbody tr:nth-child(even) {
    background: $c-table-zebra-light;
}


// ==============================================
// Modifier: Zebra Light
// ==============================================

.table--zebra-light tbody tr:nth-child(odd) {
    background: $c-table-zebra-light;
}

.table--zebra-light tbody tr:nth-child(even) {
    background: $c-table-zebra-dark;
}

// ==============================================
// Table Rows
// ==============================================

tr[role="row"] {
    position: relative;
}

.col--name a {
    color: $c-darkgray;
}

.col-product-name {
    color: $c-darkgray;
    margin-bottom: $space-xxs;
    display: inline-block;
}

.col--image {
    width: 75px + $space-xs * 2;
}

.col--no-wrap {
    white-space: nowrap;
}

.col--price {
    @extend .col--no-wrap;

    .price-wrapper {
        display: flex;
        flex-direction: column;
    }

    .price, .old-price {
        padding: 0;
    }
}

table td {
    &.col--align-top {
        vertical-align: top;
    }

    &.col--icon {
        padding: 0 $space-xs;

        .material-icons {
            line-height: unset;
        }
    }
}

.table-action-menu {
    display: flex;
}

.table-action-menu__item:not(:first-child) {
    margin-left: $space-s;
}

.material-icons--table-small {
    font-size: $font-size-l !important;
    vertical-align: bottom;
}

// ==============================================
// Modifier: Hover
// ==============================================

@media (min-width: $bp-table-linearize) {
    .table--hover tbody tr:hover th,
    .table--hover tbody tr:hover td {
        background: $c-table-hover;
    }
}


// ==============================================
// Mixin: Linearize
// ==============================================

@mixin table-linearize() {
    border: 0;

    tr, th, td {
        display: block;
    }

    th, td {
        border: 0;
    }

    thead {
        display: none;
    }

    tbody tr {
        padding: $space-s 0;
    }

    tbody td {
        padding: 0;
        border: 0;
    }

    tfoot tr {
        border-bottom: 1px solid $c-table-border;
    }

    .label--linearized {
        display: inline;
    }

    .col--price {
        .price-wrapper {
            flex-direction: row;
        }

        .price, .old-price {
            padding: $space-xs;
        }
    }

    .table--select {
        td > [type="checkbox"] {
            margin-left: 4px;
            margin-right: 10px;
        }

        td:not(:first-child) {
            margin-left: 28px;
        }
    }

    .col {
        line-height: 1;
        word-break: keep-all;
    }

    .col--actions {
        margin: $space-base 0 $space-xs;
    }

    .col--no-label:not(:first-child),
    .col--no-linearized-label:not(:first-child) {
        margin-top: $space-base;
    }

    .col--no-linearized-label {
        .col-label {
            display: none;
        }
    }

    .col-product-name {
        margin-bottom: 0;
    }

    .col--actions {
        line-height: $space-l;
    }
}

.table--linearize-always {
    @include table-linearize();
}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-table-linearize - 1px) {
    .table--linearize {
        @include table-linearize();
    }
}
