/**
* East Coast Metal Distributors
*/


// ==============================================
// Payment Method
// ==============================================

.payment-method._active .account-credit {
    display: flex;

}


// ==============================================
// Form Purchase Order
// ==============================================

.form-purchase-order {
    margin-bottom: $space-base;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .form-purchase-order {
        float: left;
        margin-bottom: 0;
        width: 40%;
    }

}

// ==============================================
// Credit Balance List
// ==============================================

.credit-balance-list {
    display: flex;
}

.credit-balance-item {
    margin-right: 10rem; // [todo] don't rely on REM

    &:last-child {
        margin-right: 0;
    }
}

.credit-balance-label {
    font-size: 1.6rem; // [todo] don't rely on REM
    margin-bottom: 0;
}

.credit-balance-price {
    font-size: $font-size-xxl;
    font-weight: 600;

    &._negative {
        color: $c-danger;
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .credit-balance-list {
        flex-direction: column;
    }

    .credit-balance-item {
        margin-right: 0;
    }

}


// ==============================================
// Available Credit
// ==============================================

.account-credit {
    display: none;
}

.account-credit_item {
    &:first-child {
        border-right: 1px solid $c-border;
        padding-right: 16px;
        margin-right: 16px;
    }
}


// ==============================================
// Change Result
// ==============================================

.change-result {
    clear: both;
    padding-top: $space-l;
}

.change-result strong {
    padding-left: ($space-base * 0.5);
}


// ==============================================
// Checkout Agreements Block
// ==============================================

@media (min-width: $bp-screen-m) {

    .checkout-agreements-block {
        clear: both;
    }

}


// ==============================================
// Data Grid Cell Amount
// ==============================================

@media (max-width: $bp-screen-s - 1px) {

    .data-grid-cell-content {
        display: inline-block;
    }

}


// ==============================================
// Quotes Grid Toolbar
// ==============================================

@media (max-width: $bp-screen-s - 1px) {

    .company-credit-history-index .quotes-grid-toolbar .toolbar-amount {
        display: none;
    }

}
