/**
* East Coast Metal Distributors
*/


// ==============================================
// UI Datepicker
// ==============================================

.ui-datepicker {
    display: none;
    z-index: $z-index-10 !important;
}

.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
}

.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
    cursor: pointer;
}

.ui-datepicker .ui-datepicker-next-hover span, .ui-datepicker .ui-datepicker-prev-hover span {
    border-color: transparent $c-primary transparent transparent;
}

.ui-datepicker .ui-datepicker-prev {
    left: 2px;
}

.ui-datepicker .ui-datepicker-next {
    right: 2px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -5px;
    font-size: 0px;
    width: 0px;
    height: 0px;
    border: solid;
    border-width: 5px 8px 5px 0;
    border-color: transparent $c-primary transparent transparent;
}

.ui-datepicker .ui-datepicker-next span {
    margin-left: 0px;
    top: 50%;
    margin-top: -5px;
    border-width: 5px 0 5px 8px;
    border-color: transparent transparent transparent $c-primary;
}

.ui-datepicker table {
    width: 100%;
    font-size: .9em;
}

.ui-datepicker {
    width: auto;
    background: $c-white;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: $box-shadow-base;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-year,
.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
    width: 48%;
    font-weight: $font-weight-semibold;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
    margin-right: 4%;
}

.ui-datepicker .ui-datepicker-calendar {
    background: #ffffff;
    border: 1px solid $c-border-light;
    border-collapse: collapse;
}

.ui-datepicker .ui-datepicker-calendar th {
    background: $gray-100;
    border: 1px solid $c-border;
    padding: 4px;
    text-transform: uppercase;
}

.ui-datepicker td {
    padding: 0;
}

.ui-datepicker .ui-datepicker-calendar td {
    border: 1px solid $c-border-light;
}

.ui-datepicker td span,
.ui-datepicker td a {
    display: block;
    padding: 4px;
    text-align: right;
    text-decoration: none;
    color: $c-text;
    letter-spacing: 0.04rem;
}

.ui-datepicker .ui-datepicker-calendar td a {
    padding: 4px;
    display: block;
}

.ui-datepicker .ui-datepicker-calendar td .ui-state-hover:not(.ui-state-active) {
    background: $c-lightestblue;
}


//  Today
//  ---------------------------------------------

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today {
    background: $c-lightestblue;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today span {
    font-weight: $font-weight-semibold;
}


//  Active - selected day
//  ---------------------------------------------

.ui-datepicker .ui-datepicker-calendar .ui-state-active {
    background: $c-primary;
    color: $c-white;
}


//  Disabled
//  ---------------------------------------------

.ui-datepicker .ui-datepicker-calendar .ui-state-disabled {
    background: $c-background;
}

