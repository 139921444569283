// ==============================================
// Assistance
// ==============================================

.assistance {
    font-weight: $font-weight-normal;
}

.assistance__label {
    margin: 0
}

.assistance__link {
    display: table;
}
