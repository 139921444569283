/**
* East Coast Metal Distributors
*/


// ==============================================
// Totals
// ==============================================

.totals-table {
    letter-spacing: $letter-spacing;
}

// ==============================================
// Totals th
// ==============================================

.totals-table th {
    padding-right: $space-base;
    white-space: normal;
    padding-bottom: $space-xxxs;
}

// ==============================================
// Totals td
// ==============================================

.totals-table td {
     text-align: right;
    padding-bottom: $space-xxxs;
}

.totals-table .grand.totals {
    font-size: 16px;
}
