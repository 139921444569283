$fotorama-arw-size: 80px !default;
$fotorama-duration-time: 0.3s !default;
$fotorama-fullscreen-zoom-time: 0.3s !default;
$fotorama-inner-box-shadow: 3px !default;
$fotorama-spinner-size: 64px !default;
$fotorama-thumb-arrow: 30px !default;
$fotorama_close_button: 80px !default;
$size-fotorama-block: 90px !default;

$fotorama-focus-box-shadow: 0 0 3px 1px $c-primary !default;
