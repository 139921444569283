// ==============================================
// Product Line Group
// ==============================================

.product-lines {
    border: 1px solid #babab9;
    margin: $space-xl 0;
}

.product-lines__title {
    display: flex;
    flex-direction: column;
    font-weight: $font-weight-bold;
    padding: $space-s;
    margin: 0;

    &--green {
        color: $c-green-light;
    }
}

.product-lines__list-item--clickable:hover {
    background: $gray-50;
    cursor: pointer;
}

.product-lines__list--alternate {
    .info-products__list-item:nth-child(even) {
        background: $c-background;
    }
}

// ==============================================
// Product Line
// ==============================================

.product-lines__line {
    display: flex;
    padding: $space-s;
    justify-content: space-between;
    width: 100%;

    &--main {
        background: $c-gray-backgound;
        border: 1px solid $gray-400;
    }
}

.product-view .product-lines__line .assistance {
    padding: 0;
}

.product-lines__line-column {
    margin-left: $space-base;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-of-type {
        margin-left: 0;
    }

    &--qty {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    &--details {
        width: 100%;
        font-size: $font-size-s;
        flex-direction: column;
    }

    &--action {
        white-space: nowrap;
    }
}

.product-line__message {
    background: $gray-100;
    display: inline-block;
    padding: $space-xxxs $space-xxs;
    font-size: $font-size-s;
    width: fit-content;
}

.add-product-qty-label {
    margin-right: $space-s;
}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-s - 1px) {
    .product-lines__line {
        flex-wrap: wrap;
    }

    .product-lines__line-column {
        &--details {
            order: 0;
            margin: 0 0 $space-base 0;
            flex: 1 0 100%;
        }

        &--qty {
            order: 1;
        }

        &--action {
            order: 2;
            flex: 1 0 50%;
            align-items: flex-end;
        }
    }

    .product-lines--link .product-lines__line-column {
        &--thumbnail {
            order: 0;
        }

        &--action {
            order: 1;
        }

        &--details {
            order: 2;
        }
    }
}

.ahri-anchor {
    text-transform: uppercase;
    width: 100%;
    color: $c-white;
    background: $c-blue-light;
    font-weight: $font-weight-bold;
    text-align: center;
    padding: 10px;

    @include lib-icon-font(
        $_icon-font-content: $icon-arrow-dropdown,
        $_icon-font: $font-family-icon,
        $_icon-font-size: 18px,
        $_icon-font-line-height: 18px,
        $_icon-font-color: $c-white,
        $_icon-font-margin: 0,
        $_icon-font-vertical-align: top,
        $_icon-font-position: after
    );

    &:hover, &:active {
        color: $c-white;
        text-decoration: none;
    }
}
