/**
* Acumen - Magento Theme
* http://gravitydept.com/to/acumen
*
* @author    Brendan Falkowski
* @copyright Gravity Department. All rights reserved.
*/


// ==============================================
// Note
// ==============================================

.note {
    position: relative;
    margin-bottom: $margin-bottom;
    padding: $space-xs 10px;
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-background;
}

.note > *:last-child {
    margin-bottom: 0;
}


// ==============================================
// Modifier: Action
// ==============================================

.note--action {
    display: flex;
    align-items: center;
}

.note_body {
    flex: 1;
    margin-right: 2em;
}


// ==============================================
// Modifier: Danger
// ==============================================

.note--danger {
    border-color: $c-note-danger-border;
    background:   $c-note-danger-background;
}


// ==============================================
// Modifier: Small
// ==============================================

.note--s {
    margin-bottom: $space-base;
    padding: $space-xxs $space-xs;
    font-size: $font-size-xs;
}


// ==============================================
// Modifier: Warn
// ==============================================

.note--warn {
    border-color: $c-note-warn-border;
    background:   $c-note-warn-background;
}
