$categories-list-gutter: $space-l;
$categories-list-padding: $space-base;
$categories-list-border-color: $c-border-light;

.categories-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $gap;

    .category {
        display: flex;
        flex-direction: column;
        flex: 1 0 160px;
        flex-basis: 200px;
        background: $c-white;
        border: 1px solid $categories-list-border-color;
        margin: 10px 20px 10px 0px;
        padding: $categories-list-padding;
    }

    .category-title {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .category-image img {
        margin-bottom: $space-base
    }
}

.category-view {
    .category-description {
        margin: 0 10px;
    }
}

@media (min-width: $bp-screen-l) {
    .categories-list .category {
        flex-basis: 220px;
    }
}
