#ot-sdk-btn {
    border: none;
    -webkit-appearance: none;
    background: none;
    color: $c-white;
    font-size: $font-size-xs;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
