.homepage-top-content {
    display: flex;
    flex-wrap: nowrap;
    max-width: $bp-screen-l;
    margin: 0 auto;
}

.homepage-login {
    display: none;
}

@media (min-width: $bp-screen-m) {
    .homepage-login {
        display: block;
        flex: 0 0 315px;
        width: 315px;
        margin-left: $space-l;
    }

    .homepage-login_form {
        padding: $space-base;
        margin: -22px 0 0 0;
        background: $c-contrast-background;
    }

    .homepage-login_form .form-login {
        margin-bottom: 0;
    }

    .homepage-login_register {
        background: $gray-100;
        padding: $space-base;
    }

    .homepage-banner img {
        object-fit: cover;
        object-position: top left;
        height: 320px;
    }

    .homepage-login_form .block-title strong {
        color: $c-contrast;
    }

    .homepage-banner {
        flex: 1 1 auto;
    }
}