// ==============================================
// Product Select With Qty Input
// ==============================================

.product-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 143px;

    .MuiFormControlLabel-label {
        color: $c-blue;
        font-size: $font-size-s;
        font-weight: $font-weight-semibold;
    }

    .MuiSvgIcon-root {
        color: $c-blue;
    }
}

.product-select__qty {
    margin-right: 16px;
    background: $c-white;
}

.product-select__qty-label {
    margin-right: $space-xxs;
}

.product-select__selection {
    border: 1px solid $c-blue;
    padding-left: $space-s;
    flex-direction: row-reverse;
    transform: translate(11px);
    width: 100%;
    margin-right: 11px !important;
}

.product-select__selection--selected {
    padding: 1px 0;
    border: none;
    width: auto;
}

// ==============================================
// Modifier: Disabled
// ==============================================

.product-select--disabled {
    .MuiSvgIcon-root,
    .MuiFormControlLabel-label {
        color: $c-gray-light;
    }
}

.product-select__selection--disabled {
    border-color: $c-gray-light;
}
