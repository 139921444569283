/**
* East Coast Metal Distributors
*/


// ==============================================
// Select
// ==============================================

.select-select, // added to be able to use @extend
select.select {
    width: 100%;
    height: 38px;
    padding: 8px 20px 8px 8px;
    border: 1px solid $c-input-border-bottom;
    border-radius: 0;
    background: transparent;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23666464' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    background-origin: content-box;
    background-position: right -20px center;
    background-repeat: no-repeat;
    background-clip: padding-box;
    outline: 0;
    appearance: none;
    color: $c-text;
    vertical-align: baseline;
    transition: border ease-in-out 180ms;
    cursor: pointer;

    &:focus {
        border-bottom-color: $c-input-border-focus;
    }

    @include input-disabled;

    &:disabled {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23bebcbc' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    }

    &::-ms-expand {
        display: none;
    }
}

select option {
    color: $c-text;
}


// ==============================================
// Select ~ Label
// ==============================================
.control select ~ label {
    font-weight: $font-weight-semibold;
}

select ~ label {
    top: -12px;
    font-size: 13px;
}

select:focus ~ label {
    color: $c-label-focus;
}


// ==============================================
// Modifier: Size
// ==============================================

.select--s {
    max-width: $form-elem-width-s;
}

.select--xs {
    max-width: $form-elem-width-xs;
}

.select--xxs {
    max-width: $form-elem-width-xxs;
}


// ==============================================
// State: Validation Failed
// ==============================================

select.mage-error:focus ~ label {
    color: $c-input-focus-error;
}

select.mage-error,
select.mage-error:focus {
    border-color: $c-input-focus-error;
}


// ==============================================
// Context: .form--contrast
// ==============================================

.form--contrast select {
    color: $c-contrast-input-text;
    border-bottom-color: $c-contrast-input-border-bottom;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23e9e9e9' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");

    &:focus {
        border-bottom-color: $c-contrast-input-border-focus;
    }

    &:disabled {
        background-color: $gray-800;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23666464' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    }
}

.form--contrast select:focus ~ label {
    color: $c-contrast-label-focus;
}

// ==============================================
// Modifier: Material UI
// ==============================================

.select--mui {
    border-color: rgba(0, 0, 0, 0.23);
    color: rgba(0, 0, 0, 0.87);
    padding: 18px 32px 18px 14px;
    font-size: 16px;
    height: 56px;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;

    &:hover {
        border-color: rgba(0, 0, 0, 0.87);
    }

    &:focus {
        background-color: rgba(0, 0, 0, 0.05);
        border-color: rgba(0, 0, 0, 0.23) !important;
    }

    @include label-position(
        $label-top: 24px,
        $label-top-focus: -16px,
        $label-left: 10px,
        $label-font-size: 16px,
        $label-font-size-focus: 12px,
        $label-focus-left: 0,
        $label-font-weight: $font-weight-normal
    );
}
