.react_addproducts {
    .w-50 {
        float: left;
        width: 48%;
        margin-right: 1%;
        background: $c-gray-backgound;
    }

    .tabs_item {
        border: none !important;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .react_addproducts {
        .w-50 {
            width: 100%;
        }
    }
}
