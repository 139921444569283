/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Box Shadow
// ==============================================

.box-shadow {
    box-shadow: $box-shadow-base !important;
}


// ==============================================
// Box Shadow Inset
// ==============================================

.box-shadow-inset {
    box-shadow: $box-shadow-inset !important;
}
