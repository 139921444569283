// ==============================================
// List
// ==============================================


@media (min-width: $bp-table-linearize) {

    .order-status__table {
        .col--life_cycle {
            white-space: normal !important;
            min-width: 280px;
        }
    }

}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-m - 1px) {

    .order-status__table {
        .col--life_cycle {
            margin-top: $space-base;
        }

        .col--customer_order_pickup_status span {
            text-align: center;
            margin-top: 5px;
            display: inline-block;
            width: 100%;

            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }
    }

    .order-status__search .field-row {
        flex-direction: column;
    }
}


// ==============================================
// List
// ==============================================

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-m - 1px) {

    .order-status__view-table .col--image .label {
        display: none;
    }

}

// ==============================================
// Lifecycle
// ==============================================

.order-status-lifecycle {
    @include step-progress();
}
