/**
* East Coast Metal Distributors
*/

.package-select {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: 250px;

    &__package {
        flex: 1 0 calc(70% - #{$space-xs});
        margin-right: $space-xs;
    }

    &__qty {
        flex: 1 1 30%;
        max-width: 60px;

        input {
            text-align: center;
        }
    }

    &__details {
        flex: 1 0 100%;
        background: $gray-100;
        padding: $space-s;
        margin-top: $space-xs;
        font-size: 13px;
    }
}
