.__react_dropdownclick {
    width: auto;

    & > button {
        border-right: 0;
    }

    .dropdown-options {
        position: absolute;
        left: 0;
        top: 35px;
        z-index: 2;
        width: 100%;
        background: $c-white;
        border: 1px solid $c-gray-light;

        a {
            width: calc(100% - 20px);
            float: left;
            margin: 10px 10px 0 10px;
        }

        li > a {
            &:hover {
                text-decoration: underline;
            }
        }

        li:last-child a {
            margin-bottom: 20px;
        }
    }

    &.__react_dropdown_up {
        .dropdown-options {
            top: auto;
            bottom: 35px;
        }
    }

    .dropdown-trigger {
        padding: 7px;
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 22px,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-color-hover: initial
        );

        &::after {
            color: $c-blue;
        }

        &:hover:after {
            color: $c-white;
        }
    }

    .showing-menu {
        &.dropdown-trigger::after {
            content: $icon-up;
        }
    }

    &.__react_dropdown_up {
        .dropdown-trigger::after {
            content: $icon-up;
        }

        .showing-menu {
            &.dropdown-trigger::after {
                content: $icon-down;
            }
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .__react_dropdownclick {
        width: auto;
    }

    .order-templates__view-header {
        .top-line {
            padding-left: $space-base;
        }
    }
}
