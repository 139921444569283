/**
* East Coast Metal Distributors
*/


// ==============================================
// JS Tree
// ==============================================

@media (max-width: $bp-screen-m - 1px) {
    .jstree {
        overflow-x: auto;
    }
}

.jstree li a {
    position: relative;
    color: $c-text;
}

.jstree li a:hover {
    text-decoration: none;
}

.jstree-node {
    position: relative;
    line-height: 24px;
    margin: $space-xxs 0px $space-xxs 26px;
    white-space: nowrap;
}

//--------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .jstree-node {
        margin: $space-base 0px $space-base 26px;
    }
}


// ==============================================
// Indentation
// ==============================================

.jstree-node > a {
    margin-left: 2px;
}

.jstree-closed > a,
.jstree-open > a {
    margin-left: 28px;
}

.jstree > .jstree-container-ul > .jstree-node {
    margin-left: 0px;
}


// ==============================================
// Lines
// ==============================================

.jstree-node:not(.jstree-leaf):before {
    background: $c-border-light;
    content: '';
    height: calc(100% - 17px);
    left: 11px;
    top: 8px;
    position: absolute;
    width: 1px;

}

.jstree-node:after {
    background: $c-border-light;
    content: '';
    left: -14px;
    top: 14px;
    position: absolute;
    height: 1px;
    width: 14px;
}

.jstree-container-ul > .jstree-node:after {
    background: transparent;
}

.jstree-last > .jstree-children:last-child {
    &:before {
        background: $c-white;
        content: '';
        height: calc(100% - 20px);
        left: -15px;
        top: 15px;
        position: absolute;
        width: 1px;
    }
}

.jstree-leaf[aria-level="2"]:after {
    width: 40px;
}


//--------------------------------------------
// Icons

.jstree i {
    font-style: normal;
}

.jstree-icon.jstree-ocl {
    position: absolute;
    background: $c-white;
    cursor: pointer;
}

.jstree-open > .jstree-ocl {
    @include lib-icon-font(
                    $icon-pointer-down,
            $_icon-font-color: $c-icon-base,
            $_icon-font-color-hover: $c-icon-active,
            $_icon-font-color-active: $c-icon-active
    );
}

.jstree-closed > .jstree-ocl {
    @include lib-icon-font(
                    $icon-pointer-right,
            $_icon-font-color: $c-icon-base,
            $_icon-font-color-hover: $c-icon-active,
            $_icon-font-color-active: $c-icon-active
    );
}

.jstree-themeicon.icon-customer {
    @include lib-icon-font(
                    $icon-person,
            $_icon-font-size: 18px,
            $_icon-font-margin: 0px 6px 0px 0px,
            $_icon-font-color: $c-icon-base,
            $_icon-font-color-hover: $c-icon-active,
            $_icon-font-color-active: $c-icon-active
    );
}

.jstree-themeicon.icon-company {
    @include lib-icon-font(
                    $icon-group,
            $_icon-font-size: 18px,
            $_icon-font-margin: 0px 6px 0px 0px,
            $_icon-font-color: $c-icon-base,
            $_icon-font-color-hover: $c-icon-active,
            $_icon-font-color-active: $c-icon-active
    );
}

//--------------------------------------------
// Tooltip

.jstree .field-tooltip {
    right: -22px;
    top: -2px;
}

//--------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .jstree .field-tooltip {
        display: none;
    }
}

.jstree  .field-tooltip .field-tooltip-content {
    top: -12px;
    white-space: normal;
}


//---------------------------------------------
// Selection

.jstree-anchor {
    padding: 4px 8px;
}

.jstree-leaf[aria-level="2"] > .jstree-anchor {
    margin-left: 28px;
}

.jstree-clicked {
    background: $c-lightestblue;
}


//---------------------------------------------
// Checkbox

.jstree-checkbox-selection .jstree-clicked {
    background: transparent;
}

.jstree-checkbox {
    display: inline-block;
}

.jstree-anchor .jstree-checkbox {
    width: 18px;
    height: 18px;
    margin-left: 8px;

    &::before,
    &::after {
        content: '';
        position: absolute;
        transition:
                content .3s ease,
                background .3s ease,
                box-shadow .3s ease,
                transform .3s ease;
    }

    &::after {
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        border-radius: $radius-base;
        background: $c-white;
        box-shadow: inset 0 0 0 2px $c-border;
    }
}


.jstree-anchor.jstree-clicked .jstree-checkbox {
    &::before {
        animation: choice-ripple 1s none;
        transform: translate(-50%, -50%) scale(1);
    }

    &::after {
        content: $icon-checkmark;
        display: inline-block;
        background: $blue-600;
        box-shadow: none;
        color: $c-white;
        font-family: $font-family-icon;
        font-size: $font-size-xl;
        -moz-osx-font-smoothing: grayscale;
        line-height: 18px;
        vertical-align: top;
    }
}
