.fotorama-stretch {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.fotorama-grab-cursor {
    cursor: move;
    cursor: grab;
}

.fotorama-grabbing-cursor {
    cursor: move;
    cursor: grabbing;
}

.fotorama-gpu {
    transform: translateZ(0);
}

.fotorama-focus {
    outline: 0;
}

.fotorama-focus-overlay {
    &::after {
        @extend .fotorama-stretch;
        background-color: $c-primary;
        border-radius: inherit;
        content: '';
    }
}

.fotorama-transform-disabled {
    transform: none !important;
}

.fotorama-transition-for-slide {
    transition-duration: unset;
    transition-property: transform, width;
    transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
}

.fotorama-no-select {
    user-select: none;
}

.fotorama-select {
    user-select: text;
}

.fotorama-empty-bg {
    background: url(data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=);
}

.fotorama-auto-margin {
    margin: auto;
    padding: 0;
}

.fotorama-inline-block {
    @include fotorama-inline-block();
}

.fotorama-hidden {
    position: absolute;
    top: -99999px;
    left: -99999px;
    z-index: -$z-index-10;
}

.fotorama-visible {
    position: relative;
    left: auto;
    top: auto;
    opacity: 1;
    z-index: auto;
}

.fotorama-no-tap {
    -webkit-tap-highlight-color: black(0); // sass-lint:disable-line no-vendor-prefixes
}

.transitionDuration {
    transition-duration: 333ms;
}

.transitionDurationZero {
    transition-duration: unset;
}

.fotorama-sprite {
    @extend .fotorama-print-background;
    background-image: url('../images/gallery.png');
    background-repeat: no-repeat;
}

.fotorama-print-background {
    @media print {
        background: none !important;
    }
}

.fotorama__stage {
    max-height: 730px;

    .fotorama__fullscreen-icon {
        .fotorama__fullscreen & {
            background-image: none;

            @include lib-icon-font(
                $_icon-font-content: $icon-clear,
                $_icon-font-size: 40px,
                $_icon-font-line-height: 40px,
                $_icon-font-color: $c-darkgray,
                $_icon-font-position: after
            );
        }

        &:after {
            left: auto;
            position: relative;
            right: 15px;
            top: 27px;
        }

        &:before {
            content: 'Close';
            font-size: $font-size-xs;
            position: relative;
            top: 28px;
            right: 22px;
            text-decoration: underline;
        }

        &:focus {
            &:after {
                box-shadow: none;
                left: auto;
                position: relative;
                right: 15px;
                top: 28px;
            }
        }
    }
}

.fotorama__arr__arr {
    @include lib-icon-font(
        $_icon-font-content: $icon-pointer-left,
        $_icon-font-size: 40px,
        $_icon-font-line-height: 40px,
        $_icon-font-color: $c-darkgray
    );

    .fotorama__arr--prev &,
    .fotorama__arr--next & {
        background-image: none;

        &:before {
            left: 20px;
            position: relative;
        }
    }

    .fotorama__arr--prev & {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-pointer-left
        );

        .fotorama__fullscreen & {
            &:before {
                left: 30px;
                position: relative;
            }
        }
    }

    .fotorama__arr--next & {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-pointer-right
        );
    }
}

.fotorama__fullscreen {
    .fotorama__zoom-in,
    .fotorama__zoom-out {
        background-image: none;
        @include lib-icon-font(
            $_icon-font-content: $icon-minus,
            $_icon-font-size: 40px,
            $_icon-font-line-height: 40px,
            $_icon-font-color: $c-darkgray
        );

        &:before {
            left: 30px;
            position: relative;
        }
    }

    .fotorama__zoom-in {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-add
        );

        &:before {
            top: 30px;
        }
    }

    .fotorama__zoom-out {
        &:before {
            top: 10px;
        }
    }
}

@media (min-width: $bp-screen-s) {
    .fotorama__nav-wrap {
        .fotorama__fullscreen & {
            padding: 0 50px;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .fotorama__stage {
        max-height: 400px;

        .fotorama__fullscreen-icon {
            height: 40px;

            &:after {
                right: 0;
                top: 10px;
            }

            &:before {
                top: 10px;
                right: 0;
            }
        }
    }
}
