/**
* East Coast Metal Distributors
*/


// ==============================================
// Product Item
// ==============================================

.product-item {
    vertical-align: top;
    border: 1px solid $c-white;
    text-align: left;

    .input-container {
        cursor: pointer;

        label {
            position: relative;
            font-size: 12px;
            cursor: pointer;
        }

        label span {
            position: absolute;
            left: 25px;
            top: -4px;
        }

        input {
            cursor: pointer;
        }
    }
}

.centered-image {
    text-align: center;
    position: relative;
}

.product-item-photo {
    width: 100%;
}

.products-list .product-item-photo,
.products-grid .product-item-photo {
    height: 160px;
}

@media (min-width: $bp-screen-xs) {
    .products-list .product-item-photo,
    .products-grid .product-item-photo {
        height: 200px;
    }
}

@media (min-width: $bp-screen-l) {
    .products-list .product-item-photo,
    .products-grid .product-item-photo {
        height: 220px;
    }
}

.product-item-photo img {
    max-height: 100%;
    width: auto;
    height: auto;
    margin: 0 auto;
}

.product-item .price-box {
    margin: $space-xs 0;
}

.product-item .price-box--limit {
    visibility: hidden;

    .price-message {
        max-height: 19px;
        line-height: 1.6;
    }
}

.product-item .price-box .price-label {
    font-size: $font-size-xs;

    &::after {
        content: ':';
    }
}

.product-item .special-price,
.product-item .minimal-price {
    .price {
        font-weight: $font-weight-bold;
    }

    .price-wrapper {
        display: inline-block;
    }

    .price-including-tax + .price-excluding-tax {
        display: block;
    }
}

.product-item .special-price {
    display: block;
}

.product-item .old-price .price {
    font-weight: $font-weight-normal;
}

.product-item .regular-price .price-label {
    display: none;
}

.product-item .minimal-price .price-container {
    display: block;
}

.product-item .minimal-price-link {
    margin-top: 5px;
}

.product-item .minimal-price-link .price-label {
    color: $c-link;
}

.product-item .minimal-price-link .price {
    font-weight: $font-weight-normal;
}

.product-item .minimal-price-link,
.product-item .price-excluding-tax,
.product-item .price-including-tax {
    display: block;
    white-space: nowrap;
}

.product-item .price-from,
.product-item .price-to {
    margin: 0;
}

.product-item-actions .tocart {
    white-space: nowrap;
    width: 100%;
    transition: $transition-all;
}

.product-item .product-item-brand a,
.product-item a.tocompare {
    color: $c-text-muted;
}

.product-item-name {
    display: block;
    height: auto;
    hyphens: auto;
    word-wrap: break-word;
    margin-bottom: 0;
}

a.product-item-name {
    color: $c-text;

    &:hover {
        color: $c-text;
    }

    &:visited {
        color: $c-text;
    }

    &:active {
        color: $c-text;
    }
}

.product-item-info {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.product-item-details {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.product-item {
    .product-inventory {
        min-height: 92.5px;
        margin: $space-base 0;
        font-size: $font-size-xs;
        overflow: hidden;
        flex: 1;
        width: 100%;
    }
}


@media (min-width: $bp-screen-xs) {
    .product-item .product-inventory {
        font-size: $font-size-s;
    }
}

.product-item-actions .actions-secondary {
    margin-top: $space-s;
}

.product-item-actions .actions-secondary a {
    text-decoration: none;
}

.product-item-actions .actions-primary {
    width: 100%;
}

.product-item-actions .actions-primary--limit {
    visibility: hidden;
}

.product-item-actions .actions-primary .input-text.qty {
    text-align: center;
}

.product-item-actions .actions-primary .fieldset {
    display: flex;
    flex-wrap: nowrap;
}

.product-item-actions .actions-primary .fieldset > .field:not(.choice) > .control {
    float: none;
    width: 100%;
}

.product-item-actions .actions-primary .field {
    margin: 0;
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: 50px;
}

@media (max-width: $bp-screen-s - 1px) {

    .product-item-actions .actions-primary .field.qty {
        margin-bottom: 10px;
    }
}

.product-item-actions .actions-primary .control {
    margin: 0;
}

.product-item-actions .actions-primary .actions {
    flex-shrink: 1;
    flex-grow: 1;
    padding-left: 10px;
    margin: 0;
}
