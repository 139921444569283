/**
* East Coast Metal Distributors
*/


// ==============================================
// Row
// ==============================================

.row {
    display: flex;
    align-items: center;
}

// ----------------------------------------------
// Modifier: Width 600

.row--width-600 {
    max-width: 600px;
}


// ==============================================
// Row - Item
// ==============================================

//.row_item {}

// ----------------------------------------------
// Modifier: Grow

.row_item--grow {
    flex: 1;
}

// ----------------------------------------------
// Modifier: Pad

.row_item--pad {
    margin-right: 0.75em;
}
