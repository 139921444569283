/**
* East Coast Metal Distributors
*/


// ==============================================
// Vars
// ==============================================

$container-width: 1200px !default;

$container-max-width: $trim-l + $container-width + $trim-l !default;


// ==============================================
// Container
// ==============================================

.container {
    //max-width: $container-max-width;
    margin: 0 auto;
    padding: 0 $trim-base;

    &::after {
        @include clearfix;
    }
}

// ----------------------------------------------

@media (min-width: 900px) {

    .container {
        padding: 0 $trim-l;
    }

}


// ==============================================
// Modifier: Block
// ==============================================

.container--block {
    max-width: none;
}
