/**
* East Coast Metal Distributors
*/

// ==============================================
// Page
// ==============================================

.page-brands .page-main {
    background: $c-background;
}

.brands-view-index {
    .breadcrumbs {
        display: none;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .brands-view-index {
        .breadcrumbs {
            padding: 8px 16px 16px;
            display: block;
        }
        .brands-page-title-wrapper {
            margin-top: -9px;
            margin-left: -16px;
            margin-right: -15px;
            display: block;
            padding-bottom: 15px;
        }
    }
}