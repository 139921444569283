@mixin scrollbar() {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid $c-white;
        background-color: rgba(0, 0, 0, .5);
    }

    &::-webkit-scrollbar-track {
        background-color: $c-white;
        border-radius: 8px;
    }
}
