/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Masthead
// ==============================================

.masthead {
    margin-bottom: $gap;
    text-align: center;
}


// ==============================================
// Masthead - Heading
// ==============================================

.masthead_heading {
    margin-bottom: 0.4em;
    color: $c-text;
    font-size: $font-size-xxl;
    font-weight: bold;
}

// ----------------------------------------------

@media (min-width: 600px) {

    .masthead_heading {
        font-size: $font-size-xxxl;
    }

}


// ==============================================
// Masthead - Lead
// ==============================================

.masthead .lead {
    max-width: 35em;
    margin-right: auto;
    margin-left: auto;
}
