// ==============================================
// Chip
// ==============================================

.chip {
    display: inline-flex;
    align-items: center;
    height: $space-xxl;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    color: $c-white;
    line-height: $space-base * 2;
    border-radius: $space-base;
    background-color: $c-red-dark;
    padding: 0 $space-s;

    &--disabled {
        background: $c-gray-lighter;
    }

}

.chip__action {
    display: inline-flex;
    align-items: center;
    margin: 0 (-$space-s * 0.5) 0 ($space-s * 0.5);
    color: $c-white;
    opacity: 0.6;

    &:hover, &:active {
        color: $c-white;
        text-decoration: none;
        opacity: 0.8;
    }

    .chip--disabled &:hover {
        opacity: 0.6;
    }

    @include lib-icon-font(
        $_icon-font-content: $icon-cancel,
        $_icon-font-size: $font-size-xxl,
        $_icon-font-line-height: $space-base * 2,
        $_icon-font-text-hide: true,
        $_icon-font-position: after,
        $_icon-font-display: block,
        $_icon-font-color: $c-white
    );

    &:after {
        vertical-align: bottom;
    }
}

.chip__icon {
    cursor: pointer;
    font-size: $font-size-xs;
    line-height: 32px;
}

// ==============================================
// Chip group
// ==============================================

.chip-group {
    display: flex;
    flex-wrap: wrap;

    .chip {
        margin: ($space-xs * 0.5) $space-xs ($space-xs * 0.5) 0;
    }
}

// ==============================================
// Materialize.js chip custom styling
// ==============================================

.chip > i {
    margin: 0 (-$space-s * 0.5) 0 ($space-s * 0.5);
    color: $c-white;
    font-size: $font-size-xxl;
    opacity: 0.6;
    cursor: pointer;

    &:hover, &:active {
        opacity: 0.8;
    }
}
