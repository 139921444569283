//
//  Icons variables from core
//  _____________________________________________

$icon__position            : before !default;
$icon__text-hide           : false !default;
$icon__height              : 26px !default;
$icon__width               : 26px !default;
$icon__margin              : 0 !default;
$icon__vertical-align      : middle !default;

$icon-image__position-x    : 0 !default;
$icon-image__position-y    : 0 !default;

$icon-sprite__position-x   : 0 !default;
$icon-sprite__position-y   : 0 !default;
$icon-sprite__grid         : 26px !default;

$icon-font                 : $font-family-icon !default;
$icon-font__position       : $icon__position !default;
$icon-font__text-hide      : $icon__text-hide !default;
$icon-font__margin         : $icon__margin !default;
$icon-font__size           : inherit !default;
$icon-font__line-height    : $icon-font__size !default;
$icon-font__color          : inherit !default;
$icon-font__color-hover    : inherit !default;
$icon-font__color-active   : inherit !default;
$icon-font__vertical-align : $icon__vertical-align !default;
$icon-font__display        : inline-block !default;

$icon-calendar__font-size  : 40px !default;

//
// Variables changed from the default values before removing snowdog as parent theme
//____________________________________________________________________________________

$icon-font                  : $font-family-icon;
$icon-font__size            : 24px;
$icon-font__line-height     : 20px;

//
//  Icons
//  _____________________________________________
// Map available at https://github.com/google/material-design-icons/blob/master/iconfont/codepoints

$icon-add: '\e145';
$icon-arrow-down: '\e5db';
$icon-arrow-down-thin: '\e313';
$icon-arrow-dropdown: '\e5c5';
$icon-arrow-dropup: '\e5c7';
$icon-arrow-left-thin: '\e5c4';
$icon-arrow-right-thin: '\e5c8';
$icon-arrow-up: '\e5d8';
$icon-arrow-up-thin: '\e316';
$icon-bell: '\e7f4';
$icon-business: '\e0af';
$icon-edit: '\e254';
$icon-calendar: '\e878';
$icon-cart: '\e8cc';
$icon-checkmark: '\e5ca';
$icon-clear: '\e14c';
$icon-close: '\e5cd';
$icon-cloud-download: '\e2c0';
$icon-collapse: '\e5ce';
$icon-comment: '\e0b9';
$icon-comment-reflected: '\e0b9';
$icon-down: '\e313';
$icon-envelope: '\e0be';
$icon-error: '\e000';
$icon-expand: '\e145';
$icon-flag: '\e153';
$icon-grid: '\e8f0';
$icon-help: '\e887';
$icon-info: '\e88e';
$icon-location: '\e0c8';
$icon-menu-open: '\e9bd';
$icon-more: 'more_vert';
$icon-mylocation: '\e55c';
$icon-link: '\e157';
$icon-list: '\e8ef';
$icon-menu: '\e5d2';
$icon-dollar: '\e227';
$icon-next: '\e315';
$icon-phone: '\e0cd';
$icon-pointer-down: '\e313';
$icon-pointer-up: '\e316';
$icon-pointer-right: '\e315';
$icon-sort-by-alpha: '\e053';
$icon-pointer-left: '\e314';
$icon-present: '\e8f6';
$icon-prev: '\e314';
$icon-remove: '\e5cd';
$icon-search: '\e8b6';
$icon-settings: '\e8b8';
$icon-star: '\e838';
$icon-success: '\e86c';
$icon-swap: '\e8d5';
$icon-time: '\e192';
$icon-trash: '\e872';
$icon-up: '\e316';
$icon-update: '\e863';
$icon-warning: '\e002';
$icon-wishlist-empty: '\e03B';
$icon-minus: '\e15b';
$icon-filter: '\ef4f';
$icon-shield-approve: '\e8e8';
$icon-pro-points: '\ea23';
$icon-history: '\e889';
$icon-navigation-resources: '\e869';
$icon-unfold: '\e5d7';
$icon-sort: '\e164';
$icon-sort-alpha: '\e053';
$icon-verified: '\ef76';
$icon-cancel: '\e5c9';

//Baker Layout icons
$icon-store: '\e8D1';
$icon-shipping: '\e558';

//Customer Section icons
$icon-dashboard: '\e871';
$icon-quotes: '\e8B0';
$icon-quicklists: '\e03B';
$icon-orders: '\e558';
$icon-statements: '\e14D';
$icon-invoices: '\e870';
$icon-dollar: '\0024';
$icon-user: '\e7fd';
$icon-users: '\e8D3';
$icon-company: '\e0AF';
$icon-gear: '\e8b8';

// Magento_Company
$icon-circle-down: '\e909';
$icon-circle-up: '\e90a';
$icon-customer-lead: '\e908';
$icon-move-vertical: '\e90b';
$icon-organization: '\e906';
$icon-group:  'group';
$icon-person: 'person';

$icon-inventory-available: '\e86c';
$icon-inventory-unavailable: '\e5c9';


//Baker loading icon - use as background-image
$icon-loading: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzAuNCAzMC40Ij48c3R5bGU+LnN0MHtmaWxsOiNjODEwMmV9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNLTMzNS02MTMuOGgzLjNjMC02IDQuOS0xMC45IDEwLjktMTAuOSAzLjMgMCA2LjQgMS41IDguNSA0LjFsMi42LTIuMWMtMi43LTMuMy02LjctNS4zLTExLjEtNS4zLTcuOSAwLTE0LjIgNi4zLTE0LjIgMTQuMnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMzNiA2MjkpIi8+PHBhdGggY2xhc3M9InN0MCIgZD0iTS0zMDcuNy02MTguM2MuMS4zLS4yLjUtLjUuNGwtNi41LTEuNGMtLjMtLjEtLjMtLjMtLjEtLjRsNi41LTUuMmMuMi0uMi40LS4xLjQuMmwuMiA2LjR6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYgNjI5KSIvPjxnPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zNjYuNiA2NjUuOGgzLjNjMC02IDQuOS0xMC45IDEwLjktMTAuOSAzLjMgMCA2LjQgMS41IDguNSA0LjFsMi42LTIuMWMtMi43LTMuMy02LjctNS4zLTExLjEtNS4zLTcuOSAwLTE0LjIgNi4zLTE0LjIgMTQuMnoiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgMTk4IDM0MC41KSIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOTMuOSA2NjEuM2MuMS4zLS4yLjUtLjUuNGwtNi41LTEuNGMtLjMtLjEtLjMtLjMtLjEtLjRsNi41LTUuMmMuMi0uMi40LS4xLjQuMmwuMiA2LjR6IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTgwIDE5OCAzNDAuNSkiLz48L2c+PC9zdmc+);
$icon-loading--blue: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MiA1MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTIgNTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMzM4MGVmO30KPC9zdHlsZT4KPGc+Cgk8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzQwLjAwMDAwMCwgLTYyOS4wMDAwMDApIj4KCQk8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCA2MjkuMDAwMDAwKSI+CgkJCTxnPgoJCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTTQsMjZoNmMwLTExLDktMjAsMjAtMjBjNi4xLDAsMTEuOCwyLjgsMTUuNiw3LjVsNC43LTMuOEM0NS40LDMuNiwzOCwwLDMwLDBDMTUuNiwwLDQsMTEuNiw0LDI2eiIvPgoJCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTTU0LDE3LjhjMC4xLDAuNS0wLjQsMC45LTAuOSwwLjhsLTExLjktMi41Yy0wLjUtMC4xLTAuNi0wLjUtMC4yLTAuOGwxMS45LTkuNmMwLjQtMC4zLDAuOC0wLjIsMC44LDAuNAoJCQkJCUw1NCwxNy44eiIvPgoJCQk8L2c+CgoJCQkJPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIuMDAwMDAwLCAzOS4wMDAwMDApIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0zMi4wMDAwMDAsIC0zOS4wMDAwMDApIHRyYW5zbGF0ZSg0LjAwMDAwMCwgMjYuMDAwMDAwKSI+CgkJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNCwyNmg2YzAtMTEsOS0yMCwyMC0yMGM2LjEsMCwxMS44LDIuOCwxNS42LDcuNWw0LjctMy44QzQ1LjQsMy42LDM4LDAsMzAsMEMxNS42LDAsNCwxMS42LDQsMjZ6Ii8+CgkJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTQsMTcuOGMwLjEsMC41LTAuNCwwLjktMC45LDAuOGwtMTEuOS0yLjVjLTAuNS0wLjEtMC42LTAuNS0wLjItMC44bDExLjktOS42YzAuNC0wLjMsMC44LTAuMiwwLjgsMC40CgkJCQkJTDU0LDE3Ljh6Ii8+CgkJCTwvZz4KCQk8L2c+Cgk8L2c+CjwvZz4KPC9zdmc+Cg==);
$icon-loading--gray: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzAuNCAzMC40Ij48c3R5bGU+LnN0MHtmaWxsOiM2NjY0NjR9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNLTMzNS02MTMuOGgzLjNjMC02IDQuOS0xMC45IDEwLjktMTAuOSAzLjMgMCA2LjQgMS41IDguNSA0LjFsMi42LTIuMWMtMi43LTMuMy02LjctNS4zLTExLjEtNS4zLTcuOSAwLTE0LjIgNi4zLTE0LjIgMTQuMnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMzNiA2MjkpIi8+PHBhdGggY2xhc3M9InN0MCIgZD0iTS0zMDcuNy02MTguM2MuMS4zLS4yLjUtLjUuNGwtNi41LTEuNGMtLjMtLjEtLjMtLjMtLjEtLjRsNi41LTUuMmMuMi0uMi40LS4xLjQuMmwuMiA2LjR6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYgNjI5KSIvPjxnPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zNjYuNiA2NjUuOGgzLjNjMC02IDQuOS0xMC45IDEwLjktMTAuOSAzLjMgMCA2LjQgMS41IDguNSA0LjFsMi42LTIuMWMtMi43LTMuMy02LjctNS4zLTExLjEtNS4zLTcuOSAwLTE0LjIgNi4zLTE0LjIgMTQuMnoiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgMTk4IDM0MC41KSIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOTMuOSA2NjEuM2MuMS4zLS4yLjUtLjUuNGwtNi41LTEuNGMtLjMtLjEtLjMtLjMtLjEtLjRsNi41LTUuMmMuMi0uMi40LS4xLjQuMmwuMiA2LjR6IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTgwIDE5OCAzNDAuNSkiLz48L2c+PC9zdmc+);
$icon-double-arrow: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBmaWxsPSIjNDQ0IiBkPSJNMTEgN0g1bDMtNHpNNSA5aDZsLTMgNHoiLz48L3N2Zz4=);
$icon-z-a-sort: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIgMzIiPgogICAgPHBhdGggc3R5bGU9InRleHQtaW5kZW50OjA7dGV4dC1hbGlnbjpzdGFydDtsaW5lLWhlaWdodDpub3JtYWw7dGV4dC10cmFuc2Zvcm06bm9uZTtibG9jay1wcm9ncmVzc2lvbjp0YjstaW5rc2NhcGUtZm9udC1zcGVjaWZpY2F0aW9uOkJpdHN0cmVhbSBWZXJhIFNhbnMiIGQ9Ik0gNSA1IEwgNSA3IEwgMTAuNTYyNSA3IEwgNS4yODEyNSAxMi4yODEyNSBMIDUgMTIuNTkzNzUgTCA1IDEzIEwgNSAxNCBMIDUgMTUgTCA2IDE1IEwgMTMgMTUgTCAxMyAxMyBMIDcuNDM3NSAxMyBMIDEyLjcxODc1IDcuNzE4NzUgTCAxMyA3LjQwNjI1IEwgMTMgNyBMIDEzIDYgTCAxMyA1IEwgMTIgNSBMIDUgNSB6IE0gMjIgNSBMIDIyIDIzLjY4NzUgTCAxOS40MDYyNSAyMS4wOTM3NSBMIDE4IDIyLjUgTCAyMi4yODEyNSAyNi44MTI1IEwgMjMgMjcuNSBMIDIzLjcxODc1IDI2LjgxMjUgTCAyOCAyMi41IEwgMjYuNTkzNzUgMjEuMDkzNzUgTCAyNCAyMy42ODc1IEwgMjQgNSBMIDIyIDUgeiBNIDguMTg3NSAxNyBMIDcuOTY4NzUgMTcuNjU2MjUgTCA2LjAzMTI1IDIzIEwgNiAyMyBMIDYgMjMuMDYyNSBMIDUuMDYyNSAyNS42NTYyNSBMIDUgMjUuODEyNSBMIDUgMjYgTCA1IDI3IEwgNyAyNyBMIDcgMjYuMTU2MjUgTCA3LjQwNjI1IDI1IEwgMTAuNTkzNzUgMjUgTCAxMSAyNi4xNTYyNSBMIDExIDI3IEwgMTMgMjcgTCAxMyAyNiBMIDEzIDI1LjgxMjUgTCAxMi45Mzc1IDI1LjY1NjI1IEwgMTIgMjMuMDYyNSBMIDEyIDIzIEwgMTEuOTY4NzUgMjMgTCAxMC4wMzEyNSAxNy42NTYyNSBMIDkuODEyNSAxNyBMIDkuMDkzNzUgMTcgTCA4LjkwNjI1IDE3IEwgOC4xODc1IDE3IHogTSA5IDIwLjY1NjI1IEwgOS44NDM3NSAyMyBMIDguMTU2MjUgMjMgTCA5IDIwLjY1NjI1IHoiIG92ZXJmbG93PSJ2aXNpYmxlIiBmb250LWZhbWlseT0iQml0c3RyZWFtIFZlcmEgU2FucyIvPgo8L3N2Zz4K);
$icon-a-z-sort: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIgMzIiPgogICAgPHBhdGggc3R5bGU9InRleHQtaW5kZW50OjA7dGV4dC1hbGlnbjpzdGFydDtsaW5lLWhlaWdodDpub3JtYWw7dGV4dC10cmFuc2Zvcm06bm9uZTtibG9jay1wcm9ncmVzc2lvbjp0YjstaW5rc2NhcGUtZm9udC1zcGVjaWZpY2F0aW9uOkJpdHN0cmVhbSBWZXJhIFNhbnMiIGQ9Ik0gOC4xODc1IDUgTCA3Ljk2ODc1IDUuNjU2MjUgTCA2LjAzMTI1IDExIEwgNiAxMSBMIDYgMTEuMDYyNSBMIDUuMDYyNSAxMy42NTYyNSBMIDUgMTMuODEyNSBMIDUgMTQgTCA1IDE1IEwgNyAxNSBMIDcgMTQuMTU2MjUgTCA3LjQwNjI1IDEzIEwgMTAuNTkzNzUgMTMgTCAxMSAxNC4xNTYyNSBMIDExIDE1IEwgMTMgMTUgTCAxMyAxNCBMIDEzIDEzLjgxMjUgTCAxMi45Mzc1IDEzLjY1NjI1IEwgMTIgMTEuMDYyNSBMIDEyIDExIEwgMTEuOTY4NzUgMTEgTCAxMC4wMzEyNSA1LjY1NjI1IEwgOS44MTI1IDUgTCA5LjA5Mzc1IDUgTCA4LjkwNjI1IDUgTCA4LjE4NzUgNSB6IE0gMjIgNSBMIDIyIDIzLjY4NzUgTCAxOS40MDYyNSAyMS4wOTM3NSBMIDE4IDIyLjUgTCAyMi4yODEyNSAyNi44MTI1IEwgMjMgMjcuNSBMIDIzLjcxODc1IDI2LjgxMjUgTCAyOCAyMi41IEwgMjYuNTkzNzUgMjEuMDkzNzUgTCAyNCAyMy42ODc1IEwgMjQgNSBMIDIyIDUgeiBNIDkgOC42NTYyNSBMIDkuODQzNzUgMTEgTCA4LjE1NjI1IDExIEwgOSA4LjY1NjI1IHogTSA1IDE3IEwgNSAxOSBMIDEwLjU2MjUgMTkgTCA1LjI4MTI1IDI0LjI4MTI1IEwgNSAyNC41OTM3NSBMIDUgMjUgTCA1IDI2IEwgNSAyNyBMIDYgMjcgTCAxMyAyNyBMIDEzIDI1IEwgNy40Mzc1IDI1IEwgMTIuNzE4NzUgMTkuNzE4NzUgTCAxMyAxOS40MDYyNSBMIDEzIDE5IEwgMTMgMTggTCAxMyAxNyBMIDEyIDE3IEwgNSAxNyB6IiBvdmVyZmxvdz0idmlzaWJsZSIgZm9udC1mYW1pbHk9IkJpdHN0cmVhbSBWZXJhIFNhbnMiLz4KPC9zdmc+Cg==);

//Baker StockPro - use as background-image
$icon-qr-code: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTMsMTFoMnYySDNWMTEgTTExLDVoMnY0aC0yVjUgTTksMTFoNHY0aC0ydi0ySDlWMTEgTTE1LDExaDJ2Mmgydi0yaDJ2MmgtMnYyaDJ2NGgtMnYyaC0ydi0yaC00djJoLTJ2LTRoNHYtMgoJaDJ2LTJoLTJWMTEgTTE5LDE5di00aC0ydjRIMTkgTTE1LDNoNnY2aC02VjMgTTE3LDV2MmgyVjVIMTcgTTMsM2g2djZIM1YzIE01LDV2MmgyVjVINSBNMywxNWg2djZIM1YxNSBNNSwxN3YyaDJ2LTJINXoiLz4KPC9zdmc+Cg==);


// Forms
$icon-indeterminate: '\e909';
$icon-checkbox: '\e834';
$icon-checkbox-outline: '\e835';
$icon-checkbox-group: '\e7ef';
$icon-checkbox-pos: '\f17e';
