/**
* East Coast Metal Distributors
*/


// ==============================================
// Login page
// ==============================================

@media (min-width: $bp-screen-m) {

    .login-grid {
        display: flex;
    }

    .login-grid > .col-1 {
        width: 40%;
    }

    .login-grid > .col-2 {
        width: 60%;
        padding-left: $space-xl;
    }

    .col-1 .block-title {
        border: none;
        padding: 0;
        margin-bottom: 10px;
    }

}

// ==============================================
// Customer account request page
// ==============================================

@media (min-width: $bp-screen-m) {

    .content-cols {
        display: flex;
    }

    .content-cols--reverse {
        flex-direction: row-reverse;
    }

    .content-cols_col {
        flex: 1 1 50%;
        margin: 15px;
        max-width: 50%;
    }

    .customer-account-create fieldset:not(:first-child) {
        margin-top: $space-base;
    }

    .customer-account-create .column form .actions-toolbar {
        margin: 15px 0 0;
    }

}
