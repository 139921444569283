// ==============================================
// PRO Points
// ==============================================

.account-nav_item.pro_points {
    span {
        @include lib-icon-font(
            $_icon-font-content:      $icon-pro-points,
            $_icon-font-size:         $account-nav-icon-size,
            $_icon-font-line-height:  $account-nav-icon-size
        );
        &:before {
            content: none;
        }
    }

  .account-nav_link .account-nav_label {
    padding: 0;
  }

  border-bottom: none;
}

.pro_points-index-index .app-wrapper {
    height: auto;
}

.pro_points-index-index .height-wrapper {
    height: 100%;
}

.pro_essentials_rewards-index-index .app-wrapper {
    height: auto;
}

.pro_essentials_rewards-index-index .height-wrapper {
    height: 100%;
}

.pro-points__wrapper {
    display: flex;
    flex-direction: column;

    &.secondary {
        padding-top: 20px;
    }
}

.pro-points__wrapper:not(.pro-points__wrapper--with-past) {
    .pro-points__group {
        padding-top: 0;
    }

    .pro-points__group-title {
        display: none;
    }
}

.pro-points__group {
    margin: 0 (-$space-base) $space-base;
    padding: $space-base;

    &:not(:first-child) {
        background: $c-lightgray;
    }
}

.pro-points__group-title {
    margin: 0;
    font-weight: $font-weight-bold;
}

.pro-points__list {
    margin: 0 (-$space-xs);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min-content, 136px));

    &.no-mobile-grid {
        display: flex;
        flex-wrap: wrap;
    }
}

.amana-points__list {
    margin: 0 (-$space-xs);
}

.pro-points__item {
    margin: $space-s $space-s 0 $space-s;

    &.fixed-item {
        flex: 1 1 200px;
    }

    &.item-inline {
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
    }
}

.amana-points__item--label {
  color: $gray-660;
}

.amana-points__item--value {
    display: block;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
}

.amana-points__item--value_large {
    display: block;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
}

.pro-points__item--value {
    display: block;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
}

.pro-points__group-message {
    color: $c-darkgray;
    font-size: $font-size-xs;
    margin: $space-base 0 0;

    &:before {
        content: '*';
    }
}

.pro-points__action {
    flex: 1 1 100%;
}

// ----------------------------------------------
// Desktop View

@media (min-width: $bp-screen-m) {

    .pro-points__wrapper {
        flex-direction: row;
    }

    .pro-points__group,
    .pro-points__group:not(:first-child) {
        margin: 0 $space-base $space-base 0;
    }

    .pro-points__group:first-child {
        margin-left: -$space-base;
    }

    .pro-points__list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

}
.amanaloyalty-index-index {
    h1.page-title .title-valid{
        font-size: 20px;
    }
}