/**
* East Coast Metal Distributors
*/


// ==============================================
// Var
// ==============================================

$message-global-note__background           : $c-yellow;
$message-global-note__border-color         : $c-yellow;
$message-global-note__color                : $c-text;

$message-global-note-link__color           : $c-link;
$message-global-note-link__color-hover     : $c-link-hover;
$message-global-note-link__color-active    : $c-link-active;

$message-global-caution__background        : $c-red;
$message-global-caution__border-color      : none;
$message-global-caution__color             : $c-white;

$message-global-caution-link__color        : $c-link;
$message-global-caution-link__color-hover  : $c-link-hover;
$message-global-caution-link__color-active : $c-link-active;


// ==============================================
// Global Messages
// ==============================================

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $space-base;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}
