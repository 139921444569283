#stocking-list-typeahead {
    #autoSuggest {
        background-color: white;
        z-index: 999;
        position: absolute;
        width: 100%;
        padding: 0 !important;

        .list#products-collection {
            margin-left: 0;

            > div {
                border-bottom: 1px $gray-100;

                .autosuggest-product {
                    padding: 8px 48px 8px 16px;

                    .lazyload-wrapper {
                        margin-right: 10px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
    span.bg-white {
        display: none;
    }
}
