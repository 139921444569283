/**
* East Coast Metal Distributors
*/


// ==============================================
// Wishlist Menu
// ==============================================

.wishlist.split.button {
    @extend .abs-dropdown-items-new;
    @include lib-dropdown-split(
        $_options-selector                          : '.items',
        $_dropdown-split-button-border-radius-fix   : true,
        $_dropdown-split-list-item-padding          : 5px 5px 5px 23px,
        $_dropdown-split-toggle-icon-content        : $icon-down,
        $_dropdown-split-toggle-active-icon-content : $icon-up,
        $_dropdown-split-toggle-actions-padding     : false,
        $_icon-font-size                            : 22px,
        $_icon-font-color                           : $c-text-muted,
        $_icon-font-color-hover                     : $c-link,
        $_dropdown-split-list-pointer-position      : left,
        $_dropdown-split-list-min-width             : 200px
    );
    vertical-align: middle;
}

.wishlist.split.button .items {
    text-align: left;
}

.wishlist.split.button .items .item {
    &:last-child:hover {
        background: $dropdown-list-item__hover;
    }
}

.table-comparison .wishlist.split.button > .action.split,
.product-items .wishlist.split.button > .action.split {
    @include lib-icon-font(
        $_icon-font-content        : '',
        $_icon-font-size           : 29px,
        $_icon-font-color          : $c-text-muted,
        $_icon-font-color-hover    : $c-link,
        $_icon-font-text-hide      : true,
        $_icon-font-vertical-align : middle,
        $_icon-font-line-height    :  24px
    );
    @include lib-icon-font-symbol(
        $_icon-font-content: $icon-wishlist-empty
    );
}

.table-comparison .wishlist.split.button > .action.toggle,
.product-items .wishlist.split.button > .action.toggle {
    &::before {
        content: '';
        float: left;
        height: 15px;
        margin-top: 3px;
        border-left: 1px solid lighten($c-text-muted, 30%); // [todo] define new border var
    }
}

.product-info-main    .wishlist.split.button .action.toggle,
.block-bundle-summary .wishlist.split.button .action.toggle,
.product-add-form     .wishlist.split.button .action.toggle {
    padding: 0;
}

.cart.table-wrapper .wishlist.split.button {
    margin-bottom: $space-base;
}

.cart.table-wrapper .wishlist.split.button > .action.toggle {
    padding: $space-xxs $space-xs;
}


// ==============================================
// Wishlist . Window . Popup
// ==============================================

.wishlist.window.popup {
    @include lib-popup();

    &.active {
        display: block;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .wishlist.window.popup {
        top: 20%;
        right: auto;
        bottom: auto;
        left: 50%;
        width: 380px;
        margin-left: -((380px + ($popup__padding * 2)) * 0.5);
    }

}


// ==============================================
// Wishlist . Overlay
// ==============================================

.wishlist.overlay {
    @include lib-window-overlay();

    &.active {
        display: block;
    }
}


// ==============================================
// Wishlist . Dropdown
// ==============================================

.wishlist-dropdown {
    @include lib-dropdown(
        $_dropdown-list-item-padding : 5px 5px 5px 23px,
        $_dropdown-list-min-width    : 200px,
        $_icon-font-margin           : 0 0 0 5px,
        $_icon-font-vertical-align   : middle
    );
    @extend .abs-dropdown-items-new;
}


// ==============================================
// Block Wishlist Management
// ==============================================

.block-wishlist-management {
    position: relative;
}

.block-wishlist-management .wishlist-select .wishlist-name-label {
    @extend .abs-colon;
    font-weight: $font-weight-semibold;
}

.block-wishlist-management .wishlist-title {
    margin-bottom: $space-base;
}

.block-wishlist-management .wishlist-title strong {
    font-weight: $font-weight-light;
    margin-right: $space-base;
}

.block-wishlist-management .wishlist-notice {
    display: inline-block;
    margin-right: $space-l;
    color: $c-text-muted;
}

.block-wishlist-management .wishlist-toolbar-select,
.block-wishlist-management .wishlist-toolbar-actions {
    display: inline-block;
}

.block-wishlist-management .wishlist-toolbar-select {
    margin-right: $space-l;
}

.block-wishlist-management .wishlist-dropdown.move {
    margin-right: $space-l;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .block-wishlist-management {
        clear: both;
        margin-top: -21px;
    }

    .block-wishlist-management .wishlist-select {
        margin: 0 (-$layout__width-xs-indent) 20px;
    }

    .block-wishlist-management .wishlist-select .wishlist-name {
        @extend .abs-toggling-title-mobile;
    }

    .block-wishlist-management .wishlist-select-items {
        display: none;

        &.active {
            display: block;
            padding: 15px 0;
            border-bottom: 1px solid $c-border;
            background: $account-nav-background;
        }
    }

    .block-wishlist-management .wishlist-select-items .item {
        margin: 3px 0 0;

        &:first-child {
            margin-top: 0;
        }

        &.current {
            display: none;
        }
    }

    .block-wishlist-management .wishlist-select-items .item a {
        display: block;
        padding: $space-xs 18px;
        text-decoration: none;

        &:hover {
            background: $gray-800;
        }
    }

    .block-wishlist-management .wishlist-add.item .add {
        @extend .abs-icon-add-mobile;
    }

    .block-wishlist-management .wishlist-title strong {
        font-size: $font-size-xxxl;
    }

    .block-wishlist-management .wishlist-info {
        margin-bottom: $space-base;
    }

    .block-wishlist-management .wishlist-toolbar-select,
    .block-wishlist-management .wishlist-toolbar-actions {
        margin-bottom: $space-base;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .block-wishlist-management {
        @include lib-clearfix;
        margin-bottom: $space-l;
    }

    .block-wishlist-management .wishlist-select {
        display: table;
        width: 100%;
        margin-bottom: $space-base;
        border-bottom: 1px solid $c-secondary;
    }

    .block-wishlist-management .wishlist-select .wishlist-name {
        display: table-cell;
        width: 5%;
        margin-right: $space-base;
        padding: $space-xs $space-base $space-base;
        vertical-align: top;
        white-space: nowrap;
    }

    .block-wishlist-management .wishlist-select-items {
        display: table-cell;
        padding-right: 160px;
        vertical-align: top;
    }

    .block-wishlist-management .wishlist-select-items .item {
        display: inline-block;
        margin-right: $space-base;
        padding: $space-xs $space-base $space-base;

        &:last-child {
            margin-right: 0;
        }
    }

    .block-wishlist-management .wishlist-select-items .current {
        border-bottom: 3px solid $c-brand;
        font-weight: $font-weight-semibold;
    }

    .block-wishlist-management .wishlist-select .wishlist-name-current {
        display: none;
    }

    .block-wishlist-management .wishlist-add.item {
        position: absolute;
        right: 0;
        top: 0;
    }

    .block-wishlist-management .wishlist-title strong {
        font-size: $font-size-xxxxl;
    }

    .block-wishlist-management .wishlist-info {
        float: left;
    }

    .block-wishlist-management .wishlist-toolbar {
        float: right;
    }

}


// ==============================================
// Block Wishlist Search Form
// ==============================================

.block-wishlist-search-form .form-wishlist-search {
    margin-bottom: $space-xxl * 2;
    max-width: 500px;
}

.block-wishlist-search-form .form-wishlist-search.fieldset > .legend {
    @include lib-visually-hidden;
}


// ==============================================
// Block Wishlist Search Results
// ==============================================

.block-wishlist-search-results .toolbar-wishlist-results {
    text-align: center;
}


// ==============================================
// Block Wishlist Search
// ==============================================

// [todo] remove. should not need vertical margins
.block-wishlist-search .form-wishlist-search {
    margin: $form-field__vertical-indent 0 0;
}


// ==============================================
// Page Title
// ==============================================

.page-multiple-wishlist .page-title-wrapper .page-title {
    @include lib-visually-hidden;
}


// ==============================================
// Block Wishlist Info Items
// ==============================================

.block-wishlist-info-items .block-title {
    margin-bottom: $space-l;
    font-size: $font-size-xxxl;
}

.block-wishlist-info-items .block-title > strong {
    font-weight: $font-weight-light;
}

.block-wishlist-info-items .product-item {
    width: 100%;
}

.block-wishlist-info-items .product-item-photo {
    margin-left: $space-l;
}

.block-wishlist-info-items .product-item-info {
    width: auto;
}

.block-wishlist-info-items .product-item-info:hover .split {
    visibility: visible;
}

.block-wishlist-info-items .price-wrapper {
    display: block;
    margin: 0 0 $space-base;
}

.block-wishlist-info-items .split {
    clear: both;
    padding: $space-base 0;
}

.block-wishlist-info-items .action.towishlist {
    padding: $space-base 0;
}

.block-wishlist-info-items .input-text.qty {
    margin-right: $space-base;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .block-wishlist-info-items .product-item-photo {
        margin-left: 0;
    }

}


// ==============================================
// Toolbar Wishlist Results
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .toolbar-wishlist-results {
        @extend .abs-pager-toolbar-mobile;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .toolbar-wishlist-results {
        @extend .abs-pager-toolbar;
    }

}


// ==============================================
// Products Grid . Wishlist
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .products-grid.wishlist .product-item-checkbox {
        left: 20px;
        position: absolute;
        top: 20px;
    }

    .page-multiple-wishlist .products-grid.wishlist .product-item-photo {
        margin-left: $space-l;
    }

    .page-multiple-wishlist .products-grid.wishlist .product-item-name,
    .page-multiple-wishlist .products-grid.wishlist .product-item-description,
    .page-multiple-wishlist .products-grid.wishlist .product-item .price-box,
    .page-multiple-wishlist .products-grid.wishlist .product-item-tooltip {
        margin-left: 115px;
    }

    .products-grid.wishlist .wishlist-dropdown {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .products-grid.wishlist .product-item-checkbox {
        float: left;
    }

    .products-grid.wishlist .product-item-checkbox + .product-item-name {
        margin-left: $space-l;
    }

}
