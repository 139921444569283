/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Media
// ==============================================

.media {
    display: flex;
    align-items: flex-start;
}

.media_item {
    margin-right: $space-base;
}

.media_body {
    flex: 1;
}


// ==============================================
// Modifier: Center
// ==============================================

.media--center {
    align-items: center;
}


// ==============================================
// Modifier: Flip
// ==============================================

.media--flip {
    flex-direction: row-reverse;
}

.media--flip .media_item {
    margin-right: 0;
    margin-left: $space-base;
}
