/**
* East Coast Metal Distributors
*/


// ==============================================
// Page
// ==============================================

@media (min-width: $bp-screen-m) {

    .store-locator-index-index .column.main {
        padding-bottom: 0;
    }

    .store-locator-index-index footer .footer {
        margin-top: 0;
    }

}


// ==============================================
// Hours
// ==============================================

.branch-schedule {
    padding: 10px 0;

    .hours-link {
        position: relative;
        color: $c-action;
        cursor: pointer;

        @include lib-icon-font(
            $_icon-font-content: $icon-pointer-down,
            $_icon-font-size: 18px,
            $_icon-font-line-height: 18px,
            $_icon-font-text-hide: false,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-color: $c-action
        );

        &.arrow-down {
            @include lib-icon-font(
                $_icon-font-content: $icon-pointer-up,
                $_icon-font-size: 18px,
                $_icon-font-line-height: 18px,
                $_icon-font-text-hide: false,
                $_icon-font-position: after,
                $_icon-font-display: block,
                $_icon-font-color: $c-action
            );
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .weekday-day {
        overflow: visible;
    }

    .branch-schedule__item-day,
    .branch-schedule__item-hours {
        display: inline-block;
    }

    .branch-schedule__item-day {
        min-width: 85px;
    }

    .other-days {
        display: inline-block;
    }
}

// ==============================================
// Phone Numbers
// ==============================================

.branch-phones {
    display: inline-flex;
    flex-direction: column;
}
