/**
* East Coast Metal Distributors
*/

// ==============================================
// YearPicker
// ==============================================

.yearpicker {
    border: 0;
    border-radius: 0;
    background: transparent;
    outline: 0;
    appearance: none;
    color: $c-text;
    vertical-align: baseline;
    transition: border ease-in-out 180ms;
    border-bottom: 1px solid $c-input-border-bottom;
    display: block;
    position: relative;
    &:focus {
        border-bottom: 1px solid $c-input-border-focus;
        .yearpicker-label {
            color: $c-input-border-focus;
        }
    }
}

.yearpicker--disabled {
    background: $gray-50;
}

.yearpicker-input {
    padding: 10px 0;
    height: 44px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23666464' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");
    background-origin: content-box;
    background-position: right -5px center;
    background-repeat: no-repeat;
    background-clip: padding-box;
    margin-right: -5px;
    width: 100%;
}

.yearpicker-dropdown {
    position: absolute;
    z-index: 2;
    width: 100%;
    border-radius: 3px;
    box-shadow: 1px 1px 8px 0 rgba(0,0,0,0.2);
    background-color: white;
}

.yearpicker-header {
    display: flex;
    width: 100%;
    height: 2.5rem;
    border-bottom: 1px solid #e8e8e8;;
    align-items: center;
    justify-content: space-around;
    padding: 0 70px;
}

.yearpicker-prev, .yearpicker-next {
    cursor: pointer;
    font-size: 2rem;
}

.yearpicker-year {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5rem;
}

.yearpicker-items {
    list-style: none;
    padding: 1rem 0.5rem;
    flex: 0 0 33.3%;
    width: 100%;
    cursor: pointer;

    &:hover {
        color: $c-input-border-focus;
        background-color: #e8e8e8;
    }

    &.yearpicker-selected {
        color: $c-input-border-focus;
    }
}

.yearpicker-label {
    color: $c-label;
    font-family: $font-family-sans;
    font-size: $font-size-s;
    font-weight: $font-weight-semibold;
    font-style: normal;
    letter-spacing: $letter-spacing;
    margin-bottom: 0;
}
