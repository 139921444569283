/**
* East Coast Metal Distributors
*/


// ==============================================
// Block Search
// ==============================================

.block-search {
    display: flex;
    order: 5;
    flex-grow: 20;
    margin-bottom: 0;
    align-items: center;
}

.block-search .block-title {
    display: none;
}

.block-search .block-content {
    margin-bottom: 0;
    width: 100%;
}

.block-search .form.minisearch {
    position: relative;
    margin-bottom: 0;
}

.block-search .control {
    clear: both;
    border-top: 0;
    margin: 0;
    padding: 0;
}

.block-search .actions {
    position: absolute;
    right: 5px;
    top: 0px;
}

.block-search .nested {
    display: block;
    padding-top: 5px;
    position: absolute;
}

.block-search .field {
    margin: 0;
}

.block-search input {
    position: static;
    height: auto;
    margin: 0;
    padding-right: 35px;
    padding-left: 8px;
    border-radius: $radius-base;
    width: 100%;
    transition: 0.2s ease all;
    background: $c-white;

    @include placeholder();
}

@media (max-width: $bp-screen-m - 1px) {
    .block-search input:focus {
        border-bottom-color: $c-input-border-focus;
    }
}

.block-search .action.search {
    position: relative;
    display: inline-block;
    float: right;
    padding: 5px 8px;
    @include lib-button-icon(
        $_icon-font-content: $icon-search,
        $_icon-font-text-hide: true,
        $_icon-font-color: $gray-800
    );
    @include lib-button-reset();

    &::before {
        position: absolute;
        top: 5px;
        left: -30px;
    }

    &:focus::before {
        color: $gray-900;
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .block-search {
        position: absolute;
        top: 63px;
        left: 0;
        width: 100%;
        background: $c-header-background;
    }

    .block-search .block-content {
        margin-bottom: 0;
        padding: 5px 20px 10px;
    }

    .block-search input {
        padding-right: 30px;
        border: 0;
        border-radius: 0px;
        border-bottom: 1px solid $c-border;
        height: 38px;
    }

    .block-search .action.search {
        padding-top: 5px;
    }

    .block-search .control {
        padding: 0;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .block-search {
        z-index: 110; // [todo] z-index
        padding: 0 2%;
    }

    .block-search input {
        border: none;
        background: $c-white;

        &:focus {
            border-bottom: none;
        }
    }

}


// ==============================================
// Search Autocomplete
// ==============================================

.search-autocomplete {
    position: absolute;
    z-index: 100; // [todo] z-index
    overflow: hidden;
    box-shadow: 0 3px 3px black(0.15); // [todo] use default
}

.search-autocomplete ul {
    overflow: auto;
    max-height: 400px;
    border: 1px solid $c-border;
    background: $c-white;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .search-autocomplete ul {
        margin-top: -15px;
        margin-bottom: $space-base;
        border: 0;
        border-top: 1px solid $c-border;
        border-bottom: 1px solid $c-border;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .search-autocomplete {
        margin-top: 0;
    }

}


// ==============================================
// Typeahead Result
// ==============================================

.typeahead-result {
    margin: 0;
}

.typeahead-result a {
    padding: $space-xs $space-xxxl $space-xs $space-base;
    display: block;
    text-decoration: none;
    color: $c-text;
    display: flex;
    align-items: center;

    &:hover,
    &.ui-state-focus {
        background: $blue-50;
        color: $c-text;
        text-decoration: none;
    }
}

.typeahead-result_category a {
    padding-left: $space-xxl;
    color: $c-link;
}

.typeahead-result_product {
    border-top: 1px solid $c-border-light;
}

.typeahead-result_image {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

.typeahead-result_actions {
    float: right;
    margin-top: -42px;
    margin-right: 16px;
}

.typeahead-result_actions .button.tocart {
    width: 110px;
}

.watsco_search_wrapper {
    padding: 15px;
    h5 {
        color: $c-blue;
        border-bottom: 1px solid $c-gray-backgound;
        padding-bottom: 10px;
        margin: 0 0 5px;
    }
}

.watsco_search_products {
    margin-top: 20px;
    h5 {
        border-bottom: none;
    }
}

@media (min-width: $bp-screen-l) {
    .watsco_search_wrapper {
        display: flex;
    }
    .watsco_search_suggestions {
        width: 35%;
        margin-right: 30px;
    }
    .watsco_search_products {
        width: 65%;
        margin-top: 0;
    }
}

// ==============================================
// Search . Summary
// ==============================================

// [todo] where is this used?
.search.summary {
    margin-bottom: $space-base;
}
