/**
* East Coast Metal Distributors
*/

@mixin lib-data-accordion(
$_title-selector: '.item.title',
$_switch-selector: '.switch',
$_content-selector: '.item.content',

$_accordion-control-font-family: $accordion-control__font-family,
$_accordion-control-font-size: $accordion-control__font-size,
$_accordion-control-font-style: $accordion-control__font-style,
$_accordion-control-font-weight: $accordion-control__font-weight,
$_accordion-control-line-height: $accordion-control__line-height,

$_accordion-control-border-top: $accordion-control__border-top,
$_accordion-control-border-right: $accordion-control__border-right,
$_accordion-control-border-bottom: $accordion-control__border-bottom,
$_accordion-control-border-left: $accordion-control__border-left,

$_accordion-control-background-color: $accordion-control__background-color,
$_accordion-control-color: $accordion-control__color,
$_accordion-control-text-decoration: $accordion-control__text-decoration,

$_accordion-control-color-visited: $accordion-control__visited__color,
$_accordion-control-text-decoration-visited: $accordion-control__visited__text-decoration,

$_accordion-control-background-color-hover: $accordion-control__hover__background-color,
$_accordion-control-color-hover: $accordion-control__hover__color,
$_accordion-control-text-decoration-hover: $accordion-control__hover__text-decoration,

$_accordion-control-background-color-active: $accordion-control__active__background-color,
$_accordion-control-color-active: $accordion-control__active__color,
$_accordion-control-text-decoration-active: $accordion-control__active__text-decoration,

$_accordion-control-height: $accordion-control__height,
$_accordion-control-margin-bottom: $accordion-control__margin-bottom,

$_accordion-control-padding-top: $accordion-control__padding-top,
$_accordion-control-padding-right: $accordion-control__padding-right,
$_accordion-control-padding-bottom: $accordion-control__padding-bottom,
$_accordion-control-padding-left: $accordion-control__padding-left,

$_accordion-content-background-color: $accordion-content__background-color,
$_accordion-content-border: $accordion-content__border,

$_accordion-content-margin: $accordion-content__margin,
$_accordion-content-padding: $accordion-content__padding
) {
    @include lib-data-accordion__base(
        $_title-selector: $_title-selector,
        $_switch-selector: $_switch-selector,
        $_content-selector: $_content-selector
    );
    @if $_accordion-control-margin-bottom == 0 {
        > #{$_title-selector} {
            margin: 0;
        }
    }

    @if $_accordion-control-margin-bottom != 0 {
        > #{$_title-selector} {
            margin: 0 0 $_accordion-control-margin-bottom;
        }
    }

    > #{$_title-selector} {
        > #{$_switch-selector} {
            background: $_accordion-control-background-color;
            border-bottom: $_accordion-control-border-bottom;
            border-left: $_accordion-control-border-left;
            border-right: $_accordion-control-border-right;
            border-top: $_accordion-control-border-top;
            height: $_accordion-control-height;
            padding: $_accordion-control-padding-top $_accordion-control-padding-right $_accordion-control-padding-bottom $_accordion-control-padding-left;
            color: $_accordion-control-color;
            font-family: $_accordion-control-font-family;
            font-size: $_accordion-control-font-size;
            font-style: $_accordion-control-font-style;
            font-weight: $_accordion-control-font-weight;
            line-height: $_accordion-control-line-height;
        }

        &:not(.disabled) > #{$_switch-selector}:focus,
        &:not(.disabled) > #{$_switch-selector}:hover {
            background: $_accordion-control-background-color-hover;
        }

        &:not(.disabled) > #{$_switch-selector}:active,
        &.active > #{$_switch-selector},
        &.active > #{$_switch-selector}:focus,
        &.active > #{$_switch-selector}:hover {
            background: $_accordion-control-background-color-active;
            color: $_accordion-control-color-active;
            text-decoration: $_accordion-control-text-decoration-active;
            padding-bottom: $_accordion-control-padding-bottom;
        }
    }

    > #{$_content-selector} {
        background: $_accordion-content-background-color;
        border: $_accordion-content-border;
        margin: $_accordion-content-margin;
        padding: $_accordion-content-padding;
    }
}

@mixin lib-data-accordion__base(
    $_title-selector : '.item.title',
    $_switch-selector : '.switch',
    $_content-selector : '.item.content'
) {
    margin: 0;
    padding: 0;

    > #{$_title-selector} {
        box-sizing: border-box;
        float: none;
        width: 100%;

        > #{$_switch-selector} {
            display: block;
        }
    }

    > #{$_content-selector} {
        @include lib-clearfix();
        box-sizing: border-box;
        display: block;
        float: none;
        margin: 0;

        &.active {
            display: block;
        }
    }
}
