// ==============================================
// Summary Block Discounts
// ==============================================

.discount-info.totals .title {
    cursor: pointer;

    @include lib-icon-font(
        $_icon-font-content: $icon-add,
        $_icon-font-size: 15px,
        $_icon-font-position: after,
        $_icon-font-display: block
    );

    &.-collapsed {
        @include lib-icon-font(
            $_icon-font-content: $icon-minus,
            $_icon-font-size: 15px,
            $_icon-font-position: after,
            $_icon-font-display: block
        );
    }
}

.opc-sidebar .total-rules .mark {
    padding: 0;
}
