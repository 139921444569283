/**
* East Coast Metal Distributors
*/


// ==============================================
// Action Group
// ==============================================

.action-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -0.8rem;
    margin-left: -0.8rem;
}

.action-list {
    @extend .action-group;
    justify-content: flex-start;
    flex-direction: row-reverse;
}

.action-group--no-space {
    margin-top: -2rem;
}

.action-list__item {
    display: grid;
    gap: 0 0.8rem;
    grid-auto-columns: auto;
    grid-auto-flow: column;

    &--sticky-wrapper .action-list__item {
        width: 100%;
    }
}

// ----------------------------------------------
// Large Desktop

@media (min-width: $bp-screen-l) {

    .content__column-side-wrapper {
        .action-group {
            flex-direction: column;
        }
    }

    .action-list__item {
        width: calc(100% - 0.8rem);
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
    }

}

@media (max-width: $bp-screen-l - 1px) and (min-width: $bp-screen-m) {
    .panel_toolbar {
        .action-group .button {
            height: auto;
            padding: 8px 16px;
            font-size: $font-size-base;
        }
    }
}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-m - 1px) {

    .action-list__item--sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100% !important;
        margin: 0 !important;
        padding: 0.8rem;
        box-shadow: $box-shadow-base;
        transition: transform 0.2s linear;
        z-index: 100;

        &:before {
            content: '';
            background: $c-white;
            display: block;
            position: absolute;
            width: 100%;
            box-sizing: content-box;
            height: 100%;
            left: 0;
        }
    }
}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-m - 1px) {

    .action-list {
        flex-direction: column;
        justify-content: center;
    }

    .action-list__item {
        grid-auto-flow: row;
        width: calc(100% - 0.8rem);
        gap: 0.8rem 0;
    }
}

// ----------------------------------------------
// Modifier: Center

.action-group--center {
    justify-content: center;
}

// ----------------------------------------------
// Modifier: Right

.action-group--right {
    justify-content: flex-end;
}


// ==============================================
// Action Group - Child
// ==============================================

.action-group > * {
    margin-top: 0.8rem;
    margin-left: 0.8rem;
}


// ==============================================
// Action Group - Label
// ==============================================

.button-group_label {
    white-space: nowrap;
}

.setDefaultShippingAddress_button {
    margin-top: 0.8rem;
    margin-left: 3.8rem;
    margin-bottom: 0.8rem;
}
