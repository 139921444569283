/**
* East Coast Metal Distributors
*/

// ==============================================
// Button Group
// ==============================================

.button-group .button {
    margin: 0;
    background: $c-white;
    color: $c-primary;
    box-shadow: none;
    border: 1px solid $c-border;
    border-radius: 0;
    margin-right: -5px;

    &:first-child {
        border-radius: $radius-base 0 0 $radius-base;
    }

    &:last-child {
        border-radius: 0 $radius-base $radius-base 0;
    }
}

.button-group .button--selected {
    background: $c-primary;
    border-color: $c-primary;
    color: $c-white;
}
