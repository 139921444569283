//
//  General
//  _____________________________________________

body {
    position: relative;
}

.navigation-wrapper {
    @mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
        // For Google Chrome
        &::-webkit-scrollbar {
            width: $size;
            height: $size;
        }

        &::-webkit-scrollbar-thumb {
            background: $foreground-color;
        }

        &::-webkit-scrollbar-track {
            background: $background-color;
        }

        // For Internet Explorer
        & {
            scrollbar-face-color: $foreground-color;
            scrollbar-track-color: $background-color;
        }
    }

    .navigation {

        .the-dark-side {
            width: 50px;
            background: $c-black;
            height: 100px;
            position: fixed;
            right: -200px;
            top: 0;

            .close-dark {
                @include lib-icon-font(
                        $icon-close,
                    $_icon-font-size: 30px,
                    $_icon-font-line-height: 30px,
                    $_icon-font-color: $c-white,
                    $_icon-font-color-hover: $c-white,
                    $_icon-font-text-hide: false
                );

                padding-left: 10px;
                padding-top: 10px;
            }
        }

        ul.nav-menu,
        .nav-topmenu {
            background: $c-white;
            top: 0;
            left: 0;
            right: 0;
            display: block;
            overflow: hidden;
            overflow-y: scroll;

            &.menu-scroll {
                @include scrollbars(7px, $c-gray, $c-lightgray);
            }

            li,
            .topmenu-item {
                border-bottom: 1px solid $gray-400;
                position: relative;

                &.parent {

                    > a {
                        @include lib-icon-font(
                            $_icon-font-content: $icon-pointer-right,
                            $_icon-font-size: 30px,
                            $_icon-font-line-height: 30px,
                            $_icon-font-text-hide: false,
                            $_icon-font-position: before,
                            $_icon-font-display: block,
                            $_icon-font-color: $c-black
                        );

                        &:before {
                            position: absolute;
                            right: 15px;
                            top: 10px;
                        }
                    }

                    ul {
                        display: none;
                    }

                    &:before {
                        position: absolute;
                        right: 65px;
                        top: 10px;
                    }
                }

                &.menu-opened-back {
                    margin-top: 30px;
                    cursor: pointer;

                    @include lib-icon-font(
                        $_icon-font-content: $icon-pointer-left,
                        $_icon-font-size: 30px,
                        $_icon-font-line-height: 30px,
                        $_icon-font-text-hide: false,
                        $_icon-font-position: before,
                        $_icon-font-display: block,
                        $_icon-font-color: $c-black
                    );

                    > a {
                        &:before {
                            content: none;
                        }

                        span {
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }

                    &:before {
                        content: 'Back';
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMTUuNDEgMTYuNTlMMTAuODMgMTJsNC41OC00LjU5TDE0IDZsLTYgNiA2IDYgMS40MS0xLjQxeiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMFYweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==");
                        background-repeat: no-repeat;
                        background-position: 8px 3px;
                        font-weight: bold;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: -22px;
                        font-family: inherit;
                        width: 150px;
                        font-size: 14px;
                        text-align: left;
                        padding-left: 35px;
                        cursor: pointer;
                        height: 60px;
                    }
                }
            }

            a,
            span {
                padding: 7px;
                display: block;
                color: inherit;
                text-decoration: none;
            }

            span:hover {
                text-decoration: underline;
            }
        }

        ul.nav-menu.submenu {
            display: none;
        }

        .side-links {
            position: absolute;
            left: 110px;
            top: 8px;
            z-index: 2;
            width: 100%;

            ul li {
                display: inline;
                padding-right: 25px;

                a:hover {
                    text-decoration: none;
                }
            }

        }
    }
}

//
//  Desktop
//  _____________________________________________

@media (min-width: $bp-screen-m) {
    .navigation {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 7;

        &.desktop-menu {
            flex: 1 1 100%;
            position: relative;

            .nav-menu-container {
                position: absolute;
                width: 440px !important;
                color: $c-white;
                background: none;
                left: -20px;
                top: 0;
                overflow: hidden;

                .nav-menu > li {
                    border-bottom: none;

                    a.level-top {
                        width: 440px;
                        display: block !important;
                        background: $c-brand;
                        z-index: 2;
                        height: 44px;
                        position: absolute;

                        span {
                            text-transform: none;
                            font-weight: normal;
                            padding-top: 1px;
                            padding-left: 12px;
                        }

                        &:after {
                            display: none !important;
                        }

                        &:before {
                            color: $c-white;
                            content: $icon-pointer-down;
                            font-size: 20px;
                            right: 330px;
                            top: 5px;
                        }
                    }

                    a.level-top span:hover {
                        text-decoration: none !important;
                    }

                    &.menu-opened-back {

                        a.level-top {
                            position: absolute;
                            top: -30px;
                            left: 0;
                            text-transform: none;
                            border-bottom: none !important;
                        }
                    }

                    &.desktop-opened {
                        a.level-top {
                            &:before {
                                color: $c-white;
                                content: $icon-pointer-up;
                            }
                        }
                    }

                    &:before {
                        display: none !important;
                    }
                }

                li {

                    &.menu-opened-back {
                        > a {
                            border-bottom: 1px solid $gray-400;
                        }
                    }

                    &.parent ul {
                        position: absolute;
                        background: $c-white;
                        color: $c-black;
                        width: 440px;
                        top: 50px;

                        &.menu-scroll {
                            overflow-y: scroll;
                            overflow-x: hidden;
                        }
                    }

                    &.menu-opened-back {
                        ul.level0 {
                            top: 24px;
                        }
                    }
                }
            }
        }

        .the-dark-side {
            display: none;
        }
    }

    .under-header-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $c-black;
        filter: alpha(opacity=50);
        -moz-opacity: 0.5;
        -khtml-opacity: 0.5;
        opacity: 0.5;
        z-index: 10;
    }

}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {
    .navigation-wrapper {
        .navigation {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            z-index: 1000;
        }

        .side-links {
            display: none;
        }
    }

    .menu-opened-back {
        padding-top: 40px;
        width: calc( 100% - 50px );
        top: 0;
        z-index: 99999999;
        margin-top: 0 !important;
        position: absolute !important;
        background: $c-white;

        &::before {
            top: 10px !important;
        }

        ul {
            display: block;
            overflow: auto;
            height: calc( 100vh - 88px);
        }
    }
}
