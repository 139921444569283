/*
* East Coast Metal Distributors
*/

$pager-items_line-height: 38px;

.company-role-index {
    .toolbar {
        display: flex;
        justify-content: flex-end;

        .pager {
            order: 1;
        }

        .pages {
            order: 3;
        }

        .pages .pages-items {
            line-height: $pager-items_line-height;
            margin-left: $space-base;
        }

        .toolbar-amount {
            line-height: $pager-items_line-height;
            margin: 0 0 $space-base;
        }

        .toolbar-limiter {
            order: 2;
        }
    }

    .data-grid-wrap tr.disabled td {
        color: $c-darkgray;
    }

    .company-users-menu {
        @include dropdown-button();

        .dropdown-options {
            width: 100px;
        }
    }
}

// ==============================================
// Company Users Grid
// ==============================================

.company-users-index {
    td.data-grid-actions-cell.col.actions {
        .action {
            display: inline-block;
        }

        .action:first-child {
            margin-right: $space-l;
        }
    }

    .company-users-menu {
        @include dropdown-button();

        .dropdown-options {
            width: 100px;
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .company-role-index {
        .admin__data-grid-outer-wrap {
            min-height: auto;
        }

        .company-users-menu {
            .panel_float {
                height: auto;
                top: unset;
                bottom: -20px;
            }
        }

        .toolbar {
            flex-wrap: wrap;

            .pager,
            .pages,
            .toolbar-limiter {
                width: 100%;
            }

            .pages {
                margin-bottom: $space-xs;
            }

            .pages .pages-items {
                margin-left: 0;
            }

            .toolbar-amount {
                line-height: $space-base;
            }
        }

        .toolbar-wrap--bottom {
            margin-top: 35px;

            .pager {
                order: 3;
            }

            .toolbar-limiter {
                order: 2;
            }

            .pages {
                order: 1;
            }
        }
    }
}

@media (max-width: $bp-table-linearize - 1px) {
    .company-users-index {
        .table--linearize tbody td.actions {
            padding-top: $space-base;
        }
    }
}

// ==============================================
// Admin - Data Grid Pager
// ==============================================

// [todo] is this not part of the frontend?
@media (max-width: $bp-screen-s - 1px) {

    .company-users-index .admin__data-grid-pager,
    .company-role-index .admin__data-grid-pager {
        margin-top: $space-l;
    }

}


// ==============================================
// Company Account Create
// ==============================================

@media (min-width: $bp-screen-m) {

    .company-account-create .columns .main .form-create-account {
        width: 51%;
    }

    .company-account-create .columns .main .form-create-account .fieldset-fullname .fields .field {
        &:last-child {
            margin: 0 0 $space-base;
        }
    }

}


// ==============================================
// Company Account Index
// ==============================================

.company-account-index .columns .column.main .block:not(.widget) {
    @extend .abs-account-blocks; // [todo] remove this.
}

.company-account-index .columns .column.main .block:not(.widget) .block-title {
    margin-bottom: $space-base;
    padding-bottom: $space-s;
    border-bottom: 1px solid $c-secondary;
    font-size: $font-size-xl;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .company-account-index .column.main .block:not(.widget) .block-title {
        @include lib-clearfix;
    }

    .company-account-index .column.main .block:not(.widget) .block-title > .action {
        margin-top: $space-xs;
        float: right;
    }

    .company-account-index .column.main .block:not(.widget) .block-content strong {
        float: left;
        margin-right: 5px;
    }

    .company-account-index .column.main .block:not(.widget) .block-content .box-content span {
        display: block;
        overflow: hidden;
        vertical-align: top;
    }

    .company-account-index .column.main .block:not(.widget) .block-content .box {
        margin-bottom: $space-l;

        &:last-child {
            margin-bottom: 0;
        }
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .company-account-index .columns .column.main .block:not(.widget) .box-content strong {
        display: inline-block;
        width: 17%;
    }

}

// ==============================================
// Exist Notification Message
// ==============================================

// [todo] use a component for styling
.form-add-user .field.email .exist-notification-message {
    padding-top: $space-xs;
    color: $gray-400;
    font-size: $font-size-xs;
}


// ==============================================
// Links Container
// ==============================================

@media (max-width: $bp-screen-s - 1px) {

    .links-container-title {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .links-container {
        @include lib-dropdown(
            $_toggle-selector                       : '.action.switch',
            $_options-selector                      : 'ul',
            $_dropdown-actions-padding              : 0,
            $_dropdown-list-item-padding            : 0,
            $_dropdown-toggle-icon-content          : $icon-down,
            $_dropdown-toggle-active-icon-content   : $icon-up,
            $_icon-font-text-hide                   : true,
            $_icon-font-size                        : 1rem,
            $_icon-font-line-height                 : 2.2rem,
            $_dropdown-list-pointer-position        : right,
            $_dropdown-list-position-right          : 0,
            $_dropdown-list-z-index                 : $dropdown-list__z-index + 1
        );
    }

    .links-container li a {
        display: block;
        padding: 0.8em;
        color: $gray-900;

        &:hover,
        &:visited,
        &:active {
            color: $gray-900;
        }
    }

    .links-container .customer-menu {
        display: none;
    }

    .links-container .action.switch {
        @include lib-button-reset();
        color: $c-white;
    }

    .links-container .header.links {
        min-width: 225px;
    }

    .links-container.active .action.switch {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-up,
            $_icon-font-position: after
        );
    }

    .links-container.active .customer-menu {
        display: block;
    }

    .links-container .greet {
        display: none;
    }

    .customer-register .links-container-title {
        cursor: pointer;
    }

}


// ==============================================
// Message . Company Warning
// ==============================================

.message.company-warning {
    @include lib-message($_message-type: global-caution);
    margin-bottom: 0;
    text-align: center;
}


// ==============================================
// Modal Container
// ==============================================

.modal-container {
    display: none;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .popup-tree .modal-footer .action,
    .popup-tree .modal-footer .action-primary {
        display: block;
        margin-bottom: $space-base;
        width: 100%;
    }
}


// ==============================================
// Table Wrapper
// ==============================================

@media (max-width: $bp-screen-s - 1px) {

    .table-wrapper .table:not(.cart) > tbody > tr td.actions.col {
        &::before {
            display: none;
        }
    }

    .company-users-index,
    .company-role-index {
        .table-wrapper .table:not(.cart) > tbody > tr td {
            &:last-child::before {
                content: '';
                padding: 0;
            }
        }

        .table-wrapper .table:not(.cart) > tbody > tr.data-grid-tr-no-data td {
            &::before {
                content: '';
            }
        }
    }
}


// ==============================================
// Toolbar Amount
// ==============================================

@media (max-width: $bp-screen-s - 1px) {

    .company-users-index .toolbar-amount,
    .company-role-index .toolbar-amount {
        position: relative;
    }

}


// ----------------------------------------------
// Temporary solution to hide block that we can't remove because it has no name

.page-title-wrapper .edit-company-profile {
    display: none;
}


// ----------------------------------------
// BE-1390 Hide Company Profile view permission
// BE-1378
[id='Magento_Company::view'],
[id='Magento_Company::credit'],
[id='Magento_NegotiableQuote::view_quotes_sub'],
[id='Magento_NegotiableQuote::manage'] {
    display: none;
}

// ----------------------------------------------
// Company Users - actions menu

@media (min-width: $bp-table-linearize) {
    .company-users-menu .menu_content {
        right: 0;
    }
}
