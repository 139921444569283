/**
* East Coast Metal Distributors
*/


// **********************************************
// [todo]
// This rainbow is incomplete.
// Consider dropping this and use the "color-library" which covers the full spectrum.
// **********************************************


// ==============================================
// Complete Color Palette
// ==============================================
$red-100            : #FF4F3F;
$red-700            : #d13139;
$red-750            : #CB0015;
$red-800            : #c42a32;
$red-900            : #b52027;
$red-1000           : #920000;

$blue-50            : #e1f4fd;
$blue-100           : #b3e1f8;
$blue-200           : #81cef4;
$blue-300           : #53bbee;
$blue-350           : #4976B7;
$blue-400           : #35adea;
$blue-500           : #229fe6;
$blue-600           : #2091d7;
$blue-700           : #1d7fc4;
$blue-800           : #1a6eaf;
$blue-900           : #144e8d;
$blue-1000          : #004B87;
$blue-1100          : #002459;


$gray-50            : #fafaf9;
$gray-75            : #F6F6F6;
$gray-100           : #f4f4f3;
$gray-200           : #ececeb;
$gray-300           : #dddddc;
$gray-350           : #C3C3C3;
$gray-400           : #babab9;
$gray-500           : #9a9a99;
$gray-550           : #939393;
$gray-600           : #727271;
$gray-650           : #656565;
$gray-660           : #636363;
$gray-700           : #5e5e5d;
$gray-800           : #3F4449;
$gray-850           : #3B3B3B;
$gray-900           : #1e1e1e;

$green-200          : #70D058;
$green-400          : #32bc61;
$green-500          : #0eb045;
$green-501          : #49a54c;
$green-600          : #0ca93e;
$green-700          : #1F7E0C;

$dark-magenta       : #8a00a9;

$orange       : #A99800;
