/**
* East Coast Metal Distributors
*/


// ==============================================
// Textarea
// ==============================================

textarea.input-text {
    min-height: 80px;
    max-width: 100%;

    @include text-field(
        $input-border: 1px solid $c-border,
        $input-padding: 26px 8px 0 8px
    );

    background: $c-white;

    @include label-color (
        $label: $c-label,
        $label-focus: $c-label-focus
    );

    @include label-position(
        $label-top: 16px,
        $label-top-focus: 1px,
        $label-left: 1px,
        $label-font-size: $form-elem-font-size,
        $label-font-size-focus: 13px,
        $label-focus-left: 1px
    );

    height: auto;
}

textarea.input-text ~ label {
    background: $c-white;
    padding: 10px;
    width: calc(100% - 20px);
}

textarea.input-text:disabled ~ label {
    background: none;
}


// ==============================================
// Context: .form--contrast
// ==============================================

.form--contrast textarea.input-text {
    border: 1px solid $c-darkgray;
    border-bottom: 1px solid $c-white;

    &:focus {
        border-bottom-color: $c-contrast-input-border-focus;
    }

    @include label-color (
        $label: $c-contrast-label,
        $label-focus: $c-contrast-label-focus
    );
}

.form--contrast textarea.input-text ~ label {
    background: $c-contrast-background;
}
