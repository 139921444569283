// ==============================================
// Layout
// ==============================================

.contact-index-index {
    .content-wrapper {
        height: auto;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    .panel {
        max-width: 960px;
        flex: 0 1 60%;
    }

    .panel--form {
        flex: 1 1 40%;
        max-width: $bp-table-linearize;
        margin-right: 20px;
    }
}

// ----------------------------------------------
// Mobile

@media (max-width: $bp-screen-m - 1px) {

    .contact-index-index {
        .content-wrapper {
            flex-direction: column;
        }

        .panel--form {
            margin-right: 0;
        }
    }

}
