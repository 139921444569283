/**
* East Coast Metal Distributors
*/

.related-products__container {
    .loader-related .loader {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MiA1MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTIgNTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMzM4MGVmO30KPC9zdHlsZT4KPGc+Cgk8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzQwLjAwMDAwMCwgLTYyOS4wMDAwMDApIj4KCQk8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzYuMDAwMDAwLCA2MjkuMDAwMDAwKSI+CgkJCTxnPgoJCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTTQsMjZoNmMwLTExLDktMjAsMjAtMjBjNi4xLDAsMTEuOCwyLjgsMTUuNiw3LjVsNC43LTMuOEM0NS40LDMuNiwzOCwwLDMwLDBDMTUuNiwwLDQsMTEuNiw0LDI2eiIvPgoJCQkJPHBhdGggY2xhc3M9InN0MCIgZD0iTTU0LDE3LjhjMC4xLDAuNS0wLjQsMC45LTAuOSwwLjhsLTExLjktMi41Yy0wLjUtMC4xLTAuNi0wLjUtMC4yLTAuOGwxMS45LTkuNmMwLjQtMC4zLDAuOC0wLjIsMC44LDAuNAoJCQkJCUw1NCwxNy44eiIvPgoJCQk8L2c+CgoJCQkJPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIuMDAwMDAwLCAzOS4wMDAwMDApIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0zMi4wMDAwMDAsIC0zOS4wMDAwMDApIHRyYW5zbGF0ZSg0LjAwMDAwMCwgMjYuMDAwMDAwKSI+CgkJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNCwyNmg2YzAtMTEsOS0yMCwyMC0yMGM2LjEsMCwxMS44LDIuOCwxNS42LDcuNWw0LjctMy44QzQ1LjQsMy42LDM4LDAsMzAsMEMxNS42LDAsNCwxMS42LDQsMjZ6Ii8+CgkJCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTQsMTcuOGMwLjEsMC41LTAuNCwwLjktMC45LDAuOGwtMTEuOS0yLjVjLTAuNS0wLjEtMC42LTAuNS0wLjItMC44bDExLjktOS42YzAuNC0wLjMsMC44LTAuMiwwLjgsMC40CgkJCQkJTDU0LDE3Ljh6Ii8+CgkJCTwvZz4KCQk8L2c+Cgk8L2c+CjwvZz4KPC9zdmc+Cg==');
        margin: $space-xxxs;
        width: 18px;
        height: 18px;
        position: static;
        animation: rotate-clockwise 4s linear infinite;
    }
}

.related-products {
    margin: auto;
    padding: 0 11px;
    width: 100%;

   .menu_content {
        bottom: 35px;
        top: auto;
        left: 0;
    }

    .menu_content[aria-hidden=false] {
        display: flex;
        flex-direction: column;

        li:last-child {
            margin-top: auto;
        }
    }

    .products-grid {
        .compare-items {
            display: none;
        }

        .price-box .price {
            white-space: normal;
        }

        .product-item {
            border: 1px solid $gray-400;
            list-style: none;
            margin: $space-xs;
        }

        .product-item-photo img {
            margin: 0 auto $space-base;
        }
    }

    .slick-arrow {
        &.slick-next {
            right: -10px;
        }

        &.slick-prev {
            left: -10px;
        }
    }

    .slick-list .product-inventory--limit-assistance {
        min-height: 92.5px;
    }
}

@media (min-width: $bp-screen-m) {
    .related-products {
        margin: 0;
        overflow: hidden;
        max-width: $bp-screen-l;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .related-products__container {
        .loader-related.content-wrapper {
            text-align: center;
        }

        .loader-related .loader {
            display: inline-block;
            position: relative;
            top: 6px;
        }
    }

    .related-products {
        max-width: calc(100vw - 2 * #{$space-base});
    }
}
