/**
* East Coast Metal Distributors
*/


#checkout-step-shipping {
    &.step-content {
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;
        border: none;
    }

    .shipping-methods__container {
        padding: 20px ;
        border: 1px solid $c-gray-lighter;
        padding-top: 30px;

        .payment-method-content .input-radio--checked::before,
        .payment-method-content [type='radio']:checked + label::before {
            box-shadow: inset 0 0 0 6px $c-green-light;
        }
    }

    .shipping-methods__container .field {
        margin-bottom: 20px;
    }

    .shipping-methods__container .checkout-delivery_address .field {
        margin-bottom: 0;
    }

    .button-group {
        margin-bottom: -1px;
    }

    .button-group .button {
        padding: 12px 24px 12px 24px;
        text-transform: none;
        background: $gray-300;
        color: $c-darkgray;
        border: 1px solid $c-gray-lighter;
        z-index: 2;

        &:hover {
            background: none;
        }
    }

    .button-group .button--selected {
        background: $c-white;
        color: $c-darkgray;
        border-bottom: 1px $c-white solid;
    }
}

// ==============================================
// Shipping: Errors
// ==============================================

.pickup-date_error,
.delivery-date_error {
    position: absolute;
}

// ==============================================
// Shipping: Pick up
// ==============================================

.checkout-pickup_branch {
    margin-bottom: $space-base;
}

.checkout-pickup_branch address {
    font-weight: $font-weight-light;
}

.pickup-date input {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsLW9wYWNpdHk9IjAuNTQiIGQ9Ik0yMCAzaC0xVjFoLTJ2Mkg3VjFINXYySDRjLTEuMSAwLTIgLjktMiAydjE2YzAgMS4xLjkgMiAyIDJoMTZjMS4xIDAgMi0uOSAyLTJWNWMwLTEuMS0uOS0yLTItMnptMCAxOEg0VjhoMTZ2MTN6Ii8+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjwvc3ZnPgo=') no-repeat 95%;
    background-size: 20px;
}

.pickup-store-tooltip {
    position: absolute;
    margin-left: $space-xs;
}


// ==============================================
// Form Shipping Address
// ==============================================

.opc-wrapper .action-show-popup {
    margin: 0 0 $space-l;
}

.opc-wrapper .action-show-popup > span {
    &::before {
        content: '+';
        padding-right: $space-xs;
    }
}

.opc-wrapper .edit-address-link {
    display: block;
    float: left;
    margin: 26px 5px 0 0;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .opc-wrapper .action-show-popup {
        width: 100%;
    }

    .opc-wrapper .edit-address-link {
        @include lib-icon-font(
                $icon-settings,
            $_icon-font-size         : 28px,
            $_icon-font-line-height  : 28px,
            $_icon-font-text-hide    : true,
            $_icon-font-color        : $gray-900,
            $_icon-font-color-hover  : $gray-900,
            $_icon-font-color-active : $gray-900
        );
        margin: 0;
        position: absolute;
        right: 0;
        top: 1px;
    }

}

// ==============================================
// Shipping Address Item
// ==============================================

.delivery-address_items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $space-l;
}

.delivery-address_item {
    flex: 0 0 200px;
    word-wrap: break-word;
    margin: $space-base $space-l 0 28px;

}

.delivery-address_item:last-child {
    margin-right: 0;
}

.delivery-address_item .input-radio {
    margin-left: -26px;
    margin-right: 5px;
    position: absolute;
}

.delivery-address_item .input-radio::before {
    top: 4px;
}


// ==============================================
// Checkout Shipping Method
// ==============================================

.checkout-delivery_method {
    padding-top: $space-base;
    border-top: 1px solid $c-border;
}


// ==============================================
// Table Checkout Shipping Method
// ==============================================

.delivery-method_list thead th {
    display: none;
}
