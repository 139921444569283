//
// Variables changed for Baker Theme
//____________________________________________________________________________________


$button-icon__font                            : $font-family-icon;
$button__border-radius                      : $radius-base;
$button__font-weight                        : $font-weight-normal;
$button__color                              : $c-primary;
$button__background                         : transparent;
$button__border                             : 0;
$button__hover__background                  : $blue-50;
$button__hover__color                       : $button__color;
$button__hover__border                      : $button__border;
$button__active__background                 : $blue-100;

$button-primary__padding                    : 10px 16px 10px 16px;
$button-primary__color                      : $c-white;
$button-primary__background                 : $blue-600;

$button-primary__border                     : 0;
$button-primary__hover__color               : $button-primary__color;
$button-primary__hover__background          : darken($button-primary__background, 5%);
$button-primary__hover__border              : $button-primary__border;
$button-primary__active__background         : darken($button-primary__background, 15%);

$button-secondary__color                    : $c-white;
$button-secondary__background               : $gray-800;
$button-secondary__border                   : 0;

$button-secondary__hover__color             : $button-secondary__color;
$button-secondary__hover__background        : lighten($button-secondary__background, 5%);
$button-secondary__hover__border            : $button-secondary__border;

$button-secondary__active__color            : $button-secondary__color;
$button-secondary__active__background       : lighten($button-secondary__background, 15%);
$button-secondary__active__border           : $button-secondary__hover__border;


//
//  Buttons
//  ---------------------------------------------

//  Font style
$button__font-size                            : $font-size-base !default;
$button__font-weight                          : $font-weight-bold !default;
$button__line-height                          : $font-size-base + 2 !default;
$button__margin                               : 0 !default;
$button__padding                              : 7px 15px !default; // is set up to false when buttons don't depend on side paddings or have fixed width
$button__width                                : false !default; // is set up to false when buttons depend on side paddings or to fixed value when needed

//  Display settings
$button__display                              : inline-block !default;
$button__cursor                               : pointer !default;
$button__border-radius                        : 3px !default;

$button__disabled__opacity                    : 0.5 !default;

//  Default                                   = secondary button
$button__color                                : $c-primary !default;
$button__background                           : $gray-50 !default;
$button__border                               : 1px solid $c-border !default;
$button__gradient-color-start                 : false !default;
$button__gradient-color-end                   : false !default;

$button__hover__color                         : $gray-800 !default;
$button__hover__background                    : $gray-100 !default;
$button__hover__border                        : $button__border !default;
$button__hover__gradient-color-start          : false !default;
$button__hover__gradient-color-end            : false !default;

$button__active__color                        : $button__color !default;
$button__active__background                   : $button__hover__background !default;
$button__active__border                       : $button__border !default;
$button__active__gradient-color-start         : false !default;
$button__active__gradient-color-end           : false !default;

//  Primary button
$button-primary__line-height                  : false !default;
$button-primary__width                        : false !default;
$button-primary__margin                       : false !default;
$button-primary__padding                      : $button__padding !default;
$button-primary__gradient                     : false !default;
$button-primary__gradient-direction           : false !default;

$button-primary__color                        : $c-white !default;
$button-primary__gradient-color-start         : false !default;
$button-primary__gradient-color-end           : false !default;

$button-primary__hover__color                 : $button-primary__color !default;
$button-primary__hover__gradient-color-start  : false !default;
$button-primary__hover__gradient-color-end    : false !default;

$button-primary__active__background           : $button-primary__hover__background !default;
$button-primary__active__border               : $button-primary__hover__border !default;
$button-primary__active__color                : $button-primary__color !default;
$button-primary__active__gradient-color-start : false !default;
$button-primary__active__gradient-color-end   : false !default;

//  Gradient button
$button__gradient                             : false !default; // [true|false] - button has a gradient background
$button__gradient-direction                   : false !default; // button gradient direction if button has a gradient background

//  Button with icon
$button-icon__use                             : false !default;
$button-icon__content                         : $icon-settings !default;
$button-icon__font                            : $icon-font !default;
$button-icon__font-size                       : 22px !default;
$button-icon__line-height                     : $button-icon__font-size !default;
$button-icon__color                           : inherit !default;
$button-icon__margin                          : 0 !default;
$button-icon__vertical-align                  : top !default;
$button-icon__position                        : $icon__position !default;
$button-icon__text-hide                       : false !default;

$button-icon__hover__font-color               : inherit !default;
$button-icon__active__font-color              : inherit !default;

//  Large button
$button__font-size__l                         : $font-size-xl !default;
$button__line-height__l                       : $font-size-xl + 4 !default;
$button__padding__l                           : 14px 17px !default;

//  Small button
$button__font-size__s                         : 11px !default;
$button__line-height__s                       : $button__font-size__s + 1 !default;
$button__padding__s                           : $space-xs 8px !default;
