/**
* East Coast Metal Distributors
*/

// ==============================================
// Wrapper
// ==============================================

.dropdown-button {
    display: inline-flex;
    position: relative;
}

// ==============================================
// Dropdown
// ==============================================

.dropdown-button__primary {
    min-width: 130px;
}

.dropdown-button__actions {
    margin-left: -1px;
}

.dropdown-button__action {
    min-width: 30px;
    position: static;
    padding-left: 2px; // preserve button top & bottom padding
    padding-right: 2px;

    @include lib-icon-font(
        $_icon-font-content: $icon-down,
        $_icon-font-size: 22px,
        $_icon-font-position: after,
        $_icon-font-display: block
    );
}

.dropdown-button__action-title {
    display: none;
}

.dropdown-button__actions--active .dropdown-button__action {
    &:after {
        transform: rotate(180deg);
    }
}

.dropdown-trigger {
    &.button--secondary:not(:hover) {
        background: $c-white;
    }
}

// ==============================================
// Dropdown - List
// ==============================================

.dropdown-button__list {
    border: 1px solid $c-blue;
    background: white;
    margin-top: 1px;
    position: absolute;
    right: 0;
    z-index: 1;
    display: none;
    min-width: 100%;
}

.dropdown-button__list--disabled {
    background: #f4f4f3;
    border-color: #dddddc;
}

.dropdown-button__actions--active .dropdown-button__list {
    display: block;
}

// ==============================================
// Dropdown - List Item
// ==============================================

.dropdown-button__list-item {
    padding: 8px 16px;
    display: block;
    line-height: 22px;

    // Ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.button--s {
        padding: $space-xxs $space-xs;
    }

    &:hover {
        text-decoration: none;
    }

    &.disabled {
        color: $gray-300;

        &:hover {
            color: $gray-300;
        }
    }
}
