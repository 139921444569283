//
//  Messages variables
//  _____________________________________________


$message-icon__inner-padding-left    : 46px;

$message__padding                    : 10px $space-l;
$message__margin                     : $space-xxs 0 0;
$message__background                 : $gray-50;
$message__color                      : false; // Each message type has its own message color
$message__font-size                  : $font-size-base;
$message__font-family                : false;
$message__font-style                 : false;
$message__font-weight                : false;
$message__line-height                : 1.2em;

$message-icon__font-size             : 22px;
$message-icon__font-line-height      : 35px;
$message-icon__lateral-width         : 36px;
$message-icon__lateral-arrow-size    : 5px;
$message-icon__right                 : 0px;
$message-icon__bottom                : false;
$message-icon__left                  : false;
$message-icon__top                   : 0px;

$message__border-width               : false;
$message__border-color               : false;
$message__border-style               : false;
$message__border-radius              : $radius-base;

//  Information message
$message-info__color                 : $c-text;
$message-info__background            : $message__background;
$message-info__border-color          : false;

$message-info-link__color            : $c-link-dark;
$message-info-link__color-hover      : $c-link-dark-hover;
$message-info-link__color-active     : $c-link-dark-active;

$message-info-icon                   : $icon-info;
$message-info-icon__color-inner      : $c-white;
$message-info-icon__background       : $dark-magenta;
$message-info-icon__top              : $message-icon__top;
$message-info-icon__right            : $message-icon__right;
$message-info-icon__bottom           : $message-icon__bottom;
$message-info-icon__left             : $message-icon__left;

$message-info-map                   : (
        color                            : $message-info__color,
        background                       : $message-info__background,
        border-color                     : $message-info__border-color,
        link__color                      : $message-info-link__color,
        link__color-hover                : $message-info-link__color-hover,
        link__color-active               : $message-info-link__color-active,
        icon                             : $message-info-icon,
        icon__color-inner                : $message-info-icon__color-inner,
        icon__background                 : $message-info-icon__background,
        icon__top                        : $message-info-icon__top,
        icon__right                      : $message-info-icon__right,
        icon__bottom                     : $message-info-icon__bottom,
        icon__left                       : $message-info-icon__left
);

//  Warning message
$message-warning__color              : $c-text;
$message-warning__background         : $message__background;
$message-warning__border-color       : false;

$message-warning-link__color         : $message-info-link__color;
$message-warning-link__color-hover   : $message-info-link__color-hover;
$message-warning-link__color-active  : $message-info-link__color-active;

$message-warning-icon                : $message-info-icon;
$message-warning-icon__color-inner   : $c-white;
$message-warning-icon__background    : $orange;
$message-warning-icon__top           : $message-icon__top;
$message-warning-icon__right         : $message-icon__right;
$message-warning-icon__bottom        : $message-icon__bottom;
$message-warning-icon__left          : $message-icon__left;

$message-warning-map                : (
        color                            : $message-warning__color,
        background                       : $message-warning__background,
        border-color                     : $message-warning__border-color,
        link__color                      : $message-warning-link__color,
        link__color-hover                : $message-warning-link__color-hover,
        link__color-active               : $message-warning-link__color-active,
        icon                             : $message-warning-icon,
        icon__color-inner                : $message-warning-icon__color-inner,
        icon__background                 : $message-warning-icon__background,
        icon__top                        : $message-warning-icon__top,
        icon__right                      : $message-warning-icon__right,
        icon__bottom                     : $message-warning-icon__bottom,
        icon__left                       : $message-warning-icon__left
);

//  Error message
$message-error__color                : $c-text;
$message-error__background           : $message__background;
$message-error__border-color         : $message__border-color;

$message-error-link__color           : $c-link-dark;
$message-error-link__color-hover     : $c-link-dark-hover;
$message-error-link__color-active    : $c-link-dark-hover;

$message-error-icon                  : $icon-error;
$message-error-icon__color-inner     : $c-white;
$message-error-icon__background      : $c-red-light;
$message-error-icon__top             : $message-icon__top;
$message-error-icon__right           : $message-icon__right;
$message-error-icon__bottom          : $message-icon__bottom;
$message-error-icon__left            : $message-icon__left;

$message-error-map                  : (
        color                            : $message-error__color,
        background                       : $message-error__background,
        border-color                     : $message-error__border-color,
        link__color                      : $message-error-link__color,
        link__color-hover                : $message-error-link__color-hover,
        link__color-active               : $message-error-link__color-active,
        icon                             : $message-error-icon,
        icon__color-inner                : $message-error-icon__color-inner,
        icon__background                 : $message-error-icon__background,
        icon__top                        : $message-error-icon__top,
        icon__right                      : $message-error-icon__right,
        icon__bottom                     : $message-error-icon__bottom,
        icon__left                       : $message-error-icon__left
);

//  Success message
$message-success__color             : $c-text;
$message-success__background        : $message__background;
$message-success__border-color      : $message__border-color;

$message-success-link__color         : $c-link-dark;
$message-success-link__color-hover   : $c-link-dark-hover;
$message-success-link__color-active  : $c-link-dark-hover;

$message-success-icon                : $icon-success;
$message-success-icon__color-inner   : $c-white;
$message-success-icon__background    : $c-green-light;
$message-success-icon__top           : $message-icon__top;
$message-success-icon__right         : $message-icon__right;
$message-success-icon__bottom        : $message-icon__bottom;
$message-success-icon__left          : $message-icon__left;

$message-success-map                : (
        color                            : $message-success__color,
        background                       : $message-success__background,
        border-color                     : $message-success__border-color,
        link__color                      : $message-success-link__color,
        link__color-hover                : $message-success-link__color-hover,
        link__color-active               : $message-success-link__color-active,
        icon                             : $message-success-icon,
        icon__color-inner                : $message-success-icon__color-inner,
        icon__background                 : $message-success-icon__background,
        icon__top                        : $message-success-icon__top,
        icon__right                      : $message-success-icon__right,
        icon__bottom                     : $message-success-icon__bottom,
        icon__left                       : $message-success-icon__left
);

//  Notice message
$message-notice__color               : $message-info__color;
$message-notice__background          : $message-info__background;
$message-notice__border-color        : $message-info__border-color;

$message-notice-link__color          : $message-info-link__color;
$message-notice-link__color-hover    : $message-info-link__color-hover;
$message-notice-link__color-active   : $message-info-link__color-active;

$message-notice-icon                 : $message-info-icon;
$message-notice-icon__color-inner    : $message-info-icon__color-inner;
$message-notice-icon__background     : $message-info-icon__background;
$message-notice-icon__top            : $message-icon__top;
$message-notice-icon__right          : $message-icon__right;
$message-notice-icon__bottom         : $message-icon__bottom;
$message-notice-icon__left           : $message-icon__left;

$message-notice-map                 : (
        color                            : $message-notice__color,
        background                       : $message-notice__background,
        border-color                     : $message-notice__border-color,
        link__color                      : $message-notice-link__color,
        link__color-hover                : $message-notice-link__color-hover,
        link__color-active               : $message-notice-link__color-active,
        icon                             : $message-notice-icon,
        icon__color-inner                : $message-notice-icon__color-inner,
        icon__background                 : $message-notice-icon__background,
        icon__top                        : $message-notice-icon__top,
        icon__right                      : $message-notice-icon__right,
        icon__bottom                     : $message-notice-icon__bottom,
        icon__left                       : $message-notice-icon__left
);

//  Email messages
$message-email__color                : $c-text;
$message-email__background           : $message-info__background;
$message-email__border-color         : $message-info__border-color;
$message-email-link__color           : $message-info-link__color;
