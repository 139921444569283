#order_templates_app {
    .rename_button {
        line-height: $line-height-xxs;
        font-size: $font-size-xs;
        font-family: $font-family-sans;
    }

    .button, .button--secondary, a.pagebuilder-button-secondary {
        box-shadow: none;
    }

    .searchInput {
        width: 400px;
    }

    .table tbody tr:last-child {
        border: none;
    }

    .input-text.input-text:focus {
        border-color: $c-label;
    }

    .react_addproducts {
        background-color: transparent;

        .w-50,
        .quickorder-wrapper {
            background-color: transparent;
        }

        .add-product-area {
            padding-top: 48px;
        }
    }
}


.order-templates__view-header {

    .action-group a:hover {
        text-decoration: none;
    }
}

.order-templates .price-box--limit {
    display: none;
}

.order-templates__view-details {
    z-index: 1;
}

.order-templates__selected-items {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70px;
    z-index: 500;
    background: $c-white;
    color: $blue-1000;
    border-top: 1px $c-lightgray solid;
    padding-top: 15px;

    &.not-sticky {
        position: relative;
        padding: 20px 0 60px 0;
    }

    .line-1 .exp-checkout {
        display: none;
    }

    .update-qty {
        display: none;
    }

    .second-express-checkout {
        display: none;
    }

    .hidden-visibility {
        visibility: hidden;
        display: none;
    }
}

@media (max-width: 849px) {
    #order_templates_app {
        .searchInput {
            width: 100%;

            label {
                font-size: $font-size-xs;
                letter-spacing: 0;
            }
        }

        .rename_button {
            display: flex;
            margin-top: 20px;
        }

        .react_addproducts {
            .add-product-area {
                padding-top: 0;
            }
        }

        .order-templates__view-header .panel_body {
            padding-left: 0;

            .button--block-mobile {
                width: 99%
            }

            .width-100 {
                width: 99%
            }
        }
    }

    .panel1 {
        padding-left: 0;
    }

    .order-templates__selected-items {
        padding-left: 10px;
        height: 140px;

        .m-l-xl {
            margin-left: 14px !important;
        }

        .m-l-xxxl {
            margin-left: 0 !important;
        }

        .line-1 {
            width: 100%;
            float: left;
        }

        .line-2 {
            float: left;
            width: 100%;
            margin-top: 20px;
        }

        &.expresscheckout {
            .exp-checkout {
                position: absolute;
            }

            .line-2 {
                margin-top: 0;
            }

            .selected-items {
                position: absolute;
                bottom: 30px;
                left: 40px;
            }
        }

        .line-1 .exp-checkout {
            position: relative;
            margin-top: 15px;
            margin-right: 20px;
        }

        .line-2 .exp-checkout {
            display: none;
        }

        .update-quantity, .line-1 .first-express-checkout {
            display: none;
        }

        .update-qty, .line-1 .second-express-checkout {
            display: inline-block;
            padding-left: 9px;
            padding-top: 9px;
        }

        .line-1 .second-express-checkout {
            letter-spacing: -.5px;
            padding: 8px 4px;
        }

        .add-to-cart-sticky-bar {
            top: 15px;
            margin-right: 20px;
        }

    }
}

@media (max-width: $bp-screen-xs - 1px) {
    .order-templates__selected-items {
        .line-1 .exp-checkout {
            display: none;
        }

        .line-2 .exp-checkout {
            display: inline-block;
        }

        &.expresscheckout.expresscheckout-enabled {
            .selected-items {
                bottom: 10px;
            }

            .add-to-cart-sticky-bar {
                top: 0;
            }
        }

        .hidden-visibility {
            visibility: hidden;
            display: inline-block;
        }
    }
}

// ==============================================
// Supersede Message
// ==============================================

.order-template-message {
    position: sticky;
    top: 0;
    z-index: 10;
}

.order-template-message__content {
    display: flex;
    flex-direction: column;
}

.order-template-message__text {
    margin: 0;
}

.order-template-message__button {
    margin-top: 10px;
}

.order-templates__view-header--with-supersedes .ecmd__view-table-head {
    top: 56px;
}

// ----------------------------------------------
// Desktop View

@media (min-width: $bp-screen-s) {
    .order-template-message__content {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .order-template-message__button {
        overflow: visible;
        margin-top: 0;
        margin-left: 10px;
    }
}
