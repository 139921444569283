@media (min-width: $bp-screen-m) {
    .product-preview.algolia-preview {
        .page-title-wrapper {
            border-bottom: none;
        }

        .product-inventory {
            margin: 15px 0;
            font-size: 13px;
        }
    }

    .catalog-app__main {
        display: flex;

        .items__header {
            display: flex;
            border: 1px solid $gray-700;
            padding: 5px 10px;
            font-size: 13px;

            &.sticky {
                position: fixed;
            }

            div {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: left;

                &.product-name {
                    flex: 1.95;
                }

                &.mpn-product-name {
                    max-width: 165px;
                }

                &.qty-right {
                    max-width: 50px;
                    justify-content: right;
                }

                &.learn-more {
                    max-width: 110px;
                }
            }
        }

        .collection__container {
            .products-list.product-item {
                margin: 0;
                padding: 0;
                border: none;
            }
        }

        .item__container {
            display: flex;
            border: 1px solid $gray-200;
            border-top: none;
            font-size: 12px;
            cursor: pointer;

            &:hover {
                background: $gray-100;
            }

            .price-box .not-login-in {
                font-size: 12px;
            }

            li {
                flex: 1;
                display: inline-flex;
                justify-content: left;
                height: 48px;
                padding: 5px 10px;
                align-items: center;

                &.product-name {
                    flex: 2;
                    font-size: 13px;

                    a {
                        color: $c-darkgray;
                    }
                }

                &.mpn-product-name {
                    max-width: 165px;
                    font-size: 13px;
                }

                &.product-brand {
                    flex-wrap: wrap;
                    font-size: 11px;

                    .brand__container {
                        padding-right: 15px;
                        font-size: 12px;
                    }

                    .product-propoint-badge {
                        height: 16px;
                    }
                }

                &.learn-more {
                    max-width: 110px;
                }

                &.qty-right {
                    align-items: flex-end;
                    justify-content: right;
                    max-width: 78px;

                    input {
                        background: $c-white;
                    }
                }
            }

            &.category-row {
                cursor: auto;
                font-size: 14px;
                font-weight: 500;

                &:hover {
                    background: $c-white;
                }

                li {
                    height: 90px;
                }

                img {
                    width: 100px;
                    padding: 10px;
                    max-height: 100px;
                    object-fit: contain;
                }
            }

            .product-inventory__availability {
                margin: 0;
            }

            .price-block {
                .message-container {
                    font-size: 11px;
                    font-weight: normal;
                }

                .price {
                    font-size: 13px;
                }

                .price-box.price-final_price {
                    display: block;
                }

                text-align: center;
            }

            .product-inventory {
                margin: 0;
                padding: 0;
                background: none;
                text-align: center;
                font-size: 11px;

                .inventory__inner {
                    div:nth-child(2) {
                        display: none;
                    }

                    div:nth-child(3) {
                        display: none;
                    }
                }
            }
        }

        .cat-tab-simple {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        [type='checkbox'] + label {
            margin-left: -19px;
        }

        .product-item-name {
            font-size: 16px;
        }

        .product-item-actions {
            margin: 16px 0 8px 0;
        }

        .catalog-app__sidebar {
            margin-bottom: 110px;
            background: transparent;

            &.simplified {
                margin-bottom: 73px;
            }
        }

        .column.main {
            margin-right: 20px;
            width: 100%;
        }

        .filter-options .item {
            display: flex;
            line-height: 18px;

            [type='checkbox'] {
                visibility: hidden;
            }
        }
    }
    #app-wrapper .page-main {
        padding: 0;
    }

    #branch_inventory_filter .field {
        margin-bottom: 0;
    }

    .fixed__addto .selected-items {
        color: $blue-1100;
        padding-bottom: 10px;
    }

    .fixed__addto button {
        margin: 0 15px;
    }

    .cat-tab-like {
        border-left: 1px $c-gray-lighter solid;
        margin: 0;
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .category-additional {
        margin: 0.6em;
    }
}

.product-card__root {
    height: 100%;
}

.catalog-app__main {
    width: 100%;
    margin-bottom: 35px;

    .product-item-info__bottom-container {
        vertical-align: bottom;
        display: flex;
        flex-direction: column;

        .bottom-inner {
            display: inline-block;
            align-self: flex-end;
            width: 100%;
        }
    }

    .products-list.product-item {
        padding: 16px;
        margin-bottom: 20px;
    }

    .block.view-mode {
        margin: 0;
    }

    .product-item-photo {
        height: 200px;
        display: flex;
        justify-content: center;
        position: relative;
    }

    .list {
        margin-left: 0;
    }

    .product-inventory {
        margin: 16px 0;
        min-height: 92.5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .simplified-badge__container .badge-list {
        flex-wrap: nowrap;
    }

    .simplified-inventory__container,
    .simplified-price__container {
        .product-card__root {
            height: auto;
        }
    }
}

.catalog-app__carousel h2:hover {
    text-decoration-line: underline;
    cursor: pointer;
}

.react-app-container {
    background: rgb(238, 238, 238);
}

.catalog-app__sidebar label.filter-item__label {
    line-height: 22px;
    margin-right: 4px;
}

.catalog-app__sidebar .branch-input__container > div {
    line-height: 30px;
    font-size: 14px;

    [type='radio'] + label::after {
        transform: none;
        -webkit-transform: none;
        background: transparent;
    }
}

.catalog-app__see-more {
    flex: 1 0 220px;
    background: #fff;
    border: 1px solid #f4f4f3;
    padding: 16px;
}

.catalog-app__see-more a {
    color: $c-link;

    &:hover {
        color: $c-link-hover;
    }
}

#app-wrapper {
    .red-link {
        text-decoration: none;
        cursor: pointer;
    }

    #page-title-wrapper {
        background: #fff;
    }

    .price-box .price.login-in {
        line-height: 1.4;
        white-space: normal;
        font-size: 14px;
    }

    .product-item .price-box {
        line-height: 18px;
        margin: 0;
    }

    .mobile-small-toolbar {
        display: flex;
        justify-content: space-between;
    }

    .toolbar-limiter .pager-container {
        z-index: 1;
    }

    .catalog-app__main .price-box .old-price {
        font-weight: normal;
        padding-left: 0;
        line-height: 15px;
    }

    .product-mfg {
        font-size: 0.8rem;
    }

    .product-item-info .price-box .price.not-login-in {
        font-size: 12px;
    }

    .product-item-info .mobile-info {
        display: flex;

        .right-side {
            width: 35%;

            .product-item-photo {
                height: auto;
            }
        }

        .left-side {
            padding-left: 15px;
            font-size: 12px;

            .product-item-name {
                height: auto;
                font-size: 14px;
            }
        }
    }

    .bottom-container-mobile {
        //height: 165px;
        //height: 240px;
        margin-top: 20px;


        .compare__container {
            margin-top: 10px;
        }

        .price-box .price {
            font-size: 14px;
        }

        &.bottom-guest {
            height: auto;
        }
    }
}

.block-search {
    .search-suggestion {
        line-height: 28px;
    }

    .suggestion-button {
        padding: 4px 0;
    }
}

// Tablet View
@media (min-width: $bp-screen-tablet) and (max-width: $bp-screen-m - 1px) {
    #app-wrapper {

        .product-item-info .mobile-info {
            .left-side {
                padding-left: 15px;
                font-size: 12px;

                .product-item-name {
                    height: 60px;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }
}

// Mobile View
@media (max-width: $bp-screen-m - 1px) {
    .search-index-index {

        .page-title-wrapper {
            margin-top: -9px;
            margin-left: -16px;
            margin-right: -15px;
            display: block;
            padding-bottom: 15px;
        }
    }
}


@media (max-width: $bp-screen-xs - 1px) {
    #app-wrapper {

        .toolbar-limiter span {
            display: none;
        }
    }
}
