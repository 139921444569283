.__react_dropdown_search_click {
    .dropdown-options {
        width: auto;
        box-shadow: 0 0 10px RGBA(0,0,0,.2);
        border: none;
        bottom: 36px;

        li {
            float: left;
        }

        li:last-child a {
            margin: 10px 10px 10px 10px;
            color: $c-darkgray;
        }

        .elements {
            height: 120px;
            overflow: auto;
            overflow-y: scroll;
            float: left;

            li {
                width: 100%;

                a {
                    max-width: 170px;
                    color: $c-darkgray;
                }
            }
        }

        .search {
            float: left;
            width: 100%;
            padding: 20px 10px 0 10px;
            min-width: 210px;

            li > div {
                min-width: 210px;
            }

            label {
                max-width: 200px;
                overflow: hidden;
                font-size: $font-size-s;
            }
        }

        .bottom {
            float: left;
            width: 100%;
            padding-bottom: 0;
            padding-top: 10px;
        }
    }

    .dropdown-action {
        white-space: nowrap;
        padding-bottom: 7px;
    }
}

.add-icon {
    @include lib-icon-font(
        $_icon-font-content: $icon-add,
        $_icon-font-size: 18px,
        $_icon-font-line-height: 17px,
        $_icon-font-color: $c-darkgray
    );
}
