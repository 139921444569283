// ==============================================
// Quantity Group
// ==============================================

.qty-group {
    display: flex;
    flex-direction: column;
}

.qty-group__item {
    white-space: nowrap;
}

.qty-group__item--red {
    color: $c-error;
    font-weight: 500;
}
