//
//  Brands
//  _____________________________________________

@media only screen and (max-width: $bp-screen-m - 1px) {
    .brands .pagebuilder-column {
        flex-basis: 100%;
    }

    @media only screen and (max-width: $bp-screen-m - 1px) {
        .brands.background-overlay {
            background: none;
        }
    }


    .brands-grid .pagebuilder-column {
        flex-basis: 25%;

        @media only screen and (max-width: $bp-screen-s - 1) {
            flex-basis: 50%;
        }
    }

}
