//
//  Button as link
//  ---------------------------------------------

//
//  Button reset
//  ---------------------------------------------

@mixin lib-button-reset() {
    background-image: none; // Reset unusual Firefox-on-Android default style
    background: none;
    -moz-box-sizing: content-box; // Hack: fix Firefox button line-height problem
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: $font-weight-normal;

    &:focus,
    &:active {
        background: none;
        border: none;
    }

    &:hover {
        background: none;
        border: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        pointer-events: none; // Disabling of clicks
        opacity: $button__disabled__opacity;
    }
}

//
//  Inner buttons mixins
//  ---------------------------------------------

@mixin _lib-button-icon (
    $_button-icon-use          : $button-icon__use,
    $_icon-font-content        : $button-icon__content,
    $_icon-font                : $button-icon__font,
    $_icon-font-size           : $button-icon__font-size,
    $_icon-font-line-height    : $button-icon__line-height,
    $_icon-font-color          : $button-icon__color,
    $_icon-font-color-hover    : $button-icon__hover__font-color,
    $_icon-font-color-active   : $button-icon__active__font-color,
    $_icon-font-margin         : $button-icon__margin,
    $_icon-font-vertical-align : $button-icon__vertical-align,
    $_icon-font-position       : $button-icon__position,
    $_icon-font-text-hide      : $button-icon__text-hide
) {
    @if $_button-icon-use {
        @include lib-button-icon (
                $_icon-font-content       : $_icon-font-content,
                $_icon-font               : $_icon-font,
                $_icon-font-size          : $_icon-font-size,
                $_icon-font-line-height   : $_icon-font-line-height,
                $_icon-font-color         : $_icon-font-color,
                $_icon-font-color-hover   : $_icon-font-color-hover,
                $_icon-font-color-active  : $_icon-font-color-active,
                $_icon-font-margin        : $_icon-font-margin,
                $_icon-font-vertical-align: $_icon-font-vertical-align,
                $_icon-font-position      : $_icon-font-position,
                $_icon-font-text-hide     : $_icon-font-text-hide
        );
    }
}

@mixin lib-button-icon(
    $_icon-font-content,
    $_icon-font: $button-icon__font,
    $_icon-font-size: $button-icon__font-size,
    $_icon-font-line-height: $button-icon__line-height,
    $_icon-font-color: $button-icon__color,
    $_icon-font-color-hover: $button-icon__hover__font-color,
    $_icon-font-color-active: $button-icon__active__font-color,
    $_icon-font-margin: $button-icon__margin,
    $_icon-font-vertical-align: $button-icon__vertical-align,
    $_icon-font-position: $button-icon__position,
    $_icon-font-text-hide: $button-icon__text-hide
) {
    @include lib-icon-font(
            $_icon-font-content: $_icon-font-content,
            $_icon-font: $_icon-font,
            $_icon-font-size: $_icon-font-size,
            $_icon-font-line-height: $_icon-font-line-height,
            $_icon-font-color: $_icon-font-color,
            $_icon-font-color-hover: $_icon-font-color-hover,
            $_icon-font-color-active: $_icon-font-color-active,
            $_icon-font-margin: $_icon-font-margin,
            $_icon-font-vertical-align: $_icon-font-vertical-align,
            $_icon-font-position: $_icon-font-position,
            $_icon-font-text-hide: $_icon-font-text-hide
    );
}
