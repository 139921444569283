// ==============================================
// Table Totals Summary
// ==============================================

.totals-summary {
    padding: $space-base;
}

.totals-summary__title {
    display: inline-block;
    padding-bottom: $space-s;
}

.totals-summary__table {
    width: 100%;
}

.totals-summary__item :is(td, th) {
    padding: $space-xxxs 0;
}

.totals-summary__item td {
    text-align: right;
}

.totals-summary__item--total :is(td, th) {
    font-weight: $font-weight-bold;
}
