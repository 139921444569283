/**
* East Coast Metal Distributors
*/


// ==============================================
// Animation - Choice Ripple
// ==============================================

@keyframes choice-ripple {
    5%, 100% { opacity: 0; }
    5%       { opacity: 1; }
}
