/**
* East Coast Metal Distributors
*/


// ==============================================
//  Widget
// ==============================================

.widget {
    clear: both;
}
