/**
* East Coast Metal Distributors
*/


// ==============================================
// Simple Table
// ==============================================

.table .simple-table,
.simple-table {
    margin-bottom: $gap;
}

.table .simple-table tr,
.simple-table tr {
    border: none !important;
    display: table-row;
    padding: 0;
}

.table .simple-table th,
.simple-table th {
    @include bold;
    color: $c-text-muted;
}

.table .simple-table th,
.table .simple-table td,
.simple-table th,
.simple-table td {
    padding: 0 1.75em 0.5em 0;
    line-height: $line-height-xxs;
    vertical-align: top;
    display: table-cell;

    &:last-child {
        padding-right: 0;
    }
}

.table .simple-table tr:last-child th,
.table .simple-table tr:last-child td,
.simple-table tr:last-child th,
.simple-table tr:last-child td {
    padding-bottom: 0;
}

@media (max-width: $bp-screen-m - 1px) {
    .table .simple-table,
    .simple-table {
        table-layout: fixed;
        width: 100%;
    }

    .table .simple-table th,
    .simple-table th {
        max-width: 50%;
    }
}
