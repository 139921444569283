.requisition-list {
    .panel_toolbar & {
        justify-content: center;
    }

    &.menu--short {
        display: flex;
        position: relative;
    }

    .menu_content {
        min-width: 223px;
        padding: 10px;
        z-index: 100;
        max-height: fit-content;
        text-align: left;
        width: 100%;
    }

    .menu_toggle {
        display: flex;
        line-height: 36px;

        &:after {
            margin-top: 0;
            line-height: 36px;
        }
    }

    .menu_content .order-templates-filter {
        line-height: 1.2;
        margin: 5px 0 10px;

        label {
            font-size: $font-size-s;
        }

        &:hover {
            text-decoration: none !important;
            background: none;
        }
    }

    .menu_content > li
    .menu_content div > li{
        line-height: 24px;
        padding: 0;

        &:hover {
            text-decoration: underline;
            background: none !important;
        }
    }

    .menu_content > li,
    .menu_content div > li {
        position: relative;
    }

    .menu_content > li a,
    .menu_content div > li a {
        margin: 0;
        padding: $space-xs 0;
        color: $c-darkgray;
    }

    .menu_content > li.create-new-template a,
    .menu_content div > li.create-new-template a {
        padding-left: 20px;

        @include lib-icon-font(
            $_icon-font-content: $icon-add,
            $_icon-font-size: 18px,
            $_icon-font-line-height: 16px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $c-darkgray
        );

        &:before {
            left: 0;
            position: absolute;
            top: 12px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.requisition-list {
    &--disabled, &--loading {
        visibility: hidden;
    }
}
