@mixin step-progress(
    $_default_color: $c-gray-lighter,
    $_active_color: $c-blue,
    $_step_diameter: 10px,
    $_line_height: 2px,
    $_stretched_offset: 15px
) {
    $block: &;

    &__item {
        position: relative;
        padding: 3px 0;

        &:before {
            content: '';
            position: relative;
            display: inline-block;
            background: $_default_color;
            vertical-align: middle;
            border-radius: 50%;
            margin-right: 10px;
            width: $_step_diameter;
            height: $_step_diameter;
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            background: $_default_color;
            height: 100%;
            width: 2px;
            left: ($_step_diameter * 0.5) - ($_line_height * 0.5);
            top: -50%;
            z-index: 1;
        }

        &:first-of-type:after {
            display: none;
        }

        &.active:before,
        &.active:after {
            background: $_active_color;
        }
    }

    // ----------------------------------------------
    // Horizontal View

    &--horizontal,
    &--horizontal-stretched {
        display: flex;
        justify-content: space-between;
        text-align: center;

        #{$block}__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;

            &:before {
                margin: 0 0 5px;
            }

            &:after {
                width: 100%;
                height: 2px;
                top: ($_step_diameter * 0.5) + $_line_height;
                left: -50%;
            }
        }
    }

    &--horizontal-stretched {
        #{$block}__item--first + #{$block}__item {
            &:after {
                width: calc(150% - #{$_stretched_offset});
                left: calc(-100% + #{$_stretched_offset});
            }
        }

        #{$block}__item--first {
            align-items: start;

            &:before {
                margin-left: $_stretched_offset;
            }

            &:after {
                left: $_stretched_offset;
            }
        }

        #{$block}__item--last {
            align-items: end;

            &:before {
                margin-right: $_stretched_offset;
            }

            &:after {
                right: $_stretched_offset;
                left: auto;
                width: calc(150% - #{$_stretched_offset});
            }

            &:nth-child(2):after {
                width: calc(200% - #{$_stretched_offset * 2});
            }
        }
    }
}
