/**
* East Coast Metal Distributors
*/


// ==============================================
// Badge
// ==============================================

.badge {
    display: inline-block;
    padding: $space-xxxs 0.5em;
    border-radius: $radius-base;
    background: $c-gray-dark;
    color: $c-white;
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
}

// ----------------------------------------------
// Modifier: Danger

.badge--danger {
    background: $c-red-light;
}

// ----------------------------------------------
// Modifier: Info

.badge--info {
    background: $dark-magenta;
}

// ----------------------------------------------
// Modifier: Success

.badge--success,
.badge--received {
    background: $c-green-light;
}

// ----------------------------------------------
// Modifier: Warning

.badge--warning,
.badge--warn {
    background: $orange;
}

.badge--help {
    background: $dark-magenta;
}

.badge--new {
    background: $c-green-light;
}

.badge--sale {
    background: $c-green-light;
}

.badge--cancelled {
    background: $c-red-light;
}
.badge--pending {
    background: $dark-magenta;
}
.badge--processing {
    background: $dark-magenta;
}
// ----------------------------------------------
// Print Pages

.page-print .badge {
    padding: 0;
    color: $c-text;
    text-shadow: none;
    font-weight: $font-weight-semibold;
    background: transparent;
}
