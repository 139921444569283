/**
* East Coast Metal Distributors
*/


// ==============================================
//  Newsletter Subscribe
// ==============================================

.newsletter-subscribe {
    margin-bottom: $space-xxl;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .newsletter-subscribe {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 300px;
    }

}


// ==============================================
//  Newsletter Subscribe - Icon
// ==============================================

.newsletter-subscribe_icon {
    width: 24px;
    height: 24px;

    @include lib-icon-font(
        $icon-envelope,
        $_icon-font-size        : 24px,
        $_icon-font-line-height : 35px,
        $_icon-font-color       : $gray-700
    );

    &::before {
        position: absolute;
    }
}
