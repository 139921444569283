/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Mixin - Text Replacement
// ==============================================

// Resources:
// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
// http://nicolasgallagher.com/another-css-image-replacement-technique/
// https://github.com/h5bp/html5-boilerplate/issues/1005#issuecomment-4947702

@mixin image-replacement {
    // Note: this technique has issues on Android 3.x
    // An alternative that requires the ::before generated content was added to H5BP
    // See: https://github.com/h5bp/html5-boilerplate/blob/master/css/main.css

    //color:transparent; font:0/0 a; text-shadow:none; vertical-align:middle;

    // The simplest option remains using text-indent:-9999px despite the performance drawback
    text-indent: -9999px;
}

// Undo the replacement for child elements

@mixin image-replacement-override {
    text-indent: 0;
}
