/**
* East Coast Metal Distributors
*/


// ==============================================
// Vars
// ==============================================

$account-nav-background: $c-white;

$account-nav-width:      256px;

$account-nav-icon-size: 24px;
$account-nav-header-size: 30px;

$account-nav-item-background-hover: $red-750;
$account-nav-item-background-focus: $gray-800;
$account-nav-item-border:           $gray-400;
$account-nav-item-color:            $c-white;
$account-nav-item-color-focus:      $c-white;
$account-nav-item-color-hover:      $c-white;


$account-nav-item-current-background: $c-white;

$account-nav-sub-item-color:       $c-white;
$account-nav-sub-item-color-focus: $gray-200;
$account-nav-sub-item-color-hover: $c-white;

$account-nav-sub-item-current-color: $c-white;

$account-nav-item-current-background: $c-white;

@mixin external-link($color) {
    position: absolute;
    right: 30px;
    top: 5px;
    font-size: 14px;
    line-height: 30px;
    color: $color;
    content: "open_in_new";
    font-family: "Material Icons";
}
// ==============================================
// Account Nav
// ==============================================

.account-dropdown_links.account-dropdown_myaccount {
    .new a {
        @include badge-new;
    }
}

.header-links {
    .external {
        position: relative;

        &::before {
            @include external-link($gray-850);
            top: 3px !important;
        }
    }

    &.new a {
        @include badge-new;
    }
}

.account-nav_item {
    .external {
        position: relative;

        &::before {
            @include external-link($c-white);
            top: 8px !important;
            color: unset !important
        }
    }
}

.account-nav {
    width: $account-nav-width;
    margin-bottom: 0;
}

.account-nav .title {
    display: none;
}


// ==============================================
// Account Nav - Item
// ==============================================

.account-nav_item {
    border-bottom: 1px solid $account-nav-item-border;
    transition: background-color 0.1s linear;

    &:last-child {
        border-bottom: 0;
    }

    &.new .account-nav_label{
        @include badge-new;
    }
}


.account-nav_item .flag {
    margin-left: $space-xs;
    position: absolute;
}

.nav.item {
    &.new > a,
    &.new > strong{
        @include badge-new;
    }
}

// ----------------------------------------------
// Modifier: Current

.account-nav_item--current {
    background: $account-nav-item-current-background;

    &::before {
        color: $c-white;
    }
}


// ==============================================
// Account Nav - Link
// ==============================================

.account-nav_link {
    display: block;
    color: $c-black;
    text-decoration: none;

    &:focus {
        color : $c-black;
    }

    &:hover {
        background-color: $account-nav-item-background-hover;
        color: $account-nav-item-color-hover;
        text-decoration: none;
    }
}

// ----------------------------------------------
// Parent Modifier: Current

.account-nav_item--current .account-nav_link {
    color: $account-nav-item-color-hover;
}


// ==============================================
// Account Nav - Label
// ==============================================

.account-nav_label {
    padding: $space-s $space-base;

    &::before {
        padding-right: 10px;
    }
}


// ==============================================
// Account Nav - Dropdown
// ==============================================

.account-nav_dropdown {
    margin-bottom: $space-s;
}


// ==============================================
// Account Nav - Dropdown Item
// ==============================================

.account-nav_dropdown a,
.account-nav_dropdown strong {
    display: block;
    padding: 0.7em 0 0.7em ($space-base * 2);
    line-height: $line-height-xs;
    text-decoration: none;
}

.account-nav_dropdown a {
    color: $c-black;

    &:focus {
        color: $c-black;
    }

    &:hover {
        background: $account-nav-item-background-hover;
        color: $account-nav-sub-item-color-hover;
    }
}

.account-nav_dropdown strong {
    font-weight: normal;
}


// ==============================================
// Icons
// ==============================================

// Dashboard
.account-nav_item.dashboard span {
    @include lib-icon-font(
        $_icon-font:              $font-family-special,
        $_icon-font-content:      $icon-dashboard,
        $_icon-font-size:         $account-nav-header-size,
        $_icon-font-line-height:  $account-nav-header-size
    );
    font-size: 19px;
    font-family: 'Open Sans';
    &:before {
        content: none;
    }
}

// Quotes
.account-nav_item.quotes span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-quotes,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size
    );
}

// Orders
.account-nav_item.orders span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-history,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size
    );
}

// Requisition
.account-nav_item.requisition {
    display: none;
    span {
        @include lib-icon-font(
            $_icon-font-content:      $icon-quicklists,
            $_icon-font-size:         $account-nav-icon-size,
            $_icon-font-line-height:  $account-nav-icon-size
        );
    }
}

// Stocking Lists
.account-nav_item.stocking-list span {
    display:  $icon-font__display;
    text-decoration: none;

    &:before {
        margin-right: 10px;
        padding-right: 0;
        display: $icon-font__display;
        content: "";
        background-repeat: no-repeat;
        background-image: $icon-qr-code;
        background-size: $account-nav-icon-size;
        width: $account-nav-icon-size;
        height: $account-nav-icon-size;
        line-height: $account-nav-icon-size;
        font-size: $account-nav-icon-size;
        background-color: $c-black;
        vertical-align: $icon-font__vertical-align;
        overflow: hidden;
    }
}

// Invoices
.account-nav_item.invoices span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-invoices,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size
    );
}


// Company
.account-nav_item.company span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-gear,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size
    );
}

// My Account
.account-nav_item.my-account span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-users,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size
    );
}

// Buying Tools
.account-nav_item.buying-tools span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-quicklists,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size
    );
}

// Buying Tools
.account-nav_item.navigation-resources span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-navigation-resources,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size
    );
}

.account-nav_item.invoices span:before {
    margin-right: 10px;
    padding-right: 0;
    display: inline-block;
    content: $icon-dollar;
    background-repeat: no-repeat;
    background-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 16px;
    font-size: 24px;
    vertical-align: middle;
    overflow: hidden;
}
