.fixed-header {
    position: fixed;
    display: none;
    height: 66px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    background: white;
    justify-content: space-between;
    align-items: center;
    padding: 12px 40px;
    margin: 0 !important;
    box-shadow: $box-shadow-base;
    transform: translate(0, -120%);
    transition: transform 0.2s linear;

    &.active {
        display: flex;
        transform: translate(0, 0);
    }

    &.bottom-location {
        bottom: 0;
        top: auto;
    }

    .fixed__addto {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    /// ----------------------------------------------
    // Title

    .page-title-wrapper.fixed__title {
        display: flex;
        overflow: hidden;
        margin-top: 0;
        margin-right: 20px;
        padding: 0;

        .page-title {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            font-size: $font-size-l;
            line-height: $line-height-l;
            min-height: 25px;
            max-height: 50px;
        }
    }

    /// ----------------------------------------------
    // Add To Cart

    .add-product {
        margin-left: $space-l;
    }

    .field.qty {
        margin-right: $space-base;

        .control {
            margin-top: 0;
        }

        .label {
            display: none;
        }
    }

    .box-tocart .fieldset {
        display: flex;
        align-items: flex-end;
    }

    .actions .primary.tocart {
        font-size: 16px;
        height: 42px;
    }

    .box-tocart .field .input-text.qty {
        height: 41px;
    }
}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-m - 1px) {
    .fixed-header {
        padding: 12px 15px;
        justify-content: flex-end;

        .page-title-wrapper.fixed__title {
            display: none;
        }

        .field.qty .qty {
            width: 48px;
        }

        .actions .primary.tocart {
            padding: 8px 18px;
        }
    }

    .fixed__addto {
        justify-content: flex-end;
        white-space: normal;

        .price-wrapper {
            display: flex;
            flex-direction: column;
        }
    }
}

// ----------------------------------------------
// Tablet View

@media (min-width: 768px) and (max-width: $bp-screen-m - 1px) {
    .fixed-header {
        justify-content: space-between;

        .page-title-wrapper.fixed__title {
            display: flex;
        }
    }
}

