/* Slider */
.banner-slick,
.category-additional {
    .slick-slide > div {
        border: 1px solid $gray-300;
        margin: 0 5px;

        &:hover {
            a {
                text-decoration: underline;
            }
        }
    }
}

.banner-slick,
.category-additional,
.comparison.compare-slick {
    background: $c-white;
    border: 1px solid $c-gray-border;
    margin-bottom: 15px;

    .item-container.non-bloomreach {
        background: $c-gray-border;
        opacity: 0.4;
    }

    .category-title {
        padding: 12px 5px;
        line-height: 1;

        span {
            color: $gray-850;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: inline-block;
            text-transform: uppercase;
        }
    }

    .slick-slider {
        position: relative;
        display: table;
        table-layout: fixed;
        width: 100%;
        box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 0;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        left: 0;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }

        .slick-loading & {
            visibility: hidden;
        }
    }

    .slick-slide {
        float: left;
        height: 130px;
        min-height: 1px;
        text-transform: uppercase;
        text-align: center;

        [dir="rtl"] & {
            float: right;
        }

        img {
            display: inline-block;
            height: 86px;
            padding-top: 10px;
        }

        &.slick-loading img {
            display: none;
        }

        display: none;

        &.dragging img {
            pointer-events: none;
        }

        .slick-initialized & {
            display: block;
        }

        .slick-loading & {
            visibility: hidden;
        }

        .slick-vertical & {
            display: block;
            height: auto;
            border: 1px solid transparent;
        }
    }

    .slick-arrow.slick-hidden {
        display: none;
    }

}

.banner-slick .slick-track {
    margin: 0;
}

.banner-slick__top {
    margin: $space-xxl 0 0;
    padding: 15px;

    &.panel {
        margin-bottom: 0;
    }
}
