//
//  Cards
//  _____________________________________________

@media (max-width: $bp-callout - 1px) {
    .callout_media, .callout_body {
        flex-basis: 100%;
    }

    .callout_body {
        padding: 0 $space-l;
    }
}
