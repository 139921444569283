/**
* East Coast Metal Distributors
*/


// ==============================================
// Catalog Widgets
// ==============================================

.block-product-link.widget,
.block-category-link.widget {
    display: block;
    margin-bottom: $space-l;
}

.block-product-link-inline.widget {
    margin: 0;
}

.block.widget .product-item-info {
    width: auto;
}

.block.widget .pager {
    padding: 0;
}

.block.widget .pager .toolbar-amount {
    float: none;
    font-size: $font-size-xs;
}

.block.widget .pager .pages-item-previous {
    padding-left: 0;
}

.block.widget .pager .pages-item-next {
    position: relative;
}

.block.widget .pager .items {
    white-space: nowrap;
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 100% / 3;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .block.widget .products-grid .product-item {
        width: 100% / 3;
    }

    .sidebar .block.widget .products-grid .product-item {
        margin-left: 0;
        width: 100%;
    }

    .sidebar .block.widget .products-grid .product-item .actions-secondary {
        display: block;
        padding: 10px 0;
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        width: 100% / 4;
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 100% / 2;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-l) {

    .block.widget .products-grid .product-item {
        width: 100% / 5;
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        margin-left: calc((100% - 5 * (100% / 6)) / 4);
        width: 100% / 6;

        &:nth-child(4n + 1) {
            margin-left: calc((100% - 5 * (100% / 6)) / 4);
        }

        &:nth-child(5n + 1) {
            margin-left: 0;
        }
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 100% / 4;
    }

    .block.widget .products-grid .product-items {
        margin: 0;
    }

    .block.widget .products-grid .product-item {
        margin-left: calc((100% - 4 * 24.439%) / 3);
        padding: 0;
        width: 24.439%;

        &:nth-child(4n + 1) {
            margin-left: 0;
        }
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
        display: none;
    }

    .sidebar .block.widget .pager .pages-item-next {
        padding: 0;
    }

    .sidebar .block.widget .pager .pages-item-next .action {
        margin: 0;
    }

}

@media (max-width: $bp-screen-m - 1px) {
    .product-item-info .mobile-info {
        display: flex;

        .right-side {
            width: 35%;

            .product-item-photo {
                height: auto;
            }
        }

        .left-side {
            padding-left: 15px;
            font-size: 12px;

            .product-item-name {
                height: auto;
                font-size: 14px;
            }
        }
    }
}


