/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Space
// ==============================================

$space-xxxl:  48px;
$space-xxl:   32px;
$space-xl:    24px;
$space-l:     20px;
$space-ll:     18px;
$space-base:  16px;

$space-s:     12px;
$space-xs:    8px;
$space-xxs:   4px;
$space-xxxs:  2px;
$space-xxxxs:  1px;


// ==============================================
// Units
// ==============================================

// Gap is the standard vertical spacing between modules.
$gap: $space-xxl;

// Gutter is the space between grid columns.
$gutter: $space-base;

// Liner is the whitespace between the content box and content.
$liner-base: $space-base;
$liner-l:    $space-xxl; // min-width: 900px

// Trim is the whitespace between the content and viewport edge.
$trim-base: $space-xs;
$trim-l:    $space-xl; // min-width: 900px


// ==============================================
// Elements
// ==============================================

// ----------------------------------------------
// Form

$form-elem-width-base: 40rem !default;
$form-elem-width-s:    28rem !default;
$form-elem-width-xs:   14rem !default;
$form-elem-width-xxs:  6rem  !default;
