.ordertemplates-requisition-view,
.quickorder-index-index {
    .quickorder-search {
        #search-input {
            padding-right: 40px;
        }
    }
}

.requisition_list-requisition-index {

    .table th {
        font-size: 14px;
        font-weight: normal;
        color: $c-darkgray;
    }

    .clickable-column {
        cursor: pointer;
    }

    .page-title-wrapper {
        position: relative;

        button {
            position: absolute;
            right: 0;
        }
    }

    .data-grid-actions-cell {
        text-align: left;
    }

    .action-select-wrap {
        ul li {
            display: inline;
            padding-right: 10px;

            .action-class-add_to {
                text-transform: uppercase;
                margin-bottom: 2px;
            }
        }
    }

    .flexing-here {
        display: flex;
    }

    .shared__container {
        padding-top: 10px;
        padding-left: 11px;
    }

    .list-name-field {
        padding: 12px 16px 12px 0;

        input {
            margin-right: 10px;
            cursor: pointer;
            margin-top: 4px;
        }

        .shared-visible.shared__container {
            text-transform: uppercase;
            cursor: pointer;
        }

        .un-shared-visible.shared__container {
            padding-left: 27px;

            span span {
                text-transform: uppercase;
                cursor: pointer;
            }
        }

        .cell-label-line-name {
            color: $c-action;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .toolbar {
        justify-content: flex-end;

        .toolbar-amount {
            margin-left: 0;
            padding-right: 10px;
        }

        .limiter .control {
            margin-top: 0;
        }
    }
}

.requisition_list-requisition-view {
    display: block;

    .tabs {
        z-index: 400;
    }

    .visible-seven {
        display: none;
    }

    .pager {
        text-align: right;
        padding: 5px;
        display: flex;

        .per-page {
            margin-left: auto;
            padding-right: 15px;
        }
    }

    textarea.order-template-area {
        padding: 5px;
    }

    input.order-template-input {
        padding: 0 5px;
    }

    .panel_header {
        position: relative;

        .toolbar-pricebook {
            margin-top: -7px;
            min-width: 260px;
        }
    }

    h1.page-title > span {
        padding-left: 10px;
    }

    h1.page-title .title-actions__container span,
    h1.page-title .title-actions__container a {
        font-size: 14px;
        display: inline;
        margin-right: 5px;
    }

    h1.page-title .title-actions__container .title-checkbox {
        margin-right: 5px;
    }

    .quicklist-count {
        margin-left: auto;
    }

    .action-group {
        width: 100%;

        a:hover {
            text-decoration: underline;
        }

        .delete__container {
            margin-left: auto;
        }

        .delete__container a {
            text-decoration: none;
        }
    }

    .action-group .menu.requisition-select-all {
        padding-right: 30px;
    }

    .bottom-actions__container {
        padding-bottom: 5px;

        .toolbar-pricebook {
            margin-top: 20px;
        }
    }

    .toolbar-pricebook p {
        @include lib-icon-font(
            $_icon-font-content: $icon-cloud-download,
            $_icon-font-size: 30px,
            $_icon-font-line-height: 30px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $gray-400
        );

        &:before {
            padding-right: 10px;
        }
    }

    .switch-new-order-template {
        background: $dark-magenta;
        font-weight: $font-weight-semibold;
        color: $c-white;
        padding: $space-s;

        .switch-slider {
            margin-right: $space-s;
        }
    }

    .checkbox-switcher {
        width: 45px;
    }

}

.ordertemplates-requisition-view {
    a.pagebuilder-button-link,
    button.pagebuilder-button-link,
    div.pagebuilder-button-link {
        font-weight: $font-weight-normal;
    }

    .price-container__price {
       .price-wrapper {
           flex-direction: column;
           .old-price {
               padding-left: 0;
           }
       }
    }

    .collapsible-table th {
        letter-spacing: normal;
    }

    .add-to-cart-sticky-bar {
        margin-right: 5%;
    }

    #DownloadTop {
        & > .button--secondary {
            width: calc(100% - 39px);
        }
    }

    .description-area {max-width: 500px}
}

.ordertemplates-requisition-view .action-group.top-actions,
.requisition_list-requisition-view .action-group.top-actions {
    .menu--short {
        display: flex;
        position: relative;
    }

    .menu_content {
        min-width: 223px;
        padding: 10px;
        z-index: 400;
        max-height: fit-content;
        text-align: left;
    }

    .menu_content .order-templates-filter {
        line-height: 1.2;
        margin: 5px 0 10px;

        .input-text {
            height: 35px;
            padding: 7px;
        }

        &:hover {
            text-decoration: none !important;
            background: none;
        }
    }

    .menu_content > li {
        line-height: 35px;
        padding: 0;

        &:hover {
            text-decoration: underline;
            background: none !important;
        }
    }

    .menu_content > li {
        position: relative;
    }

    .menu_content > li a {
        margin: 0;
        padding-left: 3px;
        color: $c-darkgray;
    }

    .menu_content > li.create-new-template a {
        padding-left: 20px;

        @include lib-icon-font(
            $_icon-font-content: $icon-add,
            $_icon-font-size: 18px,
            $_icon-font-line-height: 16px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $c-darkgray
        );

        &:before {
            left: 0;
            position: absolute;
            top: 18px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}


.ordertemplates-requisition-view .requisition-grid,
.requisition_list-requisition-view .requisition-grid {
    padding: 0 15px;

    .price-box--limit {
        display: none;
    }
}

.ordertemplates-requisition-view .requisition-grid table,
.requisition_list-requisition-view .requisition-grid table {
    td,
    th {
        vertical-align: top;
    }

    .product-item-subtotal {
        padding-top: 10px;
        white-space: nowrap;
    }

    .order-item-part__container {
        input,
        textarea {
            width: 100%;
        }
    }

    th {
        font-weight: normal;
        font-size: 14px;
        color: $c-darkgray;
        letter-spacing: normal;
    }

    td {
        padding: 12px 10px;
    }

    .action-links {
        button {
            height: 37px;
        }
    }

    td.col.actions {
        vertical-align: top;

        button {
            height: 37px;
        }

        .action-item {
            margin-top: 20px;
        }
    }

    .product-item-name a {
        color: $c-darkgray;
    }

    div.action-links {
        display: flex;

        .input__conatainer {
            padding-right: 15px;
            text-align: left;
            min-width: 190px;
        }

        .action-item {
            text-align: center;
            width: 100%;
        }

        a {
            white-space: nowrap;
            line-height: 36px;
        }
    }

    .inventory__container {
        margin-left: 90px;
        font-size: 13px;
    }

    .product-price {
        margin-top: 20px;
    }
}

.ordertemplates-requisition-view .content-wrapper,
.requisition_list-requisition-view .content-wrapper {
    display: block;
}

.ordertemplate__no-product {
    vertical-align: middle;

    & span {
        background: $gray-100;
        color: $c-darkgray;
        padding: $space-base;
        font-weight: $font-weight-normal;
        display: inline-block;
    }
}

.button--remove-product {
    margin-left: $space-xl;
}

@media (min-width: $bp-table-linearize) {
    .data-grid-search-control-wrap .field {
        display: flex;

        input.input-text {
            min-width: 400px;
        }

        .control {
            padding-right: 20px;
        }
    }

    .data-grid-search-control-wrap .actions {
        margin-top: 18px;

        button {
            margin-right: 10px;
        }
    }
}

//
//  Mobile - 700 px
//  _____________________________________________

@media (max-width: $bp-table-linearize - 1px) {
    .ordertemplates-requisition-view, .requisition_list-requisition-view {
        .share-mobile {
            padding: 15px 0;
        }

        .col.select [type='checkbox'] {
            margin: 2px;
        }
    }

    .requisition_list-requisition-index {
        .page-title-wrapper {
            position: relative;

            button {
                position: absolute;
                right: 0;
                left: 0;
                margin: auto !important;
                bottom: -60px;
                z-index: 2;
                margin-top: 0;
                width: 100%;
            }
        }

        .panel_body button {
            margin-top: 10px;
            width: 100%;
        }

        .shared__container {
            padding-top: 10px;
            padding-left: 0;
        }

        .data-grid-actions-cell {
            text-align: left;
            padding: 10px 0;

            ul li {
                padding-left: 0;
                padding-right: 10px;
            }
        }

        .toolbar-wrap {
            display: flex;
        }

        .toolbar {
            justify-content: flex-start;
        }
    }

    .ordertemplates-requisition-view,
    .requisition_list-requisition-view {
        .pager {
            text-align: left;
            padding: 5px;
        }

        .visible-seven {
            display: block;
            margin-bottom: 10px;
        }

        .panel_header {
            flex-wrap: wrap;
        }

        .display-block {
            display: block;
        }

        .pager-top .items.pages-items {
            position: absolute;
            right: 15px;
            top: 15px;
            z-index: 3;
        }

        textarea.order-template-area {
            width: 90%;
        }

        .requisition-content {
            position: relative;

            .requisition-select-all {
                position: absolute;
                bottom: 0;
                left: 0;
            }

            .quicklist-count {
                position: absolute;
                bottom: 0;
                right: 15px;
            }
        }

        .top-actions {
            margin-bottom: 40px !important;
        }

        .title-actions__container > span {
            padding-right: 20px;
            line-height: 35px;
        }

        .bottom-actions__container {
            .action-group {
                width: auto;
            }

            button,
            .button {
                width: 100%;
            }
        }

        .delete__container {
            margin-left: 15px !important;
            width: 100%;
        }

        .addproduct {
            margin-right: 7px;
        }
    }


}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m) {
    .ordertemplates-requisition-view {
        .price-container__price {
            .price-wrapper {
                flex-direction: inherit;

                .old-price {
                    padding-left: 0;
                }
            }
        }

        .product_numbers__container {
            margin: 10px 0;

            .product-attribute div {
                margin: 4px 0;
            }
        }

        .product_extra__container {
            margin: 4px 0;
        }
    }


    .ordertemplates-requisition-view .requisition-grid table,
    .requisition_list-requisition-view .requisition-grid table {
        thead {
            display: none;
        }

        div.action-links,
        .col.product {
            position: relative;
            display: block;
        }

        .col {
            margin-left: 20px !important;
        }

        .col.select {
            margin-left: 0 !important;
        }

        .col.select {
            padding: 0;
        }

        .col.qty {
            float: none;
        }

        .col.price-box .price-container__price {
            width: auto;
        }

        .inventory__container {
            margin: 0;
        }

        .product-item-image {
            position: absolute;
        }

        .product-item-name {
            margin-left: 85px;
            min-height: 90px;
        }

        tbody tr {
            padding: 12px 0;
        }

        tbody td {
            padding: 12px 0;
        }

        .input__conatainer input,
        .input__conatainer textarea {
            width: 100%;
        }

        .action-item {
            text-align: center;
        }

        .action-links button {
            width: 100%;
        }

        div.action-links > div {
            padding: 0;
        }

        .label {
            font-size: 14px;
            font-weight: normal;
            color: $c-darkgray;
        }
    }

    .ordertemplates-requisition-view,
    .requisition_list-requisition-view {
        .title-actions__container > span {
            padding-right: 20px;
            line-height: 35px;
        }

        .requisition-grid {
            .table--linearize {
                tr {
                    display: block;
                }
            }

            .col.select {
                position: absolute;
            }

            .col.qty {
                float: left;
                margin-right: 20px;
            }

            .col.subtotal {
                height: 58px;
            }

            .col.product {
                width: auto;
            }
        }

        .requisition-content {
            .requisition-select-all {
                position: absolute;
                bottom: 0;
                left: 0;
            }

            .quicklist-count {
                position: absolute;
                bottom: 0;
                right: 15px;
                width: auto;
            }
        }

        .panel_header {
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        .visible-seven {
            display: block;
        }

        .top-actions.m-b-base {
            margin-bottom: 40px !important;
        }

        div.not-linearized-none:not(.hide-mobile) {
            display: block !important;
        }

        .not-linearized-align-right {
            text-align: left !important;
        }
    }

    .requisition-grid {
        div.product-item-name,
        div.product-attribute,
        div.product-price {
            margin-left: 0;
        }
    }

    .ordertemplate__no-product {
        padding-top: $space-xxl;
        order: 2;
    }

    .button--remove-product {
        margin-left: 0;
    }
}


@media (max-width: $bp-table-linearize - 1px) {
    .quicklists-list {
        td:first-child .flat-button {
            margin-left: 12px;
        }
    }

}


//
//  Mobile small
//  _____________________________________________

@media (max-width: $bp-screen-xs) {
    .ordertemplates-requisition-view,
    .requisition_list-requisition-view {
        .manage-perms {
            white-space: pre-line;
        }
    }
}

@media (max-width: $bp-screen-s) {
    .ordertemplates-requisition-view,
    .requisition_list-requisition-view {
        .action-group > * {
            margin-top: 10px;
            width: 100%;
        }
    }
}
