/**
* East Coast Metal Distributors
*/


// ==============================================
// Search
// ==============================================

.account-bids-form {
    input[type="date"] {
        background-position-x: calc(100% - 10px);
    }

    .field-row.field-row--stack > * {
        flex-basis: 33.33%;
    }
}

// ==============================================
// Quote - line item comment
// ==============================================

.panel .account-table-bids .details-wrapper {
    display: flex;

    .bid-simple-table tr th,
    .bid-simple-table tr td {
        padding: 5px 10px 5px 0px;
    }

    .panel_info {
        flex-grow: 0.7;
    }
}

.table tbody tr.quote-item_comment td {
    border-top: 0;
    padding-top: 0;
}

.table tbody tr.quote-item--has-comment td {
    border-bottom: 0;
    padding-bottom: $space-xxs;
}

// ----------------------------------------------
// Linearized table

@media (max-width: $bp-table-linearize - 1px) {
    .table--linearize tbody tr.quote-item_comment {
        border-top: 0;
        padding-top: 0;
    }
    .table--linearize tbody tr.quote-item--has-comment {
        padding-bottom: $space-xxs;
    }
}

// ----------------------------------------------
// Mobile

@media (max-width: $bp-screen-s - 1px) {
    .quote-info {
        order: 1;
    }

    .quote-totals {
        order: 2;
    }

    .quote-header {
        flex-wrap: nowrap;
    }

    .quote-header_info {
        order: 1;
        width: auto;
    }

    .quote-header_action {
        order: 2;
        width: auto;

        .pricebook {
            justify-content: center;
        }
    }
}

// ----------------------------------------------
// Mobile & Tablet

@media (max-width: $bp-screen-m - 1px) {
    .panel .account-table-bids .details-wrapper {
        display: block;

        .quote-info {
            padding-bottom: 15px;
        }

        .quote-totals table {
            width: 100%;
        }

        .quote-totals table th.a-right {
            text-align: left !important;
        }
    }

    .mobile-body li {
        line-height: 20px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $c-gray;

        a {
            text-decoration: underline;
        }

        &:last-child {
            padding: 0;
            border: none;
        }
    }
}
