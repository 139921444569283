.pagebuilder-column-group {
    ul {
        margin: 15px 0;
        padding-left: 30px;

        li:not(.product-item) {
            list-style-type: disc;
        }
    }

    ol {
        margin: 15px 0;
        padding-left: 30px;

        li:not(.product-item) {
            list-style-type: decimal;
        }
    }
}

.pagebuilder-button-primary,
.pagebuilder-button-secondary {
    cursor: pointer !important;
    height: auto;
}


.widget-product-grid {
    .product-item {
        max-width: 100%;
        min-width: 50px;


        .price-box {
            word-wrap: break-word;

            .price {
                white-space: normal;
            }
        }

        .product-item-actions {
            .actions-primary {
                display: inline-block;
                max-width: 100%;
            }

            .actions-secondary {
                display: inline-block;
                width: auto;
            }

            .tocart {
                max-width: 100%;
                white-space: normal;
            }
        }
    }
}

.widget-product-carousel {
    .product-item-actions .actions-primary {
        display: block !important;
        visibility: hidden;
    }

    .product-item-actions .actions-primary--visible {
        visibility: visible;
    }
}

[data-content-type='row'] {
    ul {
        list-style: initial;
        padding-inline-start: 20px;
    }

    ol {
        list-style: decimal;
        padding-inline-start: 20px;
    }
}

[data-content-type='products'] {
    .block.widget .products-grid .widget-product-grid.product-items .product-item {
        padding: 16px;
        margin-top: 20px;
        margin-left: 20px;

        .actions-primary {
            width: 100%;
            padding: 0;
        }
    }

    .widget-product-carousel {
        .slick-next {
            right: -10px;
        }

        .slick-prev {
            left: -10px;
        }

        .slick-dots {
            padding: 0;
        }

        .slick-slide {
            margin: 8px;
        }

        .product-item {
            padding: 16px;
            margin: 0;
            flex-basis: 200px;
            background: $c-white;
            border: 1px solid $gray-300;
        }

        .product-item .product-item-name {
            height: 42px;
        }

        .product-item .actions-primary {
            width: 100%;
            padding: 0;
        }

        .menu_content {
            bottom: 35px;
            top: auto;
            left: 0;
        }

        .menu_toggle {
            @include lib-icon-font(
                $_icon-font-content: $icon-arrow-dropdown,
                $_icon-font-position: 'after',
                $_icon-font-size: 24px,
                $_icon-font-line-height: 24px
            );

            &::after {
                margin-top: -2px;
                width: 18px;
            }
        }

        .menu_content[aria-hidden=false] {
            display: flex;
            flex-direction: column;
        }

        .menu_content[aria-hidden=false] li:last-child {
            margin-top: auto;
        }
    }

    .block.widget .widget-product-grid {
        padding: 0;
        margin-left: -$space-l;
    }

    .widget-product-carousel {
        padding: 0;
        margin: 0 (-$space-xs) 0 (-$space-xs);
    }

    ol, ul {
        list-style: none;
    }
}

@media (min-width: $bp-screen-s - 1px) {

    [data-content-type='products'] {
        .product-item {
            .actions-primary form {
                display: flex;
            }
        }

    }
}

@media (max-width: $bp-screen-s - 1px) {

    [data-content-type='products'] {
        .product-item {
            .actions-primary form {
                display: block;
            }
        }

        .product-item-actions .actions-primary .field.qty {
            margin-bottom: 10px;
        }
    }
}

// ----------------------------------------------
// IE11

.ie11 {
    .pagebuilder-column,
    .pagebuilder-column figure,
    .pagebuilder-column-group,
    .row-full-width-inner,
    .page-main-full-width {
        min-height: 1px;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    [data-content-type='products'] {
        .block.widget .products-grid .widget-product-grid {
            padding: 10px;
        }
    }
}