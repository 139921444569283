/**
* East Coast Metal Distributors
*/

.parts-app {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.parts-link {
    display: flex;
    align-items: center;
    color: $c-blue-light;
}

.parts-link-icon {
    width: $space-l;
    height: $space-l;
    margin-right: $space-xxs;
}

.parts-links {
    margin-top: $space-base;
    display: flex;
}

// ----------------------------------------------
// Desktop

@media (min-width: $bp-screen-m) {
    .parts-app {
        flex-direction: row;

        thead tr {
            min-height: 30px;
            position: sticky;
            z-index: 3;
            top: 0;
        }

        section {
            border: 1px solid $c-gray;
        }

        .models-dropdown,
        .parts-search {
            flex: 0 1 calc(50% - #{$space-xl * 0.5});
        }

        .models-dropdown {
            margin-right: $space-xl;
        }

        .parts-component {
            flex: 0 0 100%;
        }
    }

    .parts-links {
        margin-top: $space-xxs;
    }
}
