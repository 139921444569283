/**
* East Coast Metal Distributors
*/


// ==============================================
// Page Main
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .account .page-main {
        position: relative;
    }

}


// ==============================================
// Columns
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .account .column.main {
        margin: 0;
        width: 100%;
    }

    .account.page-layout-2columns-left .sidebar-main {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .account.page-layout-2columns-left .sidebar-main {
        width: $account-nav-width;
        margin: 0 $layout-indent__width 0 ($layout-indent__width * -1); // [todo] abstract -16px to vars for "main" margins
        background: $account-nav-background;
    }

    .account .columns .column.main {
        flex: 1;
        padding-bottom: 0;
        max-width: calc(100% - #{$account-nav-width});
    }

}
