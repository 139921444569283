/**
* East Coast Metal Distributors
*/


// ==============================================
// Logo Wrapper
// ==============================================

.logo-wrapper {
    z-index: 5;
}

// ==============================================
// Logo
// ==============================================

.logo img {
    display: block;
}


// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .logo-wrapper {
        display: flex;
        align-items: flex-end;
        float: left;
        margin-left: 20px;
        position: relative;
        flex-grow: 1;
    }

    .header-logo {
        height: 40px;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .logo-wrapper {
        order: 2;
        flex-grow: 1;
        text-align: left;
    }

    .header-logo {
        width: 175px;
        height: auto;
    }
}
