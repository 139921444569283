/**
* East Coast Metal Distributors
*/


// ==============================================
// Legend
// ==============================================

.legend {
    @include h2;
    margin-bottom: $space-xs;
}


// ==============================================
// Context: .form--contrast
// ==============================================

.form--contrast .legend {
    color: $c-white;
}
