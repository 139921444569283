// ==============================================
// Button With Checkbox
// ==============================================

.button-checkbox {
    border: 1px solid $c-blue;
    padding-left: 12px;

    &.MuiFormControlLabel-root {
        margin: 0;
    }

    &.Mui-disabled {
        border-color: $gray-100;
    }

    .MuiFormControlLabel-label {
        color: $c-blue;
        font-size: 13px;
        font-weight: 500;
    }

    // ----------------------------------------------
    // Checkbox

    .MuiSvgIcon-root {
        color: $c-blue;
    }

    .Mui-disabled .MuiSvgIcon-root {
        color: $gray-100;
    }
}
