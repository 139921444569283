/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Text Color
// ==============================================

.text-warning { color: $c-text-warning !important; }
.text-danger  { color: $c-text-danger !important; }
.text-muted   { color: $c-text-muted-dark !important; }
.text-success { color: $c-text-success !important; }
.text-subtle { color: $c-gray-lighter !important; }

.text-contrast        { color: $c-text-contrast !important; }
.text-contrast-subtle { color: $c-text-contrast-subtle !important; }


// ==============================================
// Text Size
// ==============================================

.text-xxxxxxl   { font-size: $font-size-xxxxxxl !important; }
.text-xxxxxl    { font-size: $font-size-xxxxxl !important; }
.text-xxxxl     { font-size: $font-size-xxxxl !important; }
.text-xxxl      { font-size: $font-size-xxxl !important; }
.text-xxl       { font-size: $font-size-xxl !important; }
.text-xl        { font-size: $font-size-xl !important; }
.text-l         { font-size: $font-size-l !important; }
.text-base      { font-size: $font-size-base !important; }
.text-s         { font-size: $font-size-s !important; }
.text-xs        { font-size: $font-size-xs !important; }
.text-xxs       { font-size: $font-size-xxs !important; }


// ==============================================
// Text Transform
// ==============================================

.text-capitalize { text-transform: capitalize !important; }
.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }


// ==============================================
// Text Truncate
// ==============================================

.text-truncate {
    overflow:      hidden !important;
    width:         100% !important;
    text-overflow: ellipsis !important;
    white-space:   nowrap !important;
}


// ==============================================
// Text No Wrap
// ==============================================

.text-nowrap,
.nobr {
    white-space: nowrap !important;
}


// ==============================================
// Text Preformatting
// ==============================================

.text-preline {
    white-space: pre-line;
}
