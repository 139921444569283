/**
* East Coast Metal Distributors
*/

// ==============================================
// Product Inventory - container
// ==============================================

.product-inventory {
    background: $gray-100;
    padding: 10px;
    font-size: $font-size-s;
    width: clamp(150px, 200px, 240px);
    line-height: $space-ll;
}

.product-inventory__label {
    float: left;
    margin: 0 0 10px;
}

.product-inventory--limit .product-inventory__label {
    display: none;
}

.product-inventory__availability {
    font-weight: $font-weight-normal;
    float: right;
    margin: 0 0 10px;
}

.product-inventory--limit {
    display: flex;
    align-items: center;
}

.product-inventory__message {
    white-space: normal;
}


// ==============================================
// Inventory - stock
// ==============================================

.status:nth-child(2),
.stock {
    margin-top: 2px;
    clear: both;
}

.stock.available,
.stock.unavailable {
    font-weight: $font-weight-normal;
    vertical-align: top;
}

.stock.available .status {
    color: $c-inventory-available;
}

.stock.out-of-stock .status,
.stock.unavailable .status {
    color: $c-inventory-unavailable;
}

.status.available {
    color: $c-inventory-available !important;
}

.stock span {
    font-weight: $font-weight-normal;
}


// ==============================================
// Inventory
// ==============================================

.inventory {
    display: inline-block;
    margin: $space-base 0;
}

.product-info-main .inventory {
    height: 119px;
}

.inventory_instore {
    font-weight: 500;
}

.inventory_instore .inventory_count {
    min-width: auto;
}

.inventory_stock__item {
    line-height: 22px;
}

.inventory_stock.available {
    color: $c-inventory-available;
}

.inventory_stock.unavailable,
.inventory_stock.out-of-stock {
    color: $c-inventory-unavailable;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .inventory_list {
        max-width: 248px;
    }
}

// ==============================================
// Inventory Stock
// ==============================================

.inventory-stock {
    font-weight: $font-weight-bold;
    width: fit-content;
}

.inventory-stock--available {
    color: $c-green-light;
}

.inventory-stock--unavailable {
    .inventory_count {
        color: $c-black;
    }
}

.inventory-stock--limit-supersede {
    display: none;
}

// ==============================================
// Inventory List
// ==============================================

.inventory-list {
    border: 1px solid $gray-400;
    padding: $space-base;
    font-size: $font-size-s;
    font-weight: $font-weight-light;

    & > *:not(:last-child) {
        padding-bottom: $space-base;
    }
}

.inventory-list__nearby,
.inventory-list__all {
    font-size: $font-size-s;
    font-weight: $font-weight-light;
}

.inventory-list__item {
    line-height: 22px;
}

.inventory-list__count {
    font-weight: $font-weight-bold;
    min-width: 25px;
    display: inline-block;
}

.inventory-list__branch {
    margin: auto;
    display: inline;
}

.inventory-list__title {
    font-weight: $font-weight-bold;
    margin: 0;
}

.inventory-list__expand {
    font-weight: $font-weight-bold;
    @include lib-icon-font(
        $icon-add,
        $_icon-font-color: $gray-700,
        $_icon-font-size: 16px,
        $_icon-font-margin: -2px 2px 0 -4px
    )
}

.inventory-list__expand--expanded {
    @include lib-icon-font(
        $icon-minus,
        $_icon-font-color: $gray-700,
        $_icon-font-size: 16px,
        $_icon-font-margin: -2px 2px 0 -4px
    )
}
