@mixin fixed(
$_sticky_padding-y: $space-s,
$_sticky_padding-x: $space-s,
$_sticky_border_color: $c-gray-lighter, // @deprecated
$_sticky_border_size: 5px // @deprecated
) {
    position: fixed;
    top: 0;
    left: 0;
    padding: $_sticky_padding-y $_sticky_padding-x;
    margin: 0;
    background: $c-white;
    width: 100%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 10;
}
