/**
* East Coast Metal Distributors
*/

.ahri-details-view,
.catalog-product-view,
.checkout-cart-index,
.quickorder-index-index {
    .block-requisition-list {
        display: inline-block;
        width: 100%;

        .menu--short {
            display: flex;
            position: relative;
        }

        .add-template-default {
            text-transform: none;

            &:active,
            &:focus {
                background: $c-white;
                color: $blue-1000;

                &:hover {
                    background: $blue-1000;
                    color: $c-white;
                }
            }
        }
    }

    .block-requisition-list .dropdown-trigger {
        height: 100%;
        margin-left: -1px;
        min-width: 35px;
        padding: 0;

        &[aria-expanded=true] {
            background: $blue-1000;
            color: $c-white;
        }

        &[aria-expanded=false] {
            &:active,
            &:focus {
                background: $c-white;
                color: $blue-1000;

                &:hover {
                    background: $blue-1000;
                    color: $c-white;
                }
            }
        }
    }

    .requisition-list.active .menu_content,
    .dropdown-trigger.active ~ .menu_content {
        display: block;
        left: 0;
    }

    .requisition-list > button {
        width: 100%;
    }

    .block-requisition-list .dropdown-options {
        background: $c-white;
        border: 1px solid $c-gray;
        display: none;
        height: auto;
        min-width: 222px;
        opacity: 1;
        padding: 10px;
        right: 0;
        position: absolute;
        z-index: 400;

        &[aria-hidden=false] {
            display: block;
        }
    }

    .block-requisition-list .dropdown-options > li {
        line-height: 35px;
        padding: 0;

        a {
            color: $c-darkgray;
            margin: $space-base * 0.5 0;
        }
    }

    .block-requisition-list .dropdown-options > li.templates-filter {
        line-height: 1.2;
        margin: 5px 0 10px;

        .input-text {
            height: 35px;
            padding: 7px;
        }
    }

    .block-requisition-list .dropdown-options > li {
        position: relative;
    }

    .block-requisition-list .dropdown-options > li a {
        margin: 0;
        padding-left: 3px;
    }

    .block-requisition-list .dropdown-options > li.create-new-template a {
        padding-left: 20px;

        @include lib-icon-font(
            $_icon-font-content: $icon-add,
            $_icon-font-size: 18px,
            $_icon-font-line-height: 16px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $c-darkgray
        );

        &:before {
            left: 0;
            position: absolute;
            top: 8px;
        }
    }
}

.catalog-product-view,
.checkout-cart-index,
.quickorder-index-index {
    .page-title-wrapper {
        margin-top: -20px;
    }
}

@media (min-width: $bp-screen-m) {
    .catalog-product-view,
    .quickorder-index-index,
    .checkout-cart-index {
        .block-requisition-list {
            min-width: 248px;
            width: auto;
        }
    }
}

.checkout-cart-index,
.catalog-product-view {
    .block-requisition-list {
        padding: $space-base 0 0;
    }


    .block-requisition-list .dropdown-options > li {
        a {
            text-decoration: none;
        }
    }

    .block-requisition-list .dropdown-options > li a {
        > span {
            text-decoration: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .block-requisition-list .dropdown-options > li.create-new-template a {
        &:hover {
            &:before {
                text-decoration: underline;
            }
        }
    }
}

.quickorder-index-index {

    .page.messages {
        margin-top: -40px;
        margin-bottom: 20px;
    }

    .action-group .button {
        padding: 9px $space-base;
    }

    .block-requisition-list .dropdown-options > li {
        margin: 8px 0;
    }

    .block-requisition-list .dropdown-options > li a.add-to-requisition-list:hover {
        > span {
            text-decoration: underline;
        }
    }

    .block-requisition-list .dropdown-options > li.create-new-template a {
        &:hover > span {
            text-decoration: underline;
        }
    }

    .page-wrapper {
        .page-main .page-title-wrapper .page-title {
            margin-top: 20px;
        }
    }
}

.modal.requisition-popup .modal--show {
    z-index: 501;
}

@media (max-width: $bp-screen-m - 1px) {
    .ahri-details-view,
    .catalog-product-view,
    .checkout-cart-index,
    .quickorder-index-index {
        .block-requisition-list {
            .add-template-default {
                flex-grow: 1;
            }

            .dropdown-trigger {
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 22px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );
            }

            .dropdown-options {
                z-index: 401;
            }
        }

        .actions-group.active {
            .dropdown-options {
                display: block;
            }

            .dropdown-trigger {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .catalog-product-view {
        .block-requisition-list {
            max-width: calc(100vw - 2 * #{$space-base});

            .dropdown-options {
                width: calc(100vw - 2 * #{$space-base});
            }
        }
    }

    .quickorder-index-index {
        .block-requisition-list {
            width: 100%;

            .dropdown-options {
                width: calc(100vw - 2 * 28px);
            }
        }
    }
}
