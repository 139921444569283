/**
* East Coast Metal Distributors
*/


// ==============================================
// Product Image Wrapper
// ==============================================

.product-image-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 0;
}
