/**
* East Coast Metal Distributors
*/


// ==============================================
// Visibility
// ==============================================

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visually-hidden {
    @include lib-visually-hidden;
}

//
//  Desktop
//  _____________________________________________

@media (min-width: $bp-screen-m - 1px) {
    .visible-mobile {
        display: none;
    }
    .visible-mobile-liearize {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m) {
    .visible-desktop {
        display: none;
    }
}
