/**
* East Coast Metal Distributors
*/


// ==============================================
// Sortable
// ==============================================

.ui-sortable-helper {
    background: $c-white;
    box-shadow: $box-shadow-thin;
    height: auto !important;
}
