/**
* East Coast Metal Distributors
*/


// ==============================================
// Browser Support message
// ==============================================

.browser-support {
    padding: $space-base;
    text-align: center;
    background: $c-yellow;
}
