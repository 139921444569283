// ==============================================
// Product Preview
// ==============================================

.product-preview__wrapper {
    height: 100%;
}

.product-preview {
    display: flex;
    flex-direction: column;

    & > * {
        margin-bottom: $space-s;
    }
}

.product-preview__image {
    display: flex;
    justify-content: center;
    margin: 0 0 $space-s 0;
    position: relative;
}

.product-preview__page-title {
    font-size: $font-size-xxxl;
    margin: 0;
}

.product-preview .content-wrapper {
    margin: $space-xl (-$space-base) (-$space-base) (-$space-base);
}

.product-preview .product-attribute_value.description {
    font-size: $font-size-base;
}

.product-preview .documentation-item {
    text-transform: capitalize;
}

.product-preview-select {
    margin: (-$space-base) (-$space-base) 0;
    padding: $space-base;
    border-bottom: 1px solid $gray-100;
}

// ----------------------------------------------

.modal--product-preview .assistance {
    background: $c-gray-backgound;
    padding: $space-l;
}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-m - 1px) {
    .product-preview .content-wrapper {
        padding: 32px 24px !important;
    }
}
