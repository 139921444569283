/**
* East Coast Metal Distributors
*/


// ==============================================
// Vars
// ==============================================

$accordion-box-shadow: $box-shadow-thin !default;

$accordion-elem-width-base: 50rem !default;
$accordion-elem-width-s:    40rem !default;
$accordion-elem-width-xs:   28rem !default;


// ==============================================
// Accordion
// ==============================================

.accordion {
    max-width: $accordion-elem-width-base;
    margin-bottom: $margin-bottom;
}

// ----------------------------------------------
// Modifier: Size S

.accordion--s {
    max-width: $accordion-elem-width-s;
}

// ----------------------------------------------
// Modifier: Size XS

.accordion--xs {
    max-width: $accordion-elem-width-xs;
}


// ==============================================
// Accordion - Item
// ==============================================

.accordion_item {
    box-shadow: $accordion-box-shadow;
    background: $c-white;
    margin: 0;
    transition: margin, background-color $transition-base;
}

.accordion_item.active {
    transition: margin $transition-base;
}

.accordion_item.active:not(:first-child) {
    margin-top: 16px;
}

.accordion_item.active:not(:last-child) {
    margin-bottom: 16px;
}

.accordion_item:not(.active):hover {
    background: $c-background;
    transition: background-color $transition-base;
}


// ==============================================
// Accordion - Head
// ==============================================

.accordion_head {
    position: relative;
    flex-wrap: wrap;
    padding: $space-base 40px $space-base 24px;
    border-radius: $radius-base;
    cursor: pointer;

    @include lib-icon-font(
        $icon-pointer-down,
        $_icon-font-position: after,
        $_icon-font-color: $c-icon-base,
        $_icon-font-display: flex
    );

    &::after {
        position: absolute;
        top: 16px;
        right: 24px;
    }
}

.accordion_item.active .accordion_head {
    @include lib-icon-font(
        $icon-pointer-up,
        $_icon-font-position: after,
        $_icon-font-display: flex,
        $_icon-font-color: $c-icon-base
    );
}


// ==============================================
// Accordion - Title
// ==============================================

.accordion_title {
    min-width: 150px;
    padding-right: $space-base;
}


// ==============================================
// Accordion - Helper
// ==============================================

.accordion_helper {
    min-width: 150px;
    padding-right: $space-base;
    color: $c-text-muted;
}

.accordion_helper > span {
    color: $c-text-muted-light;
}


// ==============================================
// Accordion - Content
// ==============================================

.accordion_content {
    display: none;
    border-radius: $radius-base;
    padding: 0 24px $space-base 24px;
}

.accordion_item.active > .accordion_content {
    display: block;
}
