// ==============================================
// Order History List
// ==============================================

.order-history__toolbar {
    margin-bottom: 16px;
    overflow: hidden;
}

.order-history__controls {
    margin-bottom: 0;

    .fluid-list_item {
        align-items: flex-end;
    }
}

// ----------------------------------------------
// Tablet View

@media (min-width: $bp-fluid-list-2-columns) {

    .order-history__source-control.fluid-list_item {
        align-items: flex-start;
    }

}

// ==============================================
// Order History View
// ==============================================

.order-history__view {

    .requisition-list {
        display: inline-block;

        .menu_content {
            min-width: 240px;
        }
    }

}

.order-history__view-table {
    .product-attribute_value {
        white-space: nowrap;
    }

    .field.qty {
        display: none;
    }

    .tocart {
        min-width: 130px;
        width: 100%;
    }

    .col--price,
    .col--qty,
    .col--subtotal {
        white-space: nowrap;
    }

    .col--image img {
        width: 75px;
        max-width: none;
    }

    .col--red,
    .col--red .label {
        color: $c-error;
        font-weight: 500;
        white-space: nowrap;
    }
}

.product-serial {
    display: flex;
}

.product-serial__list {
    margin-left: 10px;
}

.order-history__totals {
    display: flex;
    justify-content: flex-end;

    .totals-summary {
        width: 260px;
        padding: 0 0 $space-base;
    }
}


// ----------------------------------------------
// Desktop View

@media (min-width: $bp-screen-m) {


    .order-history__view-table {
        .tocart {
            width: auto;
        }
    }

}

// ==============================================
// Order History Print
// ==============================================

@media print {

    .col--image {
        padding: 0;

        & * {
            display: none;
        }
    }

    .order-history__view-table {
        tbody tr:last-child {
            border-bottom: none;
        }
    }

}
