//
//  General
//  _____________________________________________

.compare-flyout {
    position: fixed;
    z-index: 401;
}

.flyout-container {
    position: fixed;
    z-index: 1;
    bottom: 0;
    height: 77px;
    background: $gray-350 ;
    border-top: 1px solid $c-black;
    width: 100%;
    padding-top: 5px;

    .button-container {
        padding-top: 14px;
        float: right;

        button.button {
            margin-left: 30px;
        }
    }

    .items-container {
        margin: 0 auto;
    }

    .items-to-compare {
        float: left;
        div {
            float: left;
            margin-left: 10px;
            margin-top: 4px;
            border: 1px solid $gray-100;
            padding-right: 3px;
            background: $c-white;
            padding: 3px;
            position: relative;

            span {
                @include lib-icon-font(
                    $_icon-font-content: $icon-close,
                    $_icon-font-size: 20px,
                    $_icon-font-line-height: 20px,
                    $_icon-font-text-hide: false,
                    $_icon-font-position: before,
                    $_icon-font-display: inline-block,
                    $_icon-font-color: $c-black
                );
                position: absolute;
                right: -13px;
                top: -9px;
                text-align: center;
                cursor: pointer;
                font-size: 16px;
                line-height: 16px;
                border-radius: 50%;
                background: $c-white;
            }
        }
    }

    .compare-close {
        position: relative;

        a {
            span {
                right: -5px;
                top: -34px;
                position: absolute;
                display: inline-block;
                text-decoration: none;
                background: $gray-350;
                border-radius: 50%;

                @include lib-icon-font(
                    $_icon-font-content: $icon-pointer-down,
                    $_icon-font-size: 34px,
                    $_icon-font-line-height: 34px,
                    $_icon-font-text-hide: false,
                    $_icon-font-position: before,
                    $_icon-font-display: inline-block,
                    $_icon-font-color: $c-black
                );

            }
        }
    }
}

.product-item {
    .compare-items {
        position: relative;

        .view-compare {
            position: absolute;
            right: 0px;
            top: -2px;
            text-decoration: underline;
            font-size: 12px;
        }
    }
}

.visible-mobile {
    display: none;
}
//
//  Desktop
//  _____________________________________________

@media (min-width: $bp-screen-m - 1px) {

}


//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {
   .flyout-container {
       height: auto;
       right: 0;

       .items-container {
           width: auto!important;
       }

       .button-container {
           padding-top: 10px;
           float: none;
           clear: both;

           button.button {
               margin: 10px 10%;
               width: 79%;
           }
       }

       .items-to-compare {

           div {
               margin-left: 15px;
           }
       }
   }
    .visible-mobile {
        display: block;
    }
}
