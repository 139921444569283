$table-border: 1px solid $c-gray-lighter;
$required-border: 2px solid $c-red;

.tab__content .additional-attributes-wrapper th {
    white-space: normal;
}

.tab__content .table-caption {
    display: none;
}

.catalog-product-view .MuiPaper-rounded {
    border-radius: 0;
}

.table--linearize tbody .ahri-table-cell_accessory {
    display: flex;
    flex-direction: column;
}

.ahri-table-cell_accessory-item {
    @include lib-icon-font(
        $_icon-font-content: $icon-expand,
        $_icon-font-size: $font-size-sl,
        $_icon-font-line-height: $font-size-sl,
        $_icon-font-vertical-align: middle
    );

    &:before {
        bottom: 1px;
        position: relative;
        right: 1px;
    }
}

#ahrilist-app {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
}

#ahri-scroll1 {
    width: 100%;
    overflow: scroll;
}



#ahri-scroll1-content {
    height: 20px;
}

.ahri-filter-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &:before {
        content: "";
        background: white;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.5;
    }

    .simple-loading {
        z-index: 20;
    }
}

// ==============================================
// Checkout
// ==============================================

.checkout-index-index .ahri-number {
    margin-top: $space-l;
    padding: $space-base;
    background: $gray-75;
}

// ==============================================
// Catalog
// ==============================================

.catalog-product-view .ahriTable {
    .ahri-loading {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;

        .simple-loading {
            margin: $space-xs;
        }
    }

    .ahri-required-filters-intro {
        margin-bottom: $space-xxl;
    }

    .attention {
        color: $c-red;
    }

    .required-filters .required-filter-row {
        display: flex;
        margin: $space-base 0;

        > div {
            display: flex;
        }

        > div p {
            margin-left: 0;
        }

        .selection-required .MuiOutlinedInput-input {
            border: $required-border;
            border-radius: 0;
            color: $c-red;
        }

        .selection-required .MuiFormHelperText-contained,
        .selection-required .MuiSelect-iconOutlined {
            color: $c-red;
        }

        .selection-required .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline,
        .selection-required .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline,
        .selection-required .MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
            border: none;
        }

        .MuiOutlinedInput-root {
            border-radius: 0;
        }

        .Mui-focused.label {
            color: $c-text;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $c-border;
        }

        .MuiPaper-root.MuiMenu-paper {
            border-radius: 0;
        }

        .MuiFormControl-root.no-selection .MuiSelect-icon {
            display: none;
        }
    }

    .ahri-table-cell:not(.ahri-table-cell-ahri_certified_ref) span {
        text-transform: capitalize;
    }

    .ahri-table-cell-ahri_certified_ref a {
        color: $c-text;
    }

    .table--linearize tbody tr {
        &.ahri-item-id {
            background: $c-gray-backgound;
        }

        &.ahri-item-action .ahriTable_row-componentAction {
            text-align: center;
        }
    }

    .system-price {
        display: block;
        font-size: $font-size-s;
        font-weight: $font-weight-bold;
    }

    .ahri-table-toolbar {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .product-select__container {
            width: 40px;
            margin: 5px 0;
        }

        .product-select {
           min-width: 115px;
            margin-left: -11px;
            height: 36px;
        }

        .ahri-toolbar-applied-filters {
            display: flex;
            flex-grow: 1;
            flex-wrap: wrap;
        }

        .ahriTable_columnContainer,
        .filters-container {
            display: flex;
        }

        .ahriTable_columnContainer .field-item {
            display: flex;
            line-height: $space-base;
            padding: $space-xxs 0 $space-xs $space-xxxs;
        }

        .filters-container {
            flex-wrap: wrap;
        }

        .filter-actions,
        .filters-selected {
            width: 100%;
        }

        .filters-selected .filter-cell-delete > .delete-icon {
            cursor: pointer;
            font-size: 0;
        }

        .filters-selected .filter-cell-delete > .delete-icon:disabled {
            cursor: not-allowed;

            &:before {
                color: $gray-500;
            }
        }

        .filter-cell.filter-cell-value {
            padding: 0 0 $space-xxs 0;
        }
    }

    .ahri-table-toolbar__filters {
        display: flex;
    }

    .ahri-toolbar-applied-filters .applied-filter {
        display: flex;
        align-items: center;
    }

    .remove-all-filters {
        text-decoration: underline;
    }

    .ahri-toolbar-applied-filters .remove-icon {
        cursor: pointer;
        font-size: 0;
        margin-left: (-$space-xxs);

        @include lib-icon-font(
            $icon-close,
            $_icon-font-size: $font-size-xxxl,
            $_icon-font-line-height: $font-size-xxxl,
            $_icon-font-text-hide: true,
            $_icon-font-color: $gray-700,
            $_icon-font-color-hover: $gray-700,
            $_icon-font-color-active: $gray-700
        );

        &.disabled {
            display: none;
        }

        &:hover:before {
            color: $c-darkgray;
        }
    }

    .remove-all-filters {
        font-size: $font-size-l;
        line-height: $font-size-xxxl;
    }

    .remove-all-filters:hover {
        color: $c-red;
        cursor: pointer;
    }

    .applied-filter {
        color: $c-darkgray;
        margin-right: $space-s;
    }

    .applied-filter label {
        line-height: $font-size-xxxl;
    }

    .ahri-table-toolbar .ahriTable_infoContainer,
    .ahri-table-toolbar .ahri-toolbar-filters {
        .toggle-area {
            position: relative;
        }

        .toggle-area-closed .toggle-content-wrapper {
            display: none;
        }

        .toggle-area-opened .toggle-content-wrapper {
            display: block;
            position: absolute;
            right: 0;
            top: 120%;
            z-index: 601;
        }

        .toggle-area-dropdown h3 {
            font-weight: bold;
            font-size: $font-size-base;
            margin-bottom: $space-xl;
        }

        .toggle-area-trigger {
            cursor: pointer;
            font-weight: bold;
            text-transform: uppercase;
        }

        .toggle-area-trigger > span:first-child {
            margin-right: $space-xxs;
        }

        .toggle-area-trigger:after {
            transform: rotate(0);
            transition: all .3s ease;
        }
    }

    .ahri-table-toolbar .ahriTable_infoContainer {
        .toggle-area-dropdown {
            padding: $space-base;
        }
    }

    .ahri-table-toolbar .ahri-toolbar-filters {
        .toggle-area-dropdown {
            padding: 1rem;
            position: relative;
        }

        .toggle-area-dropdown table {
            width: 100%;
        }

        .filter-action-add,
        .filter-action-apply {
            width: 100%;
        }

        .filter-action-add  .action.secondary.add-filter {
            background: $c-white;
            border: none;
            color: $c-gray-dark;
            font-weight: $font-weight-bold;
            margin-bottom: $space-base;
            text-transform: uppercase;

            &:before {
                position: relative;
                bottom: 2px;
            }

            &:hover {
                color: $c-darkgray;
            }

            &:disabled {
                color: $c-gray-lighter;
                cursor: not-allowed;
            }
        }

        .filter-action-add .add-filter {
            @include lib-icon-font(
                $_icon-font-content: $icon-expand,
                $_icon-font-size: $font-size-xxxl,
                $_icon-font-line-height: $font-size-xxxl,
                $_icon-font-vertical-align: middle
            );
        }

        .action.primary.apply-filter:disabled {
            background: $gray-600;
            cursor: not-allowed;
        }
    }

    .package-details {
        .label {
            color: $c-text;
            font-size: $font-size-base;
            margin-right: $space-xxs;
        }
    }

    .package-details-row {
        padding: 5px 0;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-50%);
    }
    100% {
        transform: translateY(0%);
    }
}

@media (min-width: $bp-screen-m) {
    .catalog-product-view .ahriTable {
        .ahri-table-wrapper {
            min-height: 400px;
        }

        .sticky-header-hidden,
        .table-scroll-actions-hidden {
            display: none;
        }

        .sticky-header-visible {
            overflow: hidden;
            position: sticky;
            top: 0;
            z-index: 1;
            animation: slideDown 200ms ease-in;
        }

        .sticky-header-scroll {
            margin-right: 15px;
        }

        .table-scroll-actions {
            display: flex;
            height: 0;
            top: 46px;
            width: 100%;
            z-index: 1;
        }

        .slick-arrow {
            position: relative;

            &.slick-prev {
                left: -16px;
                top: 0;
            }

            &.slick-next {
                margin-left: auto;
                right: -16px;
                top: 0;
            }
        }

        .ahri-required-filters-summary {
            margin-top: $space-base;
        }

        .required-filters .required-filter-row {
            > div {
                flex: 1;
                margin-right: $space-xl;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .filters-selected .filter-cell-delete > .delete-icon {
            @include lib-icon-font(
                    $icon-trash,
                $_icon-font-size: $font-size-xxxl,
                $_icon-font-line-height: $font-size-xxxl,
                $_icon-font-text-hide: true,
                $_icon-font-color: $gray-700,
                $_icon-font-color-hover: $gray-700,
                $_icon-font-color-active: $gray-700
            );
        }

        .ahriTable_columnContainer > div {
            margin-right: 50px;
        }

        .ahri-toolbar-filters {
            margin: 0 30px 0 auto;
        }

        .ahri-filter-loader {
            position: absolute;
        }

        .ahri-toolbar-filters .filter-actions {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin-top: $space-base;
        }

        .ahri-item-mobile,
        .ahri-content-mobile {
            display: none;
        }

        .table--linearize {
            border: $table-border;
            border-top: none;
        }

        .ahri-table-toolbar .filter-cell {
            padding: 0 $space-xxs $space-xxs 0;
        }

        .filter-cell.filter-cell-delete {
            padding: 0 0 $space-xxs $space-xxs;
        }

        .ahri-table-toolbar .ahriTable_infoContainer,
        .ahri-table-toolbar .ahri-toolbar-filters {
            .toggle-area-trigger {
                @include lib-icon-font(
                    $icon-pointer-down,
                    $_icon-font-position: after,
                    $_icon-font-color: $c-icon-base,
                    $_icon-font-display: flex
                );
            }

            .toggle-area-opened .toggle-area-trigger:after {
                transform: rotate(180deg);
            }
        }

        .ahri-filter-table {
            table-layout: fixed;

            .filter-column-delete {
                width: 2.5rem;
            }
        }

        th {
            background: $c-white;
            z-index: 1;
        }

        td {
            text-align: center;

            &.ahri-table-cell-ahri_certified_ref {
                text-align: left;
                max-width: 400px;
                white-space: normal;
            }
        }

        .ahriTable_systemSection {
            background: $c-gray-backgound;
        }

        .table--linearize tbody tr {
            border: $table-border;

            &:first-child {
                border-top: none;
            }

            &.ahri-item-units {
                border-bottom: none;
                border-top: none;

                label {
                    display: none;
                }
            }

            &.ahri-item-id {
                border-bottom: none;
            }

            &.ahri-item-id td {
                padding: $space-xxs $space-xs $space-xxs $space-base;
                text-align: left;
            }
        }

        .ahri-table-cell-ahri_certified_ref {
            padding-left: $space-base;
        }

        .table--linearize tbody tr.ahri-item-units .ahriTable_componentAction {
            border-right: $table-border;
        }

        .table--linearize thead tr.ahriTable_head-sections,
        .table--linearize thead tr.ahriTable_head-columns {
            border-bottom: none;

            .ahriTable_componentAction > div {
                border-right: $table-border;
                margin-right: -1px;
                width: calc(100% + 1px);

                @-moz-document url-prefix() {
                    margin-right: 0;
                }

            }
        }

        .ahriTable_head-columns th,
        .ahriTable_head-sections th {
            height: 100%;
            padding: 0;

            &.ahriTable_componentSection {
                background: $c-white;
            }

            &.ahri-table-cell-ahri_certified_ref > div {
                padding-left: $space-base;
            }

            > div {
                border-top: $table-border;
                display: inline-block;
                padding:  $space-s $space-xs;
                width: 100%;
            }
        }

        .ahriTable_head-columns th > div {
            border-bottom: $table-border;
        }

        .table-duplicate .ahriTable_head-columns th > div {
            border-bottom: none;
            border-top: none;
        }

        .ahriTable_head-columns th {
            text-align: center;

            &.ahri-table-cell-ahri_certified_ref {
                text-align: left;
            }
        }

        .ahriTable_head-sections th {
            border-top: none;
            font-weight: bold;
            text-align: center;
        }

        .table--linearize thead tr.ahriTable_head-sections .ahriTable_componentSection,
        .table--linearize thead tr.ahriTable_head-sections .ahriTable_systemSection {
            .ahriTable_componentAction {
                border-right: $table-border;
            }
        }

        .ahri-table-cell-ahri_certified_ref {
            width: 22%;

            > a,
            > span {
                display: block;
                width: 100%;
            }
        }

        .ahriTable_componentAction {
            width: 170px;
        }

        td.ahriTable_componentAction {
            text-align: center;
        }

        .system-price {
            margin-top: $space-xxs;
        }

        .ahri-table-toolbar .ahri-toolbar-filters {
            .toggle-area-dropdown {
                min-width: 430px;
            }
        }

        .ahri-table-cell {
            min-width: 40px;

            &-ahri_certified_ref {
                min-width: 270px;
            }

            &-eer,
            &-item_height,
            &-item_length,
            &-item_weight,
            &-item_width {
                min-width: 45px;
            }

            &-voltage {
                min-width: 70px;
            }

            &-Airflow {
                min-width: 85px;
            }

            &-availability {
                min-width: 150px;
            }
        }

        .ahri-table-cell-availability .product-inventory {
            text-align: left;
        }

        .package-details {
            column-count: 2;
        }
    }

    .catalog-product-view.fixed-tabs--top {
        .ahriTable .sticky-header-visible {
            top: 60px;
        }

        &.fixed__header .ahriTable .sticky-header-visible {
            top: 66px + 60px;
        }
    }
}

@media (max-width: $bp-screen-m - 1) {
    .catalog-product-view .ahriTable {
        #ahri-scroll1 {
            display: none;
        }

        .sticky-header-visible,
        .sticky-header-hidden,
        .table-scroll-actions-hidden,
        .table-scroll-actions {
            display: none;
        }

        .ahri-item-desktop {
            display: none;
        }

        .required-filters {
            margin: $space-l (-$space-l);
        }

        .ahri-required-filters-intro {
            padding: 0 $space-l;
        }

        .ahri-required-filters-summary {
            padding: $space-base $space-l;
        }

        .required-filters .required-filter-row {
            border-bottom: 1px solid $c-border;
            flex-wrap: wrap;
            padding: 0 $space-l $space-s;
            width: 100%;

            &:last-of-type {
                border-bottom: none;
            }

            > div {
                min-width: 250px;
                margin-top: $space-base;
                width: 100%;
            }

            > .label {
                font-size: $font-size-xl;
                font-weight: normal;
                padding: 0 $space-s;
            }

            .label {
                left: -$space-s;
                position: relative;
                top: $space-xxs;
            }
        }

        .table--linearize thead {
            display: none;
        }

        .table--linearize {
            tr,
            th,
            td {
                display: block;
            }

            tbody td {
                padding: 0;
                border: 0;
            }
        }

        .table--linearize tbody tr {
            &.ahri-item-units {
                border-bottom: none;
                display: flex;
                flex-wrap: wrap;
            }

            &.ahri-item-units label {
                margin-right: 4px;
            }

            &.ahri-item-id {
                border-top: $table-border;
                font-weight: bold;
                margin-top: $space-base;
                padding: $space-xxs $space-xxs $space-xxs $space-xs;

                &:first-child {
                    border-top: none;
                }
            }

            &.ahri-item-id td {
                line-height: $font-size-l;
                position: relative;
                top: 1px;
            }

            &.ahri-item-action {
                border-bottom: none;
                padding-top: 0;
            }

            &.ahri-item-action .ahriTable_row-componentAction {
                display: block;
                width: 100%;
            }

            &.ahri-item-action .ahriTable_row-componentAction .action.primary {
                width: 100%;
            }

            .ahri-table-cell {
                line-height: 1.6;
            }

            .ahri-table-cell.ahri-table-cell-availability {
                width: 100%;
            }

            .ahri-table-cell-ahri_certified_ref {
                display: flex;
                flex-wrap: wrap;
                padding: $space-xs 0;
            }

            .ahri-table-cell-ahri_certified_ref * {
                display: block;
            }

            .ahri-table-cell-ahri_certified_ref span {
                margin-top: $space-xs;
                width: 100%;
            }

            .ahri-table-cell-ahri_certified_ref .ahri-row-product + .ahri-row-product {
                flex: 0 0 100%;
            }

            .ahriTable_componentSection,
            .ahriTable_systemSection {
                width: 50%;
            }

            .ahriTable_componentAction {
                display: none;
            }

            .system-price {
                margin-top: $space-s;
            }
        }

        .ahri-table-toolbar {
            flex-wrap: wrap;
            margin-top: $space-xl;

            .ahri-toolbar-applied-filters {
                order: 2;
            }

            .ahri-table-toolbar__filters {
                order: 1;
            }
        }

        .filter-row {
            padding: $space-base $space-s $space-l;
            border-bottom: 1px solid $c-gray-lighter;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .filters-selected tbody {
            margin: 0 (-$space-s);
        }

        .ahri-table-toolbar .filter-cell {
            padding: 0 0 $space-s 0;

            &.filter-cell-name {
                order: 2;
            }

            &.filter-cell-condition {
                order: 3;
            }

            &.filter-cell-value {
                order: 4;
            }

            &.filter-cell-delete {
                order: 1;
            }
        }

        .filters-selected .filter-cell-delete {
            display: flex;
        }

        .filters-selected .filter-cell-delete > .delete-icon {
            margin-left: auto;
            @include lib-icon-font(
                $icon-trash,
                $_icon-font-size: $font-size-xxxll,
                $_icon-font-line-height: $font-size-xxxll,
                $_icon-font-text-hide: true,
                $_icon-font-color: $gray-700,
                $_icon-font-color-hover: $gray-700,
                $_icon-font-color-active: $gray-700
            );
        }

        .filters-selected .filter-cell-delete > .label {
            color: $c-text;
            font-size: $font-size-xl;
            font-weight: $font-weight-normal;
            line-height: $font-size-xxxll;
        }

        .ahriTable_columnContainer > div {
            width: 50%;
        }

        .ahri-toolbar-applied-filters {
            margin: $space-base 0 0;
            width: 100%;
        }

        .applied-filter,
        .remove-all-filters {
            margin-bottom: $space-s;
        }

        .remove-all-filters {
            width: 100%;
        }

        .ahri-toolbar-filters,
        .ahriTable_infoContainer {
            width: 50%;
        }

        .ahri-table-toolbar__filters {
            width: 100%;
            justify-content: space-between;

            & > * {
                flex: 0 1 calc(50% - 5px);
            }
        }

        .ahri-toolbar-filters .filter-actions {
            margin: $space-xl (-$space-s);
            padding: 0 $space-s;
            padding-bottom: 1rem;
            width: calc(100% + (2 * #{$space-s}));

            .filter-action-add,
            .filter-action-apply {
                text-align: center;
                width: 100%;
            }

            .filter-action-apply button.apply-filter,
            .filter-action-reset button.reset-filter {
                width: 100%;
            }
        }

        .filters-container {
            padding-bottom: $space-xxxl;
            margin-bottom: $space-xxxl;
        }

        .ahri-table-toolbar .ahriTable_infoContainer,
        .ahri-table-toolbar .ahri-toolbar-filters {
            .toggle-area-opened .toggle-content-wrapper {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                overflow: auto;
            }

            .toggle-content-wrapper-title {
                display: block;
                font-size: $font-size-xxxl;
                margin-bottom: $space-base;
            }

            .ahriTable_close {
                text-align: right;
                width: 100%;

                @include lib-icon-font(
                    $_icon-font-content: $icon-clear,
                    $_icon-font-size: $font-size-xxxll,
                    $_icon-font-line-height: $font-size-xxxll,
                    $_icon-font-color: $c-darkgray,
                    $_icon-font-position: after
                );
            }

            .ahriTable_close span {
                font-size: $font-size-xs;
                text-decoration: underline;
            }

            .toggle-area-trigger {
                @include button-base;
                display: block;

                @include button-style (
                    $background         : transparent,
                    $background--hover  : $c-blue,
                    $background--active : $c-blue,
                    $color              : $c-blue
                );

                line-height: 18px;
                border: 1px solid $c-blue;

                &:hover, &:active, &:focus {
                    color: $c-white;
                }

                &:disabled {
                    border-color: $c-gray;
                }
            }

            .toggle-area-flush-fit {
                @include button-base;
                display: block;

                @include button-style(
                    $background: transparent,
                    $background--hover: $c-blue,
                    $background--active: $c-blue,
                    $color: $c-blue
                );

                line-height: 18px;
                border: 1px solid $c-blue;

                &:hover, &:active, &:focus {
                    color: $c-blue-dark;
                    background: $c-white;
                }

                &:disabled {
                    border-color: $c-gray;
                }

                &:active {
                    color: $c-blue-dark;
                    background: $c-white;
                }

                &.active-area {
                    &:hover, &:active, &:focus {
                        color: $c-white;
                        background: $c-blue-dark;
                    }

                    &:disabled {
                        border-color: $c-gray;
                    }

                    &:active {
                        color: $c-white;
                        background: $c-blue-dark;
                    }
                }
            }
        }

        .filters-selected {
            table,
            tbody,
            tr,
            td {
                display: block;
            }

            thead {
                display: none;
            }

            tr {
                display: flex;
                flex-wrap: wrap;
            }

            td {
                width: 100%;
            }
        }
    }
}

// ==============================================
// AHRI Details Page
// ==============================================

.ahri-details_table {
    width: 100%;
    margin-bottom: 0;

    .order-total {
        font-weight: $font-weight-bold;
    }
}

.ahri-details_group {
    border-top: 1px solid $c-gray-lighter;
    padding: $space-base 0 ($space-base - $space-s);

    .table tbody tr {
        border: none;
    }

    .col--image {
        width: 94px;
        position: relative;

        img {
            max-width: 78px;
            max-height: 78px;
        }
    }

    .col--name {
        a {
            color: $c-darkgray;
        }
    }

    .col--inventory {
        width: 30%;
        min-width: 200px;
    }

    .product-inventory {
        margin-top: 0;
    }

    h2 {
        margin-bottom: $space-xs;
    }

    &:first-child {
        border-top: none;
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    .badge-list {
        margin-bottom: $space-xs;
    }
}

.ahri-details_actions-title {
    font-weight: $font-weight-bold;
}

.ahri-details_summary,
.ahri-details_tocart,
.ahri-details_quickorder {
    margin-bottom: $space-base;
    width: 100%;
}

.ahri-details_tocart .action.tocart {
    font-size: $font-size-xl;
}

.ahri-details_tocart {
    .tocart {
        width: 100%;
    }
}

.ahri-details_quickorder {
    .menu > button {
        width: 100%;
    }

    button {
        background: $c-white;
    }
}
.ahri-details-view .price-message {
    padding: 5px 0;
}

.ahri-details-view .block-requisition-list {
    .menu .actions-group {
        position: static;
    }

    .dropdown-options {
        border: 1px solid $c-border;
        box-shadow: $box-shadow-base;
        width: 100%;
    }
}

.ahri-details-view .requisition-list {
    display: flex;
    justify-content: center;
}

// ----------------------------------------------
// Mobile

@media (max-width: $bp-screen-m - 1px) {

    .ahri-details {
        display: flex;
        flex-direction: column-reverse;
        height: auto;
    }

    .catalog-product-view .ahriTable .ahri-table-toolbar .product-select__container {
        margin: 14px 0 15px 0;
    }

    .ahri-details_group {
        .col.image {
            margin-bottom: $space-s;
        }

        .product-attribute {
            padding-top: $space-xs;
        }
    }
}

// ----------------------------------------------
// Linearized tabled

@media (max-width: $bp-table-linearize - 1px) {
    .table--linearize tbody .ahri-table-cell_accessory--empty {
        display: none;
    }
}

// ----------------------------------------------
// Desktop

@media (min-width: $bp-screen-m) {

    [data-sidescroll="scroll"] #ahri-scroll2-content.gradient:after {
        content: " ";
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        width: 20%;
        background: linear-gradient(to right, transparent, white);
    }

    .ahri-details {
        display: flex;
        justify-content: flex-start;
    }

    .ahri-details_content {
        flex: 0 1 960px;
    }

    .ahri-details_sidebar {
        flex: 1 0 320px;
        max-width: 400px;
        margin-left: 40px;
    }

    .ahri-details_actions {
        position: sticky;
        top: $space-xxl;
    }

    .ahri-details_group {
        padding: $space-base 0;

        h2 {
            margin: $space-xs 0 $space-s;
        }
    }

    .ahri-details_info {
        tbody {
            display: block;
            column-count: 2;
        }

        tr {
            display: inline-block;
            padding: 2px 0;
            width: 100%;
        }

        th, td {
            padding: 0 10px 0 0;
        }

        th:after {
            content: ':';
        }
    }
}

// ----------------------------------------------
// Table linearize

@media (max-width: $bp-table-linearize - 1px) {

    .ahri-details_group {
        .col--inventory {
            width: 100%;
            margin-top: $space-s;
        }
    }

}

// ----------------------------------------------
// Ahri Related Products
// ----------------------------------------------

.ahri-details-view .ahri-related-content {
    .ahri-related-empty,
    .ahri-loading {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: $space-xl;
    }

    .ahri-loading {
        > span:first-child {
            margin-right: $space-base;
        }

        .simple-loading {
            margin: 0;
        }
    }

    .ahri-details_group,
    .ahri-related-info,
    .ahri-related-details,
    .pricing-data {
        display: flex;
    }

    .ahri-related-info {
        flex-wrap: wrap;
    }

    .ahri-related-actions {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        min-width: 200px;

        .ahriDetails_inventory {
            margin-top: auto;
        }
    }

    .col--name,
    .ahri-related-details {
        width: 100%;
    }

    .col--product-attributes {
        padding: $space-s $space-xs;
    }

    .product-attribute_value {
        .label {
            margin-right: $space-xxs;
        }

        .value {
            color: $c-text;
        }
    }

    .pricing-data {
        align-items: baseline;
        justify-content: flex-start;
        margin-bottom: $space-xxs;

        .price {
            font-size: $font-size-l;
            font-weight: $font-weight-bold;
        }

        .label {
            display: none;
        }
    }

    .ahri-related-select {
        display: flex;
        justify-content: flex-end;
        margin-bottom: $space-xs;
        padding: 0 $space-xs $space-xxs;

        .ahri-related-item-selection {
            border: 1px solid $c-blue;
            padding-left: $space-s;
        }

        .ahri-related-item-selected {
            border: none;
        }

        .MuiFormControlLabel-label {
            color: $c-blue;
            font-size: $font-size-s;
            font-weight: $font-weight-semibold;
        }

        .MuiSvgIcon-root {
            color: $c-blue;
        }

        .field.qty {
            margin-bottom: 0;
            padding-top: $space-xxs;
        }

        .field.qty .control {
            margin-top: 0;
        }

        .field.qty label {
            margin-right: $space-xxs;
            position: static;
        }
    }
}

.ahri-matchup-loading {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: $space-xl;
    min-height: 120px;

    & > span:first-child {
        margin-right: $space-base;
    }

    & .simple-loading {
        margin: 0;
    }
}

@media (min-width: $bp-screen-m) {
    .ahri-details-view .ahri-related-content {
        .ahri-related-info {
            max-width: 70%;
            padding-right: $space-l;
        }

        .ahri-related-actions {
            padding: 0 $space-xs $space-s;
            width: 30%;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .ahri-details-view .ahri-related-content {
        .ahri-details_group {
            flex-wrap: wrap;
            padding-bottom: $space-base;
        }

        .ahri-related-actions {
            width: 100%;

            .pricing-data {
                order: 1;
            }

            .ahriDetails_inventory {
                margin-bottom: $space-xs;
                order: 2;
            }

            .ahri-related-select {
                order: 3;
            }

            .ahri-related-select .MuiFormControlLabel-labelPlacementStart {
                margin-left: 0;
                margin-right: -$space-xs;
            }
        }
    }
}

// ==============================================
// Callouts
// ==============================================

.ahri-callout {
    display: flex;
    flex-direction: column;
}

.ahri-callout__item {
    @extend .panel;

    margin-bottom: $space-base;
    padding: $space-base;

    &-title {
        color: $c-text-muted-dark;
        font-size: $font-size-s;
    }

    &-value {
        font-weight: $font-weight-bold;
        font-size: $font-size-xxl;
    }

    &-value--green {
        color: $c-green-light;
    }
}


@media (min-width: $bp-screen-m) {
    .ahri-callout {
        flex-direction: row;
    }

    .ahri-callout__item {
        flex: 1;

        &:not(:first-child) {
            margin-left: $space-base;
        }
    }
}

// ==============================================
// Sticky Add to cart
// ==============================================

.ahri-summary-sticky {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
}

@media (max-width: $bp-screen-m - 1px) {
    .ahri-summary-sticky {
        @include fixed($space-xs, 15px);
    }
}
