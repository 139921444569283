// ==============================================
// Save Cart Buttons
// ==============================================

.minicart-save-cart-buttons {
    grid-column: 1 / span 2;
}

.save-cart-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 12px;

    margin-top: $space-xs;
    width: 100%;

    .cart-container .checkout-methods-items & {
        margin: $space-xs $space-xs 0 $space-xs;
    }
}

.save-cart-buttons__item {
    display: inline-flex;

    & > div,
    .flat-button {
        width: 100%;
    }
}

.form--save-cart-title {
    width: fit-content;

    .field {
        margin-bottom: 0;
        margin-right: $space-xs;
    }

    .control {
        margin: 0;
    }
}

.save-cart-share-button,
.save-cart-share-button .button {
    width: 100%;
}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-m - 1px) {

    .form--save-cart-title {
        margin-top: -4px;
    }

    .save-cart-shared-with__list {
        padding: $space-xxs 0;
    }

    .save-cart-shared-with__item {
        line-height: $line-height-xxs;
    }
}

// ----------------------------------------------
// Table View

@media (max-width: $bp-table-linearize - 1px) {

    .col--save-cart-share {
        position: absolute;
        top: $space-s;
        right: 0;
        padding: 0 !important;
    }

    .col--shared .label {
        display: none;
    }
}
