.fixed-tabs__wrapper {
    height: 60px;
    display: none;

    .fixed-tabs {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $gray-400;

        .title {
            padding: 18px 12px;
            margin: 0 10px;
            position: relative;
            display: block;
            cursor: pointer;

            &.active:after,
            &:hover:after {
                content: '';
                display: block;
                height: 3px;
                background: $red-100;
                bottom: 0;
                position: absolute;
                left: 0;
                width: 100%;
            }

            &:not(.active):hover:after {
                background: $gray-350;
            }

            h2 {
                margin: 0;
            }
        }
    }
}


// ----------------------------------------------
// Desktop View


@media (min-width: $bp-screen-m) {

    .fixed-tabs__wrapper {
        display: block;

        & ~ .title {
            display: none !important;
        }

        .fixed-tabs {
            &.fixed-tabs--bottom,
            &.fixed-tabs--top {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                background: white;
                z-index: 20;
            }

            &.fixed-tabs--bottom {
                box-shadow: $box-shadow-base--top;
            }

            &.fixed-tabs--top {
                box-shadow: $box-shadow-base;
                bottom: auto;
                top: 0;
            }
        }
    }

    .fixed__header .fixed-tabs__wrapper .fixed-tabs--top {
        top: 66px;
        z-index: 20;
        border-top: 1px solid $gray-400;
    }

}


// ----------------------------------------------
// Mobile View


@media (max-width: $bp-screen-m - 1px) {

    .product.data.items > .fixed-tabs__wrapper {
        & ~ .item.title {
            height: 50px;

            & > .switch {
                background: none !important;
                border: none !important;
                padding-left: 30px !important;
            }

            &:before {
                left: 0;
            }
        }

        & ~ .item.content {
            padding: 0 !important;
            border: none !important;
        }
    }

}
