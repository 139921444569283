// ==============================================
// Pricebook Download
// ==============================================

.pricebook {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricebook__icon {
    color: $gray-400;
    margin-right: 6px;
}

.pricebook__list {
    display: flex;
}

.pricebook__item {
    margin-left: 6px;
}

.pricebook__link {
    line-height: 36px;
    display: inline-flex;
}
