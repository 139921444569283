@mixin badge-new {
    @include badge(
        $_badge_text: 'New',
        $_badge_background_color: $c-red-light
    );
}

@mixin badge(
    $_badge_text: '',
    $_badge_text_color: $c-white,
    $_badge_background_color: $c-gray-dark,
) {
    &:after {
        content: $_badge_text;
        display: inline-block;
        color: $_badge_text_color;
        background: $_badge_background_color;
        padding: 5px 6px;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 0.9;
        border-radius: 10px;
        transform: translate(5px, -1px);
    }
}
