/**
* East Coast Metal Distributors
*/

// ==============================================
// Vars
// ==============================================


$sidebar-width: 200px;
$bp-product-view-heading-stack: 700px;

.catalog-product-view {
    .page-main {
        padding-top: $space-l;
    }

    b {
        font-weight: 500;
    }

    .price-message {
        padding: 5px 0;
    }

    .also-purchased.hide-non-related {
        display: block;
        margin-bottom: 15px;
    }

    .inventory {
        height: auto;

        #inventory-loading,
        .inventory_instore {
            display: block;
        }

        .inventory_all,
        .inventory_nearby,
        .product-inventory_availability {
            display: none;
        }
    }

    .product-addto-links {
        #inventory-loading,
        .inventory_instore {
            display: none;
        }

        .inventory_all,
        .product-inventory_availability,
        .inventory_nearby {
            display: block;
        }
    }

    .read-more-link {
        text-decoration: underline;

        &:hover {
            color: $c-black;
        }
    }

    .read-more-dots,
    .read-more-link {
        display: inline-block;
        position: relative;
        right: 3px;
    }

    .data.item.content h2 {
        font-size: $font-size-xl;
    }

    .box-tocart {
        .control {
            margin-top: 9px;
        }

        .input-text.qty {
            height: 47px;
        }

        .input-text.qty:not(.hasValue):not(:focus) ~ label {
            left: 10px;
            top: 18px;
        }

        .input-text.qty + .mage-error {
            min-width: 240px;
            position: absolute;
        }
    }
}

.content-wrapper--product-info {
    background: none;
}

.sign-in-to-view {
    font-weight: $font-weight-bold;
    font-size: $font-size-s;
}

// ==============================================
// Product View - Col 1
// ==============================================

.product-view_col1 {
    flex: 1 1 auto;
    align-self: flex-start;
    position: sticky;
    top: $space-xxl + 80px;

    canvas,
    [type='image'],
    .s7button {
        cursor: pointer;
    }
}

.product-view_col1 .product-attribute {
    display: inline-block;
}

.product-view_col1 .product-attribute .mfg {
    margin-left: $space-l;
}

.product-view_col1 .page-title-wrapper .page-title {
    margin-bottom: 0;
}

.product-view_col1 .product.photo .photo.image {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}

.product-view_col1 .placeholder .photo.container {
    max-width: 100%;
}

.product-view_col1 .notice {
    margin: $space-base 0;
    color: $c-text-muted;
    font-size: $font-size-xs;
}

.product-view_col1 .product.thumbs {
    margin: $space-l 0 $space-xxl;
}

.product-view_col1 .items.thumbs > li {
    display: inline-block;
    vertical-align: top;
}

.product-view_col1 .items.thumbs .active {
    display: block;
    line-height: 1;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .catalog-product-view {
        .content-wrapper {
            display: flex;
            justify-content: center;

            .product.info.detailed {
                max-width: $bp-screen-l;
                margin: $space-base;
                width: 100%;
            }
        }

        .product-info-brand {
            top: -5px;
            position: relative;
        }

        .page-header {
            z-index: 501;
        }

        [role="dialog"] {
            z-index: 502;
        }

        .page-footer {
            z-index: 500;
        }

        .product.data.items {
            @include lib-data-tabs(
                $_tab-content-border: 1px solid $gray-400,
                $_tab-content-padding-bottom: 2 * $space-base,
                $_tab-content-padding-left: 0,
                $_tab-content-padding-right: 0,
                $_tab-content-padding-top: 2 * $space-base,
                $_tab-control-background-color: $c-gray,
                $_tab-control-background-color-active: $c-white,
                $_tab-control-border-bottom-color-active: $c-red,
                $_tab-control-border-color: $gray-400,
                $_tab-control-border-width: 1px,
                $_tab-control-line-height: 24px
            );

            & > .fixed-tabs__wrapper ~ .item.content {
                border: none;
                margin-top: 0;
            }
        }

        .product-social-links {
            + .product-attribute {
                margin-bottom: $space-base;
            }
        }

        #description {
            .product-attribute {
                display: inline-block;
                padding-right: 20px;
                width: 74%;
            }

            .product-attribute_value {
                font-size: $font-size-l;
            }

            .documentation-item {
                display: inline-block;
                vertical-align: top;
                width: 25%;
            }
        }
    }

    .product-details_wrapper {
        margin-right: calc(#{$sidebar-width} + #{$space-base});
    }

    .product-view_col1,
    .product-view_col2 {
        max-width: 50%;
        width: 50%;
    }

    .product-view_col1 {
        padding-right: 2 * $space-base;
    }

    .product-view_col2 {
        padding-left: 0;
    }
}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-m - 1px) {
    .catalog-product-view {
        .content-wrapper {
            padding-left: 0;
            padding-right: 0;
        }

        .product-info-brand {
            top: -15px;
            position: relative;
        }

        .box-tocart .actions button {
            width: 100%;
        }

        .column.main {
            display: flex;
            flex-direction: column;
        }

        .product.data.items {

            @include lib-data-accordion(
                $_accordion-content-margin: 0,
                $_accordion-control-background-color: $c-background,
                $_accordion-control-background-color-active: $c-background,
                $_accordion-control-background-color-hover: $c-lightgray,
                $_accordion-control-border-bottom: 1px solid $c-gray,
                $_accordion-control-border-top: none,
                $_accordion-control-height: 50px,
                $_accordion-control-line-height: 34px,
                $_accordion-control-margin-bottom: 0,
                $_accordion-control-padding-left: 45px,
                $_accordion-control-font-weight: $font-weight-normal
            );
            z-index: 1;
            margin: 15px;

            .data.switch {
                height: 100%;
            }
        }

        .switch {
            font-family: inherit !important;
            border-bottom: 1px solid $gray-400 !important;
            border-left: 1px solid $gray-400 !important;
            border-right: 1px solid $gray-400 !important;
            //font-size: 14px !important;
            color: $gray-800 !important;
        }

        .data.item.title {
            position: relative;

            &:first-of-type {
                border-top: 1px solid $gray-400;
            }

            &:before {
                position: absolute;
                top: 12px;
                left: 10px;
            }

            @include lib-icon-font(
                $_icon-font-content: $icon-add,
                $_icon-font-size: 25px,
                $_icon-font-line-height: 25px,
                $_icon-font-color: $c-darkgray
            );

            &.ui-state-active {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-minus
                );
            }
        }

        .data.item.content {
            height: auto !important;
            border-bottom: 1px solid $gray-400 !important;
            border-left: 1px solid $gray-400 !important;
            border-right: 1px solid $gray-400 !important;
        }
    }

    .page-wrapper {
        max-width: 100vw;
        overflow: hidden;
    }

    .product-view {
        flex-wrap: wrap;
        max-width: calc(100vw - 2 * #{$space-base});

        .product-add-form {
            margin-top: $space-base;
        }
    }

    .product-view_col1 {
        margin: 0 0 20px;
        order: -1;
    }

    .product-view_col2 {
        flex: 1 1 auto;
    }

    .product-view_col1,
    .product-view_col2 {
        padding: 0;
        width: 100%;
    }
}

// ==============================================
// Product View
// ==============================================

.product-view {
    display: flex;
    flex-wrap: wrap;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    justify-content: center;

    .price-box--limit {
        display: none;
    }
}

.product-view_col1,
.product-view_col2 {
    max-width: 650px;
}

// ==============================================
// Product View - Inventory
// ==============================================

.product-view {
    .inventory {
        height: auto;
    }

    .inventory-wrapper {
        padding: $space-base 0;
    }
}

// ==============================================
// Product View - Social Links
// ==============================================

.product-social-links {
    padding: $space-base 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .product-add-form {
        margin: 0;
    }

}


// ==============================================
// Product . Attribute . Overview
// ==============================================

.product.attribute.overview {
    margin-bottom: $space-base;
    padding-bottom: 10px;
    border-bottom: 1px solid $c-border;
}


// ==============================================
// Product Info Main
// ==============================================

.product-view_col2 {
    flex: 1 0 285px;
}

.product-attribute {
    display: block;
}

.product-attribute--inline {
    display: inline-block;
}

.product-attribute_value {
    display: inherit;
    margin: 0;
    color: $c-text-muted-dark;
    font-size: $font-size-s;
}

.product-attribute_value.description {
    word-break: break-word;
}

.product-view_col2 .product.alert {
    margin: $space-base 0;
}

.col {
    .product-attribute_value {
        color: inherit;
    }
}


// ==============================================
// Product Table Row
// ==============================================

.table .col.name a {
    color: $c-darkgray;
}


// ==============================================
// Product Details
// ==============================================

.product-details {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
}

.catalog-product-view {
    .content-wrapper {
        height: auto;

        #description {
            ul,
            ol {
                margin: 2em;
            }

            ul li {
                list-style-type: circle;
                margin-left: 1em;
            }

            ol li {
                margin: 2em;
                list-style: decimal;
            }

            .documentation-item a {
                text-transform: capitalize;
            }
        }

        .product.info.detailed {
            border: none;
        }
    }
}

// ==============================================
// Product Options Wrapper
// ==============================================

.product-options-wrapper .fieldset-product-options-inner .legend {
    display: inline-block;
    float: none;
    margin: 0 0 $space-xs;
    padding: 0;
    border: 0;
    font-weight: $font-weight-bold;
}

//  Date & Time custom option (Affect Time that goes only after Date)
.product-options-wrapper .fieldset-product-options-inner input.datetime-picker ~ select.datetime-picker {
    margin-top: $space-base;
}

.product-options-wrapper .fieldset-product-options-inner.required .legend,
.product-options-wrapper .fieldset-product-options-inner._required .legend {
    &::after {
        content: '*';
        margin: $form-field-label-asterisk__margin;
    }
}

.product-options-wrapper .field .note {
    display: block;
}

.product-options-wrapper .field .price-notice {
    @extend .abs-adjustment-incl-excl-tax;
}


// ==============================================
// Box To Cart
// ==============================================

.product-view_col2 .box-tocart .fieldset,
.product-options-bottom .box-tocart .fieldset {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.product-view_col2 .box-tocart .field.qty,
.product-options-bottom .box-tocart .field.qty {
    padding-right: 0.75 * $space-l;
    flex: 0 0 50px;
    margin-top: 0px;
}

.product-view_col2 .box-tocart .input-text.qty,
.product-options-bottom .box-tocart .input-text.qty {
    text-align: center;
    width: 54px;
}

.product-view_col2 .box-tocart .actions,
.product-options-bottom .box-tocart .actions {
    text-align: left;
    flex: 1 1 auto;
    margin-top: 9px;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .product-view_col2 .box-tocart .actions {
        text-align: center;
        vertical-align: bottom;
    }

    .product-view_col2 .box-tocart,
    .product-options-bottom .box-tocart {
        display: table;
    }

    .product-view_col2 .box-tocart .field.qty,
    .product-options-bottom .box-tocart .field.qty {
        display: table-cell;
    }

    .product-view_col2 .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
        display: table-cell;
        text-align: center;
        vertical-align: bottom;
    }

}


// ==============================================
// Block Related
// ==============================================

.block.related .action.select {
    margin: 0 $space-xs;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .block.related .action.select {
        display: block;
        margin: $space-xs 0;
    }

}


// ==============================================
// PDP Sidebar
// ==============================================

.sidebar .product-items .product-item {
    margin-bottom: $space-l;
    position: relative;
}

.sidebar .product-items .product-item-info {
    position: relative;
    width: auto;
}

.sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0;
}

.sidebar .product-items .product-item-name {
    margin-top: 0;
}

.sidebar .product-items .product-item-details {
    margin: 0 0 0 85px;
}

.sidebar .product-items .product-item-actions {
    display: block;
    margin-top: $space-base;
}

.sidebar .product-items .price-box {
    display: block;
    margin: 7px 0;
}

.sidebar .product-items .text {
    margin-right: 8px;
}

.sidebar .product-items .counter {
    color: $c-primary;
    font-size: $font-size-xs;
    white-space: nowrap;
}

.sidebar .product-items .minilist .price {
    display: inline;
    padding: 0;
}

.sidebar .product-items .minilist .weee::before {
    display: inline-block;
}

.sidebar .action.delete {
    position: absolute;
    right: 0;
    top: 0;
}

.sidebar .subtitle {
    display: none;
}

// ----------------------------------------------
//  Product images only

.sidebar .product-items-images {
    @include lib-clearfix;
    margin-left: -$space-xs;
}

.sidebar .product-items-images .product-item {
    float: left;
    padding-left: $space-xs;
}

// ----------------------------------------------
// Product names only

.sidebar .product-items-names .product-item {
    margin-bottom: $space-base;
}

.sidebar .product-items-names .product-item-name {
    margin: 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .sidebar .product-items .product-item-info .product-item-photo {
        float: left;
        left: auto;
        margin: 0 $space-base $space-base 0;
        position: relative;
        top: auto;
    }

    .sidebar .product-items .product-item-details {
        margin: 0;
    }

    .sidebar .product-items .product-item-actions {
        clear: left;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-xl) {

    .sidebar .product-items .product-item-info .product-item-photo {
        float: none;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
    }

    .sidebar .product-items .product-item-details {
        margin-left: 85px;
    }

}

.additional-attributes th {
    font-weight: $font-weight-bold;
    color: $c-darkgray;
    font-size: $font-size-base;
}

.additional-attributes tr:last-of-type {
    border-bottom: none;
}

.additional-attributes--product-specification {
    tr {
        display: table-row;
    }

    th, td {
        display: table-cell;
        white-space: unset;
        padding: $space-s $space-xs !important;
        line-height: 1.2 !important;
    }

}

// ==============================================
// Assistance
// ==============================================

.product-view .assistance {
    background: $c-gray-backgound;
    padding: $space-l;
}

// ==============================================
// Product Info
// ==============================================

.product-info {
    margin-bottom: $space-xxl;
}

.product-info > * {
    margin: 10px 0;
}

.product-info__row--loader {
    display: flex;
}

// ==============================================
// Info Products
// ==============================================

.info-products {
    border: 1px solid #babab9;
    margin: $space-xl 0;
}

.info-products__title {
    font-weight: $font-weight-bold;
    padding: $space-s;
    margin: 0;
}

.info-products__list-item {
    display: flex;
    padding: $space-s;
}

.info-products__list-item--clickable:hover {
    background: $gray-50;
    cursor: pointer;
}

.info-products__list--alternate {
    .info-products__list-item:nth-child(even) {
        background: $c-background;
    }
}

.info-products__col {
    flex: 0 0 auto;
    align-self: center;

    &:not(:first-child) {
        margin-left: $space-s;
    }
}

.info-products__link-details-message {
    display: inline-block;
    margin-bottom: $space-xxs;
    background: $c-green-light;
    font-size: $font-size-xs;
    color: $c-white;
    border-radius: 9px;
    padding: $space-xxxxs $space-xs;
    width: fit-content;

    & + .info-products__product {
        margin-top: $space-xs;
    }
}

.info-products__col--image {
    padding-right: $space-base;
}

.info-products__col--details {
    overflow: hidden;
    flex: 1 1 100%;

    .inventory-stock {
        font-weight: $font-weight-normal;
    }

    .price {
        font-size: $font-size-base;
    }
}

.product-line__preview-link:not(.disabled) {
    color: $c-text;

    &:hover {
        color: $c-link-hover;
    }
}


// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-s - 1px) {
    .info-products__list-item {
        flex-wrap: wrap;
    }

    .info-products__col {
        &:not(:first-child) {
            margin-left: 0;
        }
    }

    .info-products__col--select {
        order: 1;
        flex: 0 1 145px;
    }

    .info-products__col--details {
        order: 3;
        margin-top: $space-s;
    }

    .info-products__col--learn-more {
        order: 2;
        display: flex;
        justify-content: flex-end;
        flex: 0 1 calc(100% - 145px);
    }
}

// ----------------------------------------------
// Tablet & Desktop View

@media (min-width: $bp-screen-s) {
    .product-info__row {
        max-width: 280px;
    }

    .product-info__row--full {
        max-width: none;
    }

    .product-info__row--loader {
        height: 123px;
        display: flex;
        align-items: center;
        max-width: none;
    }

    .info-products__product {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .info-products__link-details-message + .info-products__product {
        margin-top: 0;
    }
}
