/**
* East Coast Metal Distributors
*/


// ==============================================
// Field Tooltip
// ==============================================

.field-tooltip {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 5px;
}

.field-tooltip._active {
    z-index: $tooltip__z-index;
}

.field-tooltip._active .field-tooltip-content {
    display: block;
}

.field-tooltip._active .field-tooltip-action {
    &::before {
        color: $c-primary;
    }
}

.field-tooltip .field-tooltip-action {
    @include lib-icon-font(
        $icon-help,
        $_icon-font-size         : 24px,
        $_icon-font-text-hide    : true,
        $_icon-font-color        : $c-text-muted,
        $_icon-font-color-hover  : $c-primary,
        $_icon-font-color-active : false
    );

    &:before {
        transition: color $transition-base;
    }
}

._keyfocus .field-tooltip .field-tooltip-action:focus {
    z-index: $tooltip__z-index;

    &::before {
        color: $c-primary;
    }
}

._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
    display: block;
}

.field-tooltip .field-tooltip-content {
    display: none;
    position: absolute;
    top: -9px;
    left: 38px;
    z-index: 2;
    width: 270px;
    padding: $space-s;
    border: 1px solid $c-border;
    border-radius: $radius-s;
    background: $c-background;
    font-size: $font-size-base;
    text-transform: none;
    word-wrap: break-word;

    &:before {
        @include triangle($direction: left, $size: 10px, $color: $c-border);
        left: -10px;
        top: 12px;
    }

    &:after {
        @include triangle($direction: left, $size: 12px, $color:  $c-background);
        left: -9px;
        top: 10px;
    }
}

.field.cvv .control {
    padding-right: 30px;
}

// ----------------------------------------------

@media (max-width: $bp-screen-xs - 1px) {

    .modal-popup .field-tooltip .field-tooltip-content {
        width: 200px;
    }

}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .field-tooltip .field-tooltip-content {
        @extend .abs-checkout-tooltip-content-position-top-mobile;
    }

}
