.small-wrapper {
    max-width: 480px;
}

.medium-wrapper {
    max-width: 600px;
}

.large-wrapper {
    max-width: 960px;
}

.x-large-wrapper {
    max-width: 1200px;
}


// -----------------------------------
// App wrapper - div for react apps

.app-wrapper,
.app-wrapper > div,
.app-wrapper > div > div {
    height: 100%;
}
