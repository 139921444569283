/**
* East Coast Metal Distributors
*/


// ==============================================
// Homepage Categories Wrapper
// ==============================================

.homepage-category-wrapper {

    max-width: $bp-screen-l;
    margin: 20px auto;
}

// ==============================================
// Homepage Category
// ==============================================

.homepage-category {
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
    margin-left: -20px;
}

// ==============================================
// Homepage Category Item
// ==============================================
.homepage-category_item {
    flex: 1 0 160px;
    margin-top: 20px;
    margin-left: 20px;
    text-align: center;
}


// ==============================================
// Category Item - Anchor
// ==============================================

.homepage-category_item a {
    color: $gray-500;
    font-weight: $font-weight-normal;
    letter-spacing: 0.04em;

    &:hover {
        text-decoration: none;
        color: $c-link;
    }
}


// ==============================================
// Homepage Category Item - Image
// ==============================================

.homepage-category_image {
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-white;
    height: 168px;
    margin-bottom: 5px;
    padding: 8px;
    box-shadow: none;
    transition: $transition-all;

}

.homepage-category_image img {
    margin: 0 auto;
}

.homepage-category_item:hover .homepage-category_image {
    box-shadow: 0px 0px 4px 1px black(0.15);
}
