/**
* East Coast Metal Distributors
*/

$dropdown-mobile-close-width: 50px;

// ==============================================
// Dropdown
// ==============================================

.dropdown {
    position: relative;
    display: inline-block;
}


// ==============================================
// Dropdown Trigger
// ==============================================

@media (min-width: $bp-screen-m) {
    .dropdown-trigger {
        cursor: pointer;
        user-select: none;

        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 22px,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-color-hover: inherit,
            $_icon-font-margin: 0
        );
    }

    .dropdown-trigger:hover {
        text-decoration: none;
    }

    .dropdown-trigger.active {
        @include lib-icon-font(
            $_icon-font-content: $icon-up,
            $_icon-font-size: 22px,
            $_icon-font-position: after,
            $_icon-font-display: block
        );
    }
}

// ==============================================
// Dropdown Content
// ==============================================


.dropdown-content {
    position: absolute;
    background: $c-white;
    border: 1px solid $c-border;
    box-shadow: $box-shadow-base;
    margin-top: 4px;
    height: auto;
    width: 260px;
    left: 0px;
    z-index: $z-index-9;
}

.dropdown .dropdown-content {
    display: none;
}

.dropdown.active .dropdown-content {
    display: block;
}

.dropdown-content > .close-btn {
    @include lib-icon-font(
        $_icon-font-content: $icon-close,
        $_icon-font-size: 32px,
        $_icon-font-line-height: 30px,
        $_icon-font-text-hide: false,
        $_icon-font-position: before,
        $_icon-font-display: inline-block,
        $_icon-font-color: $c-white
    );

    width: $dropdown-mobile-close-width;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    left: ($dropdown-mobile-close-width * -1);
    top: 0;
    background: $c-black;
    padding-top: 10px;
}

// ==============================================
// Dropdown Content - size modifiers
// ==============================================

.dropdown-content--m {
    width: 320px;
}

.dropdown-content--l {
    width: 660px;
}

.dropdown-content--right {
    left: auto;
    right: 0;
}


// ==============================================
// Dropdown Content list
// ==============================================


ul.dropdown-content li {
    margin: 0;
}

ul.dropdown-content li:hover {
    background: $c-primary;
}

ul.dropdown-content li a {
    display: block;
    color: $c-black;
    padding: 8px 16px;
}

ul.dropdown-content li:hover a {

    color: $c-white;
    text-decoration: none;
}


// ==============================================
// Dropdown Footer
// ==============================================

.dropdown-footer {
    border-top: 1px solid $c-border;
    padding: 8px;
    margin: 16px -16px -16px -16px;
}


@media (max-width: $bp-screen-m - 1px) {
    .dropdown-content {
        display: block;
        position: fixed;
        margin-top: 0;
        border: 0;
        z-index: 1010;
        width: calc(100% - #{$dropdown-mobile-close-width});
        height: 100%;
        top: 0;
        right: 0;
        transform: translate3d(calc(100% + #{$dropdown-mobile-close-width}), 0, 0);

        transition: {
            property: transform;
            duration: 0.3s;
            timing-function: ease;
        }
    }

    .dropdown.active .dropdown-content {
        transform: translate3d(0, 0, 0);
    }
}

@media (min-width: $bp-screen-m) {
    .dropdown.active .dropdown-content {
        position: absolute;
    }

    .dropdown-content > .close-btn {
        display: none;
    }
}
