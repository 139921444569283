/**
* East Coast Metal Distributors
*/


// ==============================================
// Vars
// ==============================================

$checkout-sidebar-width: 300px;


// ==============================================
// Checkout Container
// ==============================================

.checkout-container {
    display: flex;
    flex-wrap: nowrap;

    .form.form-discount {
        margin-bottom: 0;
    }

    .current-branch__container {
        flex: 0 0 100%;
    }

    #order-details {
        position: relative;

        label {
            font-size: 13px;
        }

        p.ahri-number {
            max-width: 60%;
        }
    }

    #order-details .control.small-size {
        textarea {
            max-width: 60%;
        }

        label {
            max-width: 50%;
        }

        textarea.input-text:not([value]) ~ label {
            top: 1px;
            font-size: 13px;
        }

    }

    #review-step .step-content {
        overflow-y: scroll;
        max-height: 1000px;
    }

    #checkout-step-title {
        height: auto;
    }

    img {
        max-width: 75px;
    }

    .product-inventory {
        font-size: 13px;
        max-width: 250px;
    }

    .discount-collapsible__container {
        cursor: pointer;

        @include lib-icon-font(
            $_icon-font-content: $icon-add,
            $_icon-font-size: 28px,
            $_icon-font-line-height: 30px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $gray-400
        );

        &.area-opened {
            @include lib-icon-font(
                $_icon-font-content: $icon-minus,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 30px,
                $_icon-font-text-hide: false,
                $_icon-font-position: before,
                $_icon-font-display: block,
                $_icon-font-color: $gray-400
            );
        }
    }

    .opc-sidebar-wrapper {
        flex: 1 1 28%;
        position: sticky;
        top: $space-l;
    }

    .tab-enabled {
        color: $c-gray-lighter;
    }

    .pickup-only-message {
        background: $c-security;
        padding: $space-s $space-l;
    }

    // Loader is handled in app/design/frontend/Ecmd/base/Magento_Checkout/web/js/view/shipping.js
    #checkout-loader {
        display: none;
    }
}

.delivery-delay-message {
    font-style: italic;
    color: $gray-500;
    font-size: 12px;
    display: inline-block;

    &:before {
        content: '*';
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .delivery-delay-message {
        max-width: 60%;
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .checkout-container {
        flex-wrap: wrap;
    }

    .checkout-container .opc-summary-action.fixed-container {
        @include fixed();
        z-index: 2;

        button {
            margin: 0;
        }
    }
}

.checkout-container .no-quotes-block {
    margin: $space-l 0;
}

.checkout-container .checkout-payment-method .payment-method button.checkout,
.checkout-container .checkout-payment-method .payment-method #review-buttons-container button {
    display: none;
}

.checkout-container .navigate-to-review .actions-toolbar {
    margin-left: 0 !important;
    margin-top: 20px;
}

.checkout-container .navigate-to-review button.button {
    cursor: pointer;

    &.disabled {
        @include button-style(
            $background: $gray-100,
            $background--hover: $gray-100,
            $background--active: $gray-100,
            $color: $gray-300
        );
        box-shadow: none;
    }
}

// ==============================================
// Order Details
// ==============================================

.order-details {
    display: flex;
    flex-direction: column;

    &__list {
        display: grid;
        grid-template-columns: minmax(100px, 1fr) 2fr;
        grid-gap: 0.25rem 0;

        dt:not(&-separator):after {
            content: ':';
            margin-right: 0.5rem;
        }

        &-separator {
            margin-top: 1.125rem;
        }
    }

    &__emails {
        display: flex;
        flex-wrap: wrap;

        li:not(:last-child):after {
            content: ',';
            margin-right: 0.25rem;
        }
    }

    &__note {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .order-details__list {
        grid-template-columns: minmax(100px, max-content) auto;
    }

}

@media (min-width: $bp-screen-l) {

    .order-details__list {
        grid-template-columns: minmax(100px, 1fr) 2fr;
    }

}


// ==============================================
// OPC Wrapper
// ==============================================

.opc-wrapper .step-content {
    padding: $space-l;
    border: 1px solid $c-gray-lighter;
}

// ----------------------------------------------

.opc-wrapper {
    flex: 1 1 100%;
    order: 2;
}

@media (min-width: $bp-screen-m) {

    .opc-wrapper {
        flex-basis: 100%;
        padding-right: $space-l;
        order: 1;
    }

}

// ==============================================
// OPC
// ==============================================

.opc {
    margin-top: 2rem;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .opc--review {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 1rem;

        .step-summary {
            display: flex;
            flex-direction: column;
        }

        .step-content {
            height: 100%;
        }

        #order-details-information, #review-step {
            grid-column: 1 / -1;
        }
    }

}

@media (min-width: $bp-screen-l) {

    .opc--review {
        grid-template-columns: repeat(3, minmax(0, 1fr));

        #order-details-information {
            grid-column: auto;
        }
    }

}

// ==============================================
// OPC Sidebar
// ==============================================

.opc-sidebar {
    flex: 0 0 100%;
    order: 1;
    position: relative;
    z-index: 3;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .opc-sidebar {
        flex-basis: $checkout-sidebar-width;
        order: 2;
        background: $c-lightestgray;
        border: none;
    }

    .opc-sidebar .action-close {
        display: none;
    }

    .opc-sidebar .modal-inner-wrap {
        transform: none;
        overflow: hidden;
        background: transparent;
    }

}


.opc-sidebar .total-rules {
    th, td {
        padding-top: $space-xxs;
        padding-bottom: $space-xxs;
    }
}

.opc-sidebar .total-rules th.mark {
    padding-left: $space-xl;
}

// ==============================================
// Checkout Control
// ==============================================

.checkout-control {
    margin: 0 0 $space-l 0;
    border: 1px solid $c-gray-lighter;
    padding: $space-base;

    &.review-overlay {
        position: relative;

        .overlay__container {
            background-color: rgba(255, 255, 255, 0.65);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 3;
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .checkout-control {
        display: inline-flex;
        width: 100%;
        flex-wrap: wrap;
    }

    .checkout-control .control {
        margin-right: $space-l;
    }

    .checkout-control .field {
        width: 100%;
    }

    .checkout-control .job-selector {
        margin-left: 0;
    }

    .checkout-control .label {
        display: block;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .checkout-control {
        width: 100%;
    }

}


// ==============================================
// Checkout PO Number
// ==============================================

.ponumber-tooltip {
    position: absolute;
    right: 0;
    top: 10px;
}

.checkout-ponumber {
    width: 170px;
    padding-right: 22px;
}

// ==============================================
// Authorizenet
// ==============================================

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .checkout-ponumber, .checkout-jobname {
        margin-top: 26px;
    }

}

// Hide steps temporarily
//-----------------------------------------

.checkout-container .steps {
    display: none;
}

// Hide Apply Gift Card
//-----------------------------------------

.opc-payment-additional.giftcardaccount {
    display: none;
}


// Billing Step
//-----------------------------------------

.checkout-billing-address {
    border-bottom: 1px solid $c-border-light;

    fieldset {
        max-width: 500px;
    }
}

//
//  Mobile
//  _____________________________________________
@media (max-width: $bp-screen-m - 1) {
    .checkout-container {
        #order-details {
            margin-top: 15px;
        }

        .opc-summary-placeorder {
            width: 100%;
        }
    }
}

//
//  Mobile small
//  _____________________________________________
@media (max-width: $bp-screen-s - 1) {
    .checkout-container {
        #order-details .control.small-size textarea {
            width: 100%;
            max-width: 100%;
        }
    }
}
