//
//  Default appearance styles
//  _____________________________________________

div[data-content-type='tabs'] {
    z-index: 1;

    .tabs-navigation {
        display: block;
        font-size: 0;
        margin: 0 0 -1px;
        padding: 0;

        li:first-child {
            margin-left: 0 !important;
        }

        li.tab-header {
            background: $gray-300;
            font-family: $font-family-special;
            border: 1px solid $gray-400;
            border-bottom: 0;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            display: inline-block;
            margin: 0;
            max-width: 100%;
            overflow-wrap: break-word;
            position: relative;
            word-wrap: break-word;
            z-index: 1;

            &:not(:first-child) {
                margin-left: -1px;
            }

            a.tab-title {
                border-right: 0;
                color: $gray-800;
                cursor: pointer !important;
                display: block;
                font-size: 14px;
                font-weight: normal;
                padding: 0.7rem 2rem;
                position: relative;
                transition: all .3s;
                vertical-align: middle;
                white-space: normal;

                span.tab-title {
                    display: block;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            &:last-child {
                border-right: 1px solid $gray-400;
            }

            &.ui-state-active {
                background: $c-white !important;
                z-index: 19;

                a.tab-title {
                    position: relative;
                    transition: all .3s;
                }
            }

            &.ui-state-hover {
                background: transparent;
                z-index: 19;

                a.tab-title {
                    position: relative;
                    transition: all .3s;
                }
            }
        }
    }

    .tabs-content {
        border: 1px solid $gray-400;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        z-index: 9;
        background: $c-white;

        [data-content-type='tab-item'] {
            box-sizing: border-box;
            min-height: inherit;

            &:not(:first-child) {
                display: none;
            }
        }
    }

    &.tab-align-left {
        .tabs-content {
            border-top-left-radius: 0 !important;
        }
    }

    &.tab-align-right {
        .tabs-content {
            border-top-right-radius: 0 !important;
        }
    }
}

@media only screen and (min-width: $bp-screen-m - 1px) {
    div[data-content-type='tabs'] {
        .tabs-navigation li.tab-header div.tab-content-container {
            display: none;
        }
    }
}

//
//  Responsive Classes
//  _____________________________________________

@media only screen and (max-width: $bp-screen-m - 1px) {
    div[data-content-type='tabs'] .tabs-navigation {
        font-size: 14px;
        border-bottom: 1px solid $gray-400;

        p {
            margin-bottom: 0;
            font-family: $font-family-sans;
        }

        li.tab-header {
            display: block;
            position: initial;
            margin-left: 0 !important;
            background: $gray-200;
        }

        li.tab-header a.tab-title {
            position: relative;

            @include lib-icon-font(
                $_icon-font-content: $icon-add,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 32px,
                $_icon-font-text-hide: false,
                $_icon-font-position: before,
                $_icon-font-display: block,
                $_icon-font-color: $gray-850
            );

            &::before {
                position: absolute;
                left: 0;
                top: 5px;
            }
        }

        li.tab-header.ui-state-active {
            div.tab-content-container {
                padding: 10px;
            }

            a.tab-title {
                background: $gray-200;
                @include lib-icon-font(
                    $_icon-font-content: $icon-minus,
                    $_icon-font-size: 22px,
                    $_icon-font-line-height: 32px,
                    $_icon-font-text-hide: false,
                    $_icon-font-position: before,
                    $_icon-font-display: block,
                    $_icon-font-color: $gray-850
                );
            }
        }
    }

    div[data-content-type='tabs'] .tabs-content {
        display: none;
    }

    [data-content-type='tab-item'] {
        background-attachment: scroll !important;
    }
}
