.ecmd__view-table {
    &.table th {
        white-space: pre-wrap;
    }

    thead {
        position: sticky;
        top: 0;
        z-index: 10;
        background: $c-white;

        th {
            text-align: center;
        }

        .header-s {
            width: 92px;
            white-space: nowrap;
        }

        .header-xs {
            width: 48px;
        }
    }

    .col {
        color: $c-darkgray;
        font-size: 14px;
        font-weight: normal;
    }

    .col-select, .col-editableLabel, .col-price, .col-editableNumber {
        text-align: center;
    }

    .col-editableQtyNumber {
        width: 15%;
        min-width: 190px;
    }

    .editableQtyNumber__package {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .field_input--package {
        margin-right: $space-xs;
        min-width: 130px;
    }

    .editableQty {
        flex: 1 0 45px;
        text-align: center;

        input {
            height: 20px;
        }
    }

    .editableQtyNumber__package-info {
        background: $gray-100;
        padding: 10px;
        flex: 1 0 100%;
        font-size: $font-size-s;
        margin-top: $space-xs;
    }

    .price-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: $space-base + 5px;
        justify-content: flex-end;
    }

    .col-price {
        width: 10%;
        text-align: center;
    }

    .price, .old-price {
        padding-top: 5px;
    }

    .old-price {
        margin-left: 10px;
    }

    .col-quantity {
        width: 5%;
        text-align: right;
    }

    .col-select {
        width: 20%;

        & > div {
            margin-top: $space-base;
        }

        &.desktop {
            display: table-cell;
        }

        .MuiIconButton-colorPrimary {
            padding: 9px;
        }
    }

    .col-image {
        width: 10%;

        img {
            max-height: 100px;
        }
    }

    .col-product {
        width: 20%;
    }

    .col-ordertemplate-product {
        & > span {
            float: left;
            width: 100%;

            &.prod_name {
                font-size: $font-size-l;
            }
        }
    }

    .col-inventory {
        width: 20%;

        .product-inventory {
            max-width: 100%;
            font-size: $font-size-s;

            .product-inventory__label {
                font-size: $font-size-s;
            }
        }
    }

    .col-label {
        width: 20%;
    }

    .col-editableLabel {
        width: 20%;

        & > span {
            width: 100%;
            float: left;
        }

        .MuiTextField-root {
            & > div {
                height: 40px;
            }
        }

        .MuiAutocomplete-root {
            .MuiFormControl-root {
                margin: 0;
            }

            .MuiInputLabel-root {
                float: left;
                transform: none;
                position: relative;
                color: $c-brand;
                font-size: $font-size-s;
                margin-bottom: 8px;
            }

            .MuiOutlinedInput-notchedOutline {
                top: 0;

                legend {
                    display: none;
                }
            }
        }

    }

    .selectProduct {
        background: $c-blue-dark;
        color: $c-white;
        padding-left: 12px;
        text-transform: uppercase;

        .MuiFormControlLabel-label {
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;
        }

        .MuiCheckbox-colorPrimary {
            color: $c-white;
            padding: 7px;
            padding-left: 3px;

            &.Mui-disabled {
                color: $gray-300;
            }
        }

        &.Mui-disabled {
            @include button-style (
                $background         : $gray-100,
                $background--hover  : $gray-100,
                $background--active : $gray-100,
                $color              : $gray-300
            );
            box-shadow: none;
            border-color: $gray-100;
            cursor: default !important;
        }
    }

    .MuiAutocomplete-root > div {
        width: 90%;
        margin: 0 5%;
    }

    tbody {
        th {
            vertical-align: top;
        }

        .col-editableQtyNumber label {
            display: none;
        }
    }

    a.tag {
        width: auto;
        border: 1px transparent solid;
        background: $c-red-dark;
        border-radius: 12px;
        white-space: pre-wrap;
        color: $c-white;
        float: left;
        text-decoration: none;

        span {
            border: 1px $c-white solid;
            border-radius: 100%;
            padding: 0 3.5px;
            margin-left: 8px;
            margin-right: -4px;
            line-height: 23px;
        }

        &.nolabel {
            color: $c-brand;
            background: $c-white;

            span {
                border-color: $c-brand;
            }
        }
    }
}

.ordertemplates-requisition-view {
    .MuiAutocomplete-popper {
        min-width: fit-content;

        .MuiPaper-root {
            border-radius: 0;
            background: $c-white;
            border: 1px solid $c-gray-light;
            box-shadow: none;

            .MuiAutocomplete-option {
                color: $c-brand;
                font-size: $font-size-s;
            }
        }
    }

    .Inventory {
        max-width: 400px;
    }

    .edit_button {
        font-weight: $font-weight-normal;
    }

    .col-price .price {
        color: $c-darkgray;
    }

    .product-inventory__label,
    .bu_product_num,
    .name,
    .mfg_item_num,
    .item_part,
    .item_comment {
        color: $c-darkgray;
        text-shadow: none;
        font-weight: $font-weight-normal;
        font-size: $font-size-s;
    }

    .name {
        font-size: $font-size-l;
    }

    .order-template-item-qty {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: $space-base;

        &__qty {
            flex: 1 1 30%;
            max-width: 60px;

            input {
                text-align: center;
            }
        }
    }

    .col-editableLabel {
        .MuiInputBase-input {
            height: 0;
        }
    }

    .MuiOutlinedInput-root {
        border-radius: 0;
    }

    .MuiMenu-list li {
        display: block;
        padding: 2px 20px;
    }

    .ecmd_filter {
        .filterLine {
            float: left;
            width: 100%;

            ._labelFilter {
                min-width: 100px;
            }
        }

        .textInput {
            input {
                padding: 8px 10px;
            }
        }
    }

    .filterLine {
        .field-item {
            width: auto;
            display: inline-table;

            label {
                padding-right: 10px;
            }
        }
    }

    .line-1 {
        margin-top: -13px;
        float: left;

        .__react_dropdown_up {
            margin-left: 20px;
            top: 15px;
        }
    }
}

.filterButton {
    cursor: pointer;
}

.order-templates__view-header {
    .top-line {
        margin-left: 20px;
    }
}

.ecmd__view-table {
    .desktop {
        display: initial;
    }

    .mobile {
        display: none;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .ecmd__view-table {
        .desktop {
            display: none !important;
        }

        .mobile {
            display: block;
        }

        tbody tr {
            padding: 12px 0;
        }
    }

    .ecmd__view-table-head {
        .col-price, .col-quantity {
            display: none;
        }
    }

    .ecmd__view-table.table tbody tr {
        float: left;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .col {
            padding: $space-base 0 0 0;
        }

        .col-ordertemplate-product.p-t-xxl {
            padding: 0 !important;
        }

        .col-editableLabel {
            width: 100%;
            order: 3;

            .m-t-xl {
                margin: 0 !important;
            }
        }

        .col-inventory {
            width: 100%;
            float: left;
            order: 4;
        }

        .col-editableQtyNumber {
            width: 60px;
            min-width: auto;
            order: 7;

            label {
                display: none;
                margin-top: -25px;
                white-space: nowrap;
                margin-left: -10px;
                color: $c-text-muted;
                width: 70px;
                text-align: center;
            }

            .MuiFormControl-root.m-t-base {
                margin: 0 !important;
            }

            &--packages {
                width: 100%;
            }
        }

        .editableQtyNumber__package {
            justify-content: flex-start;
        }

        .field_input--package {
            flex: 1 0 50%;
        }

        .col-price {
            width: calc(100% - #{$space-base} - 60px);
            margin-right: $space-base;
            text-align: start;
            order: 5;

            &.p-t-xl {
                padding: 0 !important;
            }
        }

        .price-wrapper {
            justify-content: flex-start;
            margin: 0;
            height: 38px;
            align-items: center;
        }

        .col-select {
            width: 100%;
            float: left;
            order: 8;

            & > div {
                margin-top: 0;
            }

            .m-t-xs {
                margin: 0;
            }

            .selectProduct {
                margin: 0;
                width: 100%;
                justify-content: center;
            }
        }
    }

    .ecmd__view-table.table thead {
        display: block;

        tr {
            border: none;
        }

        th {
            float: left;
            margin: 0;
            padding: 20px 5px;

            &:empty {
                display: none;
            }

            &.col-product {
                width: 25%;
            }

            &.col-inventory {
                width: 23%;
            }

            &.col-price {
                width: 15%;
            }

            &.col-quantity {
                width: 10%;
            }

            &.col-label {
                width: 40%;
                text-align: center;

                & > div {
                    display: inline-block;
                }
            }

            &.col-select {
                width: 35%;
                white-space: nowrap;
                padding: 11px;

                & > label {
                    margin-left: 0;
                    float: right;

                    .MuiButtonBase-root {
                        margin-top: -4px;
                    }
                }

                .MuiTypography-root {
                    font-size: $font-size-base;
                    color: $c-gray-footer;
                }
            }
        }
    }

    .col-value,
    .col-product-name {
        line-height: $space-l;
    }

    .order-templates__view-header {
        .top-line {
            margin-left: 0;
        }
    }

    .ordertemplates-requisition-view {
        .package-select {
            max-width: none;
            width: 100%;
        }

        .order-template-item-qty,
        .price {
            margin-top: 0;
        }
    }
}
