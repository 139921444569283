/**
* East Coast Metal Distributors
*/


// ==============================================
// OPC Block Summary
// ==============================================


.opc-block-summary h2 {
    padding: $space-base $space-base 0 $space-base;
}

.opc-block-summary .messages {
    margin-bottom: 16px;
}

.opc-totals-table.cart-totals.tax-warning {
    color: $c-warning;

    .payment-change {
        color: $c-error;
    }
}

.opc-after-summary {
    display: flex;
    flex-direction: column;
    margin-top: $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .opc-block-summary h2 {
        display: none;
    }
}

.opc-block-summary .not-calculated {
    font-style: italic;
}

.opc-block-summary .opc-table-totals {
    background: transparent;
}

// ----------------------------------------------

//
//  Desktop
//  _____________________________________________
@media (min-width: $bp-screen-m) {
    .opc-block-summary .opc-table-totals th {
        white-space: normal;
    }

    .opc-block-summary .messages {
        margin: 16px 0 0 16px;
    }

    .opc-summary-action {
        padding: $space-base;
    }

    .opc-after-summary {
        margin-top: 0;
        padding: 0 $space-base;
    }

    .opc-totals-table {
        border: 1px solid $c-border-light;
        margin: 0 $space-base;
        background: $c-white;
    }
}


// ==============================================
// OPC Estimate Wrapper
// ==============================================

.opc-estimated-wrapper {
    display: none;
}


.opc-totals-table {
    padding: $space-base;
    margin-top: 5px;

    .simple-table.totals-table {
        margin-bottom: 0;
    }
}




