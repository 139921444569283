/**
* East Coast Metal Distributors
*/


// ==============================================
// Animation -  Input hightlight
// ==============================================


@keyframes inputHighlight {
    0% {
        box-shadow: none;
    }

    5% {
        box-shadow: 0px 0px 14px #3380ef;
    }

    100% {
        box-shadow: none;
    }
}

.input-highlight {
    animation-name: inputHighlight;
    animation-duration: 1.4s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
}
