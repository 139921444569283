/**
* East Coast Metal Distributors
*/


// ==============================================
// Price Box
// ==============================================

.price-box .price {
    @include price();
}


// ==============================================
// Price Including/Excluding Tax
// ==============================================

// GravDept: [todo]
// Can possibly comment out if Baker doesn't show tax inline.

.price-including-tax,
.price-excluding-tax {
    display: block;
    @include price();
}

.price-including-tax .cart-tax-total,
.price-excluding-tax .cart-tax-total {
    position: relative;
    padding-right: $space-s;
    cursor: pointer;

    @include lib-icon-font(
        $icon-down,
        $_icon-font-size        : 26px,
        $_icon-font-line-height : 10px,
        $_icon-font-margin      : 3px 0 0 0,
        $_icon-font-position    : after
    );

    &::after {
        position: absolute;
        right: -$space-base;
        top: 3px;
    }

    &-expanded {
        @include lib-icon-font-symbol(
            $_icon-font-content  : $icon-up,
            $_icon-font-position : after
        );
    }
}

.price-including-tax .cart-tax-total-expanded,
.price-excluding-tax .cart-tax-total-expanded {
    @include lib-icon-font-symbol(
        $_icon-font-content  : $icon-up,
        $_icon-font-position : after
    );
}

.price-including-tax + .price-excluding-tax,
.weee[data-label] {
    display: block;
    font-size: $font-size-xl;

    &::before {
        content: attr(data-label) ': ';
        font-size: $font-size-xxs;
    }
}

.price-including-tax + .price-excluding-tax .price,
.weee[data-label] .price {
    font-size: $font-size-xxs;
}


// ==============================================
// Price Tier
// ==============================================

.price-tier_price .price-excluding-tax,
.price-tier_price .price-including-tax {
    display: inline;
}


// ==============================================
// Old Price
// ==============================================

.old-price,
.old.price {
    text-decoration: line-through;
    padding-left: 10px;
}


// ==============================================
// Price restriction message
// ==============================================

.price-message {
    font-size: $font-size-s;
    white-space: normal;
}


// ==============================================
// Price when there is a discount
// ==============================================

.price-discount {
    font-weight: 400;
    font-size: $font-size-base;
    color: $c-error;
    margin-bottom: $space-xs;
}
