/**
* East Coast Metal Distributors
*/


// ==============================================
// Control
// ==============================================

.control {
    position: relative;
    margin-top: 18px; // Offset for animated label. Update "field-row" when changing.
}

.control--radio {
    margin-top: $space-s;
}

label ~ .control {
    margin-top: 0;
}

.control label {
    position: absolute;
    left: 0;
    color: $c-label;
    font-weight: normal;
    letter-spacing: $letter-spacing;
    line-height: 10px;
    transition: all ease 0.2s;
    white-space: nowrap;
}

// ==============================================
// Modifier: Material UI
// ==============================================

.control--mui {
    margin-top: 32px;
    overflow: hidden;
}
