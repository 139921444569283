
.quotes-index-index .content-wrapper,
.quotes-view-index .content-wrapper {
    display: block;

    .panel.large-wrapper {
        border: 1px solid $c-gray-dark;
    }

    .quote-header {
        display: flex;
        flex-direction: column;
    }

    .details-wrapper .panel_info {
        margin-bottom: 0;
    }

    .actions-container {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    .badge {
        position: absolute;
        left: 16px;
        top: 16px;
    }

    .quote-header .account-table-bids {
        margin-top: 50px;
    }

    .panel_info p {
        margin-bottom: 0;
    }

    button.button:disabled,
    button.button:disabled:hover, button.button:disabled:focus,
    button.button.disabled:hover, button.button.disabled:focus,
    button.button:disabled:active, button.button.disabled:active {
        background: $c-blue;
        color: $c-white;
    }

    .bid-header__container {
        display: flex;
    }

    .panel_action .action-group {
        float: none;
    }

    .badge {
        font-weight: normal;
    }

    .toolbar-number.no-results {
        display: none !important;
    }

    .table__container {
        padding: 16px;
    }
}

.table .bid-status--dismissed {
    color: $c-gray-lighter;
}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {
    .quotes-index-index .content-wrapper,
    .quotes-view-index .content-wrapper {
        input.button.filter-button {
            width: 100%;
        }

        .bid-header__container {
            display: block;
        }

        .action-group button {
            width: 100%;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@media (min-width: $bp-screen-m) {
    .quotes-index-index .content-wrapper,
    .quotes-view-index .content-wrapper {
        .quote-header {
            flex-direction: row;
        }

        .account-table-bids {
            flex: 1 1 600px;
        }

        .bid-header__container {
            flex: 0 0 300px;
            justify-content: flex-end;
        }

        .pricebook {
            display: flex;
            justify-content: flex-end;
            flex-basis: 100%;
        }
    }
}
