/**
* East Coast Metal Distributors
*/


// ==============================================
// Item Options
// ==============================================

.item-options {
    overflow: hidden;
    margin-bottom: $space-base;
    font-size: $font-size-xs;
}


// ==============================================
// Item Options - DT
// ==============================================

.item-options dt {
    float: left;
    clear: left;
    margin: 0 $space-base $space-xs 0;

    &::after {
        content: ': ';
    }
}


// ==============================================
// Item Options - DD
// ==============================================

.item-options dd {
    float: left;
    display: inline-block;
    margin: 0 0 $space-xs;
}
