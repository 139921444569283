@-webkit-keyframes introjspulse {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    25% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: .1;
    }

    50% {
        -webkit-transform: scale(.1);
        transform: scale(.1);
        opacity: .3;
    }

    75% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
        opacity: .5;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}
@keyframes introjspulse {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    25% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: .1;
    }

    50% {
        -webkit-transform: scale(.1);
        transform: scale(.1);
        opacity: .3;
    }

    75% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
        opacity: .5;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

.introjs-overlay {
    position: absolute;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    z-index: 999999;
    opacity: 0;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.introjs-showElement {
    z-index: 9999999 !important;
}

tr.introjs-showElement {
    >td {
        z-index: 9999999 !important;
        position: relative;
    }

    >th {
        z-index: 9999999 !important;
        position: relative;
    }
}

.introjs-disableInteraction {
    z-index: 99999999 !important;
    position: absolute;
    background-color: #fff;
    opacity: 0;
}

.introjs-relativePosition {
    position: relative;
}

.introjs-helperLayer {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    z-index: 9999998;
    border-radius: 4px;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;

    * {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
    }

    &:before {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
    }

    &:after {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
    }
}

.introjs-tooltipReferenceLayer {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    visibility: hidden;
    z-index: 100000000;
    background-color: transparent;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.introjs-helperNumberLayer {
    color: #9e9e9e;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.introjs-arrow {
    border: 5px solid transparent;
    content: '';
    position: absolute;
}

.introjs-arrow.top {
    top: -10px;
    left: 10px;
    border-bottom-color: #fff;
}

.introjs-arrow.top-right {
    top: -10px;
    right: 10px;
    border-bottom-color: #fff;
}

.introjs-arrow.top-middle {
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #fff;
}

.introjs-arrow.right {
    right: -10px;
    top: 10px;
    border-left-color: #fff;
}

.introjs-arrow.right-bottom {
    bottom: 10px;
    right: -10px;
    border-left-color: #fff;
}

.introjs-arrow.bottom {
    bottom: -10px;
    left: 10px;
    border-top-color: #fff;
}

.introjs-arrow.bottom-right {
    bottom: -10px;
    right: 10px;
    border-top-color: #fff;
}

.introjs-arrow.bottom-middle {
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    border-top-color: #fff;
}

.introjs-arrow.left {
    left: -10px;
    top: 10px;
    border-right-color: #fff;
}

.introjs-arrow.left-bottom {
    left: -10px;
    bottom: 10px;
    border-right-color: #fff;
}

.introjs-tooltip {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    background-color: #fff;
    min-width: 250px;
    max-width: 300px;
    -webkit-transition: opacity .1s ease-out;
    -o-transition: opacity .1s ease-out;
    transition: opacity .1s ease-out;
    border-radius: 0;
    box-shadow: $box-shadow-base;
}

.introjs-tooltiptext {
    padding: 20px;
}

.introjs-tooltip-title {
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-weight: 700;
    float: left;
    line-height: 32px;
}

.introjs-tooltip-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;

    &:after {
        content: '.';
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }
}

.introjs-tooltipbuttons {
    border-top: 1px solid #e0e0e0;
    padding: 10px;
    text-align: right;
    white-space: nowrap;

    &:after {
        content: '';
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }
}

.introjs-button {
    @include button;
}

.introjs-skipbutton {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    float: right;
    cursor: pointer;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    padding: 7px 10px;
    width: 10px;
    height: 13px;
    overflow: hidden;
    color: #fff;
    @include lib-button-icon(
        $icon-close,
        $_icon-font-color       : $gray-700,
        $_icon-font-size        : 24px,
        $_icon-font-line-height : 24px,
        $_icon-font-text-hide   : true
    );
}

.introjs-prevbutton {
    float: left;
}

.introjs-nextbutton {
    float: right;
}

.introjs-disabled {
    color: #9e9e9e;
    border-color: #bdbdbd;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none;

    &:focus {
        color: #9e9e9e;
        border-color: #bdbdbd;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: default;
        background-color: #f4f4f4;
        background-image: none;
        text-decoration: none;
    }

    &:hover {
        color: #9e9e9e;
        border-color: #bdbdbd;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: default;
        background-color: #f4f4f4;
        background-image: none;
        text-decoration: none;
    }
}

.introjs-hidden {
    display: none;
}

.introjs-bullets {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;

    ul {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        clear: both;
        margin: 0 auto 0;
        padding: 0;
        display: inline-block;

        li {
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            list-style: none;
            float: left;
            margin: 0 2px;

            a {
                -webkit-transition: width .1s ease-in;
                -o-transition: width .1s ease-in;
                transition: width .1s ease-in;
                -webkit-box-sizing: content-box;
                box-sizing: content-box;
                display: block;
                width: 6px;
                height: 6px;
                background: #ccc;
                border-radius: 10px;
                text-decoration: none;
                cursor: pointer;

                &:focus {
                    width: 15px;
                    background: #999;
                    text-decoration: none;
                    outline: 0;
                }

                &:hover {
                    width: 15px;
                    background: #999;
                    text-decoration: none;
                    outline: 0;
                }
            }

            a.active {
                width: 15px;
                background: #999;
            }
        }
    }
}

.introjs-progress {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    overflow: hidden;
    height: 10px;
    margin: 10px;
    border-radius: 4px;
    background-color: #e0e0e0;
}

.introjs-progressbar {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    float: left;
    width: 0%;
    height: 100%;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    background-color: #08c;
}

.introjsFloatingElement {
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%;
}

.introjs-fixedTooltip {
    position: fixed;
}

.introjs-hint {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    background: 0 0;
    width: 20px;
    height: 15px;
    cursor: pointer;

    &:focus {
        border: 0;
        outline: 0;
    }

    &:hover {
        & > .introjs-hint-pulse {
            border: 5px solid rgba(60, 60, 60, .57);
        }
    }
}

.introjs-hidehint {
    display: none;
}

.introjs-fixedhint {
    position: fixed;
}

.introjs-hint-pulse {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 10px;
    height: 10px;
    border: 5px solid rgba(60, 60, 60, .27);
    border-radius: 30px;
    background-color: rgba(136, 136, 136, .24);
    z-index: 10;
    position: absolute;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.introjs-hint-no-anim {
    .introjs-hint-dot {
        -webkit-animation: none;
        animation: none;
    }
}

.introjs-hint-dot {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    border: 10px solid rgba(146, 146, 146, .36);
    background: 0 0;
    border-radius: 60px;
    height: 50px;
    width: 50px;
    -webkit-animation: introjspulse 3s ease-out;
    animation: introjspulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: absolute;
    top: -25px;
    left: -25px;
    z-index: 1;
    opacity: 0;
}
