// ==============================================
// Product Badges
// ==============================================

.badge-list {
    display: flex;
    flex-wrap: wrap;
    min-height: 20px;
    gap: 5px;
}

.badge-list__item {
    height: 20px;
}

.badge-list__image {
    height: 100%;
    width: auto;
}

// ==============================================
// Image Badges
// ==============================================

.image-badge-list {
    display: flex;
    gap: 5px;
    position: absolute;
    z-index: 10;
    width: 100%;
    left: 0;
    top: 0;

    &:empty {
        display: none;
    }
}

.image-badge-list__item--energy-star {
    width: clamp(20px, 33%, 40px);
}

#pdp_image_badge .image-badge-list {
    pointer-events: none;
}
