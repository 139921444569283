/**
* East Coast Metal Distributors
*/


// ==============================================
// List of stores
// ==============================================


.stores_item {
    border-bottom: 1px solid $c-border;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &:last-child {
        border-bottom: none;
    }
}
