.navigation-wrapper {
    display: flex;
    justify-content: space-between;
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size: 32px,
        $_icon-font-line-height: 18px,
        $_icon-font-color: $c-white,
        $_icon-font-color-hover: $c-white,
        $_icon-font-text-hide: false
    );
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size-xxs;
    color: $c-white;
    z-index: 15;
    height: 40px;

    & > span {
        line-height: $font-size-xxs - 4px;
    }
}

.mm-spn.mm-spn--light {
    background: $c-white;
}

.mm-ocd__backdrop {
    background: rgba(3, 2, 1, 0.3);
}

.mm-spn a:not(:last-child):after {
    content: unset;
}

.mm-spn ul {
    padding-right: 0;
    width: 100%;
}

.mm-spn ul.mm-spn--parent {
    left: 0;
    overflow-y: auto;
}

.mm-spn ul::-webkit-scrollbar {
    -webkit-appearance: none;
}

.mm-spn ul::-webkit-scrollbar:vertical {
    width: 10px;
}

.mm-spn ul::-webkit-scrollbar-thumb {
    border-radius: 0;
    border: 2px solid $c-gray; /* should match background, can't be transparent */
    background-color: $c-gray;
}

.mm-spn li {
    @include lib-icon-font(
        $_icon-font-content: $icon-pointer-right,
        $_icon-font-size: 30px,
        $_icon-font-line-height: 30px,
        $_icon-font-text-hide: false,
        $_icon-font-position: before,
        $_icon-font-display: block,
        $_icon-font-color: $c-black
    );

    &:before {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.mm-spn.mm-spn--navbar ul {
    top: 91px;
}

.mm-spn.mm-spn--navbar > ul {
    top: 0;
}

.mm-spn.mm-spn--navbar {
    &:before {
        content: 'Back';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        text-align: left;
        z-index: 10;
        background-color: $c-white;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMTUuNDEgMTYuNTlMMTAuODMgMTJsNC41OC00LjU5TDE0IDZsLTYgNiA2IDYgMS40MS0xLjQxeiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMFYweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==");
        padding-left: 35px;
        padding-top: 12px;
        background-repeat: no-repeat;
        height: 40px;
        background-position: 12px 10px;
        font-weight: bold;
    }

    &:not(.mm-spn--main):after {
        text-align: left;
        opacity: 1;
        height: unset;
        line-height: unset;
        padding: 15px 20px;
        top: 40px;
        font-weight: bold;
        text-transform: uppercase;
        background: $c-white;
        z-index: 10;
    }
}

.mm-spn a, .mm-spn span {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 20px;
}

.mm-spn a > span {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.mm-ocd--top:not(.mm-ocd--open) .mm-ocd__content {
    top: 0;
    transform: translate3d(0, -100%, 0);
}

.mm-ocd--top .mm-ocd__backdrop {
    right: 0;
}

//
//  Customer Navigation
//  _____________________________________________

.customer-navigation {
    order: 2;
}

@media (max-width: $bp-screen-m - 1px) {
    .navigation-wrapper {
        padding-right: 18px;
    }

    .navigation-wrapper .navigation {
        display: none;
    }

    .mm-ocd__content {
        width: calc(100% - 50px);
        max-width: 100%;
    }

    .mm-ocd__backdrop {
        width: 50px;
        background: $c-black;
        min-width: unset;

        @include lib-icon-font(
                $icon-close,
            $_icon-font-size: 30px,
            $_icon-font-line-height: 30px,
            $_icon-font-color: $c-white,
            $_icon-font-color-hover: $c-white,
            $_icon-font-text-hide: false
        );

        &:before {
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .navigation-wrapper {
        margin-top: 8px;
        padding-bottom: 6px;
    }
    .navigation.desktop-menu {
        width: 560px;
    }
    .navigation {
        display: flex;
        align-items: center;
        width: auto;
        font-family: $font-family-special;

        > ul {
            display: flex;
            flex-direction: row;

            > li {
                padding-left: $space-s;

                &:first-child {
                    padding-left: 0;
                }
            }
        }

        > ul > li > a {
            color: $c-white;
            text-decoration: none;
        }

        ul.level0.submenu {
            display: none;
        }

        .level0.parent > a {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 20px,
                $_icon-font-text-hide: false,
                $_icon-font-position: after,
                $_icon-font-display: block,
                $_icon-font-color: $c-white
            );

            &.open:after {
                content: $icon-up;
            }
        }
    }

    .nav-toggle {
        display: none;
    }

    .mm-ocd {
        top: 112px;
    }
}
