/**
* East Coast Metal Distributors
*/

// ==============================================
// Text Field
// ==============================================

@mixin text-field(
    $input-border,
    $input-padding
) {
    appearance: none;
    background: transparent;
    background-clip: padding-box;
    border: $input-border;
    border-radius: 0;
    border: 1px solid $c-input-border-bottom;
    height: 38px;
    padding: $input-padding;
    width: 100%;
    outline: 0;
    transition: 0.2s ease all;

    // ==============================================
    // Placeholder
    // See: https://css-tricks.com/almanac/selectors/p/placeholder/
    // ==============================================

    .control & {
        @include placeholder($color: transparent);
    }

    &:focus {
        border-bottom-color: $c-input-border-focus;

        @include placeholder();
    }

    // ---------------------------------------------------
    // Auto Fill

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: $c-input-text-fill;
        -webkit-box-shadow: 0 0 0px 1000px $c-lightestblue inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    // Fix outline on invalid input texts - Firefox
    &:invalid {
        box-shadow: none;
    }

    &:-moz-submit-invalid {
        box-shadow: none;
    }

    &:-moz-ui-invalid {
        box-shadow: none;
    }

    @include input-disabled;
}

@mixin input-hint {
    @include caption;
    margin-top: $space-xxs;
}

@mixin input-disabled {
    &:disabled, &--disable {
        border-bottom: 1px solid $c-input-border-bottom;
        background-color: $c-lightestgray;
    }
}

@mixin placeholder($color: $c-input-placeholder) {
    &::-moz-placeholder {
        color: $color;
        font-size: $form-elem-font-size; // Prevent iOS zoom on focus.
    }

    &::-webkit-input-placeholder {
        color: $color;
        font-size: $form-elem-font-size; // Prevent iOS zoom on focus.
    }

    &:-ms-input-placeholder {
        color: $color;
        font-size: $form-elem-font-size; // Prevent iOS zoom on focus.
    }
}

@mixin label-color(
    $label,
    $label-focus
) {
    & ~ label {
        color: $label;
    }

    &:focus ~ label {
        color: $label-focus;
    }
}

@mixin label-position(
    $label-top,
    $label-top-focus,
    $label-left,
    $label-font-size,
    $label-font-size-focus,
    $label-focus-left,
    $label-font-weight: $font-weight-semibold
) {
    & ~ label {
        top: $label-top;
        left: $label-left;
        font-size: $label-font-size;
    }

    &:not([value=""]) ~ label {
        top: $label-top-focus;
        font-size: $label-font-size-focus;
        pointer-events: none;
    }

    &[value=""] ~ label {
        pointer-events: none;
    }

    &:not([value]) ~ label {
        top: $label-top;
        font-size: $label-font-size;
        pointer-events: none;
    }

    &:disabled ~ label {
        top: $label-top-focus;
        left: $label-focus-left;
        font-size: $label-font-size-focus;
        font-weight: $label-font-weight;
    }

    &.hasValue ~ label,
    &:not(:empty) ~ label {
        top: $label-top-focus;
        left: $label-focus-left;
        font-size: $label-font-size-focus;
        font-weight: $label-font-weight;
    }

    &:-webkit-autofill ~ label,
    &:-webkit-autofill:hover ~ label,
    &:-webkit-autofill:focus ~ label {
        top: $label-top-focus;
        left: $label-focus-left;
        font-size: $label-font-size-focus;
    }

    &:focus ~ label,
    &[value=""]:focus ~ label,
    &[value]:focus ~ label,
    &.hasValue:focus ~ label {
        top: $label-top-focus;
        left: $label-focus-left;
        font-size: $label-font-size-focus;
    }
}

@mixin input-date (
    $placeholder,
    $placeholder-focus
){

    &[type="date"] {
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsLW9wYWNpdHk9IjAuNTQiIGQ9Ik0yMCAzaC0xVjFoLTJ2Mkg3VjFINXYySDRjLTEuMSAwLTIgLjktMiAydjE2YzAgMS4xLjkgMiAyIDJoMTZjMS4xIDAgMi0uOSAyLTJWNWMwLTEuMS0uOS0yLTItMnptMCAxOEg0VjhoMTZ2MTN6Ii8+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjwvc3ZnPgo=') no-repeat right;
        background-position-x: calc(100% - 10px);
        background-size: 20px;
    }

    &[type="date"],
    &[type="date"]::-webkit-datetime-edit-text,
    &[type="date"]::-webkit-datetime-edit-month-field,
    &[type="date"]::-webkit-datetime-edit-day-field,
    &[type="date"]::-webkit-datetime-edit-year-field {
        color: $placeholder;
    }

    // Firefox
    &[type="date"] {
        color: $placeholder;
    }

    &[value] {
        &[type="date"]::-webkit-datetime-edit-text,
        &[type="date"]::-webkit-datetime-edit-month-field,
        &[type="date"]::-webkit-datetime-edit-day-field,
        &[type="date"]::-webkit-datetime-edit-year-field {
            color: $placeholder;
        }

        // Firefox
        &[type="date"] {
            color: $placeholder;
        }
    }

    &:focus,
    &.hasValue {
        &[type="date"]::-webkit-datetime-edit-text,
        &[type="date"]::-webkit-datetime-edit-month-field,
        &[type="date"]::-webkit-datetime-edit-day-field,
        &[type="date"]::-webkit-datetime-edit-year-field {
            color: $placeholder-focus;
        }

        // Firefox
        &[type="date"] {
            color: $placeholder-focus;
        }
    }

    &[type="date"]::-webkit-calendar-picker-indicator {
        color: transparent;
        background: transparent;
    }

    &[type="date"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
    }
}
