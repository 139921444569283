// ==============================================
// Branch Finder
// ==============================================

.branch-finder {
    height: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        'branch-product'
        'branch-search'
        'branch-map'
        'branch-list';

    address.inline-address {
        span + span {
            &::before {
                content: ', ';
            }
        }
    }

    .table {
        margin-bottom: 0;

        tbody {
            tr {
                cursor: pointer;
            }
        }

        td {
            vertical-align: top;
        }
    }

    .branch-buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .button,
        .message {
            margin-top: 10px;
            width: 100%;
        }

        .button:first-child {
            margin-top: 0;
        }

        .message {
            margin: 10px 0 0;
            font-size: $font-size-s;
            line-height: 14px;
            padding-right: 8px;

            &:first-child {
                margin-top: 0;
            }
        }

        @media (min-width: $bp-screen-m + 1) {

            .dropdown-button__primary {
                min-width: 130px;
            }

            flex-direction: column;

            > * + * {
                margin-top: 10px;
            }
        }

        @media (max-width: $bp-screen-m) {
            > * + * {
                margin-left: 10px;
            }
        }
    }

    .get-directions {
        display: block;
        white-space: nowrap;
    }

    .product-availability {
        white-space: nowrap;

        .availability-count {
            font-weight: 600;
        }

        &.available .availability-count {
            color: $c-green;
        }

        &.loading .availability-count {
            font-weight: 400;
        }
    }

    .branch-marker-label {
        width: 29px;
        height: 17px;
        line-height: 17px;
        border: 1px solid #fee1d7;
        border-radius: 3px;
        background: #eb3a44;
        color: #fee1d7;
        text-align: center;
        font-size: 9px;
    }

    @media (min-width: $bp-screen-m + 1) {
        grid-template-columns: 6fr 4fr;
        grid-template-rows: auto auto 1fr;
        grid-template-areas:
            'branch-product branch-product'
            'branch-search branch-map'
            'branch-list branch-map';

        .schedule__container {
            width: 180px;

            .hours-link {
                position: relative;
                color: $c-brand;

                @include lib-icon-font(
                    $_icon-font-content: $icon-pointer-down,
                    $_icon-font-size: 18px,
                    $_icon-font-line-height: 18px,
                    $_icon-font-text-hide: false,
                    $_icon-font-position: after,
                    $_icon-font-display: block,
                    $_icon-font-color: $c-brand
                );

                &.arrow-down {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-pointer-up,
                        $_icon-font-size: 18px,
                        $_icon-font-line-height: 18px,
                        $_icon-font-text-hide: false,
                        $_icon-font-position: after,
                        $_icon-font-display: block,
                        $_icon-font-color: $c-brand
                    );
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .weekday-day {
                width: 250px;
                overflow: visible;
            }
        }
    }

    @media (max-width: $bp-screen-m) {
        h2 {
            font-size: 20px;
        }

        .table,
        .table thead,
        .table tbody,
        .table td,
        .table th {
            display: block;
        }

        .table tr {
            display: flex;
        }

        .table thead tr {
            border-top: 1px solid $c-border;
            border-bottom: 1px solid $c-border;

            th {
                border: none;
            }

            .col-name {
                width: 70%;
                padding-left: 25px;
            }

            .col-availability {
                width: 30%;
            }

            .col-address,
            .col-distance,
            .col-buttons {
                display: none;
            }
        }

        .table tbody tr {
            flex-wrap: wrap;
            padding-bottom: 10px;
            border-bottom: 1px solid $c-border;
            position: relative;

            @include lib-icon-font(
                $_icon-font-content: $icon-add,
                $_icon-font-size: 16px,
                $_icon-font-position: after,
                $_icon-font-display: block
            );

            display: flex;

            &:after {
                position: absolute;
                top: 10px;
                left: 0;
            }

            &.expanded {
                @include lib-icon-font(
                    $_icon-font-content: $icon-minus,
                    $_icon-font-size: 16px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );
                display: flex;
            }

            &:not(.expanded) {
                .col-address,
                .col-distance,
                .col-buttons {
                    display: none;
                }
            }

            td {
                padding: 10px 0 0 25px;
                border: none;
            }

            .col-name {
                order: -2;
                width: 100%;

                br {
                    display: none;
                }
            }

            .col-availability {
                order: -1;
                width: 30%;
                padding-left: 10px;

                .separator {
                    text-indent: -9999999px;
                    display: inline-block;

                    &:after {
                        content: ' / ';
                        text-indent: 0;
                        display: inline-block;
                        float: left;
                        padding-left: 5px;
                    }
                }
            }

            .col-address,
            .col-distance,
            .col-buttons {
                width: 100%;
            }

            .get-directions {
                display: inline;
                margin-left: 10px;
            }

            .store-distance {
                padding: 0;
            }
        }

        .schedule__container {
            padding-top: 15px;
        }

        .schedule.today-day {
            display: none !important;
        }

        .schedule.weekday-day {
            display: block !important;

            font-size: 12px;
        }
    }
}


.store-hours-collapsible {
    &[aria-expanded="false"] {
        [data-role="content"] {
            display: none;
        }
    }

    &[aria-expanded="true"] {
        .dropdown-trigger:after {
            transform: rotate(180deg);
        }
    }
}

.store-hours {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .dropdown-trigger {
        display: block;
        position: relative;
        padding-right: 22px;

        &:after {
            position: absolute;
            right: 0;
            top: 1px;
        }
    }
}

.store-hours-content {
    display: inline-block;
    margin-top: 5px;
    padding: 12px;
    border: 1px solid #d3d3d3;

    .day-row {
        display: flex;

        .day {
            width: 90px;
            margin-right: 10px;
        }
    }
}

.modal--branch-finder {
    &.modal--slide {
        left: 15%;

        .modal-content {
            padding-top: 0;
        }
    }

    @media (min-width: $bp-screen-m + 1) {
        .modal-content,
        .branch-finder-wrapper,
        .branch-finder {
            height: 100%;
        }
    }

    @media (max-width: $bp-screen-l) {
        &.modal--slide {
            left: 0;
        }
    }

    @media (max-width: $bp-screen-m) {
        &.modal--slide {
            .modal-content {
                padding: 0 15px 15px;
            }
        }
    }
}

// ----------------------------------------------
// IE

.ie11 {
    @media (min-width: $bp-screen-m + 1) {
        .branch-finder {
            display: -ms-grid;
            -ms-grid-columns: 3fr 2fr;
        }
    }
}

// ==============================================
// Branch Finder - Map
// ==============================================

.branch-map {
    grid-area: branch-map;
    position: relative;

    .map-container {
        height: 100%;
    }

    .branch-buttons {
        flex-direction: row;
        justify-content: flex-start;

        > * + * {
            margin-top: 0;
            margin-left: 10px;
        }
    }

    .map-expander {
        width: 100%;
        height: 24px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $c-blue-dark;
        z-index: 1;

        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 22px,
            $_icon-font-position: after,
            $_icon-font-display: block
        );
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
            color: white;
        }

        &.opened {
            transform: rotate(180deg);
        }
    }

    @media (max-width: $bp-screen-m) {
        .map-container {
            height: 100px;

            &.opened {
                height: 400px;
            }
        }
    }

    @media (min-width: $bp-screen-m + 1) {
        .map-expander {
            display: none;
        }
    }
}

// ----------------------------------------------
// IE

.ie11 {
    @media (min-width: $bp-screen-m + 1) {
        .branch-map {
            -ms-grid-row: 2;
            -ms-grid-row-span: 2;
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
        }
    }
}


// ==============================================
// Branch Finder - List
// ==============================================

.branch-list {
    grid-area: branch-list;
    overflow-y: scroll;
}

// ----------------------------------------------
// IE

.ie11 {
    @media (min-width: $bp-screen-m + 1) {
        .branch-list {
            -ms-grid-row: 3;
            -ms-grid-row-span: 1;
            -ms-grid-column: 1;
            -ms-grid-column-span: -1;
        }
    }
}

// ==============================================
// Branch Finder - Search
// ==============================================

.branch-search {
    grid-area: branch-search;

    .control-search {
        .search-bar {
            .input-text {
                border-radius: 0;
            }

            .button {
                margin-left: 10px;
            }
        }

        .my-location {
            display: inline-block;
        }

        .my-location:before,
        .my-branch:before {
            vertical-align: bottom;
        }

        @media (max-width: $bp-screen-m) {
            margin-top: 0;
        }
    }

    .form {
        max-width: none;
    }

    @media (max-width: $bp-screen-m) {
        margin-top: 20px;
    }
}

.recent-branch {
    cursor: pointer;

    @include lib-icon-font(
        $_icon-font-content: $icon-add,
        $_icon-font-size: 28px,
        $_icon-font-line-height: 30px,
        $_icon-font-text-hide: false,
        $_icon-font-position: after,
        $_icon-font-display: block,
        $_icon-font-color: $gray-400
    );

    &.recent-visible {
        @include lib-icon-font(
            $_icon-font-content: $icon-minus,
            $_icon-font-size: 28px,
            $_icon-font-line-height: 30px,
            $_icon-font-text-hide: false,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-color: $gray-400
        );
    }
}

// ----------------------------------------------
// IE

.ie11 {
    @media (min-width: $bp-screen-m + 1) {
        .branch-search {
            -ms-grid-row: 2;
            -ms-grid-row-span: 1;
            -ms-grid-column: 1;
            -ms-grid-column-span: 0;
            margin-right: 20px;
            margin-bottom: 20px;
        }
    }
}


// ==============================================
// Branch Finder - Selected
// ==============================================

.preferred-branch {
    margin-top: 20px;
}

// ==============================================
// Branch Finder - Results
// ==============================================

.branch-results {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .branch-results-list {
        .results-more {
            text-transform: uppercase;
            padding: 15px;
            display: block;
            text-align: center;
        }

        &.expanded {
            .results-more {
                display: none;
            }
        }
    }
}

// ==============================================
// Branch Finder - Product
// ==============================================

.branch-product {
    grid-area: branch-product;
    display: flex;
    align-items: center;
    padding: 10px;
    background: $c-background;

    .branch-product-image {
        margin-right: 10px;
        overflow: hidden;
    }
}

@media (min-width: $bp-screen-m + 1) {
    .modal.inventory-finder_modal .modal-content,
    .inventory-app {
        height: 100%;
    }
}

// ----------------------------------------------
// IE

.ie11 {
    @media (min-width: $bp-screen-m + 1) {
        .branch-product {
            -ms-grid-row: 1;
            -ms-grid-row-span: 1;
            -ms-grid-column: 1;
            -ms-grid-column-span: 2;
            margin-bottom: 20px;
        }
    }
}
