/**
* East Coast Metal Distributors
*/


// ==============================================
// Vars
// ==============================================

$dashboard-grid-gap: $space-xxl;
$dashboard-grid-col-2-width: 280px;


// ==============================================
// Dashboard Accounts
// ==============================================

.dashboard-section_accounts {
    margin-bottom: $dashboard-grid-gap;
    height: auto;
}

.dashboard-accounts {
    display: flex;
    flex-wrap: wrap;
    margin-top: -15px;
    margin-left: -15px;
    height: auto;
    width: calc(100% + 15px);
}

.dashboard-accounts_item {
    p {
        margin-bottom: 5px;
    }
}


// ----------------------------------------------
// Desktop

@media (min-width: $bp-screen-m) {

    .dashboard-accounts_item {
        flex: 1 0 calc(100% - 16px);
        max-width: none;
    }

}

// ==============================================
// Dashboard BakerPay
// ==============================================

.dashboard-section_bakerpay {
    flex: 0 1 250px;
    max-width: 250px;
    height: 132px;
    align-self: flex-end;
    position: relative;
    margin-bottom: $dashboard-grid-gap;
}

.dashboard-bakerpay {
    padding: 26px $space-base;
    transition: $transition-all;
    box-shadow: $box-shadow-base;
}

.dashboard-bakerpay img {
    margin: 0 auto;
}

.dashboard-section_bakerpay a {
    color: $c-text;
}

.dashboard-section_bakerpay a:hover {
    text-decoration: none;
}

a:hover .dashboard-bakerpay {
    box-shadow: $box-shadow-hover;
}

.dashboard-bakerpay--flag {
    position: absolute;
    right: 0;
    top: 0;
}

// ==============================================
// Dashboard Account Profile
// ==============================================

.dashboard-section_accountprofile {
    margin-bottom: $dashboard-grid-gap;
    margin-right: $space-xl;
    height: auto;
}

.dashboard-accountprofile {
    display: flex;
    flex-wrap: wrap;
    margin-top: -15px;
    margin-left: -15px;
    height: auto;
}

.dashboard-accountprofile_item {
    margin-top: $space-base;
    margin-left: $space-base;
    padding: $space-s;
    border: 1px solid $c-border;
    border-radius: $radius-base;
    background: $c-white;
    width: 185px;
    text-align: center;
}

.dashboard-accountprofile_item p {
    margin-bottom: 10px;
    text-align: center;
}
.dashboard-accountprofile_item svg {
    margin-top: -5px;
    margin-bottom: 10px;
}

// ==============================================
// Dashboard Grid
// ==============================================

@media (min-width: $bp-screen-m) {

    .dashboard-grid {
        display: flex;
    }

    .dashboard-grid > .col-1 {
        flex: 1 1 880px;
    }

    .dashboard-grid > .col-2 {
        padding-left: $dashboard-grid-gap;
        flex: 1 1 320px;
    }

}


// ==============================================
// Dashboard Section
// ==============================================

.dashboard-section {
    margin-bottom: $dashboard-grid-gap;
}

.dashboard-section--top {
    display: flex;
    flex-wrap: wrap;
}


// ==============================================
// Dashboard Section Title
// ==============================================

.dashboard-section h2 {
    margin-bottom: 0;
}


// ==============================================
// Dashboard Quick Lists
// ==============================================

//.dashboard-quick-lists {}

.dashboard-quick-lists_item {
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }
}

// ==============================================
// Dashboard blocks
// ==============================================

.dashboard-section.home-branch,
.dashboard-section.salesperson {
    & > * {
        display: flex;
        flex-direction: column;
    }

    .page.messages {
        padding: 0;
    }

    .error.message {
        margin: 0;
    }
}

.dashboard-section .panel_info,
.dashboard-section .panel_action {
    margin-bottom: 0;
}

// ==============================================
// Tables
// ==============================================

.dashboard-section .table th {
    padding-top: 0;
}

// ==============================================
// Action Group
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .dashboard-section .panel_action .action-group {
        float: none;

        & .flat-button {
            margin: 0;
        }
    }

}

// ==============================================
// Dashboard First Login Modal
// ==============================================

.modal.first-login .modal-header {
    display: none;
}

// ==============================================
// Order Search
// ==============================================

.dashboard-section .form--order-dashboard-search {
    max-width: none;
}
