/**
* East Coast Metal Distributors
*/


// ==============================================
// Collapsible
// ==============================================

.collapsible_title {
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing;
    color: $c-text;
    text-decoration: none;
    word-break: break-word;
    cursor: pointer;
    padding: $space-s 0 $space-s $space-xs;
    margin: 2px -8px 2px -8px;
    border-radius: $radius-base;
    transition: $transition-all;
    display: inline-block;

    &:hover {
        background: $gray-50;
        color: $c-text;
        text-decoration: none;
    }

    @include lib-icon-font(
        $_icon-font-content: $icon-add,
        $_icon-font-size: 16px,
        $_icon-font-position: before,
        $_icon-font-display: block,
        $_icon-font-margin: -3px 5px 0 0
    );

    a {
        color: $c-text;
    }
}

.cat-tab {
    border-left: 1px $c-gray-lighter solid;
    margin: 0;
    margin-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ol.cat-tab  {
    padding-left: 30px;
}

.cat-tab-1 {
    margin: 0;
    margin-left: 7px !important;
    border-left: 1px solid $c-gray-lighter;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

ol.cat-tab-1  {
    padding-left: 0;
}

ol.cat-tab-parent {
    padding-left: 30px;
}

.spantab {
    display: block;
    margin: 0;
    margin-left: 15px;
    border-left: 1px solid $c-gray-lighter;
}

.spantab-0 {
    margin-left: 7px;
}

#category_filter_sidebar {
    [type='checkbox'] + label::before {
        width: 20px;
        height: 20px;
    }
}

.collapsible_title--opened {
    @include lib-icon-font(
            $_icon-font-content: $icon-minus,
            $_icon-font-size: 16px,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-margin: -3px 5px 0 0
    );
}

.collapsible_content {
    margin-left: $space-base;
}

.collapsible_content--opened {
    display: block;
}

.collapsible_content__see-more-less {
    padding: 8px 0;
    display: inline-block;
}
