/**
* East Coast Metal Distributors
*/


// ==============================================
// Var
// ==============================================

$minicart-padding: $space-base;

$minicart-quantity-height: 24px;


// ==============================================
// Minicart
// ==============================================

.cart__container > div {
    display: block !important;
}

.minicart-wrapper {
    .dropdown-content {
        border: none;
    }

    .dropdown-trigger::after {
        display: none;
    }

    .shopping-cart-link {
        color: $c-white;
        padding: 12px 10px 0 0;
    }
}
.block-minicart {
    font-family: $font-family-sans;
}

.block-minicart .items-total {
    float: left;
}

.block-minicart .items-total .count {
    font-weight: $font-weight-bold;
}

.block-minicart .subtotal {
    margin-bottom: $space-base;
    text-align: right;
}

.block-minicart .amount {
    display: inline-block;
}

.block-minicart .amount .price-wrapper:first-child .price {
    font-weight: $font-weight-bold-bold;
}

.block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: $space-xxl 0 $space-l;
    text-align: center;
}

.block-minicart .text.empty {
    text-align: center;
}

.minicart-actions > * {
    margin-bottom: $space-xs;
}

.minicart-actions_extra:after {
    @include clearfix;
}

@media (min-width: $bp-screen-m) {
    .minicart-actions_viewcart {
        width: 41%;
    }
}


@media (max-width: $bp-screen-m - 1px) {
    .minicart-actions_extra {
        width: 100%;
    }
    .minicart-actions_extra > * {
        margin-bottom: $space-xxs;
    }
}


.block-minicart .block-content > .actions > .primary {
    margin: 0 $space-base $space-base;
}

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
    margin: 15px 0 0;
    text-align: center;
}


// ==============================================
// Minicart Wrapper
// ==============================================

.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector: '.action.showcart > .cart-icon',
        $_options-selector: '.block-minicart',
        $_dropdown-toggle-icon-content: $icon-cart,
        $_dropdown-toggle-active-icon-content: $icon-cart,
        $_dropdown-list-item-padding: false,
        $_dropdown-list-item-hover: false,
        $_dropdown-list-pointer: false,
        $_icon-font-position: before,
        $_icon-font-size: 32px,
        $_icon-font-line-height: 30px,
        $_icon-font-color: $c-white,
        $_icon-font-color-hover: $c-white,
        $_icon-font-color-active: $c-white
    );
}

.minicart-wrapper .block-minicart {
    padding: $minicart-padding;
    right: 0;
    width: 370px;
    z-index: 1001;
}

.minicart-wrapper .block-minicart .block-title {
    display: none;
}

.minicart-wrapper .action.showcart {
    white-space: nowrap;
    &:hover {
        text-decoration: none !important;
    }
}

.minicart-wrapper .action.showcart > .cart-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.minicart-wrapper .action.showcart .text {
    font-size: $font-size-xxs;
    color: $c-white;
}

.minicart-wrapper .action.showcart .counter.qty {
    background: $c-blue;
    color: $c-white;
    height: $minicart-quantity-height;
    line-height: 23px;
    font-size: $font-size-xs;
    border-radius: 50%;
    display: inline-block;
    margin: 3px 0 0 0;
    min-width: $minicart-quantity-height;
    overflow: hidden;
    padding: 0 3px;
    text-align: center;
    white-space: normal;
}

.minicart-wrapper .action.showcart .counter.qty.empty {
    display: none;
}

.minicart-wrapper .action.showcart .counter.qty .loader > img {
    max-width: $minicart-quantity-height;
}

.minicart-wrapper .action.showcart .counter-label {
    @include lib-visually-hidden;
}

.minicart-wrapper .minicart-widgets {
    margin-top: $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-xs - 1px) {

    .minicart-wrapper .block-minicart {
        width: 290px;
    }

}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .minicart-wrapper .action.showcart .counter.qty {
        background: $c-blue;
        color: $c-white;
        height: 20px;
        line-height: 19px;
        font-size: $font-size-xxxs;
        border-radius: 50%;
        display: inline-block;
        margin: 3px 0 0 0;
        min-width: 18px;
        overflow: hidden;
        padding: 0 2px;
        text-align: center;
        white-space: normal;
    }

    .minicart-wrapper .action.showcart .counter.qty {
        position: absolute;
        top: -12px;
        left: 14px;
    }

    .minicart-wrapper {
        margin-top: 10px;
        .shopping-cart-link {
            display: none;
        }
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .minicart-wrapper {
        margin-left: 25px;
        order: 30;
    }

    .minicart-wrapper .action.showcart,
    .minicart-wrapper .action.showcart.active {
        line-height: 1;
        display: flex;

        &::before {
            margin-top: 7px;
        }
    }

    .minicart-wrapper .action.showcart > .cart-icon {
        @include _lib-icon-text-hide(true);
    }

}


// ==============================================
// Minicart Items
// ==============================================

.minicart-items-wrapper {
    border-left: 0;
    border-right: 0;
    width: 100%;
    height: auto !important;
}

.minicart-item {
    border-top: 1px solid $c-gray-lighter;
    padding: $space-l 0;
}

.minicart-item-wrapper {
    @include lib-clearfix;
    position: relative;
}

.minicart-item_price {
    float: left;
}

.minicart-item_action {
    float: right;
    margin-left: $space-base;
}

.minicart-item_qty {
    position: relative;
    padding-right: 5px;
    padding-top: 3px;
}

.minicart-item-wrapper > .minicart-item_photo {
    position: absolute;
    left: 0;
    width: auto;
}

.minicart-item .minicart-item_name {
    padding-bottom: 5px;
    min-height: 45px;
}

.minicart-item .minicart-item_name a {
    color: $c-darkgray;

    &:hover {
        color: $c-darkgray;
    }
}

.minicart-item .minicart-item_details {
    padding-left: 88px;
}

.minicart-item .minicart-item_details .price {
    font-weight: $font-weight-bold-bold;
}

.minicart-item .minicart-item_details .price-including-tax,
.minicart-item .minicart-item_details .price-excluding-tax {
    margin: $space-xs 0 0;
}

.minicart-item .minicart-item_details .weee[data-label] {
    font-size: $font-size-xxs;
}

.minicart-item .minicart-item_details .weee[data-label] .label {
    display: none;
}

.minicart-item .input-text.qty {
    text-align: center;
    height: 25px;
    width: 40px;
    padding: 0;
}

.minicart-item .update-cart-item {
    position: initial;
    width: 100%;
    display: block;
    padding: 2px 10px;
    text-align: center;
    margin-top: 5px;
}

.minicart-item .action.delete {
    @include lib-icon-font(
            $icon-trash,
        $_icon-font-size: 28px,
        $_icon-font-line-height: 28px,
        $_icon-font-text-hide: true,
        $_icon-font-color: $gray-700,
        $_icon-font-color-hover: $gray-700,
        $_icon-font-color-active: $gray-700
    );
}

.minicart-item .action.delete[disabled="disabled"],
.minicart-item .update-cart-item[disabled="disabled"] {
    &:before {
        opacity: 0.5;
    }
}

// ==============================================
// Minicart Actions
// ==============================================

.minicart-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 12px;
    margin-bottom: $space-xs;

    .button {
        width: 100%;
    }
}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m) {
    .minicart-wrapper .block-minicart {
        position: initial;
    }

    .minicart-wrapper .block-minicart {
        box-shadow: none;
        border: none;
        overflow-y: scroll;
        height: 100vh;
    }

    .minicart-item .minicart-item_name {
        min-height: 50px;
    }

    .minicart-extra-buttons {
        grid-column: 1 / span 2;
    }

    .minicart-actions_viewcart:not(:has(+ a)) {
        grid-column: 1 / span 2;
    }
}

@media (max-width: $bp-screen-xs) {
    .minicart-wrapper {
        .minicart-item_qty {
            padding-right: 0;
        }
        .minicart-item_action {
            margin-left: 0;
        }
    }
}
