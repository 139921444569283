/**
* East Coast Metal Distributors
*/

// ==============================================
// Tabs
// ==============================================

.tabs {
    @include lib-data-tabs(
        $_title-selector: '.tabs_title',
        $_content-selector: '.tabs_item',
        $_tab-content-border: 1px solid $c-gray-light,
        $_tab-content-padding-bottom: 0,
        $_tab-content-padding-left: 0,
        $_tab-content-padding-right: 0,
        $_tab-content-padding-top: 0,
        $_tab-control-background-color: $c-gray,
        $_tab-control-background-color-active: $c-white,
        $_tab-control-border-bottom-color-active: $c-white,
        $_tab-control-border-color: $gray-400,
        $_tab-control-border-width: 1px,
        $_tab-control-line-height: 18px,
        $_tab-content-border-top-status: false
    );
}

.tabs_title {
    border: 1px solid $c-gray-light;
    border-bottom: none;
    min-height: 50px;

    a {
        color: $gray-800;
        font-size: $font-size-base;
        text-transform: initial;
    }

    &.active {
        border-bottom: 1px solid $c-white;

        a {
            color: $gray-900;
        }
    }

    &:not(:first-of-type) {
        border-left: none;
    }
}

.tabs_item {
    border: 1px solid $c-gray-light;
}
