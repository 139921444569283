// ==============================================
// Chip Input
// ==============================================

.chip-input {
    @include text-field(
        $input-border: none,
        $input-padding: 10px
    );

    padding: 0 8px 4px 8px;
    height: auto;
    min-height: 38px;
    align-items: center;

    .chip {
        margin: ($space-xs * 0.5) $space-xs 0 0;
    }

    &.field-error,
    &:has(~ .mage-error) {
        @extend .field-error
    }
}

.chip-input__input {
    flex: 1 0 100px;
    padding: 8px 0 4px;
    border: none;
    height: 34px;

    .control &.hasValue {
        @include placeholder();
    }

    &:disabled {
        border: none;
        background-color: transparent;
    }
}

.chip-input__validation-input {
    height: 0;
    position: absolute;
    visibility: hidden;
}


// ==============================================
// Materialize.js chip custom styling
// ==============================================


.chip-group .input {
    flex: 1 0 100px;
    padding: 8px 0 4px;
    border: none;
    height: 34px;
}

