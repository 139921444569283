/**
* East Coast Metal Distributors
*/


// ==============================================
// Input Text
// ==============================================

.input-text {
    @include text-field(
        $input-border: none,
        $input-padding: 10px
    );

    @include label-color (
        $label: $c-label,
        $label-focus: $c-label-focus
    );

    @include label-position(
        $label-top: 15px,
        $label-top-focus: -12px,
        $label-left: 10px,
        $label-font-size: $form-elem-font-size,
        $label-font-size-focus: 13px,
        $label-focus-left: 0
    );

    &[type="date"]::-webkit-calendar-picker-indicator {
        color: $c-input-border-bottom;
    }

    @include input-date(
        $placeholder: transparent,
        $placeholder-focus: $c-text
    );
}


// ==============================================
// Modifier: Size
// ==============================================

.input-text--s {
    max-width: $form-elem-width-s;
}

.input-text--xs {
    max-width: $form-elem-width-xs;
}

.input-text--xxs {
    max-width: $form-elem-width-xxs;
}


// ==============================================
// Context: .form--contrast
// ==============================================

.form--contrast .input-text {
    color: $c-contrast-input-text;
    border-bottom-color: $c-contrast-input-border-bottom;

    &:focus {
        border-bottom-color: $c-contrast-input-border-focus;
    }

    @include label-color (
        $label: $c-contrast-label,
        $label-focus: $c-contrast-label-focus
    );

    @include input-date(
        $placeholder: transparent,
        $placeholder-focus: $c-contrast-label
    );

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: $c-contrast-input-text-fill;
        -webkit-box-shadow: 0 0 0px 1000px $gray-800 inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}


// ==============================================
// Modifier: Material UI
// ==============================================

.input-text--mui {
    border-color: rgba(0, 0, 0, 0.23);
    color: rgba(0, 0, 0, 0.87);
    padding: 18px 32px 18px 14px;
    font-size: 16px;
    height: 56px;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
    line-height: 16px;

    &:hover {
        border-color: rgba(0, 0, 0, 0.87);
    }

    &:focus {
        background-color: rgba(0, 0, 0, 0.05);
        border-color: rgba(0, 0, 0, 0.23) !important;
    }

    @include label-position(
        $label-top: 24px,
        $label-top-focus: -16px,
        $label-left: 10px,
        $label-font-size: 16px,
        $label-font-size-focus: 12px,
        $label-focus-left: 0,
        $label-font-weight: $font-weight-normal
    );
}
