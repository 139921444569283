/**
* East Coast Metal Distributors
*/

// ==============================================
// Vars
// ==============================================

$panel-action-gap:        $space-l;
$panel-action-double-gap: $panel-action-gap + $panel-action-gap;


// ==============================================
// Panel
// ==============================================

.panel {
    position: relative;
    margin-bottom: $gap;
    border: 1px solid $c-gray-light !important;
    background: $c-white;
}


// ==============================================
// Panel - Header
// ==============================================

.panel_header {
    display: flex;
    flex-wrap: nowrap;
    padding: $space-base $space-base 0;
}

@media (max-width: $bp-screen-s - 1px) {
    .panel_header {
        flex-wrap: wrap;
    }
}


// ==============================================
// Panel - Info
// ==============================================

.panel_info {
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .panel_info {
        width: 100%;
    }

}


// ==============================================
// Panel - Action
// ==============================================

.panel_action {
    margin-bottom: $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .panel_action {
        width: 100%;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .panel_info {
        flex: 1 1 50%;
    }

    .panel_action {
        flex: 1 1 50%;
    }

    .panel_action .action-group {
        float: right;
    }

}


// ==============================================
// Panel - Float
// ==============================================

.panel_float {
    margin: -$panel-action-gap 0 0 $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .panel_float .float-button {
        position: sticky;
        top: calc(100% - 68px);
    }
}


// ==============================================
// Panel - Body
// ==============================================

.panel_body {
    padding: $space-base;
    height: 100%;
}


// ==============================================
// Panel - Footer
// ==============================================

.panel_footer {
    padding: $space-base;
}


// ==============================================
// Panel - Toolbar
// ==============================================

.panel_toolbar {
    position: relative;
    margin-bottom: $gap;
}
