/**
* East Coast Metal Distributors
*/


// **********************************************
// [todo] check if this is used
// **********************************************


// ==============================================
// Data Table Definition List
// ==============================================

@media (max-width: $bp-screen-s - 1px) {

    .data-table-definition-list thead {
        display: none;
    }

    .data-table-definition-list tbody th {
        padding-bottom: 0;
    }

    .data-table-definition-list tbody th,
    .data-table-definition-list tbody td {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }

}
