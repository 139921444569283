//
//  Mobile (Accordion)
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {

    .tab-accordion {
        @include lib-data-accordion(
            $_accordion-content-margin: 0,
            $_accordion-control-background-color: $c-background,
            $_accordion-control-background-color-active: $c-background,
            $_accordion-control-background-color-hover: $c-lightgray,
            $_accordion-control-border-bottom: 1px solid $c-gray,
            $_accordion-control-font-family: $font-family-special,
            $_accordion-control-border-top: none,
            $_accordion-control-height: 50px,
            $_accordion-control-line-height: 34px,
            $_accordion-control-margin-bottom: 0,
            $_accordion-control-padding-left: 45px
        );

        & > .item.title {
            position: relative;
            display: inline-block;
            text-decoration: none;

            @include lib-icon-font(
                $_icon-font-content: $icon-add,
                $_icon-font-size: 25px,
                $_icon-font-line-height: 25px,
                $_icon-font-color: $c-darkgray
            );

            &.ui-state-active {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-minus
                );
            }

            &:before {
                position: absolute;
                top: 12px;
                left: 10px;
            }
        }

        & > .item.title > .switch {
            text-decoration: none;
            border: 1px solid $gray-400;
            border-top: none;
            font-size: 14px;
            color: $gray-800;
        }

        & > .item.title:first-of-type .switch {
            border-top: 1px solid $gray-400;
        }

        & > .item.content {
            border: 1px solid $gray-400;
            border-top: none;
        }
    }

}

//
//  Desktop (Tabs)
//  _____________________________________________

@media (min-width: $bp-screen-m) {

    .tab-accordion {
        @include lib-data-tabs(
            $_tab-content-border: 1px solid $gray-400,
            $_tab-content-padding-bottom: 2 * $space-base,
            $_tab-content-padding-left: 2 * $space-base,
            $_tab-content-padding-right: 2 * $space-base,
            $_tab-content-padding-top: 2 * $space-base,
            $_tab-control-background-color: $c-gray,
            $_tab-control-background-color-active: $c-white,
            $_tab-control-border-bottom-color-active: $c-white,
            $_tab-control-font-family: $font-family-special,
            $_tab-control-border-color: $gray-400,
            $_tab-control-border-width: 1px,
            $_tab-control-line-height: 24px
        );

        & > .item.title {
            border: 1px solid $gray-400;
            height: 100%;

            &:not(:first-child) {
                border-left: none;
            }
        }

        & > .item.title > .switch {
            line-height: 18px;
            text-transform: none;
        }

        & > .item.content {
            border: 1px solid $gray-400;
        }
    }

}
