/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Mixin - SVG
// ==============================================

@mixin svg ($name) {
    background: url(../images/svg/#{$name}.svg) no-repeat;
}
