/**
* East Coast Metal Distributors
*/


// ==============================================
// Sales Guest
// ==============================================

// [todo] define "page-title" pattern
[class^='sales-guest-'] .page-title-wrapper .page-title {
    margin-right: $space-l;
}

// [todo] remove. use utility classes
.form-orders-search .field.email,
.form-orders-search .field.zip {
    margin-bottom: 0;
}

// [todo] refactor to "box"
[class^='sales-guest-'] .column.main .block:not(.widget) {
    @extend .abs-account-blocks;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    [class^='sales-guest-'] .column.main .block:not(.widget) .block-content {
        @include lib-clearfix;
    }

    [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box {
        @extend .abs-blocks-2columns;
        margin-bottom: $space-l;
    }

}
