// ==============================================
// Stocking List Listing
// ==============================================

.stocking-manage .qty-min-max {
    display: flex;
    text-align: center;
    flex-direction: column;
}

.stocking-list-listing .cell-label-line-name {
    color: $c-brand;

    &:hover {
        text-decoration: underline;
    }
}

.stocking-list-listing tbody tr {
    cursor: pointer;
}

.stocking_list-stocking-ordersview {
    #po-number-n {
        width: 20%;
    }
    .qty {
        label {
            display: none;
        }
    }
}

// ==============================================
// Truck Stock List Listing
// ==============================================
.replenishment-list-listing .cell-label-line-name {
    color: $c-brand;

    &:hover {
        text-decoration: underline;
    }
}

.replenishment-list-listing tbody tr {
    cursor: pointer;
}

.simplestock-selector {
    .page-title {
        @include lib-icon-font(
            $_icon-font-content: $icon-pointer-down,
            $_icon-font-size: 18px,
            $_icon-font-line-height: 18px,
            $_icon-font-text-hide: false,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-color: $c-darkgray
        );

        &::after {
            margin: -4px 0 0 -5px;
            font-size: 32px;
        }

        &.opened::after {
            content: $icon-pointer-up;
        }
    }

    ul {
        display: none;
        position: absolute;
        background: $c-white;
        z-index: 9;
        width: calc(100% - #{$space-xxl});
        padding: 10px;
        border: 1px $c-gray-light solid;
        margin-top: 10px;

        a {
            color: $c-brand;
        }

        span {
            padding: $space-xs $space-base;
            display: block;
        }

    }
}

// ----------------------------------------------
// Mobile & Tablet

@media (max-width: $bp-screen-m - 1px) {
    .stocking-manage .qty-min-max {
        display: flex;
        text-align: center;
        flex-direction: row;

        label {
            padding-right: 3px;
        }

        > div {
            padding-right: 20px;
        }
    }

    .stocking-list-listing .data-grid-actions-cell {
        text-align: left !important;
    }

    .stocking_list-stocking-ordersview {
        #po-number-n {
            width: 100%;
        }

        .order-item-inventory {
            margin: 30px 0;
            padding: 20px 10px;
        }

        .qty {
            label {
                display: block;
                height: 24px;
                position: relative;
            }
        }

        .shipping-loading-method {
            float: left;
            width: 100%;
        }

        .subtotal {
            display: none;
        }
    }

    li.stocking-list-quick-add-item {
        display: block;

        .stocking-list-quick-add-item_col-2 {
            max-width: 100%;
            text-align: center;
        }

        .field-row_action {
            text-align: right;
        }
    }
}


@media (max-width: $bp-table-linearize - 1px) {

    .stocking-list-listing {
        .data-grid-actions-cell {
            padding: 10px 0;
        }

        .list-name-field {
            padding-bottom: 10px;
        }
    }

}


// ==============================================
// Stocking List View
// ==============================================

.stocking-list-description {
    word-wrap: break-word;
}

.stocking-list-view-actions {
    float: right;
}

.stocking-list-view-actions ul li {
    display: inline;
    padding-right: $space-xs;

}

.stocking-list-tabs .item.content {
    padding: 0;
}

@media (max-width: $bp-screen-s - 1px) {
    .stocking-list-view-actions {
        float: left;
    }
}

@media (min-width: $bp-screen-m - 1px) {

    .stocking-list-tabs .item.title:not(:first-of-type):not(.active) + .item.content {
        display: none;
    }

}

@media all and (max-width: 768px) {

    .stocking-list-tabs .tabs_nav {
        flex-direction: column;
    }

}

// ==============================================
// Stocking List Settings
// ==============================================

.form-stocking-list-settings .field--inline .field-item {
    white-space: nowrap;
}

.form-stocking-list-settings textarea.input-text {
    max-height: 200px;
    resize: vertical;
}

.form-stocking-list-settings textarea.input-text--s {
    max-width: 28rem;
}

.form-stocking-list-settings textarea.input-text--s + label {
    max-width: calc(28rem - 20px);
}

.form-stocking-list-settings label.error[generated="true"] {
    position: relative;
    top: 0;
    color: $c-red;
    font-size: 13px;
}

.form-stocking-list-settings .input-invitee-email-wrap {
    max-width: 440px;
}

.form-stocking-list-settings .select-supplier-wrap {
    max-width: 400px;
}

.form-stocking-list-settings select option[disabled] {
    display: none;
}

@media all and (min-width: 768px) {
    .stocking-list-settings_supplier .select-row .field {
        width: 50%;
    }
}

@media all and (max-width: 768px) {

    .stocking-list-settings_info .field--inline,
    .stocking-list-settings_order .field--inline,
    .stocking-list-settings_supplier .field-row {
        display: block;
    }

    .stocking-list-settings_info .field-item,
    .stocking-list-settings_order .field-item {
        margin-left: 0;
    }

}

// ==============================================
// Stocking List Assortment
// ==============================================

.stocking-grid .table td {
    vertical-align: top;
}

.stocking-grid tr.headings th:first-child,
.stocking-grid .col.select {
    padding-left: 0;
    padding-right: 0;
}

.stocking-grid.requisition-grid .product-item-image {
    width: 60px;

    img {
        margin-left: auto;
        margin-right: auto;
        max-height: 60px;
    }
}

.stocking-grid .product-item-name,
.stocking-grid .product-attribute,
.stocking-grid .product-price {
    margin-left: 75px;
}

.stocking-grid .product-item-name a {
    color: $c-darkgray;
}

.stocking-grid .col.product {
    padding-left: 0;
}

.stocking-grid .col.actions {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
}

.stocking-list-item-attribute_value {
    color: $c-text-muted;
    font-size: $font-size-s;
    min-width: 80px;
}

.stocking-list-item-attribute_value.user-product-number {
    min-width: 140px;
}

.stocking-grid tbody .col.qty {
    text-align: center;

    .item-min-qty,
    .item-max-qty {
        display: block;
        padding: 8px;
        background-color: #efefef;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
}

.stocking-grid .simple-table tbody tr {
    border-bottom: none;
}

.stocking-list-items_user-notes table {
    th, td {
        padding-top: 0;
        padding-left: 0;
        border: none;
    }
}

#stocking-list-adding-paste textarea {
    min-width: 100%;
    max-height: 260px;
}

.action-item--simplestock {
    a {
        white-space: nowrap;
        line-height: 36px;
    }
}

@media all and (max-width: 699px) {

    .stocking-manage-column .field--inline {
        display: block;

        .field-item {
            margin-left: 0;
        }
    }

    .stocking-grid .table--linearize tbody tr {
        clear: both;
    }

    .stocking-grid .table--linearize tbody ._odd.item,
    .stocking-grid .table--linearize tbody ._even.item {
        padding-bottom: 48px;
        border-bottom: none;
        padding-top: 48px;
    }

    .stocking-grid .simple-table tbody tr {
        padding: 0;
    }

    .stocking-grid tbody .col.product {
        margin-left: 0;
    }
    #form-stocking-list-barcode tbody .col.product {
        margin-left: 30px;
    }

    .stocking-grid tbody .col.qty {
        float: none;
        margin-right: 0;
    }

    .stocking-grid .product-item-name,
    .stocking-grid .product-attribute,
    .stocking-grid .product-price {
        margin-left: 0;
    }

    .stocking-grid .col.actions {
        padding-top: $space-l;
        margin-top: 0;
        float: left;
    }

    .stocking-list-items_user-notes table.simple-table th {
        padding-bottom: 0;
    }
}

@media all and (min-width: 768px) {

    #stocking-manage-adding .panel_header {
        flex-wrap: wrap;
    }

    #stocking-manage-adding > .panel_body {
        display: flex;

        &:after {
            content: "";
            clear: both;
            display: table;
        }
    }

    #stocking-manage-adding .panel_body .stocking-manage-column {
        float: left;

        &:first-child {
            width: 30%;
        }

        &:last-child {
            width: 70%;
        }
    }

    #stocking-list-adding-paste {
        .stocking-list-paste_column-1,
        .stocking-list-paste_column-2 {
            float: left;
            padding-right: 20px;
            width: 50%;
        }
    }
}

.form-stocking-list-add-item {
    margin-bottom: 0;
}

.form-stocking-list-add-item .product-image {
    width: 60px;
    height: 60px;
    text-align: center;

    img {
        margin-left: auto;
        margin-right: auto;
        max-height: 100%;
    }
}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-m - 1px) {

    .form-stocking-list-add-item {
        .field {
            margin-bottom: 26px;
        }

        .field-row {
            display: flex;
            flex-wrap: wrap;

            .field {
                flex: 1 1 40%;
                margin-bottom: 8px;
            }

            .field:nth-child(3) {
                flex-basis: 100%;
            }
        }
    }

}

.stocking-item-popup.modal .modal-content {
    padding-top: 0;
    padding-bottom: 0;
}

.modal-stocking-quick-add-popup.modal--scroll .modal-inner-wrap {
    height: auto;
    max-height: 80%;
}

// Stocking List Quick Add
.stocking-list-quick-add-submit-form  {
    .stocking-list-quick-add-item,
    .hr--thick {
        float: left;
        width: 100%;
    }
}

.stocking-list-quick-add-submit-form .stocking-list-quick-add-item .product-image {
    max-width: 60px;
}

.stocking-list-quick-add-item_col-1 {
    flex: 1;
    max-width: 500px;
}

.stocking-list-quick-add-item_col-2 {
    max-width: 200px;
}


// ==============================================
// Stocking List Barcodes
// ==============================================

.barcodes-select-items-modified-since .control {
    margin-top: 0.8rem;
}

input[data-action="barcodes-select-modified-after"] {
    width: 234px;
}

.barcodes-select-items-modified-since {
    margin-top: $space-base;
}

.stocking-content .action-group.m-t-0.m-b-base button {
    margin-left: 18px;
}

#form-stocking-list-barcode .table--linearize tbody tr .col.select {
    padding-top: $space-ll;
    @media all and (max-width: 699px) {
        padding-top: $space-xxs;
    }
}

// ==============================================
// Stocking List Order Management
// ==============================================

.stocking_list-stocking-view {

    .page-title-wrapper {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        .action-icon {
            align-self: center;
        }
    }

    .title-actions__container ul {
        font-size: $font-size-base;
        display: inline;
        margin-left: $space-base;

        li {
            display: inline;
            padding-right: $space-base;
        }
    }

    .large-wrapper > .panel_header {
        display: none;
    }

    .notify {
        z-index: $z-modal + 1;
    }
}

.stocking-list-order-actions {
    .simple-loading {
        width: 24px;
        height: 24px;
    }
}

.stocking-list-pending-orders-table .field-po-number {
    min-width: 120px;
}

.stocking-list-in-progress-orders-table .td-po-number {
    word-break: break-word;
}

.stocking-list-order-details-items {

    .product-image {
        max-width: 75px;
    }

    .product-name {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.5;
    }

    .col.qty {
        text-align: right;
    }

    .col.subtotal .price {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
    }
}

.stocking-list-order-details-modal .totals-table {
    float: right;
    margin-bottom: $space-base;

    td {
        padding-right: 8px;
        min-width: 75px;
    }
}

.inventory-wrap {
    .inventory-instore,
    .inventory-nearby,
    .inventory-all {
        font-size: 13px;
        font-weight: 300;
    }

    .stock.available {
        color: $c-green;
    }
    .stock.unavailable,
    .stock.out-of-stock {
        color: $c-red;
    }
}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-screen-m - 1px) {

    .stocking_list-stocking-view .title-actions__container {
        width: 100%;
        margin-top: 20px;

        ul {
            margin-left: 0;
        }
    }

}

// ==============================================
// Product Table Row
// ==============================================

#form-stocking-list .table .product-item-name a {
    color: $c-darkgray;
}
