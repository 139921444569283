// ==============================================
// Punchout
// ==============================================

.checkout-cart-index {
    .punchout-actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        button:disabled {
            cursor: not-allowed !important;
        }

        button.action.primary {
            @extend .button;
            @extend %checkout-cta-button;
        }

        button.action:not(.primary) {
            @extend .flat-button;
        }
    }
}
