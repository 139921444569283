/**
* East Coast Metal Distributors
*/


// ==============================================
// Collapsible Table
// ==============================================

.collapsible-table {
    width: 100%;
    margin-bottom: $gap;
}

.collapsible-table td,
.collapsible-table th {
    padding: $space-s $space-base;
    vertical-align: top;
}

@media (max-width: $bp-screen-m - 1px) {
    .collapsible-table td,
    .collapsible-table th {
        display: inline-block;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .collapsible-table td,
    .collapsible-table th {
        padding: 13px 8px;
    }
}

.collapsible-table th {
    color: $c-text-muted;
    font-size: $font-size-s;
    white-space: nowrap;
    @include bold;
}

.collapsible-table tr {
    border-bottom: 1px solid $c-border;
}

@media (max-width: $bp-screen-m - 1px) {

    .collapsible-table tr {
        cursor: pointer;
    }

    .collapsible-table thead,
    .collapsible-table tbody,
    .collapsible-table tr {
        display: block;
        width: 100%;
    }

    .collapsible-table tr .collapsible-table_hide {
        display: none;
        width: 100%;
    }

    .collapsible-table tr.expanded .collapsible-table_hide {
        display: block;
    }

    tr td.collapsible-table_control {
        @include lib-icon-font(
                        $icon-pointer-down,
                $_icon-font-size: 18px,
                $_icon-font-margin: -3px 0 0 0
        );
    }

    tr.expanded td.collapsible-table_control {
        @include lib-icon-font(
                        $icon-pointer-up,
                $_icon-font-size: 18px,
                $_icon-font-margin: -3px 0 0 0
        );
    }
}
