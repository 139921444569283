/**
* East Coast Metal Distributors
*/


// ==============================================
// Messages
// ==============================================

.page .messages {
    overflow: hidden;
}


// ==============================================
// Message
// ==============================================

.message.info {
    @include lib-message-icon(info);
}

.message.error {
    @include lib-message-icon(error);
}

.message.warning {
    @include lib-message-icon(warning);
}

.message.notice {
    @include lib-message-icon(notice);
}

.message.success {
    @include lib-message-icon(success);
}

.message ul {
    list-style: disc outside none;
    padding-left: $space-base;
}
