//
//  Desktop
//  _____________________________________________

@media (min-width: $bp-screen-m) {
    .side-links {
        .topmenu {
            margin-top: -3px;

            .topmenu-a {
                @include lib-icon-font(
                    $_icon-font-content: $icon-pointer-down,
                    $_icon-font-size: 20px,
                    $_icon-font-line-height: 30px,
                    $_icon-font-text-hide: false,
                    $_icon-font-position: after,
                    $_icon-font-display: block,
                    $_icon-font-color: $c-white
                );
            }
        }
    }

    nav.topmenu-opened {
        &.navigation {
            overflow: visible !important;

            .nav-menu {
                background: transparent;
            }
        }

        .side-links .current-menu-container .topmenu-a {
            &::after {
                content: $icon-pointer-up;
            }
        }

        .topmenu-item {
            span {
                color: $c-black !important;
            }
        }

        .last-line-strong .topmenu-item:last-child {
            span {
                font-weight: 600;
            }
        }

        .nav-topmenu {
            position: absolute;
            top: 36px !important;
            left: -130px !important;
            max-width: 440px;
            height: calc( 100vh - 132px );
        }
    }
}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {
    .topmenu {
        display: none !important;
    }
}
