// ==============================================
// Warranty
// ==============================================

.account-nav_item.warranty {
    span {
        @include lib-icon-font(
            $_icon-font-content:      $icon-shield-approve,
            $_icon-font-size:         $account-nav-icon-size,
            $_icon-font-line-height:  $account-nav-icon-size
        );
        &:before {
            content: none;
        }
    }
    .account-nav_link .account-nav_label {
        padding: 0;
    }
    border-bottom: none;
}

.claim-item--warranty {
    .request-labor-radio,
    .checkbox__container {
        display: flex;
    }

    .checkbox__container input {
        margin-top: 3px;
        margin-right: 10px;
    }
}

#warranty_claim_app {

    .request-labor-radio .field {
        padding-right: 10px;
    }

    .request-labor-radio [type='radio'] + label {
        margin-left: -15px;
    }

    ul.lookup-autocomplete-container li {
        display: flex;
        justify-content: space-between;
        padding: 5px;
    }

    .warranty-failed-part {
        .failed-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        a.remove-button {
            @include lib-icon-font(
                    $icon-trash,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 28px,
                $_icon-font-text-hide: true,
                $_icon-font-color: $gray-700,
                $_icon-font-color-hover: $gray-700,
                $_icon-font-color-active: $gray-700
            );
        }
    }
}

.table.warranty-product {
    tr {
        border: none;
    }

    .col--image {
        vertical-align: top;
    }
}

.warranty-product-attributes {
    .table & tbody tr {
        border: none;
    }

    td, th {
        padding-top: 0;
        padding-left: 0;
        width: 50%;
        white-space: normal;
    }
}

.documentation-item--warranty p:last-child {
    margin-bottom: 0;
}

.warranty-content-type-col {
    display: inline-flex;
    flex-direction: column;
}

.claim-item.claim-item--warranty {
    .cards .cards_item {
        flex: 300px 0 1;
    }

    .lookup-autocomplete-container li {
        list-style: none;
        padding: 2px;
        margin: 1px;
        border: 1px solid;
        cursor: pointer;
    }
}

.col--failed-part,
.col--replacement-part {
    vertical-align: top !important;
}

// ----------------------------------------------
// Desktop View

@media (min-width: $bp-table-linearize) {

    .warranty__claim-history .col--explanation {
        width: 30%;
    }

}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-table-linearize - 1px) {

    .warranty-product-attributes {
        tr {
            display: table-row;
        }

        th {
            display: table-cell;
            white-space: normal;
        }

        td {
            display: table-cell;
            white-space: nowrap;
        }
    }

}
