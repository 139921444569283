/**
* East Coast Metal Distributors
*/

// ==============================================
// Payment Methods
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .payment-methods {
        margin: 0 -15px;
    }

}

.payment-method .table-caption,  {
    display: none;
}

.payment-method-content fieldset.ccard {
    max-width: 500px;
}

.payment-method-content p {
    margin-bottom: 0;
}

.checkout-payment-method hr, .checkout-payment-method .step-title {
    display: none;
}


// ==============================================
// Payment Method
// ==============================================

//.payment-method {}


// ==============================================
// Payment Method Title
// ==============================================

.payment-method-title {
    border: 1px solid $c-border;
    padding: $space-xs $space-base;
    margin-bottom: $space-base;
    background: $c-background;

    .label {
        font-weight: normal;
        font-size: 14px;
        color: $c-darkgray;
        letter-spacing: initial;
    }
}

.payment-method-title .payment-icon {
    display: inline-block;
    margin-right: $space-xs;
    vertical-align: middle;
}

.payment-method-title .action-help {
    display: inline-block;
    margin-left: $space-xs;
}


// ==============================================
// Payment Method Content
// ==============================================

.payment-method-content {
    display: none;
    position: relative;
    width: 100%;
    padding: 16px;
    border: 1px solid $c-border;
    margin-bottom: $space-xxl;

    &::before {
        @include triangle($direction: up, $size: 12px, $color: $c-border);
        left: 14px;
        top: -12px;
    }

    &::after {
        @include triangle($direction: up, $size: 10px, $color: $c-white);
        left: 16px;
        top: -10px;
    }
}

._active .payment-method-content {
    display: inline-block;
}

.payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 $space-l;
}


// ==============================================
// Payment Method - Summary
// ==============================================

.payment-card {
    display: inline-flex;
}

.payment-card_info {
    padding-top: 6px;
}


// ==============================================
// AuthorizeNet CIM
// ==============================================

.payment-method-authnetcim__address {
    .action-edit-address {
        display: none;
    }
}

.payment-method-authnetcim {
    .billing-address-form .choice.field {
        display: none;
    }
}
