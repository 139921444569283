/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Border
// ==============================================

.b-base { border: 1px solid $c-border !important; }

.b-t-base { border-top: 1px solid $gray-350 !important; }
.b-b-base { border-bottom: 1px solid $c-border !important; }
.b-r-base { border-right: 1px solid $c-border !important; }
.b-l-base { border-left: 1px solid $c-border !important; }

.b-t-gray-light { border-top: 1px solid $c-gray-light !important; }
.b-b-gray-light { border-bottom: 1px solid $c-gray-light !important; }
.b-r-gray-light { border-right: 1px solid $c-gray-light !important; }
.b-l-gray-light { border-left: 1px solid $c-gray-light !important; }

.b-0 { border: 0 !important; }

.b-t-0 { border-top:    0 !important; }
.b-r-0 { border-right:  0 !important; }
.b-b-0 { border-bottom: 0 !important; }
.b-l-0 { border-left:   0 !important; }
