/**
* East Coast Metal Distributors
*/

@mixin dropdown-button() {
    display: flex;
    position: relative;

    > .actions-group {
        position: relative;

        &.active {
            .dropdown-options {
                display: block;
            }

            .dropdown-trigger {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .dropdown-trigger {
        height: 100%;
        margin-left: -1px;
        min-width: 35px;
        padding: 0;

        &[aria-expanded=true] {
            background: $blue-1000;
            color: $c-white;
        }

        &[aria-expanded=false] {
            &:active,
            &:focus {
                background: $c-white;
                color: $blue-1000;

                &:hover {
                    background: $blue-1000;
                    color: $c-white;
                }
            }
        }

        &.button--secondary:not(:hover) {
            background: $c-white;
        }
    }

    .dropdown-options {
        background: $c-white;
        border: 1px solid $c-gray;
        display: none;
        height: auto;
        opacity: 1;
        padding: 10px;
        right: 0;
        position: absolute;
        z-index: 400;

        &[aria-hidden=false] {
            display: block;
        }
    }

    .dropdown-options > li {
        position: relative;
        line-height: 35px;
        padding: 0;

        a {
            margin: $space-base * 0.5 0;
        }
    }

    .dropdown-options > li a {
        margin: 0;
        padding-left: 3px;
    }

    @media (max-width: $bp-screen-m - 1px) {
        .dropdown-trigger {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 22px,
                $_icon-font-position: after,
                $_icon-font-display: block,
                $_icon-font-color-hover: initial
            );
        }

        .dropdown-options {
            z-index: 400;
            width: calc(100vw - 2 * #{$space-base});
        }
    }
}
