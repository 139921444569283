/**
* East Coast Metal Distributors
*/


// ==============================================
// Vars
// ==============================================

$bp-columns: $bp-screen-m;


// ==============================================
// Columns
// ==============================================

.columns {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

// ----------------------------------------------

@media (min-width: $bp-columns) {

    .columns {
        flex-direction: row-reverse;
    }

}


// ==============================================
// Columns - Column.Main
// ==============================================

//.columns .column.main {}

// ----------------------------------------------

@media (min-width: $bp-columns) {

    .columns .column.main {
        flex: 1;
        padding-bottom: 0;
        min-width: 0;
        min-height: 0;
    }

}


// ==============================================
// Columns - Sidebar Additional
// ==============================================

// [todo] remove this eventually. Not being used, but may have blocks inserted.
.columns .sidebar-additional {
    display: none;
}
