/**
* East Coast Metal Distributors
*/

// ==============================================
// Account Dropdown - Wrap
// ==============================================

.account-link {
    color: $c-white;
}

.account-link .dropdown-trigger {
    @include lib-icon-font(
        $_icon-font-content: $icon-person,
        $_icon-font-size: 36px,
        $_icon-font-line-height: 30px,
        $_icon-font-text-hide: false,
        $_icon-font-position: before,
        $_icon-font-display: inline-block,
        $_icon-font-color: $c-white
    );
}

@media (max-width: $bp-screen-m - 1) {
    .account-link {
        padding-right: 10px;
    }

    .account-link span {
        font-size: $font-size-xxs;
    }

    .account-link .dropdown-trigger > p,
    .account-link .dropdown-trigger:after {
        display: none;
    }

    .account-link .dropdown-trigger {
        display: flex;
        flex-direction: column;
    }

}

@media (min-width: $bp-screen-m) {
    .account-link {
        flex-grow: 1;
        align-items: flex-end;
        order: 6;
        margin-top: 13px;
        margin-left: $space-l;
        padding-left: 40px;
    }

    .account-link .dropdown-trigger:before {
        position: absolute;
        left: 0;
    }

    .account-link:not(.is-signed-in) {
        display: none;
    }
}

.account-link a {
    color: $c-white;
    text-decoration: none;
}

.account-link p {
    color: $c-contrast;
    margin: 0;
    font-size: $font-size-xs;
    line-height: $font-size-xs;
}

// ----------------------------------------------

.account-dropdown-wrap > div.signed-in {
    padding-top: 3px;

    .account-no {
        font-size: 12px;
        line-height: 25px;
    }
}

// ==============================================
// Account Dropdown
// ==============================================

.authorization-link {;
    border-top: 1px solid $c-gray-lighter;
}

.account-dropdown .authorization-link {;
    padding: $space-xs $space-base;
}

.account-dropdown .authorization-link a {
    color: $c-text;
}

.account-dropdown .authorization-link a:hover {
    text-decoration: underline;
}

.account-dropdown-erp {
    width: 402px;
}

.account-dropdown_links a {
    display: block;
    color: $c-text;
    padding: $space-xs $space-base;
}

.account-dropdown_links a:hover {
    color: $c-text;
    text-decoration: underline;
}

.account-dropdown_company {
    border-left: 1px solid $c-border;
}

.account-dropdown_myaccount {
    width: 100%;
}

// ==============================================
// Account Dropdown Links
// ==============================================


@media (min-width: $bp-screen-m) {
    .account-dropdown.dropdown-content {
        min-height: auto !important;
    }

    #account-dropdown-list {
        max-height: 80vh !important;
        min-height: auto !important;
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
    }

    .account-dropdown-fade {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 60px;
        transition: box-shadow 0.3s ease-in-out;
        pointer-events: none;

        &.fade {
            box-shadow: inset 0px -40px 30px -10px $c-white;
        }
    }

    .account-dropdown-erp .account-dropdown_links {
        width: 200px;
    }
}

// ==============================================
// Company Controls
// ==============================================

.account-dropdown .company-control {
    padding-left: $space-base;
    padding-right: $space-base;
    padding-bottom: $space-base;
    border-bottom: 1px solid $c-gray-lighter;

    .block {
        flex-direction: column;
    }

    .job-selector {
        margin-left: 0;
    }
}

@media (max-width: $bp-screen-m) {
    .account-dropdown-wrap > div.signed-in .account-no {
        display: none;
    }
}
