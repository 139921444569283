/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/

// ==============================================
// Grid
// ==============================================

// .grid {
//     margin-right: ($gutter * -1);
//     margin-left: ($gutter * -1);

//     &::after {
//         @include clearfix;
//     }
// }

@use "sass:math";

[class^="grid_"] {
    float: left;
    width: 100%;
    padding-right: $gutter;
    padding-left: $gutter;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .grid_1 {
        width: percentage(math.div(1, 12));
    }
    .grid_2 {
        width: percentage(math.div(2, 12));
    }
    .grid_3 {
        width: percentage(math.div(3, 12));
    }
    .grid_4 {
        width: percentage(math.div(4, 12));
    }
    .grid_5 {
        width: percentage(math.div(5, 12));
    }
    .grid_6 {
        width: percentage(math.div(6, 12));
    }
    .grid_7 {
        width: percentage(math.div(7, 12));
    }
    .grid_8 {
        width: percentage(math.div(8, 12));
    }
    .grid_9 {
        width: percentage(math.div(9, 12));
    }
    .grid_10 {
        width: percentage(math.div(10, 12));
    }
    .grid_11 {
        width: percentage(math.div(11, 12));
    }
}
