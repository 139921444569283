/**
* East Coast Metal Distributors
*/

//
//  General
//  _____________________________________________
.clearfix {
    clear: both;
    width: 0;
    padding: 0;
    margin: 0;
}

.compare-fixed-container {
    position: fixed;
    background-color: $c-white;
    width: 100%;
    z-index: 106;
    padding: 5px;

    .fixed-add-to-cart {
        width: 79%;
        float: right;
    }

    .compare-item {
        float: left;
        text-align: center;
        padding: 2px 10px;

        a {
            display: inline-block;
            color: $gray-850;
            height: 60px;
        }

    }
}

.compare-fixed-container.compare-page {
    .compare-item .add-product-actions {
        a {
            display: block;
            color: white;
            height: auto;
        }
    }
}

.catalog-product_compare-index {
    background-color: $gray-50;

    .page-title {
        margin-bottom: 30px;
    }

    form.tocart-form {
        display: flex;
    }

    .tocart {
        width: 100%;
        height: 38px;
        margin-left: 10px;
    }
}

.compare-slick {
    background-color: $c-white;
    padding: 5px;
    position: relative;

    .go-back-link {
        position: absolute;
        left: 0;
        top: -25px;
    }

    .marked-not-equal {
        background: $gray-200;
    }

    .slick-track {
        margin-left: 0;
    }

    .compare-container {
        position: relative;
    }

    .attribute.label {
        color: $c-black;
    }

    .tbody {
        width: 80%;
        float: right;
        border: 1px solid $gray-350;

        .products-container {
            width: 90%;
            float: left;
            z-index: 2;
        }

        .right-side-lines {
            width: 80%;
            float: right;
            position: absolute;
            bottom: 1px;
            right: 0;
            z-index: 0;

            .attribute.label {
                height: 36px;
                border: 1px solid #C3C3C3;
                border-bottom: none;
            }
        }

        .product-top-padded .product-info-container {
            .compare-item-name {
                height: 90px;
                color: #3B3B3B;
                line-height: 19px;
                font-size: 16px;

                a {
                    color: $gray-850;
                    font-weight: normal;
                }
            }

            .compare-item-price {
                height: 23px;
            }

            .product-attribute {
                padding-bottom: 10px;
            }

            .product-item-actions {
                text-align: center;
                padding-top: 15px;
            }

            .product-item-actions form {
                display: flex;
            }

            .product-item-actions form button {
                width: 100%;
            }

            .product-inventory {
                font-size: $font-size-s;
                min-height: 92.5px;
            }
        }
    }


    .attributes-name {
        width: 20%;
        float: left;
        position: absolute;
        left: 0;
        bottom: 0;

        .attribute.label {

            padding-right: 20px;
            text-align: right;
            line-height: 35px;
            height: 36px;
            border: 1px solid $gray-350;
            border-bottom: none;
            border-right: none;

            &:last-child {
                border-bottom: 1px solid $gray-350;
                height: 37px;
                line-height: 36px;
            }

            &.specifications {
                color: $c-black;
                font-size: 14px;
            }
        }
    }

    .top-compare {
        display: flex;
        justify-content: space-between;
        padding: 5px 4px;
    }

    .remove.product {
        text-align: right;
        margin-right: -17px;
        margin-top: -10px;
        padding-bottom: 20px;

        span {
            position: relative;

            @include lib-icon-font(
                $_icon-font-content: $icon-close,
                $_icon-font-size: 20px,
                $_icon-font-line-height: 20px,
                $_icon-font-text-hide: false,
                $_icon-font-position: after,
                $_icon-font-display: inline-block,
                $_icon-font-color: $c-black
            );
        }
    }

    .product-item-photo img {
        border: 1px solid $gray-100;
        max-width: 227px;
    }

    .attributes-list {

        .product.attribute {
            border: 1px solid $gray-350;
            border-left: none;
            border-right: none;
            border-bottom: none;
            line-height: 35px;

            .attribute.value {

                text-align: center;

            }
        }
    }

    .slick-list .product-inventory--limit-assistance {
        min-height: 88px;
    }

    .price-box--limit {
        visibility: hidden;
    }
}

//
//  Desktop
//  _____________________________________________

@media (min-width: $bp-screen-m) {
    .compare-fixed-container {
        top: 0;
        left: 0;
        border-bottom: 5px solid $gray-350;
    }

    .compare-slick {

        .product-top-padded {
            padding: 20px 30px;
        }

        .slick-initialized .slick-slide {
            box-sizing: border-box;
        }

        .attributes-list {

            .product.attribute {
                border: 1px solid $gray-350;
                border-left: none;
                border-right: none;
                border-bottom: none;
                line-height: 35px;
                height: 36px;

                .attribute.value {
                    text-align: center;
                }

                &.add-some-overflow {
                    overflow-y: scroll;
                }
            }
        }

        .slick-slider {
            .slick-prev,
            .slick-next {
                top: 8%;
            }

            .slick-prev {
                left: -16px;
            }

            .slick-next {
                right: -16px;
            }
        }
    }
}


//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {

    .compare-slick {
        .product-top-padded {
            padding: 20px 30px;
        }

        .tbody {
            width: 100%;
            float: none;
            border: 1px solid $gray-350;

            .products-container {
                width: 100%;
                float: left;
            }

            .right-side-lines {
                width: 0%;
                float: right;
                display: none;
            }

            .slick-slider {
                .slick-prev,
                .slick-next {
                    position: fixed;
                    top: 40%;
                }

                .slick-next {
                    right: 5px;
                }

                .slick-prev {
                    left: 5px;
                    z-index: 2;
                }
            }

            .attributes-list {

                .product.attribute {
                    border: 1px solid $gray-350;
                    border-left: none;
                    border-right: none;
                    border-bottom: none;
                    line-height: 35px;
                    min-height: 36px;
                    display: flex;

                    div.label {
                        width: 60%;
                        float: left;
                        padding-left: 10px;
                        border-right: 1px solid $gray-350;

                        &.specifications {
                            span {
                                color: $c-black;
                                font-size: 14px;
                            }
                        }
                    }

                    .attribute.value {
                        width: 40%;
                        float: right;
                        text-align: center;

                    }
                }
            }
        }
    }

    .compare-fixed-container {
        position: fixed;
        bottom: 0;
        background-color: $c-white;
        width: 100%;
        z-index: 106;
        padding: 5px;
        border-bottom: 5px solid $gray-350;

        .fixed-add-to-cart {
            width: 100%;
            float: none;
        }

        .compare-item {
            float: left;
            text-align: center;
            width: 100% !important;

            a {
                display: none;
            }
        }
    }

    .flyout-container {
        height: auto;

        .items-container {
            width: auto !important;
        }

        .button-container {
            padding-top: 10px;
            float: none;
            clear: both;

            button.button {
                margin: 10px 10%;
                width: 79%;
            }
        }

        .items-to-compare {
            div {
                margin-left: 15px;
            }
        }
    }
    .visible-mobile {
        display: block;
    }
}

// ==============================================
// Assistance
// ==============================================

.compare-slick .product-inventory--limit-assistance {
    min-height: 88px;
}
