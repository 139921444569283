/**
* East Coast Metal Distributors
*/

// ==============================================
// Content Wrapper
// ==============================================

.content-wrapper {
    background: $c-gray-backgound;
    padding: $space-xxl $layout-indent__width;
    height: 100%;
}

@media (max-width: $bp-screen-m - 1px) {
    .content-wrapper {
        padding: $space-xxl $layout__width-xs-indent;
    }
}

// ==============================================
// Content Wrapper on Account section
// ==============================================


.account .content-wrapper,
.page-main--app .content-wrapper {
    margin-right: -$layout-indent__width;
    margin-left: -$layout-indent__width;
}

@media (max-width: $bp-screen-m - 1px) {
    .account .content-wrapper {
        margin-right: -$layout__width-xs-indent;
        margin-left: -$layout__width-xs-indent;
    }
}

// ==============================================
// Content Wrapper With Sidebar
// ==============================================

.content__columns {
    display: flex;
}

.content__column-main {
    flex: 0 1 960px;
}

.content__column-side {
    display: flex;
    flex-direction: column;
    flex: 0 1 360px;
    min-width: 300px;
    max-width: 420px;
    margin-left: 20px;
}

.content__column-side-wrapper {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 20px;
}

// -----------------------------------

@media (max-width: $bp-screen-l - 1px) {

    .content__columns {
        flex-direction: column-reverse;
    }

    .content__column-main,
    .content__column-side {
        flex: unset;
    }

    .content__column-side {
        margin-left: 0;
        max-width: unset;
    }

    .content__column-side-wrapper--reverse {
        flex-direction: column-reverse;
    }
}
