/**
* East Coast Metal Distributors
*/

// ==============================================
// Edit Account Form
// ==============================================

.form-edit-account .fieldset.password {
    display: none;
}

.customer-address-index address p {
    margin-bottom: 0;
}
