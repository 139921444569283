/**
* East Coast Metal Distributors
*/


// ==============================================
// Var
// ==============================================

$pager-next-color:     #929090;
$pager-previous-color: #929090;


// ==============================================
// Pages
// ==============================================

//.pages {}

.pages > .label {
    @include lib-visually-hidden();
}


// ==============================================
// Pages - Items
// ==============================================

//.pages .items {}


// ==============================================
// Pages - Item
// ==============================================

.pages .item {
    display: inline-block;
    margin: 0 $space-xxxs 0 0;
    font-size: 15px;
    line-height: 38px;
}

@media (max-width: $bp-screen-m - 1px) {
    .pages .item--l {
        display: none;
    }
}

ul.items.pages-items.pages-items--l {
    line-height: 38px;
}

// ==============================================
// Pages - Page
// ==============================================

.pages a.page {
    display: inline-block;
    padding: 0 10px;
    color: $gray-700;
}

.pages strong.page {
    display: inline-block;
    background: $gray-700;
    font-size: 15px;
    letter-spacing: normal;
    line-height: 32px;
    color: #ffffff;
    padding: 0 10px;
}


// ==============================================
// Pages - Item Label
// ==============================================

.pages .item .label {
    @include lib-visually-hidden();
}


// ==============================================
// Pages - Action
// ==============================================

.pages .action {
    margin: 0;
    text-transform: uppercase;

    &.next span:after {
        content: "\00a0>";
    }

    &.previous span:before {
        content: "<\00a0";
    }

    &:visited::after {
        color: $pager-previous-color;
    }

    &:active::after {
        color: $pager-previous-color;
    }
}
