/**
* East Coast Metal Distributors
*/


// ==============================================
// Radio
// ==============================================

[type='radio'] {
    margin-left: $space-xxs;
}


// ==============================================
// Radio + Label
// ==============================================

[type='radio'] + label {
    position: relative;
    margin-left: -18px;
    padding-left: 28px;
    cursor: pointer;
    display: inline-block;
    pointer-events: all;

    &::before,
    &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        transition: all .3s ease;
    }

    &::before {
        top: 1px;
        left: 0;
        width: 18px;
        height: 18px;
        background: $c-white;
        box-shadow: inset 0 0 0 2px $gray-500;
    }

    &::after {
        top: 49%;
        left: 9px;
        width: 45px;
        height: 45px;
        opacity: 0;
        background: $c-lightestblue;
        transform: translate(-50%, -50%) scale(0);
    }
}

[type='radio'].radio--single {
    position: relative;
    margin-right: 8px;

    &::before,
    &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        transition: all .3s ease;
    }

    &::before {
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 18px;
        height: 18px;
        background: $c-white;
        box-shadow: inset 0 0 0 2px $gray-500;
    }

    &::after {
        top: 49%;
        left: 9px;
        width: 45px;
        height: 45px;
        opacity: 0;
        background: $c-lightestblue;
        transform: translate(-50%, -50%) scale(0);
    }

    &:checked:before {
        box-shadow: inset 0 0 0 6px $blue-600;
    }

    &:checked:after {
        animation: choice-ripple 1s none;
        transform: translate(-50%, -50%) scale(1);
    }
}

// ----------------------------------------------
// State: Checked

[type='radio']:checked + label {
    &::before {
        box-shadow: inset 0 0 0 6px $blue-600;
    }

    &::after {
        animation: choice-ripple 1s none;
        transform: translate(-50%, -50%) scale(1);
    }
}

// ----------------------------------------------
// State: Disabled

[type='radio']:disabled + label {
    &::before {
        box-shadow: inset 0 0 0 2px $gray-200;
    }
}

[type='radio']:disabled:checked + label {
    &::before {
        box-shadow: inset 0 0 0 6px $gray-200;
    }
}

.input-radio {
    position: relative;
    border: 0;
    background: 0;
    width: 18px;
    height: 18px;

    > span {
        display: none;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        transition: all .3s ease;
    }

    &::before {
        top: -1px;
        left: 0;
        width: 18px;
        height: 18px;
        background: $c-white;
        box-shadow: inset 0 0 0 2px $gray-500;
    }

    &::after {
        top: 12px;
        left: 9px;
        width: 45px;
        height: 45px;
        opacity: 0;
        background: $c-lightestblue;
        transform: translate(-50%, -50%) scale(0);
    }
}

.input-radio--checked {
    &::before {
        box-shadow: inset 0 0 0 6px $blue-600;
    }

    &::after {
        animation: choice-ripple 1s none;
        transform: translate(-50%, -50%) scale(1);
    }
}
