/*!
* East Coast Metal Distributors
*/

@import 'variables';

// ==============================================
// Algolia PLP
// ==============================================
@import 'component/algolia_base';

// ==============================================
// Core
// ==============================================

// ----------------------------------------------
// Animation

@import 'core/animation/choice-ripple';
@import 'core/animation/fade-in';
@import 'core/animation/gradient-loading';
@import 'core/animation/input-highlight';
@import 'core/animation/rotate-clockwise';
@import 'core/animation/scale-up';
@import 'core/animation/show-message';

// ----------------------------------------------
// Function

@import 'core/function/black';
@import 'core/function/shade';
@import 'core/function/strip-unit';
@import 'core/function/tint';
@import 'core/function/white';

// ----------------------------------------------
// Var

@import 'core/var/box-shadow';
@import 'core/var/breakpoint';
@import 'core/var/color-library'; // Must be before: "core/var/color"
@import 'core/var/color-library-other'; // [todo] temporary
@import 'core/var/color';         // Must be after:  "core/var/color-library"
@import 'core/var/radius';
@import 'core/var/space';
@import 'core/var/transition';
@import 'core/var/typography';
@import 'core/var/z-index';

// Via Magento
@import 'core/variables';

// ----------------------------------------------
// Mixin

@import 'core/mixin/button';
@import 'core/mixin/badge';
@import 'core/mixin/clearfix';
@import 'core/mixin/focus';
@import 'core/mixin/form';
@import 'core/mixin/image-replacement';
//@import 'core/mixin/menu';
//@import 'core/mixin/png';
@import 'core/mixin/step-progress';
@import 'core/mixin/svg';
@import 'core/mixin/triangle';
@import 'core/mixin/typography';

// Via Magento
@import 'core/mixins/accordion';
@import 'core/mixins/actions-toolbar';
@import 'core/mixins/buttons';
@import 'core/mixins/dropdown-button';
@import 'core/mixins/dropdowns';
@import 'core/mixins/icons';
@import 'core/mixins/messages';
@import 'core/mixins/navigation';
@import 'core/mixins/popups';
@import 'core/mixins/rating';
@import 'core/mixins/tabs';
@import 'core/mixins/tooltips';
@import 'core/mixins/utilities';
@import 'core/mixins/scrollbar';
@import 'core/mixins/sticky';

// ----------------------------------------------
// Reset

@import 'core/reset/normalize';
@import 'core/reset/gravdept-reset';
@import 'core/reset/gravdept-shame'; // Browser hacks and workarounds
@import 'core/reset/typography';


// ==============================================
// Vendor
// ==============================================

@import 'vendor/fotorama';
@import 'vendor/gravdept';
@import 'vendor/navbar';
@import 'vendor/slick';

// ==============================================
// Blocks
// ==============================================

@import 'blocks/extends';
@import 'blocks/popups';
@import 'blocks/tooltips';


// ==============================================
// Component
// ==============================================

@import 'component/address';
@import 'component/accordion';
@import 'component/accordion-tab';
@import 'component/account-nav';
@import 'component/account-selector';
@import 'component/action-group';
@import 'component/action-icon';
@import 'component/action-skip';
@import 'component/actions-toolbar';
@import 'component/assistance';
@import 'component/associate';
@import 'component/badge';
@import 'component/block';
@import 'component/block-add-by-sku';
@import 'component/block-banners';
@import 'component/block-cart-failed';
@import 'component/block-cms-hierarchy-link';
@import 'component/block-cms-link';
@import 'component/block-reorder';
@import 'component/block-static-block';
@import 'component/branch-selector';
@import 'component/breadcrumbs';
@import 'component/browser-support';
@import 'component/button';
@import 'component/button-float';
@import 'component/button-group';
@import 'component/button-checkbox';
@import 'component/callout';
@import 'component/card';
@import 'component/card_connect';
@import 'component/categories-list';
@import 'component/categories-wrapper';
@import 'component/cms';
@import 'component/collapsible';
@import 'component/collapsible-table';
@import 'component/columns';
@import 'component/compare-flyout';
@import 'component/completion-percent';
@import 'component/container';
@import 'component/content-wrapper';
@import 'component/control';
@import 'component/counter';
@import 'component/credit-card-types';
@import 'component/current-store';
@import 'component/data-table-definition-list';
@import 'component/display';
@import 'component/dropdown-button';
@import 'component/dropdown-dialog';
@import 'component/dropdown-link';
@import 'component/dropzone';
@import 'component/error';
@import 'component/field';
@import 'component/field-item';
@import 'component/field-row';
@import 'component/fieldset';
@import 'component/fixed-header';
@import 'component/fixed-tabs';
@import 'component/flag';
@import 'component/fluid-list';
@import 'component/form';
@import 'component/global-messages';
@import 'component/grid';
@import 'component/hero';
@import 'component/homepage';
@import 'component/hr';
@import 'component/image-list';
@import 'component/individual-loading';
@import 'component/input-checkbox';
@import 'component/input-hint';
@import 'component/input-quantity';
@import 'component/input-radio';
@import 'component/input-text';
@import 'component/add-product';
@import 'component/chip';
@import 'component/chip-input';
@import 'component/inventory';
@import 'component/inventory-finder';
@import 'component/item-options';
@import 'component/job-selector';
@import 'component/jstree';
@import 'component/lead';
@import 'component/legend';
@import 'component/link-dropdown';
@import 'component/list';
@import 'component/loading';
@import 'component/masthead';
@import 'component/media';
@import 'component/menu';
@import 'component/messages';
@import 'component/modal';
@import 'component/multi-input';
@import 'component/multi-select';
@import 'component/nav';
@import 'component/navigation';
@import 'component/note';
@import 'component/order_template';
@import 'component/order-status';
@import 'component/onboardingstepbystep';
@import 'component/one-trust';
@import 'component/package';
@import 'component/page-title';
@import 'component/pages';
@import 'component/panel';
@import 'component/password-strength-meter';
@import 'component/payment-methods';
@import 'component/price';
@import 'component/pricebook';
@import 'component/price-container';
@import 'component/prices-tier';
@import 'component/product-badges';
@import 'component/product-image-container';
@import 'component/product-image-photo';
@import 'component/product-image-wrapper';
@import 'component/product-item';
@import 'component/product-line';
@import 'component/product-preview';
@import 'component/product-table';
@import 'component/product-select';
@import 'component/punchout';
@import 'component/react-dropdown';
@import 'component/react-dropdown-with-search';
@import 'component/add-products';
@import 'component/products-grid';
@import 'component/products-list';
@import 'component/qty-group';
@import 'component/requisition-list';
@import 'component/resource-link';
@import 'component/section';
@import 'component/select';
@import 'component/simple-loading';
@import 'component/simple-table';
@import 'component/slider';
@import 'component/checkbox-switcher';
@import 'component/toggle-switch';
@import 'component/save-cart';
@import 'component/solid-list';
@import 'component/sortable';
@import 'component/supersede';
@import 'component/std';
@import 'component/steps';
@import 'component/stepper';
@import 'component/stocking-list';
@import 'component/store';
@import 'component/row';
@import 'component/set-location';
@import 'component/store-hour';
@import 'component/store-locator';
@import 'component/tabs';
@import 'component/tab';
@import 'component/table';
@import 'component/table-scroll-wrap';
@import 'component/totals-summary';
@import 'component/textarea';
@import 'component/toolbar';
@import 'component/tooltip';
@import 'component/totals';
@import 'component/ui-datepicker';
@import 'component/widget';
@import 'component/quotes';
@import 'component/warranty';
@import 'component/points';
@import 'component/wrapper';
@import 'component/wrapper-panel';
@import 'component/year-picker';

// ==============================================
// Layout
// ==============================================

@import 'layout/global';

@import 'layout/header';
@import 'layout/header-logo';
@import 'layout/header-top-menu';
@import 'layout/header-proguides';
// @import 'layout/header-skip';
// @import 'layout/header-bar';
// @import 'layout/header-trigger';

@import 'layout/header-account';
@import 'layout/header-cart';
@import 'layout/header-menu-top';
// @import 'layout/header-nav';
// @import 'layout/header-nav-small';
// @import 'layout/header-nav-large';
// @import 'layout/header-search';
// @import 'layout/header-wishlist';

//@import 'layout/matter';

@import 'layout/sidebar';

@import 'layout/footer';


// ==============================================
// Module - Magento Original
// ==============================================

@import '../Magento_RequisitionList/styles/module';
@import '../Magento_SharedCatalog/styles/module';


// ==============================================
// Module - Refactored
// ==============================================

@import 'module/amasty_rules';
@import 'module/ecmd_ahri';
@import 'module/ecmd_account-profile';
@import 'module/ecmd_branch';
@import 'module/ecmd_quickorder';
@import 'module/ecmd_quote';
@import 'module/ecmd_simple-stock';
@import 'module/ecmd_order-approval';
@import 'module/ecmd_order-history';
@import 'module/ecmd_order_templates';
@import 'module/ecmd_brands';
@import 'module/ecmd_parts';
@import 'module/ecmd_related-products';
@import 'module/ecmd_show-password';
@import 'module/ecmd_checkout-preferences';
@import 'module/magento_bundle';
@import 'module/magento_cart';
@import 'module/magento_catalog-compare';
@import 'module/magento_catalog-list';
@import 'module/magento_catalog-product';
@import 'module/magento_catalog-search';
@import 'module/magento_catalog-widgets';
@import 'module/magento_checkout';
@import 'module/magento_checkout-agreements';
@import 'module/magento_checkout-authentication';
@import 'module/magento_checkout-fields';
@import 'module/magento_checkout-login';
@import 'module/magento_checkout-modals';
@import 'module/magento_checkout-shipping';
@import 'module/magento_checkout-shipping-policy';
@import 'module/magento_checkout-step-summary';
@import 'module/magento_checkout-success';
@import 'module/magento_checkout-summary';
@import 'module/magento_checkout-tooltip';
@import 'module/magento_company';
@import 'module/magento_company-credit';
@import 'module/magento_contact';
@import 'module/magento_customer';
@import 'module/magento_customer-account';
@import 'module/magento_customer-auth';
@import 'module/magento_customer-dashboard';
@import 'module/magento_customer-dashboard';
@import 'module/_ecmd_instant_purchase';
@import 'module/magento_layered-navigation';
@import 'module/magento_multiple-wishlist';
@import 'module/magento_newsletter';
@import 'module/_magento_pagebuilder/module';
@import 'module/magento_requisition-list';
@import 'module/magento_sales';
@import 'module/magento_sales-guest';
@import 'module/magento_vault';
@import 'module/magento_wishlist';
@import 'module/msp_recaptcha';
@import 'module/print';
@import 'module/page-print';
@import 'module/sign-in';


// ==============================================
// Module Unused - Not Refactored
// ==============================================

/*
@import 'module/magento_braintree';
@import 'module/magento_catalog-event';
@import 'module/magento_catalog-event-widgets';
@import 'module/magento_catalog-search-advanced';
@import 'module/magento_downloadable';
@import 'module/magento_gift-card';
@import 'module/magento_gift-card-account';
@import 'module/magento_gift-message';
@import 'module/magento_gift-registry';
@import 'module/magento_gift-wrapping';
@import 'module/magento_grouped-product';
@import 'module/magento_invitation';
@import 'module/magento_msrp';
@import 'module/magento_multishipping';
@import 'module/magento_negotiable-quote';
@import 'module/magento_paypal-billing';
@import 'module/magento_paypal-button';
@import 'module/magento_paypal-review';
@import 'module/magento_product-video';
@import 'module/magento_review';
@import 'module/magento_reward';
@import 'module/magento_rma';
@import 'module/magento_send-friend';
@import 'module/magento_swatches';
@import 'module/magento_weee';
*/


// ==============================================
// Utility
// ==============================================

@import 'utility/align';
@import 'utility/background';
@import 'utility/border';
@import 'utility/box-shadow';
@import 'utility/display';
@import 'utility/flex';
@import 'utility/float';
@import 'utility/margin';
@import 'utility/padding';
@import 'utility/position';
@import 'utility/radius';
@import 'utility/table';
@import 'utility/text';
@import 'utility/visibility';
@import 'utility/width';

// ==============================================
// Page builder
// ==============================================

@import 'component/page_builder';

// ==============================================
// Algolia PLP
// ==============================================
@import 'component/algolia_plp';
