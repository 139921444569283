/**
* East Coast Metal Distributors
*/

// ==============================================
// Quickorder
// ==============================================

.tabs_item.panel.quick-search {
    form span {
        position: absolute;
        background: none;
        right: 0;
    }

    button{
        margin: 1px;
    }
}


.page-layout-1column .content-wrapper.quickorder-wrapper {
    padding: $space-l;
    margin: 0;
}

.quickorder-wrapper {
    display: flex;
    flex-wrap: nowrap;
}

.quickorder-wrapper td a {
    &:hover {
        color: $c-darkgray;
    }
}

.quickorder-wrapper .form .control {
    position: relative;

    @include lib-icon-font(
        $_icon-font-content: $icon-search,
        $_icon-font-size: 24px,
        $_icon-font-line-height: 34px,
        $_icon-font-text-hide: false,
        $_icon-font-position: before,
        $_icon-font-display: block,
        $_icon-font-color: $c-gray-light
    );

    &::before {
        position: absolute;
        right: 25px;
        top: 2px;
        z-index: 1;
    }
}

.quickorder-result {
    @extend .large-wrapper;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .action-group {
        clear: left;
    }
}

.quickorder-result .action-group.m-b-base {
    margin-top: 1px;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m) {
    .quickorder-wrapper {
        flex-wrap: wrap;

        .menu.active .menu_content {
            padding: 5px;
            min-width: 225px;
        }

        .panel_body {
            padding: 0 !important;
        }

        .panel {
            margin-bottom: 15px;
            padding: 10px;
        }

        .tabs_nav {
            max-width: 100vw;
            overflow-x: scroll;
        }

        .tabs_nav-link {
            padding: 12px 8px;
        }
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-xs) {
    .quickorder-search {
        .tabs_title {
            padding: 16px 12px 12px 12px;
        }
    }
}


// ==============================================
// Quickorder Search
// ==============================================

.quickorder-search {
    flex-basis: 400px;
    flex-shrink: 0;

    .form {
        margin-bottom: $space-base;
    }

    .label {
        white-space: pre-line;
    }

    .table.quickorder-table {
        background: $c-gray-backgound;
        border: 1px solid $c-table-border;

        &.table--linearize tr {
            border-bottom: 1px solid $c-table-border;
            border-top: none;

            &:last-child {
                border-bottom: none;
            }
        }

        &.table--linearize td {
            border: none;
        }
    }

    .quickorder-table-wrapper {
        position: relative;

        &:before {
            border: 8px solid;
            top: -15px;
            border-color: transparent transparent $c-table-border transparent;
            content: '';
            display: block;
            height: 0;
            left: 20px;
            position: absolute;
            width: 0;
            z-index: 1;
        }

        &:after {
            border: 7px solid;
            top: -13px;
            border-color: transparent transparent $c-gray-backgound transparent;
            content: '';
            display: block;
            height: 0;
            left: 21px;
            position: absolute;
            width: 0;
            z-index: 2;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .quickorder-search {
        .table.quickorder-table.table--linearize tr {
            padding: $space-s;

            &:first-child {
                border-top: none;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .quickorder-search {
        margin-right: $space-base;

        &.order-template {
            flex-basis: 510px;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .quickorder-search {
        flex-basis: 100%;

        .tabs_title.active,
        .tabs_title.active:hover {
            &:after {
                height: 3px;
            }
        }
    }
}


// ==============================================
// Quickorder Result
// ==============================================

.quickorder-result {
    flex: 1 1 100%;

    .quickorder-table {
        float: left;
    }
}

.quickorder-table .col-qty {
    .package-select {
        width: 100%;
    }

    .qty, .package-select {
        float: right;
    }
}


// ==============================================
// Quickorder Table
// ==============================================

.quickorder-table .col-image {
    width: 82px;
}

.quickorder-item__title {
    color: $gray-850;
}

@media (max-width: $bp-table-linearize - 1px) {

    .quickorder-wrapper .panel_body .action-group button {
        width: 100%;
    }

    .quickorder-wrapper .menu.active .menu_content {
        padding: 5px;
        min-width: 225px;
        right: auto !important;
    }

    .quickorder-table .col-image {
        position: absolute;

        img {
            object-fit: contain;
            max-height: 65px;
        }

    }

    .quickorder-table .col-product,
    .quickorder-table .col-matches {
        margin-left: 60px;
        padding-bottom: 15px;
    }

    .quickorder-table td button {
        width: 100%;
        margin-top: 15px;
    }

    .quickorder-table .col-action {
        float: right;
        padding-top: $space-xs;
    }

    .quickorder-table .col-qty {
        display: inline-flex;
        max-width: calc(100% - 50px);

        .qty, .package-select {
            float: none;
        }
    }

    .col-qty__label {
        white-space: nowrap;
    }
}


// ==============================================
// Quickorder Options selection
// ==============================================


@media (max-width: $bp-table-linearize - 1px) {

    .quickorder-selection_remove {
        float: right;
        margin-top: -34px;
    }

    .quickorder-selection tbody tr {
        position: relative;
    }

    .quickorder-selection .col-image {
        position: absolute;
    }

    .quickorder-selection .col-product {
        margin-left: 60px;
    }

    .quickorder-selection .col-action {
        margin-top: $space-base;
    }
}

// ==============================================
// Quickorder Warning
// ==============================================

.quickorder-warning_icon {
    font-size: 30px !important;
    line-height: inherit !important;
}

.quickorder-warning__group:not(:first-of-type) {

    &:not(:first-of-type) {
        border-top: 1px solid $c-gray-lighter;
        padding-top: $space-l;
    }

}

.quickorder-warning__group {

    tr:last-of-type {
        border-bottom: none;
    }

    .quickorder-selection__row-term {
        width: 60%;
    }

    td:last-of-type {
        width: 66px;
    }

}

// ----------------------------------------------
// Mobile View

@media (max-width: $bp-table-linearize - 1px) {

    .quickorder-warning__group td:last-of-type {
        display: inline;
    }

    .quickorder-warning__group--non-salable .col-product {
        width: calc(100% - 126px);
    }

}
