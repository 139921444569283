/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$image-list-item-max-width: 250px !default;


// ==============================================
// Image List
// ==============================================

.image-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: ($gutter * -1);
    margin-bottom: $gap;
    margin-left: ($gutter * -1);
}

.image-list--fluid {
    margin: 0;
    padding: 0 !important;
}

.image-list--grid {
    @media (max-width: $bp-screen-m - 1) {
        border-bottom: 1px solid $c-border !important;
    }
}

// ==============================================
// Image List - Item
// ==============================================

.image-list_item {
    position: relative;
    flex: 1 0 170px;
    max-width: $image-list-item-max-width;
    margin-top:  $gutter;
    margin-left: $gutter;
}

.image-list--fluid .image-list_item {
    max-width: 100%;
    padding: $space-l;
    margin: 0;
    flex: 1 0 50%;

    & > * {
        max-width: 170px;
        margin: 0 auto;
    }

    & > .image-list_name {
        margin-top: 0.75em;
    }

    @media (min-width: $bp-table-linearize) {
        flex: 1 0 25%;
    }

    @media (min-width: $bp-screen-m) {
        margin-left: -($gap - $space-l);
        margin-right: -($gap - $space-l);
        flex: 1 0 16.66%;
        padding: $space-l $gap;
    }
}

.image-list--fluid-8 .image-list_item {
    @media (min-width: $bp-screen-s) {
        flex: 1 0 25%;
    }

    @media (min-width: $bp-screen-m) {
        flex: 1 0 12.5%;
    }
}

.image-list--grid .image-list_item {
    @media (max-width: $bp-screen-s - 1) {
        &:nth-child(2n + 1) {
            border-left: 0 !important;
        }
    }

    @media (min-width: $bp-screen-s) {
        &:nth-child(3n + 1) {
            border-left: 0 !important;
        }
    }

    @media (min-width: $bp-screen-m) {
        border: 0 !important;
    }
}

// ----------------------------------------------

@media (max-width: 420px) {

    // Force 2-columns on small screens.
    .image-list_item {
        flex: 1 0 120px;
    }

}


// ==============================================
// Image List - Name
// ==============================================

.image-list_name {
    display: block;
    margin-top: 0.75em;
    font-size: $font-size-s;
    line-height: $line-height-xs;
    text-align: center;
}


// ==============================================
// Modifier: Solid
// ==============================================

.image-list--solid {
    justify-content: center;
}

.image-list--solid .image-list_item {
    flex: 0 0 170px; // Don't grow to fill area
}

.image-list--solid .flex-ghost {
    display: none; // Must be removed for centering to work
}

// ----------------------------------------------

@media (max-width: 400px) {

    // Force 2-columns on small screens.
    .image-list--solid .image-list_item {
        flex: 1 0 120px; // Grow to fill
    }

}
