/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// **********************************************
// Shame:
// Workarounds for specific browsers / devices.
// **********************************************


// ==============================================
// Enable click events to bubble to the "document" in iOS Safari.
// See: /js/src/browser-detection.js
// ==============================================

.is-ios * {
    cursor: pointer;
}


// ==============================================
// Force "media" pattern not to scale down inside tables.
// Browser: IE11
// ==============================================

table .media {
    min-width: 270px;
}


// ==============================================
// Force IE9 to properly render SVG when rotated.
// ==============================================

svg:not(:root) {
    overflow: hidden;
}
