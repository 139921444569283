/**
* East Coast Metal Distributors
*/


// ==============================================
// Input Hint
// ==============================================

.input-hint {
    @include input-hint();
}

.input-hint--count {
    min-height: auto;
    line-height: $line-height-base;
}
