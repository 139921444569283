/**
* East Coast Metal Distributors
*/

// ==============================================
// Show Password Link
// ==============================================
.show-password-link {
    margin-top: -29px;
    z-index: 2;
    text-align: right;
    margin-right: 10px;
    align-self: flex-end;

    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}
