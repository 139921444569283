/**
* East Coast Metal Distributors
*/


// ==============================================
// Block CMS Link
// ==============================================

.block-cms-link.widget {
    margin-bottom: $space-l;
}

.links .block-cms-link.widget {
    margin-bottom: 0;
}


// ==============================================
// Block CMS Link Inline
// ==============================================

.block-cms-link-inline.widget {
    margin: 0;
}
