/**
* East Coast Metal Distributors
*/

// ==============================================
// User settings table
// ==============================================

.approval-request.request {
    margin-bottom: 0;
}

.approval-request-note {
    margin-bottom: $space-base;

    .field {
        margin-bottom: 0;
    }
}

.notes-outer__container {
    position: relative;

    .white__container {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 60px;
        -webkit-box-shadow: inset 0px -50px 13px -13px $c-white;
        -moz-box-shadow: inset 0px -50px 13px -13px $c-white;
        box-shadow: inset 0px -50px 13px -13px $c-white;
    }
}

.notes__container {
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid $c-white;
        background-color: rgba(0, 0, 0, .5);
    }

    &::-webkit-scrollbar-track {
        background-color: $c-white;
        border-radius: 8px;
    }
}

.add-comment__form.form {
    margin-bottom: 0;
}

.orderapprovals-management-view .page-title-wrapper .page-title {
    margin-bottom: $space-xs;
}

.orderapprovals-management-view {
    .change-password {
        margin-top: $space-base;
        padding-left: 3px;
    }

    .panel_header {
        h2 {
            margin-bottom: $space-xs;
        }
    }
}

.user-settings > tbody > tr.active {
    background: $c-background;
}

.user-settings > tbody > tr > th {
    width: 20%;
    color: $c-text;
}

.user-settings > tbody > tr > td.content {
    width: 65%;
}

.user-settings > tbody > tr > td.action {
    width: 15%;
}

.user-settings > tbody > tr > td .summary {
    color: $c-text-muted;
}

.user-settings > tbody > tr:hover > th,
.user-settings > tbody > tr:hover > td {
    background: $c-background;
}

.user-settings [type='checkbox'] + label {
    margin-left: -18px;
}

.user-settings tr:hover table th,
.user-settings tr:hover table td {
    background: $c-white;
}

.select-all-accounts {
    display: block;
    margin-left: -2px;
    margin-bottom: $space-xs;
    text-decoration: underline;
}

@media (max-width: $bp-table-linearize - 1px) {

    .user-settings > tbody > tr > th {
        padding: 0;
    }

    .user-settings > tbody > tr > th,
    .user-settings > tbody > tr > td.content,
    .user-settings > tbody > tr > td.action {
        width: 100%;
    }

    .user-settings > tbody > tr > td {
        padding: $space-base 0 0 0 !important;
    }

    .user-settings > tbody > tr > td,
    .user-settings > tbody > tr > th {
        border: none;
    }
}


// ==============================================
// User settings - Actions
// ==============================================

@media (max-width: $bp-table-linearize - 1px) {
    .user-settings .reset {
        display: block;
        width: 38px;
        white-space: nowrap;
        overflow: hidden;
    }
}


// ==============================================
// User settings - Approval section
// ==============================================

.order-approvals-toolbar {
    overflow: hidden;
    margin-bottom: 0;

    .pages {
        padding-bottom: $space-base;
    }
}

.order-approval_limit,
.order-approval_condition {
    margin-top: -8px;
}

.order-approval_limit {
    @include lib-icon-font(
                    $icon-dollar,
            $_icon-font-size: 16px,
            $_icon-font-color: $c-icon-base);
    &:before {
        position: absolute;
        margin-top: 8px;
    }
    input {
        padding-left: 18px;
    }
}

.approval-request__header .action-group {
    justify-content: flex-end;
}

#approval-request-form-minicart {
    margin-bottom: 0 !important;
}

// ----------------------------------------------
// Mobile

@media (max-width: $bp-table-linearize - 1px) {

    .approval-request__header {
        .panel_header {
            flex-direction: column-reverse;
        }

        .dropdown-button {
            width: 100%;
        }

        .dropdown-button__primary {
            width: 100%;
        }

        .panel_action {
            margin-bottom: $space-xxl;
        }
    }

}

// ==============================================
// Order Approvals - list
// ==============================================

.order-approvals {
    margin-bottom: 0;
}

.order-approvals .badge,
.approval-request__header .badge {
    background: none;
    color: $c-darkgray;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    font-size: 14px;
    padding: 0;
    font-weight: 400;
    display: inline;
}


// ----------------------------------------------
// Desktop

@media (min-width: $bp-table-linearize) {

    .order-approvals-toolbar {
        flex-direction: row;
        justify-content: flex-end;

        & > * {
            margin-bottom: 0;
        }
    }

    .orderapprovals-management-view {
        .field-row .field {
            .input-text,
            select {
                max-width: 250px;
            }
        }
    }
}

// ----------------------------------------------
// Mobile

@media (max-width: $bp-table-linearize - 1px) {

    #order-approvals-form {
        .action-group {
            margin: 0;
            width: 100%;

            button {
                margin: 10px 0 0;
                width: 100%;
            }

            button:first-child {
                margin-top: 0;
            }
        }
    }

    .order-approvals {
        tr {
            overflow: hidden;
        }

        td {
            clear: both;
        }

        tbody tr:not(.loader):first-child {
            border-top: 1px solid $c-table-border;
        }
    }

    .order-approvals--edit {
        .col {
            padding: 2px 0 2px 28px;
        }
    }

    .order-approvals tbody tr {
        cursor: pointer;
    }

    .order-approvals .col.selector {
        float: left;
        padding-left: 0;
        width: 100%;

        .symbol {
            display: none;
        }
    }

    .order-approvals .col.action {
        display: none;
    }
}


// ==============================================
// Order Approvals - view
// ==============================================

.approval-request_items {
    .col:not(.name) {
        font-size: $font-size-s;
    }

    .col.image {
        width: 94px;
    }
}

// ----------------------------------------------
// Desktop

@media (min-width: $bp-table-linearize) {

    .approval-request_items {
        .col.number,
        .col.mfg {
            display: none;
        }

        .col.price,
        .col.subtotal,
        .col.qty {
            text-align: right;
        }
    }

}

// ----------------------------------------------
// Mobile

@media (max-width: $bp-table-linearize - 1px) {

    .approval-request_items {
        tr {
            display: flex;
            flex-direction: column;

            &:first-child {
                border-top: none;
            }
        }

        .product-attribute {
            display: none;
        }

        .col.image {
            float: left;
            margin: 0 $space-s $space-s;
        }

        .col.name {
            padding-bottom: $space-xs;
        }
    }

}

