// ==============================================
// React Tabs
// @see EMC_React/js/components/Tabs
// ==============================================

@mixin accordion {
    @include lib-data-accordion(
        $_title-selector: '.tab__link',
        $_switch-selector: 'a',
        $_content-selector: '.tab__content',
        $_accordion-content-margin: 0,
        $_accordion-control-background-color: $c-background,
        $_accordion-control-background-color-active: $c-background,
        $_accordion-control-background-color-hover: $c-lightgray,
        $_accordion-control-border-top: none,
        $_accordion-control-border-right: 1px solid $c-gray-light,
        $_accordion-control-border-bottom: 1px solid $c-gray-light,
        $_accordion-control-border-left: 1px solid $c-gray-light,
        $_accordion-control-height: 50px,
        $_accordion-control-line-height: 34px,
        $_accordion-control-margin-bottom: 0,
        $_accordion-control-padding-left: 45px,
        $_accordion-control-font-family: inherit,
        $_accordion-control-font-size: inherit,
        $_accordion-control-font-style: inherit,
        $_accordion-control-font-weight: inherit,
        $_accordion-control-color: $c-darkgray
    );

    .tab__link {
        border: none;
        padding: 0;

        &:before {
            position: absolute;
            top: 12px;
            left: 10px;
        }

        &:after {
            display: none !important;
        }

        @include lib-icon-font(
            $_icon-font-content: $icon-add,
            $_icon-font-size: 25px,
            $_icon-font-line-height: 25px,
            $_icon-font-color: $c-darkgray
        );

        &:first-of-type a {
            border-top: 1px solid $c-gray-light;
        }

        &.active {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-minus
            );
        }
    }

    .tab__content {
        border-bottom: 1px solid $c-gray-light;
        border-left: 1px solid $c-gray-light;
        border-right: 1px solid $c-gray-light;
        display: none;
        padding: 0;
    }
}

.tab {
    width: 100%;

    @include lib-data-tabs(
        $_title-selector: '.tab__link',
        $_content-selector: '.tab__content',
        $_tab-content-border: 1px solid $c-gray-light,
        $_tab-content-padding-bottom: 0,
        $_tab-content-padding-left: 0,
        $_tab-content-padding-right: 0,
        $_tab-content-padding-top: 0,
        $_tab-control-background-color: $c-gray,
        $_tab-control-background-color-active: $c-white,
        $_tab-control-border-bottom-color-active: $c-white,
        $_tab-control-border-color: $c-gray-light,
        $_tab-control-border-width: 1px,
        $_tab-control-line-height: 18px,
        $_tab-content-border-top-status: false
    );

    .tab__link {
        border: 1px solid $c-gray-light;
        border-bottom: none;
        text-transform: none;
        min-height: 50px;

        &:not(:first-of-type) {
            border-left: none;
        }
    }
}

.tab__content {
    display: none;

    &.active {
        display: block;
    }
}


// ==============================================
// Modifier: Accordion
// ==============================================

.tab--accordion {
    @include accordion;
}

@media (max-width: $bp-screen-m - 1px) {

    .tab--accordion-mobile {
        @include accordion;
    }

}

