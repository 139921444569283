//
//  Hero
//  _____________________________________________

[data-appearance="full-bleed"] [data-content-type="slide"] {
    max-width: 100%;
}

[data-content-type="slide"] {
    @extend .hero;
    margin-bottom: 0;
}

.pagebuilder-slide-wrapper {
    @extend .hero_image;
}

.pagebuilder-overlay {
    @extend .hero_body;
}

.pagebuilder-collage-content {
    @extend .hero_content;
}


// ---------------------------------------------
// Slider & Banner

.pagebuilder-slide-overlay-wrapper,
.pagebuilder-banner-overlay-wrapper {
    width: 100%;
    height: 100%;
    max-width: $bp-screen-l + 200px;
    padding: 0 100px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .pagebuilder-overlay {
        max-width: 600px;
        height: auto;
        position: static;
    }

    .pagebuilder-collage-content {
        padding: $space-xl;
        margin: 0;
    }
}

@media (max-width: $bp-hero - 1px) {
    .pagebuilder-slide-overlay-wrapper,
    .pagebuilder-banner-overlay-wrapper {
        padding: 0;

        .pagebuilder-overlay {
            max-width: none;
        }
    }

    .pagebuilder-slide-overlay-wrapper {
        .pagebuilder-overlay {
            background-color: $c-gray-backgound !important;
        }

        [data-element='content'] {
            span {
                color: $c-text !important;
            }
        }
    }
}

// ---------------------------------------------
// Banner

.pagebuilder-banner-wrapper {
    position: relative;
    //
    .pagebuilder-overlay {
        padding: 0;

    }
}

// ---------------------------------------------
// Modifiers

[data-appearance="collage-left"] {
    @extend .hero--left;

    .pagebuilder-slide-overlay-wrapper,
    .pagebuilder-banner-overlay-wrapper {
        align-items: flex-start;
    }

    .pagebuilder-collage-content {
        text-align: left;
    }
}

[data-appearance="collage-right"] {
    @extend .hero--right;

    .pagebuilder-slide-overlay-wrapper,
    .pagebuilder-banner-overlay-wrapper {
        align-items: flex-end;
    }

    .pagebuilder-collage-content {
        text-align: right;
    }
}

@media (max-width: $bp-hero - 1px) {
    [data-appearance="collage-left"] .pagebuilder-collage-content,
    [data-appearance="collage-right"] .pagebuilder-collage-content {
        text-align: center;
    }

    .pagebuilder-slide-overlay-wrapper {
        position: static;
        margin: 0;

        .pagebuilder-overlay {
            margin: 0;
            padding: 0;
        }
    }

    .pagebuilder-banner-overlay-wrapper {
        padding: 0 $space-base;
    }
}


// ---------------------------------------------
// Arrows

.arrow-wrapper {
    display: block;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 50%;
    z-index: 10;
    transform: translate(0, -50%);
    margin: auto;
    justify-content: center;
    left: 0;
    right: 0;
    pointer-events: none;
    height: 60px;

    button {
        display: block;
        pointer-events: all;
    }

    @media only screen and (max-width: $bp-hero - 1px) {
        top: 35%;
    }
}
