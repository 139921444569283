/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Radius
// ==============================================

$radius-l:    4px !default;
$radius-base: 0 !default;
$radius-s:    1px !default;
