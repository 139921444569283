/**
* East Coast Metal Distributors
*/


// ==============================================
// Vars
// ==============================================

$header-icons-color:       $c-white;


// ==============================================
// Page Header
// ==============================================

.page-header {
    background-color: $c-header-background;
    position: relative;
    display: none;
    z-index: 12;
}

@media (max-width: $bp-screen-m - 1px) {
    .page-header {
        display: none;
        z-index: 999;
    }
}

.form.minisearch {
    max-width: none;
}

.quote-dropdown-content,
.quicklist-dropdown-content {
    padding: 16px;
}

.quote-dropdown-content:before,
.quicklist-dropdown-content:before {
    @include triangle($direction: up, $size: 8px, $color: $c-border);
    top: -8px;
    right: 22px;
}

.quote-dropdown-content:after,
.quicklist-dropdown-content:after {
    @include triangle($direction: up, $size: 6px, $color: $c-white);
    top: -6px;
    right: 24px;
}

.header-quotes_item,
.header-quicklists_item {
    margin-bottom: 8px;
}

.header-links-quotes.new a span {
    @include badge-new;
}

@media (min-width: $bp-screen-m) {
    .header.sign-in a,
    .header-links-quotes > a,
    .header-links-quicklists > a,
    .header-links > a {
        color: $c-contrast;

        &:hover,
        &:focus,
        &:active {
            color: $c-contrast;
        }
    }

    .header.sign-in {
        display: flex;
        align-items: center;
        order: 6;
        margin-left: $space-l;
    }

    .header.sign-in a {
        text-decoration: underline;
    }

    .header.sign-in > .header-links-signin:after {
        color: $c-contrast;
        content: attr(data-label);
        margin-left: $space-xs - 2;
        margin-right: $space-xs;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .header.sign-in {
        display: none;
    }
}


// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .page-header {
        margin-bottom: 52px; // search bar height
    }

    .customer-navigation .header.links {
        display: none;
    }

    .header-links-quotes .dropdown-trigger:after,
    .header-links-quicklists .dropdown-trigger:after {
        content: '';
    }

    .header-links-quotes.dropdown,
    .header-links-quicklists.dropdown {
        display: block;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .page-header {
        border: 0;
        padding: 20px $space-l 0 $space-l;
    }

    .page-header .switcher {
        display: inline-block;
    }

}


// ==============================================
// Header Content
// ==============================================

.header.content {
    @include lib-clearfix;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .header.content {
        display: flex;
        padding-top: $space-xs;
        padding-bottom: $space-xs;
        padding-right: 10px;
        z-index: $z-index-10;
        flex-grow: 1;
        align-items: center;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .header.content {
        @include lib-clearfix;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

}


// ==============================================
// Header Panel
// ==============================================

.customer-navigation {
    font-family: $font-family-special;
}

@media (min-width: $bp-screen-m) {

    .customer-navigation {
        display: flex;
        flex-wrap: nowrap;
        height: 38px;
    }

    .customer-navigation > .header.links,
    .customer-navigation > .header.sign-in {
        margin: 0;
        padding: 0;
        list-style: none;
        display: inline-flex;
        align-items: center;
    }

    .customer-navigation > .header.links {
        order: 20;
    }

    .customer-navigation > .header.sign-in {
        order: 10;
    }

    .customer-navigation > .header.sign-in > li {
        margin-bottom: 3px;
    }

    .customer-navigation > .header.links > li:not(.header-links-bakerpay) {
        margin-left: 25px;
    }

    .customer-navigation > .header.links > li.welcome,
    .customer-navigation > .header.links > li > a,
    .customer-navigation > .header.sign-in > li a {
        line-height: 1.4;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

}

// ==============================================
// Header Links - Baker Pay
// ==============================================

.header-links-bakerpay {
    margin-left: 25px;
    position: relative;
}

.header-links-bakerpay svg {
    width: 74px;
    margin-bottom: 0px;
}

.header-links-bakerpay_mobile svg {
    height: 20px;
    margin-bottom: -6px;
}

.header-bakerpay_flag {
    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
}

.header-bakerpay-mobile_flag {
    margin-left: 8px;
}


// ==============================================
// Header Links - Quick Lists
// ==============================================

@media (min-width: $bp-screen-m) {

    .header-links_quicklists {
        @include lib-icon-font(
            $_icon-font-content: $icon-quicklists,
            $_icon-font-color: $header-icons-color,
            $_icon-font-size: 20px
        );

        &::before {
            margin-top: -5px;
        }
    }
}


// ==============================================
// Header Links - Quotes
// ==============================================

@media (min-width: $bp-screen-m) {

    .header-links_quotes {
        @include lib-icon-font(
            $_icon-font-content: $icon-quotes,
            $_icon-font-color: $header-icons-color,
            $_icon-font-size: 20px
        );

        &::before {
            margin-top: -5px;
        }
    }
}
