// ==============================================
// Sign In: Variables
// ==============================================


$content-width:  400px;


// ==============================================
// Sign In: Content
// ==============================================


.sign-in__panel {
    .panel_header,
    .panel_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: $space-xxl;
        padding-bottom: $space-xxl;
    }

    .panel_header {
        padding-bottom: 0;
    }
}

.sign-in__error {
    margin-top: $space-base;
    max-width: $content-width;
    width: 100%;
}

.sign-in__step {
    h2 {
        text-align: center;
        margin-bottom: 0;
    }
}

.sign-in__content {
    width: 100%;
    max-width: $content-width;
}

.sign-in__content .password-field {
    position: relative;
}

.sign-in__content .password-field .toggle-password-visibility {
    position: absolute;
    right: -40px;
    top: 7px;

    .eye-eye-slash {
        height: 24px;
        width: 24px;
    }
}

.sign-in__content--center {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .sign-in__error {
        text-align: center;
    }
}

.sign-in__content.fluid-list {
    margin-bottom: 0;
}

.sign-in__description--center {
    text-align: center;
}

// ----------------------------------------------
// Tablet View

@media (min-width: $bp-table-linearize) {

    .sign-in .content-wrapper {
        display: flex;
        justify-content: center;
        height: auto;
    }

    .sign-in__step {
        flex: 0 1 700px;
    }

}


// ==============================================
// Sign In: Account
// ==============================================

.sign-in__account {
    text-align: center;
    line-height: 1rem;
    margin-top: $space-base;
    width: 100%;
}

.sign-in__account-email {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
    display: inline-block;
    width: 100%;
}

.sign-in__account-number {
    display: inline-block;

    &:before {
        content: '(';
    }

    &:after {
        content: ')';
    }
}

// ----------------------------------------------
// Desktop View

@media (min-width: $bp-screen-m) {

    .sign-in__account {
        max-width: $content-width;
        width: 100%;
    }

    .sign-in__account-email {
        display: inline-block;
        max-width: 100%;
    }

}


// ==============================================
// Sign In: Verification
// ==============================================


.sign-in__pin-sent-value {
    font-weight: $font-weight-bold;
}

.sign-in__contact-method-list {
    margin-bottom: $space-xxl;

    p {
        margin: 0;
    }
}

.sign-in__contact-method {
    margin-bottom: $space-base;
}


// ==============================================
// Sign In: Progress
// ==============================================

.sign-in-progress {
    margin-top: $space-base + $space-l;
    @include step-progress();
}


// ----------------------------------------------
// Tablet View

@media (min-width: $bp-table-linearize) {

    .sign-in-progress {
        flex: 0 0 160px;
        margin-left: 40px;
    }

}

// ----------------------------------------------
// Desktop View

@media (min-width: $bp-screen-m) {

    .content-wrapper--progress .sign-in__step {
        margin-left: 200px;
    }

}

// ----------------------------------------------
// Mobile

@media (max-width: $bp-screen-s) {

    .sign-in__content .password-field input {
        width: calc(100% - 55px);
    }

    .sign-in__content .password-field .toggle-password-visibility {
        position: absolute;
        right: 10px;
        top: 7px;

        .eye-eye-slash {
            height: 24px;
            width: 24px;
        }
    }

}
