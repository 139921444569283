// ==============================================
// Add To Cart Button With Qty Input
// ==============================================

.add-product {
    display: flex;
}

.add-product-qty {
    margin-right: 10px;
}

.add-product-qty__input {
    height: auto;
    background: $c-white;
    line-height: $line-height-s;
}

.add-product-actions {
    width: 100%;
}

.add-product-button {
    @extend .button;
    line-height: $line-height-xxl;
    width: 100%;

    &.disabled {
        @include button-style (
            $background         : $gray-100,
            $background--hover  : $gray-100,
            $background--active : $gray-100,
            $color              : $gray-300
        );
        box-shadow: none;
        border-color: $gray-100;
        cursor: default !important;
        box-shadow: none;
    }
}

.add-product-button--adding {
    .add-product-button__label {
        visibility: hidden;
    }
    .add-product-button__adding-label {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
    }
}

.add-product-button--secondary {
    @extend .button--secondary;

    &.disabled {
        border-color: $gray-300;
    }
}

a.add-product-button--secondary {
    padding: 7px 15px;
}
