
.special-fields__container {
    .field {
        margin-bottom: 10px;

        .input-text.mage-error {
            border: 3px solid #CB0015 !important;
        }
    }

    .date-field .input-text:not([value]) ~ label {
        top: -12px;
        left: 0;
        font-size: 13px;
        font-weight: 500;
    }

    p.error-message {
        color: $c-brand;
    }

    label.mage-error {
        display: none !important;
    }

    textarea {
        width: 70%;
        padding: 5px;
        margin-top: 1px;
    }
}
