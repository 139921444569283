/**
* East Coast Metal Distributors
*/

// ==============================================
// checkout preferences
// ==============================================
.checkout_preferences_panels {
    padding: $space-base;
    width: 100%;
    margin-bottom: $space-xxl;
    position: relative;
    border: $space-xxxxs solid $c-gray-light !important;
    background: $c-white;
}

#customerPreferences_app {
    [type='radio'] + label {
        line-height: 24px;
        cursor: pointer;
    }
}

.checkout_preferences_header_text {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold-bold;
    display: block;
    margin-bottom: $space-xs;
}

.checkout_preferences_panels_footer {
    margin-top: $space-xs;
}

.credit_card_message {
    font-size: $font-size-xs;
}

//checkoutpage
.send-order-confirmation {
    & > h3 {
        margin-top: 20px;
    }

    label {
        position: relative;
        max-width: 100% !important;
    }

    .orderconfirmation-checkout {
        cursor: pointer;

        label {
            line-height: $font-size-base;
        }
    }

    .isReceiveOrderConfirmation {
        visibility: hidden;
    }
}
