// ==============================================
// Supersede modal
// ==============================================

.supersede-replace__list-item {
    border: 1px solid $c-gray-backgound;

    &:not(:last-child) {
        margin-bottom: $space-base;
    }
}

.supersede-replace__superseded {
    background: $c-gray-backgound;
    position: relative;

    @include lib-icon-font-symbol(
        $_icon-font-content : $icon-swap,
        $_icon-font-position: after
    );

    &::after {
        @include _lib-icon-font-size(
            $_icon-font-size: 28px,
            $_icon-font-line-height: 26px
        );

        font-family: $icon-font;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        z-index: 10;
    }
}

.supersede-replace__supersedes {
    margin-top: $space-xxxs;
}

.supersede-replace__supersedes--multiple {
    .supersede-replace__supersede {
        cursor: pointer;
        padding-left: $space-xs;

        &:hover {
            background: $c-gray-backgound;;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $c-gray-backgound;
        }
    }
}

.supersede-replace__supersede {
    display: flex;

    .radio--single {
        margin-right: $space-xxs + 8px;
    }
}

// ----------------------------------------------

.supersede-replace__product {
    padding: $space-base;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "name name" "image attributes";
}

.supersede-replace__product-image {
    grid-area: image;
    margin-right: 20px;
    display: flex;
    align-items: center;

    img {
        max-width: none;
    }
}

.supersede-replace__product-name {
    grid-area: name;
    margin-bottom: $space-s;
}

.supersede-replace__product-attributes {
    grid-area: attributes;
}

// ----------------------------------------------
// Mobile View

@media (min-width: $bp-screen-m) {
    .supersede-replace__product {
        grid-template-areas: "image name" "image attributes";
    }

    .supersede-replace__product-name {
        margin-bottom: 0;
    }
}