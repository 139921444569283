//
//  Collage right appearance styles
//  _____________________________________________

[data-appearance='collage-right'] {
    .pagebuilder-slide-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .pagebuilder-overlay {
            display: flex;
            justify-content: flex-end;
        }
    }
}
