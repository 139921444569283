// ==============================================
// Dropdown Link
// ==============================================

.dropdown-link {
    @extend .menu
}

.dropdown-link__trigger {
    @extend .menu_toggle
}

.dropdown-link__content {
    position: absolute;
    top: calc(100% + #{$space-xs});
    z-index: $z-index-2;
    width: max-content;
    min-width: 100%;
    height: auto;
    margin-top: 0;
    border: 1px solid $c-border;
    background: $c-white;
    box-shadow: $box-shadow-base;
    padding: $space-xs;
}

.dropdown-link__item-link {
    padding: $space-xxs 0;
    display: inline-block;
    width: 100%;
}
