/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Transition
// ==============================================

$transition-base: 280ms cubic-bezier(0.4, 0, 0.2, 1) !default;

$transition-all: all $transition-base !default;

$transition-background: background-color 0.2s ease-out;
