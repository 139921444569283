/*
 * @deprecated use ECMD_React/js/components/Form/Toggle with _toggle-switch.scss instead
 */

.checkbox-switcher {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 24px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .switch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $c-gray-lighter;
        transition: .25s;

        &:before {
            position: absolute;
            content: '';
            height: 20px;
            width: 20px;
            left: 2px;
            bottom: 2px;
            background-color: $c-white;
            transition: .25s;
        }
    }

    input:checked + .switch-slider {
        background-color: $c-green-light;
    }

    input:focus + .switch-slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .switch-slider:before {
        transform: translateX(14px);
    }

    .switch-slider.round {
        border-radius: 34px;
    }

    .switch-slider.round:before {
        border-radius: 50%;
    }

}

