/**
* East Coast Metal Distributors
*/


// ==============================================
// Action Icon
// ==============================================

.action-icon {
    display: inline-block;
    color: $c-icon-base;
    line-height: 1;
    transition: color $transition-base;
    cursor: pointer;

    &:hover,
    &:active {
        color: $c-icon-active
    }
}

.action-icon > i {
    padding: 0.8rem;
    margin: -0.8rem 0;
}
