/**
* East Coast Metal Distributors
*/


// ==============================================
// Button
// ==============================================

$loaderImage: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAzMSAzMSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij48cGF0aCBkPSJNMSwxNS4ybDMuMywwYzAsLTYgNC45LC0xMC45IDEwLjksLTEwLjljMy4zLDAgNi40LDEuNSA4LjUsNC4xbDIuNiwtMi4xYy0yLjcsLTMuMyAtNi43LC01LjMgLTExLjEsLTUuM2MtNy45LDAgLTE0LjIsNi4zIC0xNC4yLDE0LjJaIiBzdHlsZT0iZmlsbDojMDA0Yjg3O2ZpbGwtcnVsZTpub256ZXJvOyIvPjxwYXRoIGQ9Ik0yOC4zLDEwLjdjMC4xLDAuMyAtMC4yLDAuNSAtMC41LDAuNGwtNi41LC0xLjRjLTAuMywtMC4xIC0wLjMsLTAuMyAtMC4xLC0wLjRsNi41LC01LjJjMC4yLC0wLjIgMC40LC0wLjEgMC40LDAuMmwwLjIsNi40WiIgc3R5bGU9ImZpbGw6IzAwNGI4NztmaWxsLXJ1bGU6bm9uemVybzsiLz48Zz48cGF0aCBkPSJNMjkuNCwxNS4ybC0zLjMsMGMtMCw2IC00LjksMTAuOSAtMTAuOSwxMC45Yy0zLjMsMCAtNi40LC0xLjUgLTguNSwtNC4xbC0yLjYsMi4xYzIuNywzLjMgNi43LDUuMyAxMS4xLDUuM2M3LjksMCAxNC4yLC02LjMgMTQuMiwtMTQuMloiIHN0eWxlPSJmaWxsOiMwMDRiODc7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PHBhdGggZD0iTTIuMSwxOS43Yy0wLjEsLTAuMyAwLjIsLTAuNSAwLjUsLTAuNGw2LjUsMS40YzAuMywwLjEgMC4zLDAuMyAwLjEsMC40bC02LjUsNS4yYy0wLjIsMC4yIC0wLjQsMC4xIC0wLjQsLTAuMmwtMC4yLC02LjRaIiBzdHlsZT0iZmlsbDojMDA0Yjg3O2ZpbGwtcnVsZTpub256ZXJvOyIvPjwvZz48L3N2Zz4=";

.button {
    @include button;

    line-height: 20px;
}

.button--secondary {
    @include button-base;

    @include button-style (
        $background         : transparent,
        $background--hover  : $c-blue,
        $background--active : $c-blue,
        $color              : $c-blue
    );

    line-height: 18px;
    border: 1px solid $c-blue;
    background: $c-white;

    &:hover, &:active, &:focus {
        color: $c-white;
    }

    &:disabled {
        border-color: $c-gray;
    }
}

a.button,
a.button--secondary {
    padding: 8px 16px;
}

// Disabled

.button:disabled,
.button.disabled {
    @include button-style (
            $background         : $gray-100,
            $background--hover  : $gray-100,
            $background--active : $gray-100,
            $color              : $gray-300
    );
    box-shadow: none;
    border-color: $gray-100;
    cursor: default !important;
}


// Loading

.button.button--loading {
    @include button-style (
        $background         : $gray-100,
        $background--hover  : $gray-100,
        $background--active : $gray-100,
        $color              : $gray-300
    );
    box-shadow: none;
    border-color: $gray-100;
    cursor: default !important;

    &::before {
        background-image: url($loaderImage);
        content: '';
        float: left;
        margin-right: $space-s;
        display: inline;
        height: 18px;
        width: 18px;
        animation: rotate-clockwise 4s linear infinite;
    }
}


// ==============================================
// Flat Button
// ==============================================

.flat-button {
    @include flat-button;
}

.flat-button--secondary {
    @include button-style (
            $background         : transparent,
            $background--hover  : $gray-50,
            $background--active : $gray-100,
            $color              : $c-secondary
    )
}

// Disabled

.flat-button:disabled,
.flat-button.disabled {
    @include button-style (
            $background         : transparent,
            $background--hover  : transparent,
            $background--active : transparent,
            $color              : $gray-300
    );
    box-shadow: none;
}

.flat-button.flat-button--loading {
    @include button-style(
        $background         : transparent,
        $background--hover  : $gray-100,
        $background--active : $gray-100,
        $color              : $gray-300
    );

    &::before {
        background-image: url($loaderImage);
        content: '';
        float: left;
        margin-right: $space-s;
        display: inline;
        height: 20px;
        width: 20px;
        animation: rotate-clockwise 4s linear infinite;
    }
}

// ==============================================
// Context: .form--contrast
// ==============================================
.form--contrast .flat-button {
    @include button-style(
            $background         : transparent,
            $background--hover  : $gray-800,
            $background--active : $gray-700,
            $color              : $blue-400
    );
}


// ==============================================
// Button sizes
// ==============================================


.button--s,
.flat-button--s {
    font-size: 12px;
    line-height: 2;
    padding: $space-xxs $space-xs;
    height: 30px;
}

.button--l {
    font-size: $font-size-l;
    height: 42px;
}

a.button--l {
    padding: 12px 16px;
}

a.button--xl {
    padding: 14px 16px;
}

.button--xl {
    font-size: $font-size-xl;
    height: 46px;
    padding: $space-xs $space-xxl;
}

.catalog-product-view .actions .primary.button--xl {
    font-size: $font-size-xl;
    height: 46px;
    padding: $space-xs $space-xxl;
}

.button--block {
    width: 100%;
}

@media (min-width: $bp-screen-m) {

    .button--block-desktop {
        width: 100%;
    }
}

@media (min-width: $bp-screen-s) and (max-width: $bp-screen-m - 1px) {

    .button--block-tablet {
        width: 100%;
    }
}

@media (max-width: $bp-screen-s - 1px) {

    .button--block-mobile {
        width: 100%;
    }
}

// Min Widths

.button--min-m {
    min-width: 130px;
}


// [todo] Change the templates and remove temporary code
.secondary .action,
.action-secondary {
    @include flat-button;
}
.account .table-wrapper .action
.primary a,
.secondary a {
    @include flat-button;
}

.action.primary:not(.modal-action),
.action-primary {
    @include button;
}
