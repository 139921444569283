/**
* East Coast Metal Distributors
*/


// ==============================================
// Checkout Modal
// ==============================================

.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content {
    @extend .abs-checkout-tooltip-content-position-top;
}


