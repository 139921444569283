/**
* East Coast Metal Distributors
*/


// ==============================================
// Page Title
// ==============================================

.page-title-wrapper {
    padding: $space-xs 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 64px;

    .page-title {
        @include h1();
        line-height: 1.5;
        min-height: 28px;
        margin: 0;
    }
}

.page-main > .page-title-wrapper .page-title + .action {
    margin-top: $space-xxl;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .page-main > .page-title-wrapper .page-title {
        display: inline-block;
    }

    .page-main > .page-title-wrapper .page-title + .action {
        float: right;
        margin-top: $space-l;
    }
}

// ----------------------------------------------
// Back button

.page-title-wrapper .back-button {
    margin-left: -0.8rem;
    padding-top: 2px;
    i {
        font-size: $font-size-xxxll;
    }
}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {

    .page-title-wrapper {
        .back-button {
            i {
                font-size: $font-size-xxxl;
            }
        }
        .page-title {
            @include h1();
            font-size: $font-size-xxxl;
            min-height: 28px;
            margin: 0;
        }
    }
}
