/**
* East Coast Metal Distributors
*/


// ==============================================
// Current Store
// ==============================================

.header-store {
    display: flex;
    order: 8;
    align-items: center;
    margin: 0 0 0 $space-s;
    width: auto;
    color: $c-contrast;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store {
        margin: 0;
        width: auto;
    }
}

.header-store_wrapper {
    margin: 0 0 0 auto;
}

.header-store_dropdown.dropdown-content {
    background: $c-lightgray;
}


// ==============================================
// Current Store - Container
// ==============================================

.header-store_container {
    width: auto;
    display: block;
    background: transparent;
    border: none;
}


// ==============================================
// Current Store - Toggle
// ==============================================

.header-store_toggle {
    width: 100%;
    position: relative;

    @include lib-icon-font(
            $_icon-font-content: $icon-location,
            $_icon-font-size: 36px,
            $_icon-font-line-height: 36px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $c-white
    );
}

.header-store_toggle::before {
    color: $c-white;
    position: absolute;
    left: 0;
}

.header-store_toggle:after {
    margin-top: 5px;
}

@media (max-width: $bp-screen-m - 1px) {

    .header-store_toggle {
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        margin-right: 8px;
        font-size: $font-size-xxs;

        @include lib-icon-font(
                $_icon-font-content     : $icon-location,
                $_icon-font-size        : 30px,
                $_icon-font-line-height : 30px,
                $_icon-font-text-hide   : false,
                $_icon-font-position    : before,
                $_icon-font-display     : flex,
                $_icon-font-color       : $c-white
        );

        &.dropdown-trigger {
            display: flex;
        }

        &:before {
            position: static;
        }
    }

    .header-store_container .dropdown-trigger:after {
        content: none;
    }

    .header-store_dropdown {
        top: 21px;
    }

    .header-store_container .dropdown-content:before,
    .header-store_container .dropdown-content:after {
        border: none;
    }

}

.header-store_branch-name {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.header-store_toggle p {
    margin: 0;
    font-weight: 400;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_toggle p {
        display: none;
    }

    .header-store_toggle span {
        display: block;
        margin-top: 2px;
        font-size: 10px;
    }
}

@media (max-width: $bp-screen-m - 1px) {

    .header-store_toggle::before {
        left: auto;
        top: -15px;
    }

}

.header-store_toggle::after {
    position: absolute;
    right: 0;
    top: 14px;
}

@media (min-width: $bp-screen-m) {

    .header-store_toggle {
        @include _lib-icon-text-hide(true);
        padding: 0 5px 0 40px;
    }

}


// ==============================================
// Current Store - Dropdown
// ==============================================

.header-store_dropdown {
    display: none;
    color: $c-text;

    .switcher {
        padding: 20px;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_dropdown {
        right: 0;

        .branch {
            .store-information {
                > .dropdown-button {
                    display: none;
                }
            }

            > .dropdown-button {
                margin: 5px 0 0;

                .additional-actions-group {
                    > .button--secondary {
                        margin-left: 0;
                    }

                    .dropdown-trigger {
                        @include lib-icon-font(
                            $_icon-font-content: $icon-down,
                            $_icon-font-size: 22px,
                            $_icon-font-position: after,
                            $_icon-font-display: block
                        );
                    }
                }
            }
        }

        .switcher {
            height: 100vh;
            overflow-y: auto;
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .header-store_wrapper .dropdown {
        position: static;
    }

    .header-store_wrapper .dropdown-content {
        width: 100%;
        left: 0;
        right: 0;
        top: 44px;
    }
}

@media (min-width: $bp-screen-m) {
    .header-store_dropdown.dropdown-content {
        width: 430px;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_dropdown::after,
    .header-store_dropdown::before {
        border: none;
    }
}


// ==============================================
// Current Store - Name
// ==============================================

@media (min-width: $bp-screen-m) {
    .store-name {
        white-space: nowrap;
    }
}


// ==============================================
// Current Store - Hours
// ==============================================

.store-hours {
    padding: 10px 0;
}

.store-hours .open {
    color: $green-500;
}

.store-hours .closed {
    color: $c-red;
}


// ==============================================
// Current Store - Distance
// ==============================================

.store-distance {
    padding-bottom: $space-base;
}


// ==============================================
// Current Store - Address
// ==============================================

.store-address {
    padding: 10px 0 0;
}

// ==============================================
// Selected Branches
// ==============================================
.header-store_dropdown {
    .branch {
        background: $c-white;
        padding: $space-s 18px;
        border: 1px solid $c-gray;

        .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .dropdown-button {
            //display: flex;

            .additional-actions-group {
                margin-left: -1px;
                position: relative;

                .dropdown-trigger {
                    margin: 0;
                    min-width: 30px;
                    padding: 0;

                    &:after {
                        margin: 0 -4px 0 -3px;
                    }

                    &[aria-expanded=true] {
                        background: $c-header-background;
                        color: $c-white;

                        &:after {
                            transform: rotate(180deg);
                        }
                    }

                    &[aria-expanded=false] {
                        &:active,
                        &:focus {
                            background: $c-white;

                            &:hover {
                                background: $c-header-background;
                                color: $c-white;
                            }
                        }
                    }
                }

                .additional-options-dropdown {
                    background: $c-lightgray;
                    border: 1px solid $c-gray;
                    margin-top: 3px;
                    padding: 15px;
                    position: absolute;
                    right: 0;
                    z-index: 1;

                    .button--secondary {
                        background: $c-white;

                        &:hover {
                            background: $c-header-background;
                        }
                    }

                    .select-branch-button {
                        min-width: 100px;
                    }
                }
            }
        }

        .store-information > .select-branch-button {
            display: none;
        }

        & > .select-branch-button {
            display: block;
            margin-top: 10px;
        }
    }

    .selected-branches {
        .branch {
            margin-bottom: $space-xs;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .nearby-branches,
    .recent-branches {
        background-color: $c-white;
        border: 1px solid $c-gray;
        padding-top: $space-s;
        padding-left: 18px;
        padding-right: 18px;
        margin-top: $space-s;

        .branch {
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid $c-gray;
            padding-left: 0;
            padding-right: 0;

            &:last-child {
                border: 0;
            }
        }
    }

    .search {
        margin-top: $space-l;
    }
}

@media (min-width: $bp-screen-m) {
    .header-store_dropdown .branch {
        .store-information {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .dropdown-button {
                justify-content: flex-end;
            }

            .dropdown-button__primary {
                min-width: 130px;
            }

            & > .select-branch-button {
                display: block;
            }
        }

        > .dropdown-button,
        > .select-branch-button {
            display: none;
        }
    }
}
