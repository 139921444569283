// ==============================================
// Associate Account List
// ==============================================

.table--associate-account-list {
    .col--registration {
        width: 25px;
    }

    .col--registered {
        @include lib-icon-font(
            $icon-verified,
            $_icon-font-size: 18px,
            $_icon-font-color: $c-green,
            $_icon-font-color-hover: $c-green,
            $_icon-font-position: before,
            $_icon-font-vertical-align: top,
            $_icon-font-text-hide: true
        );

        display: table-cell;
    }
}

.row--associate:last-child {
    border-bottom: none;
}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-table-linearize - 1px) {
    .table--associate-account-list {
        .row--associate {
            display: grid;
            grid-template-columns: 25px auto;
            grid-template-rows: repeat(2, auto);
        }

        .col--registration {
            grid-row: 1 / 10;
            display: inline-flex;
            align-items: center;
            width: 100%;
        }

        .col--company-name {
            grid-column: 2;
        }
    }
}
