$box-shadow: $box-shadow-inset !default;

.toggle {
    display: inline-block;
}

.toggle__control {
    cursor: pointer;
}

.toggle__switch {
    display: inline-block;
    background: $c-gray-lighter;
    border-radius: 16px;
    width: 38px;
    height: 24px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s, opacity 0.25s;

    &:before {
        content: '';
        display: block;
        background: $c-white;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 2px;
        left: 2px;
        transition: transform 0.25s;
    }

    &:hover, &:focus {
        box-shadow: $box-shadow;
    }

    .toggle__checkbox:checked + & {
        background: $c-green-light;
        &:before {
            transform: translateX(14px);
        }
    }
}
.toggle__checkbox {
    position: absolute;
    visibility: hidden;

    &:disabled + {
        .toggle__switch {
            opacity: 0.5;
        }

        .toggle__label {
            color: $c-gray-lighter;
        }
    }
}

.toggle__label {
    transition: color 0.25s;
    margin-left: 5px;
    position: relative;
    top: 2px;
}