/**
* East Coast Metal Distributors
*/


// ==============================================
// My Credit Cards
// ==============================================

.my-credit-cards .status {
    font-style: italic;
}

.my-credit-cards .action.delete {
    @include lib-button-reset();
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status {
        display: none;

        &::before {
            display: none;
        }

        &._default {
            display: block;
        }
    }

    .my-credit-cards .card-type img {
        vertical-align: middle;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .my-credit-cards .card-type img {
        display: block;
    }

}


// ==============================================
// My Credit Cards Popup
// ==============================================

.my-credit-cards-popup .action.secondary {
    @include lib-button-reset();
    margin-right: $space-l;
}
