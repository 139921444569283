/**
* East Coast Metal Distributors
*/


// ==============================================
// Field Item
// ==============================================

.field-item {
    margin: $space-xs 0;
}

.field--inline .field-item {
    margin-left: $space-base;

    &:first-child {
        margin-left: 0;
    }
}
