/**
* East Coast Metal Distributors
*/

.products-grid .product-item-name,
.grid .product-item-name{
    font-size: $font-size-l;
    min-height: $line-height-base * $font-size-l * 4;
}

// ==============================================
// Vars
// ==============================================

$products-grid-gutter: $space-l;
$products-grid-padding: $space-base;
$products-grid-border-color: $c-border-light;


// ==============================================
// Products Grid
// ==============================================

.products-grid {
    clear: both;

    .product-item-actions {
        margin: $space-base 0 $space-xs 0;
    }
}


// ==============================================
// Products Grid - Product Items
// ==============================================

.products-grid .product-items {
    display: flex;
    flex-wrap: wrap;
    margin-top: ($products-grid-gutter * -1);
    margin-bottom: $gap;
    margin-left: ($products-grid-gutter * -1);
}


// ==============================================
// Products Grid - Product Item
// ==============================================

.products-grid .product-item {
    flex: 1 0 220px;
    background: $c-white;
    border: 1px solid $products-grid-border-color;
    margin-top: $products-grid-gutter;
    margin-left: $products-grid-gutter;
    padding: $products-grid-padding;
}

// ==============================================
// Products Grid - Product Photo
// ==============================================

.products-grid .product-item-photo  img {
    margin-bottom: $space-base;
}

// ==============================================
// Products Grid - Compare
// ==============================================

.products-grid .product-item-actions .tocompare {
    display: none;
}
