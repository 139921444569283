//
//  Tabs
//  _____________________________________________

@mixin lib-data-tabs__base(
    $_tab-control-height__base : $tab-control__height__base,
    $_title-selector : '.item.title',
    $_content-selector : '.item.content'
) {
    @include lib-clearfix;
      position: relative;

      > #{$_title-selector} {
        float: left;
        width: auto;
        height: $_tab-control-height__base;
        display: block;
        position: relative;
        z-index: 2;
        text-transform: uppercase;
        cursor: pointer;
      }

      > #{$_content-selector} {
        @include lib-clearfix;
        margin-top: $_tab-control-height__base;
        float: right;
        margin-left: -100%;
        width: 100%;
        border-radius: $radius-base;
        &.active {
            display: block;
        }
      }
}

@mixin lib-data-tabs(
    $_title-selector                          : '.item.title',
    $_content-selector                        : '.item.content',
    $_tab-control-font-family                 : $tab-control__font-family,
    $_tab-control-font-size                   : $tab-control__font-size,
    $_tab-control-font-style                  : $tab-control__font-style,
    $_tab-control-font-weight                 : $tab-control__font-weight,
    $_tab-control-line-height                 : $tab-control__line-height,

    $_tab-control-background-color            : $tab-control__background-color,
    $_tab-control-color                       : $tab-control__color,
    $_tab-control-text-decoration             : $tab-control__text-decoration,

    $_tab-control-color-visited               : $tab-control__color,
    $_tab-control-text-decoration-visited     : $tab-control__text-decoration,

    $_tab-control-background-color-hover      : $tab-control__hover__background-color,
    $_tab-control-color-hover                 : $tab-control__hover__color,
    $_tab-control-text-decoration-hover       : $tab-control__text-decoration,

    $_tab-control-background-color-active     : $tab-control__active__background-color,
    $_tab-control-border-bottom-color-active: $tab-control__active__border-bottom-color,
    $_tab-control-color-active                : $tab-control__active__color,
    $_tab-control-text-decoration-active      : $tab-control__text-decoration,

    $_tab-control-height                      : $tab-control__height,
    $_tab-control-margin-right                : $tab-control__margin-right,

    $_tab-control-padding-top                 : $tab-control__padding-top,
    $_tab-control-padding-right               : $tab-control__padding-right,
    $_tab-control-padding-bottom              : $tab-control__padding-bottom,
    $_tab-control-padding-left                : $tab-control__padding-left,

    $_tab-control-border-width                : $tab-control__border-width,
    $_tab-control-border-color                : $tab-control__border-color,

    $_tab-content-background-color            : $tab-content__background-color,
    $_tab-content-border                      : $tab-content__border,
    $_tab-content-border-top-status           : $tab-content__border-top-status,
    $_tab-content-margin-top                  : $tab-content__margin-top,

    $_tab-content-padding-top                 : $tab-content__padding-top,
    $_tab-content-padding-right               : $tab-content__padding-right,
    $_tab-content-padding-bottom              : $tab-content__padding-bottom,
    $_tab-content-padding-left                : $tab-content__padding-left
) {
    @include lib-data-tabs__base(
        $_title-selector: $_title-selector,
        $_content-selector: $_content-selector
    );
    > #{$_title-selector} {
        margin: 0 $_tab-control-margin-right 0 0;
        background: $_tab-control-background-color;
        height: $_tab-control-height;
        padding: $_tab-control-padding-top $_tab-control-padding-right $_tab-control-padding-bottom $_tab-control-padding-left;
        > .switch,
        > a {
            color: $_tab-control-color;
            font-size: $_tab-control-font-size;
            font-weight: $_tab-control-font-weight;
            font-style: $_tab-control-font-style;
            line-height: $_tab-control-line-height;
            text-decoration: $_tab-control-text-decoration;

            &:hover {
                color: $_tab-control-color-hover;
                text-decoration: $_tab-control-text-decoration-hover;
            }

            &:visited {
                color: $_tab-control-color-visited;
                text-decoration: $_tab-control-text-decoration-visited;
            }

            &:active {
                color: $_tab-control-color-active;
                text-decoration: $_tab-control-text-decoration-active;
            }
        }

        &:not(.disabled):focus,
        &:not(.disabled):hover {
            background: $_tab-control-background-color-hover;
            cursor: pointer;
            > .switch,
            > a {
                  color: $_tab-control-color-hover;
            }
        }

        &:not(.disabled):active,
        &.active,
        &.active:focus,
        &.active:hover {
            background: $_tab-control-background-color-active;
            &::after {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              top:  calc(#{$_tab-content-margin-top} - 2px);
              left: 0;
              background: $_tab-control-border-bottom-color-active;
              position: absolute;
            }
            > .switch,
            > a {
                color: $_tab-control-color-active;
            }
        }
    }

    > #{$_content-selector} {
        background: $_tab-content-background-color;
        margin-top: $_tab-content-margin-top;
        padding: $_tab-content-padding-top $_tab-content-padding-right $_tab-content-padding-bottom $_tab-content-padding-left;

        @include _lib-tab-content-border(
            $_tab-content-border-top-status: $_tab-content-border-top-status,
            $_tab-content-border: $_tab-content-border
        );
    }
}

@mixin _lib-tab-content-border(
    $_tab-content-border-top-status,
    $_tab-content-border
) {
    @if $_tab-content-border-top-status == true {
        border: 0;
        border-top: $_tab-content-border;
    }
    @else if $_tab-content-border-top-status == false {
        border: $_tab-content-border;
    }
}
