/**
* East Coast Metal Distributors
*/


// ==============================================
// Menu
// ==============================================

.menu {
    display: inline-block;
    position: relative;

    &::after {
        @include clearfix;
    }
}

// ---------------------------------------------
// State: Active

.menu.active {
    overflow: visible;
}


// ==============================================
// Menu - Toggle
// ==============================================

.menu_toggle {
    @include lib-icon-font(
        $_icon-font-content: $icon-arrow-dropdown,
        $_icon-font-position: 'after',
        $_icon-font-size: 24px,
        $_icon-font-line-height: 24px
    );

    &::after {
        margin-top: -2px;
        width: 18px;
    }
}

.menu_toggle:hover {
    text-decoration: none;
}


// ==============================================
// Menu - Content
// ==============================================

.menu_content {
    display: none;
    position: absolute;
    top: calc(100% + #{$space-xs});
    z-index: $z-index-2;
    width: auto;
    min-width: 200px;
    height: auto;
    margin-top: 0;
    border: 1px solid $c-border;
    background: $c-white;
    box-shadow: $box-shadow-base;
}

.menu--short .menu_content {
    max-height: 200px;
    overflow: auto;
}

.menu_content li {
    margin: 0;

    &:hover {
        background: $c-background;
        text-decoration: underline;
        cursor: pointer;
    }
}

.menu_content a {
    display: block;
    padding: $space-xs $space-base;
}

.menu_content a:hover {
    text-decoration: none;
}

// ---------------------------------------------
// Parent Modifier: Right (align content box to the right)

.menu--right .menu_content {
    right: 0;
}

// ----------------------------------------------
// Parent State: Active

.menu.active .menu_content {
    display: block;
}
