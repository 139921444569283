//
//  Button
//  _____________________________________________

.button {
    cursor: pointer!important;

    .pagebuilder-button-primary {
        padding: 0;
    }
}
