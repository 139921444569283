//
//  Slick slider styles
//  _____________________________________________

.slick-slider {
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-touch-action: pan-y;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}
.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }

    .product-inventory--limit-assistance {
        min-height: 91px;
    }
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    @include lib-icon-font(
            $_icon-font-content        : $icon-pointer-left,
            $_icon-font-vertical-align : middle,
            $_icon-font-margin         : 0 2px 0 0
        );
    left: 2rem;
}

.slick-next {
    @include lib-icon-font(
            $_icon-font-content        : $icon-pointer-right,
            $_icon-font-vertical-align : middle,
            $_icon-font-margin         : 0 0 0 2px
        );
    right: 2rem;
}

.slick-prev,
.slick-next {
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    &.slick-disabled {
        display: none !important;
    }


    &:hover {
        &.slick-disabled {
            display: none !important;
        }
        &:before {
            color: $gray-600;
        }
    }

    &:before,
    &:after {
        color: $gray-400;
        opacity: 1;
    }

    &:active,
    &:focus,
    &:not(.primary) {
        box-shadow: none;

        &:active {
            box-shadow: none;
        }
    }
}

.slick-dots {
    display: block;
    list-style: none;
    margin: 0;
    padding: 15px 0;
    text-align: center;
    width: 100%;
    outline: none;
    li {
        cursor: pointer;
        display: inline-block;
        margin: 0 10px;
        padding: 0;
        position: relative;
        vertical-align: middle;
        button {
            border: 0;
            border-bottom: 2px solid transparent;
            box-shadow: none;
            cursor: pointer;
            font-weight: bold;
            color: $gray-400;
            display: block;
            padding: 0 0 5px;
            transition: .3s;
            text-transform: uppercase;

            &:hover {
                border-color: $gray-400;
            }

            &:active,
            &:focus,
            &:not(.primary) {
                box-shadow: none;
            }
        }

        &.slick-active {
            button {
                color: $c-blue-dark;
                border-color: $c-blue-dark;
            }
        }
    }
}

div[data-content-type='slider'] {
    .slick-dots {
        bottom: -40px;
        padding-inline-start: 0;
    }
}

//  _____________________________________________
// Mobile

@media only screen and (max-width: $bp-hero - 1px) {

    .slick-dots {

        li {
            height: 14px;
            margin: 0 5px;
            width: 14px;
            button {
                background: $c-white;
                border: 1px solid $gray-800;
                border-radius: 10px;
                box-shadow: none;
                height: 14px;
                padding: 0;
                text-indent: -99999px;
                width: 14px;
            }

            &.slick-active {
                button {
                    background: $c-blue-dark;
                }
            }
        }
    }
}

//  _____________________________________________
// Desktop

@media only screen and (min-width: $bp-hero) {
    div[data-content-type='slider'] {
        .slick-dots {
            padding: 0;
            position: absolute;
            bottom: 0;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background: $c-gray-backgound;
                opacity: 0.8;
                backdrop-filter: blur(3px);
        }

        li {
            margin: 0;
            border: 2px solid $gray-400;
            border-width: 0 0 0 2px;
            width: auto;
            height: auto;

            &:first-child {
                border-left-width: 2px;
            }
            &:last-child {
                border-right-width: 2px;
            }
            &.slick-active {
                border-color: $gray-700;
                & + li {
                    border-left-color: $gray-700;
                }
            }
        }

        button {
            color: $gray-700;
            background: transparent;
            line-height: $space-base;
            padding: $space-s $space-xl;
            border: none;
            width: auto;
            height: auto;
            font-size: inherit;

            &:before {
                content: '';
            }
        }

        .slick-active button {
            background: $gray-700;
            color: $c-white;
        }
    }
}
}
