/**
* East Coast Metal Distributors
*/

$checkbox-size: 22px;

// ==============================================
// Checkbox
// ==============================================

[type='checkbox'] {
    margin-left: $space-xxs;
    align-self: baseline;
}


// ==============================================
// Checkbox + Label
// ==============================================

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        [type='checkbox'] {
            visibility: hidden;
        }

        [type='checkbox'] + label {
            display: inline-block;
        }
    }
}

[type='checkbox'] + label {
    position: relative;
    margin-left: -$checkbox-size;
    padding-left: 28px;
    cursor: pointer;

    &::before,
    &::after {
        position: absolute;
        transition:
            content .3s ease,
            background .3s ease,
            box-shadow .3s ease,
            transform .3s ease;
    }

    &::before {
        content: '';
        width: 45px;
        height: 45px;
        top: 49%;
        left: 9px;
        border-radius: 50%;
        background: $c-lightestblue;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
    }

    &::after {
        content: $icon-checkbox-outline;
        display: inline-block;
        top: -2px;
        left: 0;
        width: $checkbox-size;
        height: $checkbox-size;
        border-radius: $radius-base;
        background: $c-white;
        box-shadow: none;
        -moz-osx-font-smoothing: grayscale;
        font-size: $checkbox-size;
        font-family: $font-family-icon;
        background: $c-white;
        color: $gray-500;
        line-height: 18px;
        vertical-align: top;
    }
}

// ----------------------------------------------
// State: Checked

[type='checkbox']:checked + label,
[type='checkbox']:indeterminate + label {
    &::before {
        animation: choice-ripple 1s none;
        transform: translate(-50%, -50%) scale(1);
    }

    &::after {
        content: $icon-checkbox;
        color: $c-blue-light;
    }
}

[type='checkbox']:indeterminate + label {
    &::after {
        content: $icon-indeterminate;
        color: $c-blue-light;
    }
}

// ----------------------------------------------
// State: Disabled

[type='checkbox']:disabled + label::after {
    color: $gray-200;
}
