/**
* East Coast Metal Distributors
*/


// ----------------------------------------------

@use "sass:math";

@media (max-width: $bp-screen-m - 1px) {

    .footer-contact_separator {
        display: none;
    }

    .footer-contact_item,
    .footer-contact_label {
        display: block;
    }

    .footer-contact_label {
        font-size: $font-size-xs;
    }

}

// ==============================================
// Back Top
// ==============================================

.back-top {
    display: none;
    position: absolute;
    right: $space-l;
    top: math.div(50px, -2);
    width: 50px;
    height: 50px;
    margin-bottom: math.div(50px, -2);
    background: $c-blue-dark;
    border-radius: 50px;
    box-shadow: 0 1px 2px black(0.15);
    text-decoration: none;
    cursor: pointer;
    transition: $transition-all;
    z-index: 10;

    &:hover {
        transform: scale(1.1);
    }

    &::before {
        padding: 19px 10px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: $font-size-xxxll;
        line-height: 10px;
        color: $c-white;
        content: "";
        font-family: "Material Icons";
        margin: "";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
}


// ==============================================
// Footer Content
// ==============================================

// [todo] remove chaining
.footer.content {
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
    background: $c-gray-footer;

}


// ==============================================
// Footer Nav
// ==============================================

.footer-nav {

    [data-content-type='row'],
    [data-element='inner'] {
        width: 100%;
    }

    & > .widget.block-static-block,
    & [data-element='main'] {
        max-width: $bp-screen-l;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0 auto;
        border-bottom: 1px solid $c-border;
    }
}

.footer-nav_item {
    flex: 0 0 auto;
    margin: $gap auto;
}

.footer-nav_link {
    display: block;
    margin-bottom: 0.5em;

    &.link-opened {
        display: block;
    }
}

a.footer-nav_link {
    color: $c-white;

    &:hover {
        color: $c-gray-lighter;
    }
}

.footer-nav_heading,
.footer-nav_heading a {
    color: $c-white;
    font-weight: 600;
    font-size: 18px;
}

.social-link {
    margin-left: 20px;
    width: 160px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.social-link_item .social-link_background {
    fill-rule: evenodd;
    fill: $c-icon-base;
    transition: $transition-all;
}

.social-link_item:hover .social-link_background {
    fill: $c-icon-active;
    transition: $transition-all;
}

.social-link_icon {
    fill: $c-white;
    fill-rule: evenodd;
}

.app-link {
    width: 120px;
    margin-left: 20px;
    margin-top: $gap;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.app-link_item {
    width: 120px;
    height: 40px;
    margin-bottom: $space-l;
}

.app-link_item .app-link_color {
    fill: $c-gray-light;
    fill-rule: evenodd;
    transition: $transition-all;
}

.app-link_item:hover .app-link_color {
    fill: $c-gray-backgound;
    transition: $transition-all;
}

.toggle-mobile-links {
    display: none;
}

// ----------------------------------------------


@media (max-width: $bp-screen-m - 1px) {
    .footer-nav_item--social {
        flex: 1 0 100%;
        width: 100%;
    }

    .social-link,
    .app-link {
        margin: $gap auto 0;
    }

    .app-link {
        width: 300px;
    }
}

@media (max-width: $bp-screen-s - 1px) {

    .footer-nav_item {
        flex: 0 0 100%;
        margin: 0 auto;
    }

    .footer-nav_item--social {
        flex: 1 0 100%;
        width: 100%;
    }
}

@media (max-width: $bp-screen-xs - 1px) {

    .footer.content {
        padding: 0;
    }

    .footer-nav_link {
        display: none;
        line-height: 30px;
        padding-left: 15px;

        &.link-opened {
            display: block;
        }
    }


    .footer-nav_heading {
        position: relative;
        font-size: 18px;
        border-top: 1px solid $gray-400;
        padding: 10px 15px;
        margin: 0;

        @include lib-icon-font(
            $_icon-font-content: $icon-add,
            $_icon-font-size: 28px,
            $_icon-font-line-height: 30px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $gray-400
        );

        &::before {
            position: absolute;
            right: 25px;
            top: 8px;
        }

        &:last-child {
            border-bottom: 1px solid $gray-400;
        }

        &.footer-nav-opened {
            border-bottom: 1px solid $gray-400;
            margin-bottom: 8px;

            @include lib-icon-font(
                $_icon-font-content: $icon-minus,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 30px,
                $_icon-font-text-hide: false,
                $_icon-font-position: before,
                $_icon-font-display: block,
                $_icon-font-color: $gray-400
            );
        }
    }

    .toggle-mobile-links {
        position: relative;
        padding: 15px;
        cursor: pointer;
        display: block;
        text-decoration: none;
        font-size: 23px;
        color: $c-gray-backgound;

        @include lib-icon-font(
            $_icon-font-content: $icon-menu-open,
            $_icon-font-size: 26px,
            $_icon-font-line-height: 30px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $c-gray-backgound
        );

        &::before {
            position: absolute;
            left: 160px;
            top: 15px;
        }
    }
}


// ==============================================
// Footer Logo
// ==============================================

.footer-logo {
    padding: $gap 0;
}

.footer-logo_baker-fcs,
.footer-logo,
.footer-logo_ice-design {
    margin: 0 auto;
    height: auto;
    display: block;
}

.footer-logo {
    width: 200px;
}

.footer-logo_ice-design,
.footer-logo_baker-fcs {
    width: 200px;
}

// ==============================================
// Footer copyright
// ==============================================

.copyright {
    color: $c-white;

    ul {
        display: inline-block;
    }

    .inline-footer {
        display: inline;
        padding-left: 20px;

        a {
            color: $c-white;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {

    .copyright ul {
        margin-bottom: $space-s;
    }

}
