/**
* East Coast Metal Distributors
*/

.company-control .label {
    display: none;
}

.company-control .control {
    display: block;
}

.menu-top .company-control .control {
    margin-top: 0;
}

// ==============================================
// Account Selector
// ==============================================

@media (max-width: $bp-screen-m - 1px) {
    .menu-top .company-control {
        margin-top: 5px;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .company-control {
        max-width: 400px;
    }

    .menu-top .company-control {
        margin-top: 4px;
    }
}


// ==============================================
// Account Selector - Block
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .company-control .block {
        margin-bottom: 0;
    }

    .menu-top > .company-control select {
        color: $c-contrast-input-text;
        border-bottom-color: $c-contrast-input-border-bottom;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23e9e9e9' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");

        &:focus ~ label {
            color: $c-contrast-label-focus;
        }

        &:focus {
            border-bottom-color: $c-contrast-input-border-focus;
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {
    .company-control .block {
        display: flex;
        margin-bottom: 0;
    }
}
