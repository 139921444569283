//
//  Default appearance styles
//  _____________________________________________

@media only screen and (max-width: $bp-screen-m - 1px) {
    .pagebuilder-column-group,
    .pagebuilder-column-line {
        flex-wrap: wrap;
    }
}
