.link-dropdown {
    position: relative;
}

.link-dropdown__options {
    position: absolute;
    left: 0;
    top: 20px;
    background: $c-white;
    border: 1px solid $c-gray-footer;
    padding: 10px 5px;
}

.link-dropdown__link {
    padding: 0 10px 0 20px;
    font-weight: normal;
    line-height: 30px;

    &::before {
        content: '';
        width: 20px;
        height: 20px;
        background-image: $icon-z-a-sort;
        position: absolute;
        left: 5px;
        margin-top: 5px;
    }

    &--asc::before {
        background-image: $icon-a-z-sort;
    }
}

.link-dropdown__order {
    display: flex;
    align-items: center;
    line-height: 16px;
    color: $c-gray-footer;

    &:hover {
        color: $c-brand;
        text-decoration: underline;
    }

    &::after {
        background-image: $icon-double-arrow;
        display: inline-block;
        margin-left: 5px;
        width: 16px;
        height: 16px;
        content: '';
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .link-dropdown {
        width: auto;
    }
}
