//
//  Desktop
//  _____________________________________________

@media (min-width: $bp-screen-m) {

    .side-links {
        .proguides {
            margin-top: -3px;

            .proguides-a {
                @include lib-icon-font(
                    $_icon-font-content: $icon-pointer-down,
                    $_icon-font-size: 20px,
                    $_icon-font-line-height: 30px,
                    $_icon-font-text-hide: false,
                    $_icon-font-position: after,
                    $_icon-font-display: block,
                    $_icon-font-color: $c-white
                );
            }
        }
    }

    nav.proguides-opened {
        &.navigation {
            overflow: visible !important;

            .nav-menu {
                background: transparent;
            }
        }

        .side-links .proguides-a {
            &::after {
                content: $icon-pointer-up;
            }
        }

        .proguides-item {
            span {
                color: $c-black !important;
            }
        }

        .nav-proguides {
            position: absolute;
            top: 36px !important;
            left: -130px !important;
            max-width: 440px;
            height: calc( 100vh - 132px );
        }
    }
}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {
    .proguides {
        display: none !important;
    }
}
