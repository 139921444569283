
.card__container {
    position: relative;
    width: 100%;

    .card-logo__container {
        position: absolute;
        right: 0;
        top: -5px;
    }

    .card-tags__container {
        text-align: right;
        font-size: 12px;

        .default-card__item {
            @include lib-icon-font(
                    $_icon-font-content:      $icon-checkbox,
                    $_icon-font-size:         15px,
                    $_icon-font-line-height:  15px,
                    $_icon-font-color: $c-darkgray
            );
            display: block;

        }

        .shared-card__item {
            @include lib-icon-font(
                    $_icon-font-content:      $icon-checkbox-group,
                    $_icon-font-size:         15px,
                    $_icon-font-line-height:  15px,
                    $_icon-font-color: $c-darkgray
            );
            display: block;

        }

        .branch-card__item {
            @include lib-icon-font(
                    $_icon-font-content:      $icon-checkbox-pos,
                    $_icon-font-size:         15px,
                    $_icon-font-line-height:  15px,
                    $_icon-font-color: $c-darkgray
            );
            display: block;

        }
    }
}

.cards-container {
    .permissions-list__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-shrink: 0;

        li {
            width: 40%;
            flex-shrink: 0;
            flex-grow: 1;
        }

        .input__contaier {
            display: flex;
        }

        .field {
            width: 50%;
        }
    }

    .flat-button.cancel {
        margin-left: 10px;
    }

    .new-card__container {
        .no-border {
            position: relative;
            margin-bottom: 32px;
            background: #ffffff;
            background: none;

            .panel_body {
                padding-left: 0;
            }
        }
    }
}
#card-connect-banner-wrapper {
    cursor: pointer;
}