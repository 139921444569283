//
//  Default appearance styles
//  _____________________________________________

[data-content-type='button-item'] {
    max-width: 100%;
    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }
    [data-element='empty_link'] {
        cursor: default;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        @include flat-button;
        font-weight: 500;
        box-shadow: none;
        box-sizing: border-box;
        display: inline-block;
        text-decoration: none;
    }

    &.pagebuilder-button-primary {
        @include button;
    }

    &.pagebuilder-button-secondary {
        @include button-base;

        @include button-style (
            $background         : transparent,
            $background--hover  : $c-blue,
            $background--active : $c-blue,
            $color              : $c-blue
        );

        box-shadow: $button-box-shadow;
        line-height: 20px;
        border: 1px solid $c-blue;

        &:hover {
            color: $c-white;
        }
    }
}
