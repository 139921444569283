@media print {

    .page-wrapper {
        max-width: 960px;
        margin: 0 auto;
    }

    .page-maim,
    .columns {
        display: block;
    }

    .columns .column.main {
        max-width: 100%;
    }

    .button,
    .col--actions {
        display: none;
    }

    .content-wrapper,
    .account .content-wrapper {
        margin: 0;
        padding: 0;
    }

    // ----------------------------------------------
    // Tabs

    .tab__link {
        display: none;
    }

    .tab__content {
        margin: 0;
        border-color: $c-gray-light !important;
    }

    // ----------------------------------------------
    // Tables

    .toolbar {
        display: none;
    }

    .not-linearized-none {
        display: none !important;
    }

    .is-linearized-block {
        display: block !important;
    }

    // ----------------------------------------------
    // Pricing

    .no-price .hide-no-price {
        display: none;
    }
}
